import {Input} from "reactstrap";
import React, {useState} from "react";
import {useFilterDispatch, useFilterState} from "../../context/filter/FilterContext";
import {updateFilter} from "../../context/filter/FilterActions";
import FilterView from "./FilterView";

export default function FilterLogicView({filter, nestingLevel}) {
  const [isEditMode, setIsEditMode] = useState(false)
  const filters = useFilterState();
  const dispatch = useFilterDispatch();

  const mystyle = {
    display: "flex",
    boxShadow: "0 0 2rem 0 rgba(136, 152, 170, 0.15)",
    flexFlow: nestingLevel % 2 === 0 ? 'column wrap' : 'row wrap',
    padding: "20px",
    margin: "10px 10px 10px"

  };

  const update = (newFilter, filtersToLoop) => {
    return filtersToLoop.map((settedFilter) => {

      if (settedFilter.id === newFilter.id) {
        return newFilter
      } else if (settedFilter.hasOwnProperty('type')) {
        const newLeft = update(newFilter, [settedFilter.left])[0]
        const newRight = update(newFilter, [settedFilter.right])[0]

        return {left: newLeft, right: newRight, type: settedFilter.type}
      }

      return settedFilter
    })
  }

  const handleFilterTypeChange = (e) => {
    const newOperator = e.target.value
    const newFilter = {...filter, type: newOperator}
    const filtersNewSet = update(newFilter, filters.conditions)
    updateFilter(
      dispatch,
      {
        ...filters,
        conditions: filtersNewSet
      }
    )

    setIsEditMode(false)
  }

  const deleteFilter = (filterToDel, filtersToLoop) => {
    return filtersToLoop.map((filter) => {
      if (filter.id !== filterToDel.id && filter.hasOwnProperty('type')) {
        const left = deleteFilter(filterToDel, [filter.left])
        const right = deleteFilter(filterToDel, [filter.right])
        return {left: left[0], right: right[0], type: filter.type}
      } else if (filter.id !== filterToDel.id) {
        return filter
      } else if (filter.id === filterToDel.id) {
        return {id: filter.id}
      }
    })
  }

  const cleanUp = (filtersToLoop) => {
    return filtersToLoop.map((el) => {
      if (el !== undefined) {
        if (el.hasOwnProperty('type')) {
          const tmpFilter = {left: cleanUp([el.left])[0], right: cleanUp([el.right])[0], type: el.type}
          if (tmpFilter.left === undefined) {
            return el.right
          } else if (tmpFilter.right === undefined) {
            return el.left
          }
        }
        return el
      }
    })
  }

  const handleDelete = (filterToDel) => {
    const result = deleteFilter(filterToDel, filters.conditions)
    const filtered = cleanUp(result)
    updateFilter(
      dispatch,
      {
        ...filters,
        conditions: filtered
      }
    )
    setIsEditMode(false)
  }

  const deleteStyle = {
    justifyContent: 'space-around',
    display: 'flex',
    flexDirection: 'column'
  }

  const logicSideStyle = {
    display: 'flex',
    flexDirection: nestingLevel % 2 === 0 ? 'column' : 'row'
  }

  return (
    <div className={'filter-container__logic'} style={mystyle}>
      <div className="d-flex flex-row" onClick={() => setIsEditMode(true)}><span
        className={'fa-solid fa-pen-to-square pencil-button -cursor-pointer'}></span></div>
      <div className={'filter-container__logic--left d-flex flex-row'} style={logicSideStyle}>
        {isEditMode &&
          <div style={deleteStyle} onClick={() => handleDelete(filter)}><span className="fa-solid fa-trash-alt"></span>
          </div>}
        <FilterView filter={filter.left} key={filter.left.id + 'view'} nestingLevel={nestingLevel + 1}/>
      </div>
      {isEditMode ? <Input type="select" name="operator" id="logicType" className={'form-control'}
                           onChange={handleFilterTypeChange}>
        <option disabled selected>SELECT AN OPERATOR</option>
        <option value={"OR"}>OR</option>
        <option value={"AND"}>AND</option>

      </Input> : <div className={'filter-container__logic--type'}> {filter.type} </div>}
      <div className={'filter-container__logic--right'} style={logicSideStyle}><FilterView filter={filter.right}
                                                                                           key={filter.right.id + 'view'}
                                                                                           nestingLevel={nestingLevel + 1}/>
      </div>
    </div>
  );
}
