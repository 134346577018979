import {Link} from "react-router-dom";
import {Button, Card, CardBody, CardFooter, CardHeader, FormGroup, Input} from "reactstrap";
import React, {useEffect, useState} from "react";
import {DataSourceStepButtonGroup} from "../DataSourceStepButtonGroup/DataSourceStepButtonGroup";
import {DataSourceFeedTypeSelector} from "../DataSourceSettings/DataSourceFeedTypeSelector";
import {useProjectState} from "../../../context/project/ProjectIndex";
import {DataSources} from "../../../services/api/DataSources";
import {apiErrorRaised} from "../../../context/api_error/ApiErrorActions";
import {useApiErrorDispatch} from "../../../context/api_error/ApiErrorContext";
import {v4 as uuid4} from "uuid";
import {useNavigate} from "react-router";
import classNames from "classnames";
import {DataSourceXMLNodes} from "../DataSourceSettings/DataSourceXMLNodes";

export const DataSourceNewFeed = () => {
  const navigate = useNavigate()
  const projectState = useProjectState()
  const dispatch = useApiErrorDispatch();
  const [type, setType] = useState('')
  const [name, setName] = useState('')
  const [url, setUrl] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [combineField, setCombineField] = useState('')
  const [isValidUrl, setIsValidUrl] = useState(true)
  const [isCheckingUrl, setCheckingUrl] = useState(false)
  const [xmlNodes, setXmlNodes] = useState({})
  const [submitted, setSubmitted] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [selectedNode, setSelectedNode] = useState('')
  const [order, setOrder] = useState(1)

  useEffect(() => {
    DataSources.index(projectState.companyId, projectState.projectId).then((r) => {
      if (r?.status < 400) {
        let order = 1;
        if (r.data.length > 0) {
          order = Math.max.apply(Math, r.data.map(function (dataSource) {
            return dataSource.order;
          })) + 1
        }
        setOrder(order)
      } else {
        apiErrorRaised(dispatch, r?.response)
      }
    })
  }, [])

  const handleNext = () => {
    setSubmitted(true)
    if (name) {
      let id = uuid4()
      const importProperties = []
      if (type === 'xml') {
        importProperties.push({name: 'key', value: selectedNode})
      }

      let data = {
        name: name,
        url: url,
        mapping: [],
        dynamicProperties: [],
        type: 'Cyberclick\\Optimizer\\DataSource\\Domain\\Feed\\Feed',
        order: order,
        locale: projectState.locale,
        status: 0,
        combineField: combineField === '' ? null : combineField,
        importProperties: importProperties,
        data: {
          extension: type,
          credentials: null,
        }
      }

      if (username && password) {
        data.data.credentials = {}
        data.data.credentials.username = username
        data.data.credentials.password = password
      }

      setLoading(true);
      DataSources.put(projectState.companyId, projectState.projectId, id, data).then((r) => {
        setLoading(false);
        if (r?.status < 400) {
          navigate(`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/data_sources/${id}/mapping`)
        } else {
          apiErrorRaised(dispatch, r?.response)
        }
      });
    }
  }

  const handleNameChange = (e) => {
    const {value} = e.target;
    setName(value);
  }

  const handleUsernameChange = (e) => {
    const {value} = e.target;
    setUsername(value);
  }
  const handlePasswordChange = (e) => {
    const {value} = e.target;
    setPassword(value);
  }

  const handleCombieFieldChange = (e) => {
    const {value} = e.target;
    setCombineField(value);
  }

  const handleUrlChange = (e) => {
    const {value} = e.target;
    setIsValidUrl(false)
    setUrl(value);
  }

  const handleValidateUrl = () => {
    if (isValidUrl) {
      return
    }
    let params = {
      url: url,
    }

    if (username && password) {
      params.username = username
      params.password = password
    }

    setCheckingUrl(true)
    DataSources.getXMLStructure(projectState.companyId, projectState.projectId, params).then((r) => {
      setLoading(false);
      setCheckingUrl(false)
      if (r?.status < 400) {
        setIsValidUrl(true)
        setXmlNodes(r.data)
      } else {
        apiErrorRaised(dispatch, r?.response)
      }
    });
  }

  const handleTypeChange = (type) => {
    setIsValidUrl(false)
    setType(type)
  }

  return <>
    <div className={'d-flex flex-column'}>
      <div className={'d-flex flex-row justify-content-between'}>
        <div className={'d-flex flex-row'}>
          <Link
            to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/data_sources/new/select_type`}>
            <Button className={'btn-icon-only text-dark'}>
              <span className={'fa fa-arrow-left'}/>
            </Button>
          </Link>
          <h1 className={'ms-4'}>New</h1>
        </div>
        <DataSourceStepButtonGroup step={'settings'}/>
      </div>
      <div className={'mt-4'}>
        <Card className={'flex-grow-1 d-flex flex-column'}>
          <DataSourceFeedTypeSelector typeValue={type} handleTypeChange={handleTypeChange}/>
          <div className={'d-flex flex-row '}>
            <div className={'d-flex flex-column flex-basis-50'}>
              <div className={'ms-5 me-5 d-flex flex-row'}>
                <FormGroup
                  className="mb-3 flex-grow-1">
                  <label
                    className="form-control-label"
                  >
                    Name
                  </label>
                  <Input
                    placeholder="Name"
                    type="text"
                    valid={submitted && name.length > 0}
                    invalid={submitted && !name}
                    name="name"
                    value={name}
                    onChange={handleNameChange}
                  />
                  <div className="invalid-feedback">
                    Please provide a name
                  </div>
                </FormGroup>
              </div>
              <div className={'ms-5 me-5 d-flex flex-row'}>
                <FormGroup
                  className="mb-3  flex-grow-1">
                  <label
                    className="form-control-label"
                  >
                    Url
                  </label>
                  <Input
                    placeholder="https://mycompany.com/myfeed"
                    type="text"
                    valid={submitted && url.length > 0}
                    invalid={submitted && !url}
                    name="url"
                    value={url}
                    onChange={handleUrlChange}
                  />
                  <div className="invalid-feedback">
                    Please provide a valid url
                  </div>
                </FormGroup>
                {
                  type === 'xml' ? <div className={'d-flex flex-column justify-content-center'}>
                    <Button color={isValidUrl ? 'success' : 'primary'} className={'btn-icon ms-2'}
                            onClick={handleValidateUrl}>
                  <span className={classNames({
                    'fa fa-2x fa-solid me-2': true,
                    'fa-arrows-rotate': !isValidUrl && !isCheckingUrl,
                    'fa-check': isValidUrl && !isCheckingUrl
                  })}/>
                      {
                        isCheckingUrl === true ? <span className="spinner-border spinner-border-lg me-1"/> : <></>
                      }
                      {
                        isValidUrl ? 'Valid' : 'Validate'
                      }
                    </Button>
                  </div> : <></>
                }
              </div>
              {
                type === 'xml' && isValidUrl ? <div className={'d-flex flex-column ms-5 me-5'}>
                  <div className={'d-flex flex-row'}>
                    <Card>
                      <CardHeader>
                        <h2>Select your XML Node</h2>
                      </CardHeader>
                      <CardBody>
                        <DataSourceXMLNodes xml={xmlNodes} setSelectedNode={setSelectedNode}/>
                      </CardBody>
                    </Card>
                  </div>
                </div> : <></>
              }
              {
                order !== 1 ?
                  <div className={'d-flex flex-row ms-5 me-5'}>
                    <FormGroup
                      className="mb-3  flex-grow-1">
                      <label
                        className="form-control-label"
                      >
                        Combine Field
                      </label>
                      <Input
                        placeholder="Field"
                        type="text"
                        name="combineField"
                        value={combineField}
                        onChange={handleCombieFieldChange}
                      /> </FormGroup>
                  </div> : <></>
              }
            </div>
            <div className={'d-flex flex-column flex-basis-50'}>
              <div className={'ms-5 me-5 d-flex flex-row'}>
                <FormGroup
                  className="mb-3 flex-grow-1">
                  <label
                    className="form-control-label"
                  >
                    Username
                  </label>
                  <Input
                    placeholder="Username"
                    type="text"
                    name="username"
                    value={username}
                    onChange={handleUsernameChange}
                  />
                </FormGroup>
              </div>
              <div className={'ms-5 me-5 d-flex flex-row'}>
                <FormGroup
                  className="mb-3 flex-grow-1">
                  <label
                    className="form-control-label"
                  >
                    Password
                  </label>
                  <Input
                    placeholder="Password"
                    type="password"
                    name="password"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                </FormGroup>
              </div>
            </div>
          </div>
          <CardFooter>
            <div className={'d-flex flex-row justify-content-end'}>
              <Button color="primary" type="button" disabled={!isValidUrl && type === 'xml'}
                      onClick={handleNext}>
                Next
              </Button>
            </div>
          </CardFooter>
        </Card>
      </div>
    </div>
  </>
}
