export const objectSelect = (canvas, id) => {
  return {
    ...canvas,
    selectedObject: canvas.objects.find(canvasObject => canvasObject.object.id === id),
    objects: canvas.objects.map((canvasObject) => {
      return {
        ...canvasObject,
        selected: canvasObject.object.id === id
      }
    })
  }
};
