import {Link} from "react-router-dom";
import {Button, Card, CardFooter} from "reactstrap";
import React, {useState} from "react";
import {DataSourceStepButtonGroup} from "../DataSourceStepButtonGroup/DataSourceStepButtonGroup";
import {useProjectState} from "../../../context/project/ProjectIndex";
import {useNavigate} from "react-router";
import {DataSourceTypeSelector} from "../DataSourceSettings/DataSourceTypeSelector";

export const DataSourceNewSelect = () => {
  const navigate = useNavigate()
  const projectState = useProjectState()
  const [type, setType] = useState('')

  const handleNext = () => {
    switch (type){
      case 'feed':
        navigate(`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/data_sources/new/feed`)
        break;
      case 'connection':
        navigate(`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/data_sources/new/api_connection`)
        break;
    }
  }

  const handleTypeChange = (type) => {
    setType(type)
  }

  return <>
    <div className={'d-flex flex-column'}>
      <div className={'d-flex flex-row justify-content-between'}>
        <div className={'d-flex flex-row'}>
          <Link
            to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/data_sources/`}>
            <Button className={'btn-icon-only text-dark'}>
              <span className={'fa fa-arrow-left'}/>
            </Button>
          </Link>
          <h1 className={'ms-4'}>Select the data source type</h1>
        </div>
        <DataSourceStepButtonGroup step={'settings'}/>
      </div>
      <div className={'mt-4'}>
        <Card className={'flex-grow-1 d-flex flex-column'}>
          <DataSourceTypeSelector typeValue={type} handleTypeChange={handleTypeChange}/>
          <CardFooter>
            <div className={'d-flex flex-row justify-content-end'}>
              <Button color="primary" type="button"
                      onClick={handleNext}>
                Next
              </Button>
            </div>
          </CardFooter>
        </Card>
      </div>
    </div>
  </>
}
