import {get, post, put} from "./config/base";
import {handleError, handleResponse} from "./config/response";
import {urlParser} from "./config/utils";

const url = 'auth/token/';
const urlSignUp = 'auth/users/{id}'
const urlActivation = '/auth/users/{userId}/activate/{confirmation}'
const urlResetPassword = '/auth/users/{userId}/reset_password/{confirmation}'

export const Auth = {
  token: (payload) =>
    post(`${url}`, payload)
      .then(handleResponse)
      .catch(handleError),
  signUp: (id, payload) =>
    put(`${urlParser(urlSignUp, '', '', id)}`, payload)
      .then(handleResponse)
      .catch(handleError),
  get: () =>
    get(`/auth/users/profile`)
      .then(handleResponse)
      .catch(handleError),
  put: (payload) =>
    put(`/auth/users/profile`, payload)
      .then(handleResponse)
      .catch(handleError),
  activate: (userId, confirmation) =>
    get(urlActivation
      .replace('{userId}', userId)
      .replace('{confirmation}', confirmation))
      .then(handleResponse)
      .catch(handleError),
  resetPassword: (userId, confirmation, payload) =>
    put(urlResetPassword
        .replace('{userId}', userId)
        .replace('{confirmation}', confirmation),
      payload)
      .then(handleResponse)
      .catch(handleError),
  resetPasswordRequest: (payload) =>
    put(`/auth/users/reset_password_request`, payload)
      .then(handleResponse)
      .catch(handleError),
}
