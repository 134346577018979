import React, {useState} from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Row,} from "reactstrap";
// core components
import {v4 as uuid4} from 'uuid';
import {Auth} from "../../services/api/Auth";
import {useNavigate, useParams} from "react-router-dom";
import AuthHeader from "../Headers/AuthHeader";
import {useTranslation} from "react-i18next";

const SignUp = () => {
  const routerParams = useParams()
  const navigate = useNavigate();
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [loggingIn, setLoggingIn] = useState(false)
  const [errorResponse, setErrorResponse] = useState('')
  const {t} = useTranslation('auth')
  const [inputs, setInputs] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });

  const [submitted, setSubmitted] = useState(false);
  const {email, password, confirmPassword} = inputs;

  const parseErrors = (errors) => {
    let response = '';
    for (const [key, value] of Object.entries(errors)) {
      response += `${key}: ${value}`;
    }

    return response;
  }

  function handleChange(e) {
    const {name, value} = e.target;
    setInputs(inputs => ({...inputs, [name]: value}));
  }

  const handlSubmitClick = (e) => {
    e.preventDefault();

    setSubmitted(true);
    if (!email || !password || !confirmPassword) {
      return;
    }

    if (password !== confirmPassword) {
      return;
    }

    const payload = {
      "email": email,
      "password": password,
      "role": "user",
    }
    if (routerParams.invitation !== undefined) {
      payload.invitation = routerParams.invitation;
    }
    let id = uuid4();
    Auth.signUp(id, payload).then((r) => {
      setLoggingIn(false);
      if (r?.status < 400) {
        navigate('/auth/register/thanks');
      } else {
        setErrorResponse(parseErrors(r.response.data));
      }
    })
  }

  return (
    <>
      <AuthHeader
        title={t('SIGN_UP')}
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className="bg-white border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                </div>
                <Form role="form">
                  <FormGroup
                    className={classnames({
                      focused: focusedEmail,
                    })}
                  >
                    <label
                      className="form-control-label"
                    >
                      Email
                    </label>
                    <Input
                      placeholder="Email"
                      valid={submitted && email.length > 0}
                      invalid={submitted && !email}
                      type="email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      onFocus={() => setfocusedEmail(true)}
                      onBlur={() => setfocusedEmail(true)}
                    />
                    <div className="invalid-feedback">
                      {t('ERROR_EMAIL')}
                    </div>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <label
                      className="form-control-label"
                    >
                      Password
                    </label>
                    <Input
                      valid={submitted && password.length > 0}
                      invalid={submitted && !password}
                      placeholder="Password"
                      type="password"
                      name="password"
                      value={password}
                      onChange={handleChange}
                      onFocus={() => setfocusedPassword(true)}
                      onBlur={() => setfocusedPassword(true)}
                    />
                    <div className="invalid-feedback">
                      {t('ERROR_PASSWORD')}
                    </div>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <label
                      className="form-control-label"
                    >
                      {t('CONFIRM_PASSWORD')}
                    </label>
                    <Input
                      valid={submitted && confirmPassword.length > 0 && (password === confirmPassword)}
                      invalid={(submitted && !password) || (submitted && password !== confirmPassword)}
                      placeholder="Password"
                      type="password"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={handleChange}
                      onFocus={() => setfocusedPassword(true)}
                      onBlur={() => setfocusedPassword(true)}
                    />
                    <div className="invalid-feedback">
                      {t('ERROR_PASSWORD_MISMATCH')}
                    </div>
                  </FormGroup>
                  <div className="text-danger">{errorResponse}</div>
                  <div className="text-center">
                    <Button className="mt-4" color="primary" type="button"
                            onClick={handlSubmitClick}
                    >
                      {loggingIn &&
                        <span className="spinner-border spinner-border-sm me-1"/>}
                      {t('SIGN_UP')}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default SignUp;
