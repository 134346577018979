import React, {useEffect, useState} from "react";
import {Companies} from "../../services/api/Companies";
import {apiErrorRaised} from "../../context/api_error/ApiErrorActions";
import {useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {Button, Input, Row} from "reactstrap";
import CompanyItem from "./CompanyItem";
import CompanyNewModal from "../../views/pages/companies/CompanyNewModal";
import Paginator from "../Pagination/Paginator";
import {PlaceholderGrid} from "../PlaceholderGrid/PlaceholderGrid";

export const CompaniesList = ({}) => {
  const [companies, setCompanies] = useState([])
  const [isLoading, setLoading] = useState(false)
  const dispatchApiError = useApiErrorDispatch()
  const [showModal, setShowModal] = useState(false);
  const [createdCompany, setCreatedCompany] = useState(false);
  const [pagination, setPagination] = useState({})
  const [searchTerm, setSearchTerm] = useState('')
  const [filters, setFilters] = useState([])
  const [debounceTimer, setDebounceTimer] = useState(null);

  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(12)
  const handleClickCreate = () => {
    setShowModal(true);
  }

  useEffect(() => {
    loadCompanies(limit, offset)
  }, [limit, offset, filters])

  const loadCompanies = () => {
    setLoading(true)
    Companies.index(limit, offset, filters).then((r) => {
      setLoading(false)
      if (r?.status < 400) {
        setCompanies(r?.data.companies)
        setPagination(r?.data.pagination)
      } else {
        setLoading(false)
        apiErrorRaised(dispatchApiError, r?.response)
      }
    })
  }

  const handleClick = (e, offset, limit) => {
    e.preventDefault();
    setLimit(limit)
    setOffset(offset)
  }

  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    clearTimeout(debounceTimer);
    const newTimer = setTimeout(() => {
      setFilters([{field: 'name', operator: 'CONTAINS', value: term}])
      setOffset(0)
    }, 500);
    setDebounceTimer(newTimer);
  };

  return (<>
    <div className={'view_header'}>
      <div className={'title'}>
        <h1>Welcome</h1>
      </div>
      <div className={'button_actions'}>
        <div className={'d-flex me-2'}>
          <Input
            placeholder="Search"
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <Button color={'primary'} onClick={handleClickCreate}>Create Company</Button>
      </div>
    </div>
    <Row className={'mt-3'}>
      {isLoading ? <PlaceholderGrid/> : companies.map((company, id) => {
        return (<CompanyItem
          key={id}
          company={company}
        />)
      })}
    </Row>
    <div className={'mt-3 mb-3'}>
      <Paginator
        pagination={pagination}
        handleClick={handleClick}
        offset={offset}
        limit={limit}
        showRowOptions={false}
      />
    </div>
    <CompanyNewModal
      showModal={showModal}
      setShowModal={setShowModal}
      setCreatedCompany={setCreatedCompany}
    />
  </>)
}
