import React, {useState} from "react";
import {Button, Col, Row} from "reactstrap";
import {FilterProvider} from "../../context/filter/FilterIndex";
import Filters from "../Filters/Filters";

export default function Conditions({conditions, finishEditCallback}) {
  const [isEditing, setEditing] = useState(false)

  const handleChange = (conditions) => {
    setEditing(false)
    finishEditCallback(conditions)
  }

  return (
    <div className="d-flex flex-row gap-4 py-3 align-items-center">
      <div>
        <p className="mt-4">
          Conditions:
        </p>
      </div>
      <div>
        {
          isEditing ?
            <FilterProvider>
              <Filters
                conditions={conditions}
                finishEditCallback={handleChange}
              />
            </FilterProvider> :
            <pre className="overflow-container">
              {JSON.stringify(conditions, null, 2)}
            </pre>
        }
      </div>
      <div>
        <Button color={'primary'} className="btn-icon-only btn btn-primary"
          size={"sm"}
          onClick={() => {
            setEditing(!isEditing)
          }}
        >
          {
            isEditing ? <i className={"ni ni-bold-up"}/> : <i className={"ni ni-bold-down"}/>
          }
        </Button>
      </div>
    </div>
  )
}
