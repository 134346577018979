import React, {useCallback} from 'react'
import {FeedExportRuleItem} from "./FeedExportRuleItem";
import {Card, CardBody, CardHeader} from "reactstrap";
import {load, useRulesDispatch, useRulesState} from "../../../context/rules/RulesIndex";
import {FeedExportRuleDeleteButton} from "./FeedExportRuleDeleteButton";
import {FeedExportRuleAddButton} from "./FeedExportRuleAddButton";
import {closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from "@dnd-kit/sortable";

export const FeedExportRulesList = ({ruleGroupId}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const rulesState = useRulesState()
  const rulesDispatch = useRulesDispatch()

  const handleDragEnd = useCallback((event) => {
    const {active, over} = event;
    if (active.id !== over.id) {
      const oldIndex = rulesState.rules.findIndex(prop => prop.id === active.id);
      const newIndex = rulesState.rules.findIndex(prop => prop.id === over.id);

      const newRules = arrayMove(rulesState.rules, oldIndex, newIndex);
      load(
        rulesDispatch,
        newRules
      );
    }
  }, [rulesState.rules])

  return (
    <>
      <Card>
        <CardHeader>
          Rules
        </CardHeader>
        <div className={'feed_export_rules_box d-flex flex-column'}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={rulesState.rules}
              strategy={verticalListSortingStrategy}
            >
              {
                rulesState.rules && rulesState?.rules.map((rule, key) => {
                  if (rule.status === 'DELETED') {
                    return <></>
                  }
                  return (
                    <FeedExportRuleItem
                      rule={rule}
                      key={key}
                      id={rule.id}
                      index={key}
                    />
                  )
                })
              }
            </SortableContext>
          </DndContext>
        </div>
        <CardBody>
          <div className={'d-flex flex-row justify-content-between'}>
            <FeedExportRuleAddButton ruleGroupId={ruleGroupId}/>
            <FeedExportRuleDeleteButton/>
          </div>
        </CardBody>
      </Card>
    </>
  )
}
