import React, {useEffect} from "react";
import {Container} from "reactstrap";
import {ShopifyConnectionList} from "../../../components/Connection/ShopifyConnectionList";
import {ImportProvider} from "../../../components/Import/Context/ImportIndex";
import {clearProjectState} from "../../../context/project/ProjectActions";
import {useProjectDispatch} from "../../../context/project/ProjectContext";

export const ShopifyConnectionCreateView = () => {
  const projectDispatch = useProjectDispatch()

  useEffect(() => {
    clearProjectState(projectDispatch)
  }, []);

  return (
    <>
      <Container className="mt-5">
        <ImportProvider>
          <ShopifyConnectionList/>
        </ImportProvider>
      </Container>
    </>
  )
}
