import React, {useState} from "react";
import {AnalyticsChart} from "./AnalyticsChart";
import {AnalyticsTable} from "./AnalyticsTable/AnalyticsTable";
import {useProjectState} from "../../context/project/ProjectIndex";
import {DateSelector} from "./DateSelector";
import {Card} from "reactstrap";

export const AnalyticsDashboard = () => {
  const projectState = useProjectState()
  const [isLoading, setLoading] = useState(false)

  return (
    <>
      <div className={'d-flex flex-column'}>
        <div className={'view_header d-flex flex-row mb-3'}>
          <div className={'title'}>
            <h1>Data History</h1>
          </div>
          <div className={'date-selector'}>
            {
              projectState.features?.data_history ? <DateSelector isLoading={isLoading}/> : <></>
            }
          </div>
        </div>
        {
          projectState.features?.data_history ? <>
            <div>
              <AnalyticsChart isLoading={isLoading} setLoading={setLoading}/>
            </div>
            <div>
              <AnalyticsTable/>
            </div>
          </> : <>
            <Card className={'p-5'}>
              <h1>This company has no access to this feature</h1>
            </Card>
          </>
        }
      </div>
    </>
  )
}
