import React from "react";
import {FeedExportImportRulesCompaniesList} from "./FeedExportImportRulesCompaniesList";
import {FeedExportImportRulesProjectsList} from "./FeedExportImportRulesProjectsList";
import {FeedExportImportRulesFeedExportsList} from "./FeedExportImportRulesFeedExportsList";
import {FeedExportImportRulesList} from "./FeedExportImportRulesList";
import {TableFiltersProvider} from "../../../../context/table_filters/TableFiltersIndex";

export const FeedExportImportRules = () => {
  return (
    <>
      <div className={'flex-basis-20 d-flex flex-column'}>
        <FeedExportImportRulesCompaniesList/>
      </div>
      <div className={'flex-basis-30 d-flex flex-column'}>
        <FeedExportImportRulesProjectsList/>
      </div>
      <div className={'flex-basis-30 d-flex flex-column'}>
        <FeedExportImportRulesFeedExportsList/>
      </div>
      <div className={'flex-grow-1 d-flex flex-column'}>
        <TableFiltersProvider>
          <FeedExportImportRulesList/>
        </TableFiltersProvider>
      </div>
    </>
  )
}
