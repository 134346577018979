import Select from "react-select";
import React from "react";

export const ConnectionMarketIdSelector = ({value, handleChange, options, disabled}) => {
  return (
    <>
      <label
        className="form-control-label"
      >
        Market name
      </label>
      <div className={'d-flex flex-row'}>
        <Select className={'flex-basis-50'}
                value={value}
                onChange={handleChange}
                options={options}
                isDisabled={disabled}
                placeholder={'Select an option'}
        />
      </div>
    </>
  )
}
