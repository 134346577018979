export const initialState = localStorage.getItem('rules')
  ? JSON.parse(localStorage.getItem('rules')) : {
    rules: [],
    selected: {}
  };

const save = (state) => {
  localStorage.setItem('rules', JSON.stringify(state));
};

export const RulesReducer = (initialState, action) => {
  let index
  switch (action.type) {
    case "LOAD":
      initialState = {
        ...initialState,
        rules: action.payload,
        selected: action.payload[0]
      }
      break
    case 'DELETE_RULE':
      index = initialState.rules.findIndex(rule => rule.id === initialState.selected.id)
      initialState = {
        ...initialState,
        rules: [
          ...initialState.rules.slice(0, index),
          action.payload,
          ...initialState.rules.slice(index + 1),
        ],
        selected: initialState.rules[0],
      }
      break;
    case 'CLEAR_RULES':
      initialState = {
        ...initialState,
        rules: [],
        selected: {}
      }
      break;
    case "SELECT_RULE":
      initialState = {
        ...initialState,
        selected: initialState.rules.find(rule => rule.id === action.payload),
      }
      break;
    case "UPDATE_RULE":
      index = initialState.rules.findIndex(prop => prop.id === action.payload.id)
      if (index > -1) {
        initialState = {
          ...initialState,
          rules: [
            ...initialState.rules.slice(0, index),
            action.payload,
            ...initialState.rules.slice(index + 1)
          ],
          selected: action.payload,
        }
      }
      break;
    case "ADD_RULE":
      initialState = {
        ...initialState,
        rules: [
          ...initialState.rules,
          action.payload
        ],
        selected: action.payload
      }
      break;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
  save(initialState);
  return initialState
};
