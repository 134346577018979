import React, {useEffect, useState} from 'react'
import {Button} from "reactstrap";
import {RuleGroups} from "../../services/api/RuleGroups";
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {RuleGroupItem} from "./RuleGroupItem";
import {RuleGroupNewModal} from "./RuleGroupNewModal";
import {RuleGroupImportModal} from "./RuleGroupImportModal";
import {PlaceholderTable} from "../PlaceholderTable/PlaceholderTable";
import {ImportProvider} from "../Import/Context/ImportIndex";

export const RuleGroupsList = ({companyId, projectId}) => {
  const apiErrorDispatch = useApiErrorDispatch()
  const [isLoading, setLoading] = useState(false)
  const [ruleGroups, setRuleGroups] = useState([])
  const [isFiltersCollapsed, setFiltersCollapsed] = useState(true)
  const [showModal, setShowModal] = useState(false);
  const [createdRuleGroup, setCreatedRuleGroup] = useState(false);

  const [showModalImport, setShowModalImport] = useState(false)

  const handleClickImport = () => {
    setShowModalImport(true);
  }

  useEffect(() => {
    setLoading(true)
    RuleGroups.index(companyId, projectId).then((r) => {
      setLoading(false)
      if (r?.status < 400) {
        setRuleGroups(r.data.rule_groups)
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    });
  }, [companyId, projectId, createdRuleGroup])

  const toggleCollapsed = () => {
    setFiltersCollapsed(!isFiltersCollapsed)
  }

  return (
    <>
      <div className={'view_header'}>
        <div className={'title'}>
          <h1>Shared Rule Groups</h1>
        </div>
        <div className={'button_actions'}>
          <Button outline color="primary"
                  onClick={toggleCollapsed}>
            <span className="fa-filter fa me-1"/>
            Filter
          </Button>
          <Button color={'primary'} onClick={handleClickImport}>Import </Button>
          <Button color={'primary'} onClick={() => setShowModal(true)}>Create</Button>
        </div>
      </div>
      {
        isLoading === true ? <PlaceholderTable rows={20} firstEmpty={false} columns={1}/> :
          <div className={'mt-3'}>
            <div className={'p-3 bg-gray-table text-gray'}>
              Name
            </div>
            {
              ruleGroups && ruleGroups.map((ruleGroup, i) => {
                return (<RuleGroupItem key={i} ruleGroup={ruleGroup}/>);
              })
            }
          </div>
      }

      <RuleGroupNewModal
        showModal={showModal}
        setShowModal={setShowModal}
        setCreatedRuleGroup={setCreatedRuleGroup}
      />

      <ImportProvider>
        <RuleGroupImportModal
          showModal={showModalImport}
          setShowModal={setShowModalImport}
          setCreatedRuleGroup={setCreatedRuleGroup}>
        </RuleGroupImportModal>
      </ImportProvider>
    </>
  )
}
