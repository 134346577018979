import {BASE_PRIORITY} from "../CanvasReducer";

export const canvasObjectsReorder = (canvas, objects) => {

  return {
    ...canvas,
    objects: objects.map((canvasObject, loopId) => {
      return {
        ...canvasObject,
        object: {
          ...canvasObject.object,
          priority: loopId + 1,
        },
        index: BASE_PRIORITY - loopId,
        selected: false
      };
    })
  }
};
