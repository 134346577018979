const filterFields = [
  {name: 'Sku', value: 'sku'},
  {name: 'Title', value: 'title'},
  {name: 'Description', value: 'description'},
  {name: 'URL', value: 'link'},
  {name: 'Product Type', value: 'type'},
  {name: 'Product Category', value: 'category'},
  {name: 'Price', value: 'price'},
  {name: 'Sale Price', value: 'salePrice'},
  {name: 'Brand', value: 'brand'},
  {name: 'Gtin', value: 'gtin'},
  {name: 'Condition', value: 'condition'},
  {name: 'Color', value: 'color'},
  {name: 'Gender', value: 'gender'},
  {name: 'MPN', value: 'mpn'},
  {name: 'Material', value: 'material'},
  {name: 'Identifier Exists', value: 'identifierExists'},
  {name: 'Energy Efficiency Class', value: 'energyEfficiencyClass'},
  {name: 'Item Group Id', value: 'itemGroupId'},
  {name: 'Discount', value: 'discount'},
  {name: 'Additional images', value: 'additionalImages'},
]
export default filterFields;
