import React, {useEffect, useState} from 'react';
import FeedExportItem from "./FeedExportItem.js";
import {Button, Table} from "reactstrap";
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {FeedExports} from "../../services/api/FeedExports";
import {TableFilters} from "../TableFilters/TableFilters";
import {useTableFiltersState} from "../../context/table_filters/TableFiltersContext";
import {Link} from "react-router-dom";
import {PlaceholderTable} from "../PlaceholderTable/PlaceholderTable";
import {closeModal, openModal} from "../../context/modal/ModalActions";
import {useModalDispatch} from "../../context/modal/ModalContext";

const FeedExportList = ({companyId, projectId}) => {
  const dispatch = useApiErrorDispatch()
  const [isFiltersCollapsed, setFiltersCollapsed] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const [feedExports, setFeedExports] = useState([])
  const tableFilters = useTableFiltersState()
  const modalDispatcher = useModalDispatch()
  const [isRequestExportLoading, setRequestExportLoading] = useState(false)

  const handleRequestExportClick = () => {
    openModal(modalDispatcher, {
      title: 'Request export feeds',
      message: 'Do you want to generate all exports?',
      onConfirm: () => {
        setRequestExportLoading(true)
        FeedExports.requestExports(companyId, projectId,).then((r) => {
          setRequestExportLoading(false);
          if (r === undefined || r.status > 399) {
            apiErrorRaised(dispatch, r?.response)
          }
        });
        closeModal(modalDispatcher)
      },
      onCancel: () => closeModal(modalDispatcher),
      onBackdropClick: () => closeModal(modalDispatcher),
      buttonText: 'Yes'
    })
  }

  const toggleCollapsed = () => {
    setFiltersCollapsed(!isFiltersCollapsed)
  }

  useEffect(() => {
    setLoading(true);
    FeedExports.index(companyId, projectId, {filters: tableFilters}).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        setFeedExports(r.data);
      } else {
        apiErrorRaised(dispatch, r?.response)
      }
    });
  }, [dispatch, tableFilters]);

  return (
    <>
      <div className={'view_header'}>
        <div className={'title'}>
          <h1>Feed Exports</h1>
        </div>
        <div className={'button_actions'}>
          <Button outline color="primary"
                  onClick={toggleCollapsed}>
            <span className="fa-filter fa me-1"/>
            Filter
          </Button>
          <Link to={`/admin/companies/${companyId}/projects/${projectId}/feed_exports/new`}>
            <Button color={'primary'} className={'me-1'}>New Feed Export</Button>
          </Link>
          <Button outline color="primary" className="btn-icon"
                  onClick={handleRequestExportClick}>
            {
              isRequestExportLoading ?
                <span className="spinner-border spinner-border-sm me-1 ms-1"/>
                :
                <span className="fa fa-file-export me-1"/>
            }
            Export All
          </Button>
        </div>
      </div>
      <TableFilters entity={'feed_export'} isCollapsed={isFiltersCollapsed}/>
      {
        isLoading === true ? <PlaceholderTable rows={20} firstEmpty={false} columns={4}/> :
          <Table className="align-items-center table-flush mt-3" responsive>
            <thead className="thead-light">
            <tr>
              <th scope="col" className={'text-center'}>Name</th>
              <th scope="col" className={'text-center'}>Platform</th>
              <th scope="col" className={'text-center'}>Settings</th>
              <th scope="col" className={'text-center'}>Actions</th>
            </tr>
            </thead>
            <tbody>
            {
              isLoading ?
                <tr>
                  <td><span className="spinner-border spinner-border-lg me-1"/></td>
                </tr> : feedExports && feedExports.map((feedExport, i) => {
                return (<FeedExportItem key={i} data={feedExport}/>);
              })
            }
            </tbody>
          </Table>
      }

    </>
  );
}

export default FeedExportList;
