import React, {useReducer} from 'react';
import {CanvasReducer, initialCanvas} from './CanvasReducer';

const CanvasStateContext = React.createContext();
const CanvasDispatchContext = React.createContext();

export function useCanvasState() {
  const context = React.useContext(CanvasStateContext);
  if (context === undefined) {
    throw new Error('useCanvasState must be used within a CanvasProvider');
  }

  return context;
}

export function useCanvasDispatch() {
  const context = React.useContext(CanvasDispatchContext);
  if (context === undefined) {
    throw new Error('useCanvasDispatch must be used within a CanvasProvider');
  }

  return context;
}

export const CanvasProvider = ({children}) => {
  const [Canvas, dispatch] = useReducer(CanvasReducer, initialCanvas);

  return (
    <CanvasStateContext.Provider value={Canvas}>
      <CanvasDispatchContext.Provider value={dispatch}>
        {children}
      </CanvasDispatchContext.Provider>
    </CanvasStateContext.Provider>
  );
};
