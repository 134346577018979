import React, {useReducer} from "react";
import {EventsManagerReducer, initialState} from "./EventsManagerReducer";

const EventsManagerStateContext = React.createContext();
const EventsManagerDispatchContext = React.createContext();

export const EventsManagerProvider = ({children}) => {
  const [EventsManagerFilters, dispatch] = useReducer(EventsManagerReducer, initialState);

  return (
    <EventsManagerStateContext.Provider value={EventsManagerFilters}>
      <EventsManagerDispatchContext.Provider value={dispatch}>
        {children}
      </EventsManagerDispatchContext.Provider>
    </EventsManagerStateContext.Provider>
  );
};
