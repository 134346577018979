import React from 'react';
import {Button, Card, CardBody} from "reactstrap";
import {useProjectState} from "../../../context/project/ProjectContext";
import {useNavigate} from "react-router-dom";
import TemplateDuplicateButton from "./TemplateDetailActions/TemplateDuplicateButton";
import TemplateRenderStatusPublishButton from "./TemplateDetailActions/TemplateRenderStatusPublishButton";
import {useTemplateState} from "../../../context/template/TemplateIndex";
import TemplateDeleteButton from "./TemplateDetailActions/TemplateDeleteButton";

const TemplateDetailActions = () => {
  const projectState = useProjectState()
  const templateState = useTemplateState()
  const navigate = useNavigate()

  return (
    <>
      <Card className={'bg-0'}>
        <CardBody>
          <div className={'d-flex flex-column align-items-stretch ps-6 pe-6'}>
            <TemplateRenderStatusPublishButton/>
            <Button color={'primary'}
                    className={'m-0 mt-1 mb-2'}
                    onClick={() => navigate(`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/templates/${templateState.id}/canvas`)}>
              <span className="fa fa-palette"/> Canvas
            </Button>
            <TemplateDuplicateButton/>
            <TemplateDeleteButton/>
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default TemplateDetailActions;
