export const initialState = {
  isOpen: false,
  modalProps: {
    title: '',
    message: '',
    buttonColor: 'primary',
    buttonText: '',
    buttonIcon: '',
    onConfirm: () => {
    },
    onCancel: () => {
    },
    onBackdropClick: () => {
    },
  },
};

export const ModalReducer = (initialState, action) => {
  switch (action.type) {
    case "OPEN_MODAL":
      return {
        ...initialState,
        isOpen: true,
        modalProps: {
          title: action.payload.title,
          message: action.payload.message,
          onConfirm: action.payload.onConfirm,
          onCancel: action.payload.onCancel,
          onBackdropClick: action.payload.onBackdropClick,
          buttonColor: action.payload?.buttonColor,
          buttonText: action.payload.buttonText,
          buttonIcon: action.payload.buttonIcon
        }
      };
    case "CLOSE_MODAL":
      return {
        ...initialState,
        isOpen: false,
        modalProps: {
          title: '',
          message: '',
          onConfirm: () => {
          },
          onCancel: () => {
          },
          onBackdropClick: () => {
          },
          buttonColor: 'primary',
          buttonText: '',
          buttonIcon: ''
        }
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
