import React from "react";
import AuthHeader from "components/Headers/AuthHeader.js";
import {useTranslation} from "react-i18next";

const SignUpThanks = () => {
  const {t} = useTranslation('auth')

  return (
    <>
      <AuthHeader
        title={t('SIGN_UP_THANKS_TITLE')}
        lead={t('SIGN_UP_THANKS_DESCRIPTION')}
      />
    </>
  );
}

export default SignUpThanks;
