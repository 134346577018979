export function fetch(dispatch, payload) {
  dispatch({
    type: 'LOAD',
    payload: payload
  })
}

export function updateStatus(dispatch, payload) {
  dispatch({
    type: 'UPDATE_STATUS',
    payload: payload
  })
}

export function updateName(dispatch, payload) {
  dispatch({
    type: 'UPDATE_NAME',
    payload: payload
  })
}

export function updateAction(dispatch, payload) {
  dispatch({
    type: 'UPDATE_ACTION',
    payload: {
      position: payload.position,
      action: payload.action,
      type: payload.type,
    }
  })
}

export function updateQueryCondition(dispatch, payload) {
  dispatch({
    type: 'UPDATE_QUERY_CONDITION',
    payload: {
      condition: payload.condition,
      position: payload.position,
    }
  })
}

export function updateOrQuery(dispatch, payload) {
  dispatch({
    type: 'UPDATE_OR_QUERY',
    payload: {
      or_query: payload.or_query,
      position: payload.position,
      or_position: payload.or_position
    }
  })
}

export function addAndQuery(dispatch) {
  dispatch({
    type: 'ADD_AND_QUERY',
    payload: {
      condition: {
        field: 'all',
        operator: '=',
        value: ''
      },
      or_queries: []
    }
  })
}

export function addAction(dispatch, payload) {
  dispatch({
    type: 'ADD_ACTION',
    payload: {
      action: {
        field: 'all',
        action: 'NOTHING',
        value: ''
      },
      type: payload
    },
  })
}

export function deleteAction(dispatch, payload) {
  dispatch({
    type: 'DELETE_ACTION',
    payload: payload,
  })
}

export function addOrQuery(dispatch, position) {
  dispatch({
    type: 'ADD_OR_QUERY',
    payload: {
      query: {
        field: 'all',
        operator: '=',
        value: ''
      },
      position: position
    }
  })
}

export function deleteQuery(dispatch, position) {
  dispatch({
    type: 'DELETE_QUERY',
    payload: position
  })
}

export function deleteOrQuery(dispatch, position, orPosition) {
  dispatch({
    type: 'DELETE_OR_QUERY',
    payload: {
      position: position,
      or_position: orPosition,
    }
  })
}
