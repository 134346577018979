import React, {useCallback, useState} from "react";

import {Button} from "reactstrap";
import {CanvasObjectSidebarCard} from "./CanvasObjectSidebarCard";
import {canvasObjectsReorder, useCanvasDispatch, useCanvasState} from "../../../context/canvas/CanvasIndex";
import {closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from "@dnd-kit/sortable";
import {restrictToVerticalAxis} from '@dnd-kit/modifiers';
import {CanvasActionRemoveModal} from "../CanvasActions/CanvasActionRemoveModal";

export const CanvasObjectSidebar = () => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const canvasState = useCanvasState();
  const canvasDispatch = useCanvasDispatch();
  const handleRemoveClick = () => {
    if (canvasState.selectedObject === null) {
      return
    }
    setShowRemoveModal(true);
  }

  const handleDragEnd = useCallback((event) => {
    const {active, over} = event;
    if (active.id !== over.id) {
      const oldIndex = canvasState.objects.findIndex(prop => prop.id === active.id);
      const newIndex = canvasState.objects.findIndex(prop => prop.id === over.id);

      const newObjects = arrayMove(canvasState.objects, oldIndex, newIndex);
      canvasObjectsReorder(canvasDispatch, newObjects)
    }
  }, [canvasState.objects])
  return (
    <div
      className={
        "canvas_object_sidebar d-flex flex-column w-100"
      }
    >
      <h3 className="canvas_sidebar_header p-3 mb--1">Layers</h3>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext
          items={canvasState.objects}
          strategy={verticalListSortingStrategy}
        >
          <div className={'canvas_sidebar_layers_container'}>
            {canvasState.objects && canvasState.objects.map((item, id) => (
              <CanvasObjectSidebarCard
                key={id}
                id={item.id}
                object={item.object}
                isSelected={item.selected}
                isVisible={item.visible}
                errors={item.errors}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
      <section className={'d-flex flex-column canvas_sidebar_section_actions p-1'}>
        <Button
          color={'danger'}
          className={'align-self-end btn-icon-only'}
          disabled={canvasState.selectedObject === null}
          onClick={handleRemoveClick}
        >
          <span className={'fa fa-trash'}/>
        </Button>
      </section>
      <CanvasActionRemoveModal
        showModal={showRemoveModal}
        setShowModal={setShowRemoveModal}
      />
    </div>
  );
}
