import {Col, Row} from "reactstrap";
import LandingCarouselLogo from "./LandingCarouselLogo";
import React from "react";
import {useTranslation} from "react-i18next";

export const LandingIntegrations = () => {
  const {t} = useTranslation('landing')
  return (
    <>
      <div className="pt-6 pb-8">
        <Row className="justify-content-center text-center pt-5 pb-5">
          <Col>
            <h2 className={'landing_header'}>
              {t('INTEGRATIONS')}
            </h2>
          </Col>
        </Row>
        <div>
          <LandingCarouselLogo/>
        </div>
      </div>
    </>
  )
}
