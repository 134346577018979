import React from "react";
import {Container,} from "reactstrap";
import CompanyDetail from "../../../components/Companies/CompanyDetail";
import {useParams} from "react-router-dom";

const CompanyView = () => {
  const routerParams = useParams()

  return (
    <>
      <Container className="mt-7">
        <CompanyDetail
          companyId={routerParams.companyId}
        />
      </Container>
    </>
  );
};

export default CompanyView;
