import {destroy, get, post, put} from './config/base';
import {handleError, handleResponse} from "./config/response";
import {urlParser} from "./config/utils";

const url = 'companies/{companyId}/projects/{projectId}/templates/';

export const Templates = {
  index: (companyId, projectId, params = null) =>
    get(urlParser(url, companyId, projectId), {params: params})
      .then(handleResponse)
      .catch(handleError),
  get: (companyId, projectId, id) =>
    get(`${urlParser(url, companyId, projectId)}${id}`)
      .then(handleResponse)
      .catch(handleError),
  put: (companyId, projectId, id, payload) =>
    put(`${urlParser(url, companyId, projectId)}${id}`, payload)
      .then(handleResponse)
      .catch(handleError),
  put_batch: (companyId, projectId, id, payload) => {
    return put(`${urlParser(url, companyId, projectId)}${id}/batch_put_template_objects`, payload)
      .then(handleResponse)
      .catch(handleError)
  },
  activate: (companyId, projectId, id) =>
    put(`${urlParser(url, companyId, projectId)}${id}/activate`)
      .then(handleResponse)
      .catch(handleError),
  disable: (companyId, projectId, id) =>
    put(`${urlParser(url, companyId, projectId)}${id}/disable`)
      .then(handleResponse)
      .catch(handleError),
  publish: (companyId, projectId, id) =>
    put(`${urlParser(url, companyId, projectId)}${id}/publish`)
      .then(handleResponse)
      .catch(handleError),
  delete: (companyId, projectId, id) =>
    destroy(`${urlParser(url, companyId, projectId)}${id}`)
      .then(handleResponse)
      .catch(handleError),
  duplicate: (companyId, projectId, id, newId) =>
    put(`${urlParser(url, companyId, projectId)}${id}/duplicate/${newId}`)
      .then(handleResponse)
      .catch(handleError),
  preview: (companyId, projectId, id, params = null) =>
    get(`${urlParser(url, companyId, projectId)}${id}/preview`, {params: params})
      .then(handleResponse)
      .catch(handleError),
  import: (companyId, projectId, oldTemplateId, newTemplateId) =>
    post(`${urlParser(url, companyId, projectId)}import`, {
      new_template_id: newTemplateId,
      old_template_id: oldTemplateId
    })
      .then(handleResponse)
      .catch(handleError),
}
