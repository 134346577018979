import {useCanvasDispatch, useCanvasState} from "../../../../../context/canvas/CanvasIndex";
import {updateData} from "../../../../../context/canvas/CanvasActions";
import {Input} from "reactstrap";
import React from "react";

export const CanvasObjectInfoSidebarDetailSize = () => {
  const canvasState = useCanvasState()
  const canvasDispatch = useCanvasDispatch()
  const templateObject = canvasState.selectedObject

  const handleSize = (e) => {
    const {name, value} = e.target;
    let width = templateObject?.object.data.size.x
    let height = templateObject?.object.data.size.y

    switch (name) {
      case 'width':
        width = value;
        break;
      case 'height':
        height = value;
        break;
      default:
    }
    updateData(
      canvasDispatch,
      templateObject?.id,
      'size',
      {
        x: parseInt(width),
        y: parseInt(height),
      }
    )
  }

  return (
    <>
      <div className={'d-flex flex-column'}>
        <strong className={'mt-1'}>Size</strong>
        <div className={'d-flex flex-row mt-1'}>
          <Input
            className={'m-2'}
            placeholder="X"
            type="number"
            value={templateObject?.object.data.size.x ?? 0}
            name="width"
            onChange={handleSize}
          />
          <Input
            className={'m-2'}
            placeholder="X"
            type="number"
            value={templateObject?.object.data.size.y ?? 0}
            name="height"
            onChange={handleSize}
          />
        </div>
      </div>
    </>
  )
}
