import {Button, Input, Modal} from "reactstrap";
import React, {useState} from "react";
import {v4 as uuid4} from "uuid";
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {useProjectState} from "../../context/project/ProjectIndex";
import {RuleGroups} from "../../services/api/RuleGroups";

export const RuleGroupNewModal = ({showModal, setShowModal, setCreatedRuleGroup}) => {
  const projectState = useProjectState()
  const dispatch = useApiErrorDispatch();
  const [isLoading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [name, setName] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true)
    if (name) {
      setLoading(true);
      let ruleGroupId = uuid4();
      RuleGroups.put(projectState.companyId, projectState.projectId, ruleGroupId, {
        name: name,
        type: 'SHARED'
      }).then((r) => {
        setLoading(false);
        if (r?.status < 400) {
          setCreatedRuleGroup(true)
          setShowModal(false)
        } else {
          apiErrorRaised(dispatch, r?.response)
        }
      });
    }
  }

  const handleChange = (e) => {
    const {value} = e.target;
    setName(value);
  }


  return (
    <>
      <Modal
        size="md"
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={() => setShowModal(false)}
      >
        <div className="modal-header">
          <button
            aria-label="Close"
            className="btn-close"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
          </button>
        </div>
        <div className="modal-body d-flex flex-column pt-0 pb-0">
          <h6 className="modal-title end text-center mb-4">
            New Shared Rule Group
          </h6>
          <Input
            placeholder="Name"
            type="text"
            valid={submitted && name.length > 0}
            invalid={submitted && !name}
            name="name"
            value={name}
            onChange={handleChange}
          />
          <div className="invalid-feedback">
            Please provide a name
          </div>
        </div>
        <div className="modal-footer d-flex flex-row justify-content-between">
          <Button
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
          <Button color="primary" type="button"
                  onClick={handleSubmit}>
            {isLoading &&
              <span className="spinner-border spinner-border-sm me-1"/>}
            Create
          </Button>
        </div>
      </Modal>
    </>
  );
};
