import React from 'react'
import {Input} from "reactstrap";
import {ruleOperators} from "../FeedExportRuleFields/FeedExportRuleOperators";
import {updateQueryCondition, useRuleDispatch, useRuleState} from "../../../../context/rule/RuleIndex";
import Select from "react-select";
import {FeedExportRuleActionPropertiesSelect} from "../FeedExportRuleFields/FeedExportRuleActionPropertiesSelect";


export const FeedExportRuleQueryCondition = ({position}) => {
  const ruleState = useRuleState()
  const ruleDispatch = useRuleDispatch()

  const handleFieldChange = (e) => {
    const {value} = e;
    updateQueryCondition(ruleDispatch,
      {
        condition: {...ruleState.queries[position].condition, 'field': value},
        position: position
      })
  }

  const handleOperatorChange = (e) => {
    const {value} = e;
    updateQueryCondition(ruleDispatch,
      {
        condition: {...ruleState.queries[position].condition, 'operator': value},
        position: position
      })
  }

  const handleValueChange = (e) => {
    const {value} = e.target;
    updateQueryCondition(ruleDispatch,
      {
        condition: {...ruleState.queries[position].condition, 'value': value},
        position: position
      })
  }

  const pretext = () => {
    return position === 0 ? 'If' : 'And'
  }

  return (
    <>
      <div className='d-flex align-items-center gap-4 justify-content-start mb-3 w-100 h-100'>
        <div md={1}
             className='fe-height-statement d-flex align-items-center justify-content-evenly col-md-1 bg-gray-light rounded text-sm'>
          {pretext()}
        </div>
        <div className='w-100'>
          <div className='row d-flex align-items-center p-2'>
            <div md={3} className="col-sm">
              <div>
                <div>
                  <FeedExportRuleActionPropertiesSelect
                    value={ruleState.queries[position].condition?.field}
                    onChange={handleFieldChange}
                  />
                </div>
              </div>
            </div>
            <div md={3} className="col-sm">
              {
                ruleState.queries[position].condition?.field !== 'all' ?
                  <div>
                    <Select
                      value={ruleOperators.find(listOperator => listOperator.value === ruleState.queries[position].condition?.operator)}
                      options={ruleOperators}
                      onChange={handleOperatorChange}
                    />
                  </div>
                  : ''
              }
            </div>
            <div md={3} className="col-sm">
              {
                ruleState.queries[position].condition?.field !== 'all'
                && ruleState.queries[position].condition?.operator !== 'IS NULL'
                && ruleState.queries[position].condition?.operator !== 'IS NOT NULL' ?
                  <div>
                    <Input
                      value={ruleState.queries[position].condition?.value}
                      onChange={handleValueChange}
                      name={"value"}
                      type="text"
                    />
                  </div> : ''
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
