import React, {useEffect, useState} from 'react';
import bingImage from "../../../assets/img/icons/common/bing.png";
import facebookImage from "../../../assets/img/icons/common/facebook.png";
import metaCatalogsImage from "../../../assets/img/icons/common/metaLocalizedCatalogs.png";
import googleImage from "../../../assets/img/icons/common/google.png";
import googleLocalInventoryImage from "../../../assets/img/icons/common/googleLocalInventory.png";
import pinterestImage from "../../../assets/img/icons/common/pinterest.png";
import doubler from "../../../assets/img/icons/common/doubler.png";
import awin from "../../../assets/img/icons/common/awin.png";
import criteo from "../../../assets/img/icons/common/criteo.png";
import googleStudio from "../../../assets/img/icons/common/google_studio.png";
import tiktok from "../../../assets/img/icons/common/tiktok.png";
import custom from "../../../assets/img/icons/common/custom.png";
import FeedExportPlatformBoxItem from "./FeedExportPlatformBoxItem";

export const FeedExportPlatformBox = ({setPlatform}) => {
  const [selectedPlatform, setSelectedPlatform] = useState()

  useEffect(() => {
    setPlatform(selectedPlatform)
  }, [selectedPlatform])

  const platforms = [
    {name: 'Bing', code: 'bing', image: bingImage},
    {name: 'Meta Catalog Ads', code: 'facebook', image: facebookImage},
    {name: 'Meta Localized Catalogs', code: 'metaLocalizedCatalogs', image: metaCatalogsImage},
    {name: 'Google', code: 'google', image: googleImage},
    {name: 'Google Local Inventory', code: 'googleLocalInventory', image: googleLocalInventoryImage},
    {name: 'Pinterest', code: 'pinterest', image: pinterestImage},
    {name: 'TradeDoubler', code: 'tradedoubler', image: doubler},
    {name: 'Awin', code: 'awin', image: awin},
    {name: 'Criteo', code: 'criteo', image: criteo},
    {name: 'Google Studio', code: 'googleStudio', image: googleStudio},
    {name: 'Tiktok', code: 'tiktok', image: tiktok},
    {name: 'Custom Feed', code: 'custom', image: custom},
  ];

  return (
    <>
      <div className={'feed_export_platform-grid-container'}>
        <div className={'feed_export_platform-cards__list'}>
          {
            platforms.map((platform, id) => {
              return (
                <FeedExportPlatformBoxItem
                  key={id}
                  platform={platform}
                  selectedPlatform={selectedPlatform}
                  setSelectedPlatform={setSelectedPlatform}
                />
              )
            })
          }
        </div>
      </div>
    </>
  )
}
