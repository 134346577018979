import React, {useRef, useState} from 'react';
import {Assets} from "../../services/api/Assets";
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {useProjectState} from "../../context/project/ProjectIndex";
import {Button} from "reactstrap";

const AssetUpload = ({setUploadedAsset}) => {
  const fallbackImg = require('assets/img/asset_placeholder.png')
  const projectState = useProjectState()
  const apiErrorDispatch = useApiErrorDispatch();
  const [visible, setVisible] = useState(true)
  const hiddenFileInput = useRef(null);
  const [file, setFile] = useState(null)
  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  const handleOnChangeAsset = (e) => {
    let file = e.target.files[0]
    Assets.upload(projectState.companyId, projectState.projectId, file).then((r) => {
      if (r?.status < 400) {
        setUploadedAsset(r.data);
        setVisible(false);
        setFile(URL.createObjectURL(file));
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    });
  }

  return (
    <>
      {
        visible &&
        <>
          <Button color={"primary"} onClick={handleClick}>
            Select file
          </Button>
          <div className="custom-file">
            <input
              className="custom-file-input"
              id="assetUploader"
              lang="en"
              type="file"
              onChange={handleOnChangeAsset}
              ref={hiddenFileInput}
              style={{display: 'none'}}
            />
          </div>
        </>
      }
      <div className={'mt-3'} style={{display: visible ? 'none' : 'block'}}>
        <img src={file} className={'w-100 border border-primary rounded-1 border-2'} alt={'asset'}
             onError={({currentTarget}) => {
               currentTarget.onerror = null;
               currentTarget.src = fallbackImg;
               currentTarget.classList.remove('card-image-fit')
             }}
        />
      </div>
    </>
  )
}

export default AssetUpload;

