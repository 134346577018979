import React, {useCallback} from "react";

import {Button, Modal} from "reactstrap";
import Conditions from "../../Conditions/Conditions";
import {updateConditions} from "../../../context/canvas/CanvasActions";
import {useCanvasDispatch, useCanvasState} from "../../../context/canvas/CanvasIndex";

export const CanvasObjectInfoSidebarConditionsModal = ({showModal, setShowModal,}) => {
  const canvasDispatch = useCanvasDispatch()
  const canvasState = useCanvasState()


  const handleChangeConditions = useCallback((newConditions) => {
    if (newConditions) {
      updateConditions(canvasDispatch, canvasState?.selectedObject?.id, newConditions)
    }
  }, [canvasState?.selectedObject?.id])

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={showModal}
      toggle={() => setShowModal(false)}
      size={'xl'}
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
          Select Conditions
        </h6>
        <button
          aria-label="Close"
          className="btn-close"
          data-dismiss="modal"
          type="button"
          onClick={() => setShowModal(false)}
        >
        </button>
      </div>
      <div className="modal-body">
        <div className={'d-flex flex-col'}>
          <Conditions
            conditions={canvasState?.selectedObject?.object?.conditions}
            finishEditCallback={handleChangeConditions}
          />
        </div>
      </div>
      <div className="modal-footer">
        <Button
          className="ms-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={() => setShowModal(false)}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
}
