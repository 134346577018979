import DataTable from "react-data-table-component";
import React, {useCallback, useEffect, useState} from "react";
import {useProjectState} from "../../../context/project/ProjectIndex";
import {Analytics} from "../../../services/api/Analytics";
import {apiErrorRaised} from "../../../context/api_error/ApiErrorActions";
import {useApiErrorDispatch} from "../../../context/api_error/ApiErrorContext";
import {AnalyticsTableHeaders} from "./AnalyticsTableHeaders";
import {Card, CardBody, Input} from "reactstrap";
import {AnalyticsTableExport} from "./AnalyticsTableExport";
import {AnalyticsDimensionsSelect} from "../AnalyticsDimensionsSelect";
import {selectRows, useAnalyticsDispatch, useAnalyticsState} from "../../../context/analytics/AnalyticsIndex";
import {changeTimeZone} from "../AnalyticsUtils";
import {Projects} from "../../../services/api/Projects";
import {format} from "date-fns";

export const AnalyticsTable = () => {
  const projectState = useProjectState();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const apiErrorDispatch = useApiErrorDispatch();
  const [columns, setColumns] = useState([]);
  const analyticsState = useAnalyticsState()
  const analyticsDispatch = useAnalyticsDispatch()
  const [filterText, setFilterText] = useState('')
  const [customGroups, setCustomGroups] = useState({})
  const [selectedRowsKeys, setSelectedRowsKeys] = useState([])
  let isInitialized = false

  const customStyles = {
    rows: {
      style: {
        fontSize: '14px', // Cambia el tamaño de la fuente de las filas
        color: '#42325D',
        fontWeight: 'bold'
      },
    },
    headRow: {
      style: {
        backgroundColor: '#F6F9FC',
        color: '#8F88AA',
        fontSize: '10px',
        fontWeight: 'bold'
      },
    },
  };

  useEffect(() => {
    fetchData()
  }, [analyticsState.dates, analyticsState.dimensions])

  useEffect(() => {
    Projects.analytics_settings(projectState.companyId, projectState.projectId).then((r) => {
      let groups = {};
      if (r?.status < 400) {
        if (r.data.custom_group0 !== null) {
          groups['custom_group_0'] = r.data.custom_group0;
        }
        if (r.data.custom_group1 !== null) {
          groups['custom_group_1'] = r.data.custom_group1;
        }
        if (r.data.custom_group2 !== null) {
          groups['custom_group_2'] = r.data.custom_group2;
        }
        if (r.data.custom_group3 !== null) {
          groups['custom_group_3'] = r.data.custom_group3;
        }
        if (r.data.custom_group4 !== null) {
          groups['custom_group_4'] = r.data.custom_group4;
        }
        setCustomGroups(groups)
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    })
  }, [projectState])

  const fetchData = () => {
    setLoading(true);
    setColumns(AnalyticsTableHeaders([
      ...analyticsState.dimensions,
      'products',
      'price',
      'sale_price',
      'add_to_cart',
      'add_to_cart_values',
      'purchases',
      'purchase_values',
    ], customGroups, projectState.locale, projectState.projectCurrency));
    Analytics.insights(projectState.companyId, projectState.projectId, {
      'start_date': format(analyticsState.dates.start_date, 'yyyy-MM-dd'),
      'end_date': format(analyticsState.dates.end_date, 'yyyy-MM-dd'),
      'dimensions': analyticsState.dimensions,
    }).then((r) => {
      setLoading(false)
      if (r?.status < 400) {
        parseInsights(r.data)
      } else {
        if (r?.response !== undefined) {
          apiErrorRaised(apiErrorDispatch, r?.response)
        } else {
          apiErrorRaised(apiErrorDispatch, {
            status: r.status,
            statusText: r.name,
            data: {
              messge: r.message,
            }
          })
        }
      }
    })
  }

  const parseInsights = (insights) => {
    setData(insights.map(function (insight, index) {
        let keys = []
        for (let i = 0; i < analyticsState.dimensions.length; i++) {
          if (analyticsState.dimensions[i] === 'date') {
            continue;
          }
          if (insight[analyticsState.dimensions[i]] === '' || insight[analyticsState.dimensions[i]] === null) {
            keys.push('(not set)')
          } else {
            keys.push(insight[analyticsState.dimensions[i]])
          }
        }
        return {
          isSelected: false,
          key: keys.join('-'),
          date: insight.date !== null ? changeTimeZone(new Date(insight.date + ' UTC'), projectState.timezone) : null,
          products: insight.products,
          price: formatFloat(insight.price),
          sale_price: formatFloat(insight.sale_price),
          purchases: insight.purchases,
          purchase_values: formatFloat(insight.purchase_values),
          add_to_cart: insight.add_to_cart,
          add_to_cart_values: formatFloat(insight.add_to_cart_values),
          brand: insight.brand === '' ? '(not set)' : insight.brand,
          availability: insight.availability,
          type: insight.type === '' ? '(not set)' : insight.type,
          top_level_type: insight.top_level_type === '' ? '(not set)' : insight.top_level_type,
          custom_group_0: insight.custom_group_0 === null ? '(not set)' : insight.custom_group_0,
          custom_group_1: insight.custom_group_1 === null ? '(not set)' : insight.custom_group_1,
          custom_group_2: insight.custom_group_2 === null ? '(not set)' : insight.custom_group_2,
          custom_group_3: insight.custom_group_3 === null ? '(not set)' : insight.custom_group_3,
          custom_group_4: insight.custom_group_4 === null ? '(not set)' : insight.custom_group_4,
        }
      })
    );
  }

  useEffect(() => {
    if (filterText === '') {
      setFilteredData(data)
    } else {
      setFilteredData(data.filter(
        item => (item.top_level_type && item.top_level_type.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.brand && item.brand.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.type && item.type.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.availability && item.availability.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.custom_group_0 && item.custom_group_0.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.custom_group_1 && item.custom_group_1.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.custom_group_2 && item.custom_group_2.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.custom_group_3 && item.custom_group_3.toLowerCase().includes(filterText.toLowerCase())) ||
          (item.custom_group_4 && item.custom_group_4.toLowerCase().includes(filterText.toLowerCase())),
      ));
    }
  }, [data, filterText])

  useEffect(() => {
    if (selectedRowsKeys.length === 0) {
      selectRows(analyticsDispatch, filteredData.slice(0, 5).map((row) => row.key))
    }

  }, [filteredData])

  const handleOnSelectedRowsChange = useCallback(({selectedRows}) => {
    if (selectedRowsKeys.length === selectedRows.length) {
      isInitialized = true;
    }

    if (isInitialized === false) {
      return
    }
    if (selectedRowsKeys.length !== selectedRows.length) {
      let keys = selectedRows.map(row => row.key)
      setSelectedRowsKeys(keys)
      setFilteredData(filteredData.map((row) => ({
        ...row,
        isSelected: keys.includes(row.key)
      })))
      selectRows(analyticsDispatch, keys)
    }
  }, [filteredData])

  const formatFloat = (number, multiplier = true) => {
    if (number === null || number === 'null') {
      return null;
    }

    if (multiplier) {
      number = number / 1000;
    }
    return parseFloat(number);
  }

  const rowSelectCriteria = row => row.isSelected

  return (
    <>
      <Card>
        <CardBody className={'d-flex flex-column'}>
          <div className={'d-flex flex-row justify-content-between'}>
            <div className={'me-2 flex-basis-20'}>
              <AnalyticsDimensionsSelect customGroups={customGroups}/>
            </div>
            <div className={'d-flex flex-row'}>
              <div className={'me-2'}>
                <Input type="text"
                       onChange={e => setFilterText(e.target.value)}
                       placeholder={"Filter"}
                />
              </div>
              <AnalyticsTableExport columns={columns} data={data}/>
            </div>
          </div>
          <div className={'mt-2 analytics_table_body'}>
            <DataTable
              pagination
              selectableRows={!(analyticsState.dimensions.length === 1 && analyticsState.dimensions[0] === 'date')}
              paginationPerPage={50}
              paginationRowsPerPageOptions={[25, 50, 100, 200, 500]}
              onSelectedRowsChange={handleOnSelectedRowsChange}
              progressPending={isLoading}
              selectableRowsNoSelectAll={true}
              selectableRowsHighlight={true}
              selectableRowSelected={rowSelectCriteria}
              customStyles={customStyles}
              data={filteredData}
              columns={columns}
            />
          </div>
        </CardBody>
      </Card>
    </>
  )
}
