

  const googleProperties = [

    {destination:'id', origin: 'sku', isRequired: true},
    {destination:'title', origin: 'title', isRequired: true},
    {destination: 'description', origin: 'description', isRequired: true},
    {destination: 'link', origin: 'link', isRequired: true},
    {destination: 'image_link', origin: 'image', isRequired: true},
    {destination: 'availability', origin: 'availability', isRequired: true},
    {destination: 'price', origin: 'price', isRequired: true},
    {destination: 'brand', origin: 'brand', isRequired: true},
    {destination: 'gtin', origin: 'gtin', isRequired: true},
    {destination: 'mpn', origin: 'mpn', isRequired: true},
    {destination: 'additional_image_link', origin: '', isRequired: false},
    {destination: 'mobile_link', origin: '', isRequired: false},
    {destination: 'availability_date', origin: '', isRequired: false},
    {destination: 'expiration_date', origin: '', isRequired: false},
    {destination: 'sale_price', origin: 'salePrice', isRequired: false},
    {destination: 'sale_price_effective_date', origin: '', isRequired: false},
    {destination: 'cost_of_goods_sold', origin: '', isRequired: false},
    {destination: 'unit_pricing_measure', origin: '', isRequired: false},
    {destination: 'unit_pricing_base_measure', origin: '', isRequired: false},
    {destination: 'installment', origin: '', isRequired: false},
    {destination: 'subscription_cost', origin: '', isRequired: false},
    {destination: 'loyalty_points', origin: '', isRequired: false},
    {destination: 'google_product_category', origin: 'category', isRequired: false},
    {destination: 'product_type', origin: 'type', isRequired: false},
    {destination:  'identifier_exists', origin: '', isRequired: false},
    {destination: 'condition', origin: '', isRequired: false},
    {destination: 'adult', origin: '', isRequired: false},
    {destination: 'multipack', origin: '', isRequired: false},
    {destination: 'is_bundle', origin: '', isRequired: false},
    {destination: 'energy_efficiency_class', origin: '', isRequired: false},
    {destination: 'min_energy_efficiency_class', origin: '', isRequired: false},
    {destination: 'max_energy_efficiency_class', origin: '', isRequired: false},
    {destination: 'age_group', origin: '', isRequired: false},
    {destination: 'color', origin: '', isRequired: false},
    {destination: 'gender', origin: '', isRequired: false},
    {destination: 'material', origin: '', isRequired: false},
    {destination: 'pattern', origin: '', isRequired: false},
    {destination: 'size', origin: '', isRequired: false},
    {destination: 'size_type', origin: '', isRequired: false},
    {destination: 'size_system', origin: '', isRequired: false},
    {destination: 'item_group_id', origin: '', isRequired: false},
    {destination: 'product_detail', origin: '', isRequired: false},
    {destination: 'product_highlight', origin: '', isRequired: false},
    {destination: 'ads_redirect', origin: '', isRequired: false},
    {destination: 'custom_label_0', origin: '', isRequired: false},
    {destination: 'custom_label_1', origin: '', isRequired: false},
    {destination: 'custom_label_2', origin: '', isRequired: false},
    {destination: 'custom_label_3', origin: '', isRequired: false},
    {destination: 'custom_label_4', origin: '', isRequired: false},
    {destination: 'promotion_id', origin: '', isRequired: false},
    {destination: 'excluded_destination', origin: '', isRequired: false},
    {destination: 'included_destination', origin: '', isRequired: false},
    {destination: 'shopping_ads_', origin: '', isRequired: false},
    {destination: 'excluded_country', origin: '', isRequired: false},
    {destination:'shipping', origin: '', isRequired: false},
    {destination: 'shipping_label', origin: '', isRequired: false},
    {destination: 'shipping_weight', origin: '', isRequired: false},
    {destination: 'shipping_length', origin: '', isRequired: false},
    {destination: 'shipping_width', origin: '', isRequired: false},
    {destination:  'shipping_height', origin: '', isRequired: false},
    {destination: 'ships_from_country attribute', origin: '', isRequired: false},
    {destination: 'transit_time_label', origin: '', isRequired: false},
    {destination: 'min_handling_time', origin: '', isRequired: false},
    {destination: 'max_handling_time', origin: '', isRequired: false},
    {destination: 'tax', origin: '', isRequired: false},
    {destination: 'tax_category', origin: '', isRequired: false},
  ]



export default googleProperties;
