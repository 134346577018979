import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Badge, Button} from "reactstrap";
import {useProjectState} from "../../context/project/ProjectIndex";
import classNames from "classnames";

const ProductItemRow = ({data}) => {
  const projectState = useProjectState()
  const [product, setProduct] = useState(data);

  useEffect(() => {
    setProduct(data)
  }, [data]);

  const parsePrice = (price) => {
    if (price === undefined || price === null) {
      return '-'
    }
    const formatter = new Intl.NumberFormat(projectState.locale.replace('_', '-'), {
      style: 'currency',
      currency: price.currency,
    });

    return formatter.format(price.amount / 1000)
  }

  return (
    <>
      <tr>
        <td>
          <img src={product.image.url} className="avatar rounded-circle" alt=""/>
        </td>
        <td className="name">
          <Link
            to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/products/${product.id}`}>
            {product.title}
          </Link>
        </td>
        <td className="status" align='center'>
          <Badge color="" className="badge-stroke mx-2">
            <i className={classNames({
              'bg-gray': product.status === 0,
              'bg-success': product.status === 1,
              'bg-red-danger': product.status === 2,
              'bg-warning': product.status === 3,
            })}/>
          </Badge>
        </td>
        <td className="sku">
          <p>{product.sku}</p>
        </td>
        <td>
          <p>{parsePrice(product.price)}</p>
        </td>
        <td>
          <p>{parsePrice(product.salePrice)}</p>
        </td>
        <td className="brand">
          <p>{product.brand}</p>
        </td>
        <td className="category">
          <p>{product.category}</p>
        </td>
        <td className="url">
          <a href={product.url} target="_blank">
            <Button color={'white'} className='btn-icon-only btn btn-primary'>
              <span className="fa-solid fa-up-right-from-square color-primary"/>
            </Button>
          </a>
        </td>
      </tr>
    </>
  )
}

export default ProductItemRow;
