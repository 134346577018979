import {Col, Row} from "reactstrap";
import React from "react";
import {useTranslation} from "react-i18next";
import {HubSpotForm} from "../Forms/HubspotForm";


export const LandingForm = () => {
  const {t, i18n} = useTranslation('landing')

  const hubSpotFormIds = {
    es: '6cb282cb-4814-49ba-82dc-463fcf926c9c',
    en: 'a9572d81-6fb1-40d6-b632-9a8f9840788f',
    pt: '0dca1b71-3c86-4b5f-a921-83aea4cd275b',
  };
  return (
    <>
      <div className="py-6 bg-light-blue" id="submitForm">
        <Row className="justify-content-center text-center pt-5 pb-5 px-2 position-relative">
          <Col>
            <h2 className={'landing_header'}>
              {t('MORE_INFO_HEADER')}
            </h2>
            <p className="m-auto pb-5">
              {t('MORE_INFO_DESCRIPTION')}
            </p>
            <div className="hubspot-form bg-white p-5">
              <HubSpotForm formId={hubSpotFormIds[i18n.language] ?? null} portalId="156214"/>
              <div id={'hbsfrm'}></div>
              <div id={'hbsfrm2'}></div>
            </div>
            <div className="left-image">
              <img
                alt="..."
                className="my-auto img-fluid"
                src={require("assets/img/landing/bg-img-one.png")}
              />
            </div>
            <div className="right-image">
              <img
                alt="..."
                className="my-auto img-fluid"
                src={require("assets/img/landing/bg-img-sec.png")}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}
