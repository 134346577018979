import React from 'react'
import {Input} from "reactstrap";
import {updateAction, useRuleDispatch, useRuleState} from "../../../../context/rule/RuleIndex";
import Select from "react-select";
import {ruleActions} from "../FeedExportRuleFields/FeedExportRuleActionProperties";
import {FeedExportRuleActionAction} from "./FeedExportRuleActionAction";
import {FeedExportRuleActionPropertiesSelect} from "../FeedExportRuleFields/FeedExportRuleActionPropertiesSelect";
import {textTransformOptions} from "../FeedExportRuleFields/FeedExportRuleActionTextTransformProperties";
import {FeedExportRuleActionFindValue} from "./FeedExportRuleActionFindValue";
import {FeedExportRuleActionCombineValue} from "./FeedExportRuleActionCombineValue";

export const FeedExportRuleAction = ({position, type}) => {
  const ruleState = useRuleState()
  const ruleDispatch = useRuleDispatch()

  const handleFieldChange = (e) => {
    const {value} = e;
    updateAction(ruleDispatch,
      {
        action: {...ruleState[type][position], 'field': value},
        position: position,
        type: type,
      })
  }

  const handleActionChange = (e) => {
    const {value} = e;
    updateAction(ruleDispatch,
      {
        action: {
          ...ruleState[type][position],
          'action': value,
          'value': '',
        },
        position: position,
        type: type,
      })
  }

  const handleValueChange = (e) => {
    const {value} = e.target;
    updateAction(ruleDispatch,
      {
        action: {...ruleState[type][position], 'value': value},
        position: position,
        type: type,
      })
  }

  const handleFieldValueChange = (e) => {
    const {value} = e;
    updateAction(ruleDispatch,
      {
        action: {...ruleState[type][position], 'value': value},
        position: position,
        type: type,
      })
  }

  const handleSearchUsing = (e) => {
    const {value} = e;
    updateAction(ruleDispatch,
      {
        action: {
          ...ruleState[type][position],
          value: {
            ...ruleState[type][position].value,
            using: value
          }
        },
        position: position,
        type: type,
      })
  }

  const handleSearch = (name, value) => {
    updateAction(ruleDispatch,
      {
        action: {
          ...ruleState[type][position],
          value: {
            ...ruleState[type][position].value,
            [name]: value
          }
        },
        position: position,
        type: type,
      })
  }

  const renderSimpleValueInput = () => {
    switch (ruleState[type][position]?.action) {
      case 'NOTHING':
      case'REMOVE':
        return (<></>)
      case 'SET':
      case 'APPEND':
      case 'PREPEND':
        return (<Input
          value={ruleState[type][position]?.value}
          onChange={handleValueChange}
          name={"value"}
          type="text"
        />)
      case 'COPY':
        return (<FeedExportRuleActionPropertiesSelect
          value={ruleState[type][position]?.value}
          onChange={handleFieldValueChange}
        />)
      case 'TEXT_TRANSFORM':
        return (<>
          <Select
            value={textTransformOptions.find(listOperator => listOperator.value === ruleState[type][position]?.value)}
            options={textTransformOptions}
            onChange={handleFieldValueChange}
          />
        </>)
      case 'FIND':
        return (<>
          <div className='row'>
            <div md={2} className="col-2 d-flex align-items-center">
              <p className='m-0'>in</p>
            </div>
            <div md={3} className="col-sm">
              <FeedExportRuleActionPropertiesSelect
                value={ruleState[type][position]?.value?.using}
                onChange={handleSearchUsing}/>
            </div>
          </div>
        </>)
      default:
        return (<></>)
    }
  }

  const renderComplexValueInput = () => {
    switch (ruleState[type][position]?.action) {
      case 'COMBINE':
        return (<>
          <FeedExportRuleActionCombineValue
            value={ruleState[type][position]?.value === '' ? [] : ruleState[type][position]?.value}
            position={position}
            type={type}
          />
        </>)
      case 'FIND':
        return (<>
          <FeedExportRuleActionFindValue
            value={ruleState[type][position]?.value}
            onChange={handleSearch}
          />
        </>)
      default:
        return (<></>)
    }
  }

  const pretext = () => {
    return position === 0 ? (type === 'actions' ? 'Then' : 'Else') : 'And'
  }

  return (
    <>
      <div className='d-flex align-items-center gap-4 justify-content-start mb-3 w-100'>
        <div md={1}
             className='fe-height-statement d-flex align-items-center justify-content-evenly col-md-1 bg-gray-light rounded text-sm'>
          {pretext()}
        </div>
        <div className='w-100'>
          <div className='row d-flex align-items-center p-2'>
            <div md={3} className="col-sm">
              <>
                <div>
                  <FeedExportRuleActionPropertiesSelect
                    value={ruleState[type][position]?.field}
                    onChange={handleFieldChange}
                  />
                </div>
              </>
            </div>
            <div md={3} className="col-sm">
              {
                ruleState[type][position]?.field !== 'all' ?
                  <div>
                    <Select
                      value={ruleActions.find(listOperator => listOperator.value === ruleState[type][position]?.action)}
                      options={ruleActions}
                      onChange={handleActionChange}
                    />
                  </div>
                  :
                  <div>
                    <Select
                      value={ruleActions.find(listOperator => listOperator.value === ruleState[type][position]?.action)}
                      options={ruleActions.filter(action => action.value === 'NOTHING' || action.value === 'REMOVE')}
                      onChange={handleActionChange}
                    />
                  </div>
              }
            </div>
            <div md={3} className="col-sm">
              {
                ruleState[type][position]?.field !== 'all' ?
                  <div>
                    {renderSimpleValueInput()}
                  </div> : ''
              }
            </div>

          </div>

          {renderComplexValueInput()}
        </div>
        <div md={1} className='col-1 d-flex align-items-center p-2'>
          <FeedExportRuleActionAction position={position} type={type}/>
        </div>
      </div>
    </>
  )
}
