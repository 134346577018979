import React, {useEffect, useState} from "react";
import classNames from "classnames";

export const DataSourceXMLNodes = ({xml, setSelectedNode}) => {
  const [xmlNodes, setXmlNodes] = useState(xml)
  const [selectedKey, setSelectedKey] = useState(null);

  const handleKeySelection = (key) => {
    setSelectedKey(key);
    setSelectedNode(key)
  };

  useEffect(() => {
    setXmlNodes(xml)
  }, [xml])

  const renderData = (data, depth = 0, parent = '') => {
    return (
      <ul className={'selectable_list'}>
        {data.map(([key, value], index) => {
          const node = parent === '' ? key : parent + '|' + key
          const isObject = typeof value === 'object' && value !== null;
          const isArray = Array.isArray(value) && value !== null;
          const isSelected = node === selectedKey;

          return (
            <li key={key}>
              <p
                className={classNames({
                  'ps-2 pe-2 pt-1 pb-1': true,
                  'm-0': true,
                  'selected': isSelected
                })}
                onClick={() => handleKeySelection(node)}
              >
                {isObject ? `${key}:` : `${key}`}
              </p>
              {isObject && renderData(Object.entries(value), depth + 1, node)}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <>
      <div className={'d-flex flex-column'}>

        <h2>{selectedKey}</h2>
        <div className={'d-flex flex-row'}>
          {renderData(Object.entries(xmlNodes))}
        </div>
      </div>
    </>
  );
}
