import React, {useEffect, useState} from "react";
import {Auth} from "../../services/api/Auth";

import AuthHeader from "components/Headers/AuthHeader.js";
import {useApiErrorDispatch} from "../../context/api_error/ApiErrorContext";
import {useTranslation} from "react-i18next";

const ConfirmationDetail = ({userId, confirmation}) => {
  const [isLoading, setLoading] = useState(false)
  const [title, setTitle] = useState('')
  const [lead, setLead] = useState('')
  const apiErrorDispatch = useApiErrorDispatch()
  const {t} = useTranslation('auth')

  useEffect(() => {
    setLoading(true);
    Auth.activate(userId, confirmation).then((r) => {
      if (r?.status < 400) {
        setTitle(t('SUCCESSFUL_CONFIRMATION'))
        setLead(t('LOGIN_ENTER'))
      } else {
        setTitle(t('ERROR_CONFIRMATION'))
        setLead(t('ERROR_CONFIRMATION_TEXT'))
      }
      setLoading(false);
    });
  }, [userId, confirmation, apiErrorDispatch]);

  if (isLoading) {
    return <div className="error">
      <p>Loading</p>
    </div>
  }

  return (
    <>
      <AuthHeader
        title={title}
        lead={lead}
      />
    </>
  );
}

export default ConfirmationDetail;
