import {Button, FormGroup, Input, Modal} from "reactstrap";
import React, {useState} from "react";
import {v4 as uuid4} from "uuid";
import {Companies} from "../../../services/api/Companies";
import {apiErrorRaised, useApiErrorDispatch} from "../../../context/api_error/ApiErrorIndex";
import {useNavigate} from "react-router";

const CompanyNewModal = ({showModal, setShowModal, setCreatedCompany}) => {
  const dispatch = useApiErrorDispatch();
  const [isLoading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const navigate = useNavigate()

  const [company, setCompany] = useState(
    {
      name: '',
      address: {
        address: '',
        postal_code: '',
        city: '',
        country: ''
      },
      contact_info: {
        email: '',
        telephone: '',
      },
      subscription: {
        name: 'Basic'
      },
      features: [
        {
          name: ''
        }
      ]
    }
  )

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true)

    if (company.name) {
      setLoading(true);
      let companyId = uuid4();
      Companies.put(companyId, company).then((r) => {
        setLoading(false);
        if (r?.status < 400) {
          setCreatedCompany(true)
          setShowModal(false)
          navigate(`/admin/companies/${companyId}`)
        } else {
          apiErrorRaised(dispatch, r?.response)
        }
      });
    }
  }

  const handleChange = (e) => {
    const {name, value} = e.target;
    setCompany(company => ({...company, [name]: value}));
  }

  return (
    <>
      <Modal
        size="md"
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={() => setShowModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            New Company
          </h6>
          <button
            aria-label="Close"
            className="btn-close"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
          </button>
        </div>
        <div className="modal-body pb-2">
          <FormGroup
            className="">
            <label
              className="form-control-label"
            >
              Company Name
            </label>
            <Input
              placeholder="Trainor"
              type="text"
              valid={submitted && company.name.length > 0}
              invalid={submitted && !company.name}
              name="name"
              value={company.name}
              onChange={handleChange}
            />
            <div className="invalid-feedback">
              Please provide a name
            </div>
          </FormGroup>
        </div>
        <div className="modal-footer justify-content-end pb-2 pt-1">
          <Button color="primary" type="button"
                  onClick={handleSubmit}>
            {isLoading &&
              <span className="spinner-border spinner-border-sm me-1"/>}
            Create
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default CompanyNewModal;
