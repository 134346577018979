import {Button, Card, Col, Container, Row} from "reactstrap";
import {scrollToTargetDiv} from "./scrollUtils";
import React from "react";
import {useTranslation} from "react-i18next";

export const LandingWhyFeedest = () => {
  const {t} = useTranslation('landing')
  return (
    <>
      <div className="py-6 bg-wf">
        <Row className="justify-content-center text-center pt-5 pb-5 px-2">
          <Col>
            <h2 className={'landing_header'}>
              {t('WHY_FEEDEST_1')}
            </h2>
            <p className="mt-4">
              {t('WHY_FEEDEST_2')}
            </p>
          </Col>
        </Row>
        <Container>
          <div className="d-flex gap-3 custom-p1 responsive">
            {/* CARD 1 */}
            <Card className="col-sm card-lift--hover">
              <div className="p-5 d-flex flex-column flex-grow-1">
                <div className="text-center">
                  <img
                    alt="..."
                    className="img-fluid w-50"
                    src={require("assets/img/landing/investment.png")}
                  />
                </div>
                <div>
                  <h3 className="display-6 text-primary text-center">
                    <strong>25%</strong> {t('ROAS')}
                  </h3>
                  <p className="mt-4 text-center">
                    {t('ROAS_DESCRIPTION')}
                  </p>
                </div>
              </div>
            </Card>

            {/* CARD 2 */}
            <Card className="col-sm card-lift--hover">
              <div className="p-5 d-flex flex-column flex-grow-1">
                <div className="text-center">
                  <img
                    alt="..."
                    className="img-fluid w-50"
                    src={require("assets/img/landing/ads.png")}
                  />
                </div>
                <div>
                  <h3 className="display-6 text-primary text-center">
                    <strong>30%</strong> {t('CTR')}
                  </h3>
                  <p className="mt-4 text-center">
                    {t('CTR_DESCRIPTION')}
                  </p>
                </div>
              </div>
            </Card>
            {/* CARD 3 */}
            <Card className="col-sm card-lift--hover">
              <div className="p-5 d-flex flex-column flex-grow-1">
                <div className="text-center">
                  <img
                    alt="..."
                    className="img-fluid w-50"
                    src={require("assets/img/landing/time.png")}
                  />
                </div>
                <div>
                  <h3 className="display-6 text-pink-dark text-center">
                    <strong>-40%</strong> {t('TIME')}
                  </h3>
                  <p className="mt-4 text-center">
                    {t('TIME_DESCRIPTION')}
                  </p>
                </div>
              </div>
            </Card>


          </div>
          <Row className="justify-content-center text-center pt-5 pb-5">
            <Col>
              <Button color={"primary"} onClick={() => scrollToTargetDiv('submitForm')}>{t('MORE_INFO')}</Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
