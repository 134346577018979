import React from "react";
import {Card, CardBody} from "reactstrap";

export const DataSourceTypeItem = ({type, selectedType, setSelectedType}) => {

  const handleClick = () => {
    setSelectedType(type.value)
  }

  return (
    <>
      <div className={'m-2'}>
        <Card className={selectedType === type.value ? "asset selected" : ""} onClick={handleClick}>
          <CardBody className={'d-flex flex-column'}>
            <div className={'d-flex flex-row justify-content-center'}>
              <img
                className="w-25"
                alt="..."
                src={type.image}
              />
            </div>
            <h4 className={'text-center pt-3 m-0'}>
              {type.label}
            </h4>
          </CardBody>
        </Card>
      </div>
    </>
  )
}

