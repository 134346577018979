import React from 'react';
import {Link} from "react-router-dom";

export const RuleGroupItem = ({ruleGroup}) => {

  return (
    <>
      <div className={'p-4 border-top'}>
        <Link
          to={`/admin/companies/${ruleGroup.company_id}/projects/${ruleGroup.project_id}/rule_groups/${ruleGroup.id}`}>
          {ruleGroup.name}
        </Link>
      </div>
    </>
  )
}
