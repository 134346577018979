import React from "react";
import {Container,} from "reactstrap";
import {CompaniesList} from "../../../components/Companies/CompaniesList";

const CompaniesView = () => {
  return (
    <>
      <Container className="mt-7">
        <CompaniesList/>
      </Container>
    </>
  );
};

export default CompaniesView;
