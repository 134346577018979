import React, {useEffect, useState} from "react";
import classNames from "classnames";
import {loadCompanies, selectCompany, useImportDispatch, useImportState} from "./Context/ImportIndex";
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {Companies} from "../../services/api/Companies";
import Paginator from "../Pagination/Paginator";

export const ImportCompaniesList = () => {
  const [isLoading, setLoading] = useState(false)
  const dispatch = useApiErrorDispatch();
  const importState = useImportState()
  const importDispatch = useImportDispatch()
  const [pagination, setPagination] = useState({})
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)

  useEffect(() => {
    setLoading(true)
    Companies.index(limit, offset, null).then((r) => {
      setLoading(false)
      if (r?.status < 400) {
        loadCompanies(importDispatch, r?.data.companies)
        setPagination(r.data?.pagination);
      } else {
        setLoading(false)
        apiErrorRaised(dispatch, r?.response)
      }
    })
  }, [limit, offset])

  const handleClick = (e, offset, limit) => {
    e.preventDefault();
    setLimit(limit)
    setOffset(offset)
  }

  const handleSelectCompany = (companyId) => {
    selectCompany(importDispatch, companyId)
  }

  return (
    <>
      <h5>Companies</h5>
      <div className={'d-flex flex-column'}>
        {
          isLoading ? <span className="spinner-border spinner-border-sm me-1"/> :
            importState.companies && importState.companies.map((company, i) => {
              return (
                <div role={"button"} className={classNames({
                  'p-2 me-4 text-primary': true,
                  'bg-primary': importState.selected_company === company.id,
                  'text-white': importState.selected_company === company.id
                })}
                     key={i}
                     onClick={() => handleSelectCompany(company.id)}>
                  {company.name}
                </div>
              )
            })
        }
      </div>
      {
        importState.companies.length > 0 && <Paginator
          pagination={pagination}
          handleClick={handleClick}
          offset={offset}
          limit={limit}
          showRowOptions={false}
        />
      }
    </>
  )
}
