import React, {useEffect} from "react";
import {Container,} from "reactstrap";
import {useProjectDispatch} from "../../../context/project/ProjectContext";
import {useParams} from "react-router-dom";
import {updateCompanyAndProjectBasedOnUrl} from "../../../context/project/ProjectActions";
import * as PropTypes from "prop-types";
import {RuleGroupDetail} from "../../../components/RuleGroups/RuleGroupDetail";
import {RulesProvider} from "../../../context/rules/RulesIndex";

RuleGroupDetail.propTypes = {
  companyId: PropTypes.string,
  projectId: PropTypes.string,
  ruleGroupId: PropTypes.string
};
const RuleGroupView = () => {
  const projectDispatch = useProjectDispatch()
  const routerParams = useParams();
  useEffect(() => {
    updateCompanyAndProjectBasedOnUrl(projectDispatch, routerParams.companyId, routerParams.projectId)
  }, [])

  return (
    <>
      <Container className="mt-5" fluid>
        <RulesProvider>
          <RuleGroupDetail
            companyId={routerParams.companyId}
            projectId={routerParams.projectId}
            id={routerParams.id}
          />
        </RulesProvider>
      </Container>
    </>
  );
};

export default RuleGroupView;
