export const setValidationError = (canvas, id, newError) => {
  let selectedObject = canvas.objects.find(canvasObject => canvasObject.object.id === id);
  selectedObject = {
    ...selectedObject,
    errors: [...(selectedObject?.errors ?? []).filter((error) => error.code !== newError.code), newError],
  }
  return {
    ...canvas,
    selectedObject: selectedObject,
    objects: canvas.objects.map((canvasObject) => {
      if (canvasObject.object.id !== id) {
        return canvasObject;
      }
      return selectedObject;
    })
  }
}
