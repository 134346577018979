import {useCanvasDispatch, useCanvasState} from "../../../../../context/canvas/CanvasIndex";
import {updateData} from "../../../../../context/canvas/CanvasActions";
import {Input} from "reactstrap";
import React from "react";

export const CanvasObjectInfoSidebarDetailTextFitTextOnArea = () => {
  const canvasState = useCanvasState()
  const canvasDispatch = useCanvasDispatch()
  const templateObject = canvasState.selectedObject

  const handleToggle = (e) => {
    const {name, checked} = e.target;
    updateData(
      canvasDispatch,
      templateObject?.id,
      name,
      checked ? 1 : 0,
    )
  }

  return (
    <>
      <div className={'d-flex flex-row justify-content-between mt-1 mb-1'}>
        <strong className={'mt-1'}>Fit Text on Area</strong>
        <Input
          placeholder="50"
          className={'me-2'}
          type="checkbox"
          name="fitTextOnArea"
          onChange={handleToggle}
          checked={templateObject?.object.data.fitTextOnArea ?? 0}
        />
      </div>
    </>
  )
}
