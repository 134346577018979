import React, {useEffect, useState} from 'react';
import {Button, Card, CardBody, CardFooter, CardHeader} from "reactstrap";
import {apiErrorRaised, useApiErrorDispatch} from "../../../context/api_error/ApiErrorIndex";
import {Products} from "../../../services/api/Products";
import TemplateProductPreview from "./TemplateProductPreview";
import {useTableFiltersDispatch, useTableFiltersState} from "../../../context/table_filters/TableFiltersContext";
import Paginator from "../../Pagination/Paginator";
import {TableFilters} from "../../TableFilters/TableFilters";
import {useProjectState} from "../../../context/project/ProjectIndex";
import {Templates} from "../../../services/api/Templates";
import {v4 as uuid4} from "uuid";

const TemplatePreview = ({templateId}) => {
  const projectState = useProjectState()
  const dispatchApiError = useApiErrorDispatch();
  const [isLoading, setLoading] = useState(false)
  const [products, setProducts] = useState([]);
  const [offset, setOffset] = useState(0);
  const [pagination, setPagination] = useState({})
  const [limit, setLimit] = useState(30)
  const [isFiltersCollapsed, setFiltersCollapsed] = useState(true)
  const tableFilters = useTableFiltersState()
  const tableFiltersDispatch = useTableFiltersDispatch()

  const handleClick = (e, offset, limit) => {
    e.preventDefault();
    setLimit(limit)
    setOffset(offset)
  }

  const toggleCollapsed = () => {
    setFiltersCollapsed(!isFiltersCollapsed)
  }

  const handlePreviewClick = () => {
    setLoading(true);
    Templates.preview(projectState.companyId, projectState.projectId, templateId, {
      'products': products.map((product) => product.id)
    }).then((r) => {
      setLoading(false)
      if (r?.status < 400) {
        const mergedProducts = products.map(product => {
          const preview = r.data.find(preview => product.id === preview.id);
          if (preview === undefined) {
            return product
          }
          return {...product, image: {...product.image, url: process.env.REACT_APP_BASE_URL + preview.url}};
        });
        setProducts(mergedProducts)
      } else {
        apiErrorRaised(dispatchApiError, r?.response)
      }
    })
  }

  useEffect(() => {
    setLoading(true);
    let filters = [...tableFilters]
    filters.push({
      field: 'status',
      id: uuid4(),
      label: 'Status',
      operator: '=',
      type: "integer",
      value: "1",
    })
    Products.index(projectState.companyId, projectState.projectId, limit, offset, filters).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        setProducts(r.data?.products.filter(pr => pr?.image?.md5 !== ''));
        setPagination(r.data?.pagination);
      } else {
        apiErrorRaised(dispatchApiError, r?.response)
      }
    });
  }, [templateId, dispatchApiError, limit, offset, tableFilters]);

  return (
    <>
      <Card className="shadow">
        <CardHeader className="border-0 d-flex flex-row justify-content-between">
          <div>
            <h3 className="mb-0">Products</h3>
          </div>
          <div>
            <Button outline color="primary" onClick={toggleCollapsed}>
              <span className="fa-filter fa me-1"/>
              Filter
            </Button>
            <Button className={'g-2'} color="primary" onClick={handlePreviewClick} disabled={isLoading}>
              {
                isLoading ? <span className="spinner-border spinner-border-sm me-2"/> : <></>
              }
              Preview
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <TableFilters entity={'product'} isCollapsed={isFiltersCollapsed}/>
          <div className={'products-grid-container position-relative'}>
            <div className={'products-cards__list position-relative'}>
              {
                products && products.map((product, i) => {
                  return (
                    <TemplateProductPreview key={i} product={product} isLoading={isLoading}/>
                  );
                })
              }

            </div>
            {
              isLoading ?
                <div
                  className={'position-absolute w-100 h-100 top-0 bg-blur d-flex justify-content-center align-items-center rounded-2'}>
                  <span className="spinner-border spinner-border-lg"/>
                </div> : <></>
            }
          </div>
        </CardBody>
        <CardFooter>
          <Paginator
            pagination={pagination}
            handleClick={handleClick}
            offset={offset}
            limit={limit}
          />
        </CardFooter>
      </Card>
    </>
  );
}

export default TemplatePreview;
