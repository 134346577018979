import {get} from './config/base';
import {handleError, handleResponse} from "./config/response";
import {urlParser} from "./config/utils";

const url = 'companies/{companyId}/projects/{projectId}/analytics/';

export const Analytics = {
  insights: (companyId, projectId, params = null) =>
    get(`${urlParser(url, companyId, projectId)}insights`, {params: params})
      .then(handleResponse)
      .catch(handleError),
}
