import {Table} from "reactstrap";
import React from "react";
import {PlaceholderTableHead} from "./PlaceholderTableHead";
import {PlaceholderTableBody} from "./PlaceholderTableBody";

export const PlaceholderTable = ({rows = 5, columns = 7, firstEmpty = true}) => {
  return (
    <>
      <div className={'position-absolute w-100 h-100 bg-blur d-flex justify-content-center align-items-center'}>
        <span className="spinner-border spinner-border-lg"/>
      </div>
      <Table className={'align-items-center table-flush position-relative'}>

        <PlaceholderTableHead
          firstEmpty={firstEmpty}
          columns={columns}
        />
        <PlaceholderTableBody
          rows={rows}
          columns={columns}
          firstEmpty={firstEmpty}
        />
      </Table>
    </>
  )
}
