import React, {useEffect, useState} from 'react';
import {Button, Row} from "reactstrap";
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {Companies} from "../../services/api/Companies";
import {Projects} from "../../services/api/Projects";
import {CompanyProjectElement} from "./CompanyProjectElement";
import {selectCompany, useProjectDispatch, useProjectState} from "../../context/project/ProjectIndex";
import {Link} from "react-router-dom";
import {useAuthState} from "../../context/auth/AuthIndex";

const CompanyDetail = ({companyId}) => {
  const authState = useAuthState()
  const apiErrorDispatch = useApiErrorDispatch()
  const projectDispatch = useProjectDispatch()
  const projectState = useProjectState()
  const dispatch = useApiErrorDispatch()
  const [isLoading, setLoading] = useState(false)
  const [company, setCompany] = useState({})
  const [projects, setProjects] = useState([])

  useEffect(() => {
    setLoading(true);
    Companies.get(companyId).then((r) => {
      if (r?.status < 400) {
        setCompany(r.data);
        selectCompany(projectDispatch, companyId, r.data.name, r.data.features.reduce((features, feature) => {
            features[feature.name] = true
            return features
          }, {}),
          authState.user.role, authState.user.id,)
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
      setLoading(false);
    });
    Projects.index(companyId).then((r) => {
      if (r?.status < 400) {
        setProjects(r.data.sort(function (a, b) {
            if (a.status === 'ACTIVE') {
              return -1;
            } else {
              return 1
            }
          })
        )
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
      setLoading(false);
    });
  }, [companyId, dispatch, apiErrorDispatch]);

  if (isLoading) {
    return <div className="error">
      <p>Loading</p>
    </div>
  }

  return (
    <>
      <div className={'view_header'}>
        <div className={'back-button'}>{
          <Link
            to={`/admin/companies/`}>
            <Button className={'btn-icon-only text-dark'}>
              <span className={'fa fa-arrow-left'}/>
            </Button>
          </Link>
        }</div>
        <div className={'title'}>
          <h1>{company.name}</h1>
        </div>
        <div className={'button_actions'}>
          <Link
            to={`/admin/companies/${companyId}/users`}>
            <Button className="btn-icon me-2" color="primary" outline type="button">
                  <span className="btn-inner--icon mr-1 fa-solid fa-user">
                  </span>
              <span className="btn-inner--text">Users</span>
            </Button>
          </Link>
          <Link to={`/admin/companies/${projectState.companyId}/projects/new?from=${window.location.pathname}`}>
            <Button color={'primary'}>Create Project</Button>
          </Link>
        </div>
      </div>
      <Row className={'mt-3'}>
        {
          projects.map((project, id) => {
            return (
              <CompanyProjectElement
                key={id}
                project={project}
              />
            )
          })
        }
      </Row>
    </>
  );
}

export default CompanyDetail;
