import {get,post} from './config/base';
import {handleError, handleResponse} from "./config/response";
import {urlParser} from "./config/utils";

const url = 'companies/{companyId}/projects/{projectId}/products/';

export const Products = {
  index: (companyId, projectId, limit, offset, filters) =>
    get(urlParser(url, companyId, projectId), {params: {limit: limit, offset: offset, filters: filters}})
      .then(handleResponse)
      .catch(handleError),
  detail: (companyId, projectId, productId) =>
    get(urlParser(`${urlParser(url, companyId, projectId)}${productId}`))
      .then(handleResponse)
      .catch(handleError),
  manuallyDisable: (companyId, projectId, productId) =>
    post(urlParser(`${urlParser(url, companyId, projectId)}${productId}/manually_disable`))
      .then(handleResponse)
      .catch(handleError),
  revertManuallyDisable: (companyId, projectId, productId) =>
    post(urlParser(`${urlParser(url, companyId, projectId)}${productId}/revert_manually_disabled`))
      .then(handleResponse)
      .catch(handleError),
}
