import {useProjectDispatch} from "../../../context/project/ProjectContext";
import {useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {updateCompanyAndProjectBasedOnUrl} from "../../../context/project/ProjectActions";
import {Container} from "reactstrap";
import {EventsManagerDashboard} from "../../../components/EventsManager/EventsManagerDashboard";

export const EventsManagerView = () => {
  const projectDispatch = useProjectDispatch()
  const routerParams = useParams();
  useEffect(() => {
    updateCompanyAndProjectBasedOnUrl(projectDispatch, routerParams.companyId, routerParams.projectId)
  }, [])
  return (
    <>
      <Container className="mt-5" fluid>
        <EventsManagerDashboard/>
      </Container>
    </>
  );
};
