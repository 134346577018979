import React from "react";
import {createRoot} from "react-dom/client";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/vendor/nucleo/css/nucleo.css";
// core styles
import "assets/scss/app.scss?v1.2.0";

import App from "./App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {ApiErrorProvider} from "./context/api_error/ApiErrorIndex";

const container = document.getElementById('app')
const root = createRoot(container)
root.render(
  <>
    <ApiErrorProvider>
      <App/>
    </ApiErrorProvider>
  </>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
