import {defaults} from "chart.js";

const {enUS} = require("date-fns/locale");

const mode = "light";
const fonts = {
  base: "Nunito Regular",
};

// Colors
export const chartColors = {
  primary: '#553AC0',
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529",
  },
  theme: {
    blue: "#5e72e4",
    indigo: "#5603ad",
    purple: "#8965e0",
    pink: "#f3a4b5",
    red: "#f5365c",
    orange: "#fb6340",
    yellow: "#ffd600",
    green: "#2dce89",
    teal: "#11cdef",
    cyan: "#2bffc6",
    dataHistory: "#FF6086",
    feedManager: "#FF7300",
    designer: "#553AC0",
    "purple-header": "#42325D",
    "background-header": "#F2F2F2",
  },
  metrics: {
    0: "#00B4CCFF",
    1: "#FF7169",
    2: "#79C411",
    3: "#E88F00",
    4: "#8878E8",
  },
  products: {
    0: "#553AC0",
    1: "rgba(106,84,210,0.75)",
    2: "rgba(138,127,183,0.5)",
  },
  price: {
    0: "#2bffc6",
    1: "rgba(104,157,143,0.75)",
    2: "rgba(140,169,164,0.5)",
  },
  salePrice: {
    0: "#FF6086",
    1: "rgba(232,157,172,0.75)",
    2: "rgba(183,149,159,0.5)",
  },
  black: "#12263F",
  white: "#FFFFFF",
  transparent: "transparent",
  severities: {
    'UNKNOWN': "#ced4da",
    'INFO': "#5e72e4",
    'WARNING': "#ffd600",
    'ERROR': "#f5365c",
    'CRITICAL': "#212529",
  },
  platforms: {
    'internal': "#f5365c",
    'facebook': "#5e72e4",
    'google': "#ffd600",
  },
  validProducts: "#8FDC24",
  invalidProducts: "#FF7169",
  topLevels: {
    0: "#FFA113",
    1: "#FF7169",
    2: "#8FDC24",
    3: "#3BD1E4",
    4: "#8878E8",
  },
  gauge: [
    "#FF7169",
    "#FFA113",
    "#8FDC24",
  ],
};


export function chartOptions() {
  let options = {
    responsive: true,
    maintainAspectRatio: false,
    font: {
      family: 'NunitoRegular',
      size: 13,
      weight: 400,
    },
    defaultColor: mode === "dark" ? chartColors.gray[700] : chartColors.gray[600],
    defaultFontColor: mode === "dark" ? chartColors.gray[700] : chartColors.gray[600],
    layout: {
      padding: 0,
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true,
          padding: 16,
        },
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        titleAlign: 'center'
      },
    },
    elements: {
      point: {
        radius: 0,
        backgroundColor: chartColors.theme["designer"],
      },
      line: {
        tension: 0.4,
        borderWidth: 2,
        borderColor: chartColors.theme["designer"],
        backgroundColor: chartColors.transparent,
        borderCapStyle: "rounded",
      },
      bar: {
        backgroundColor: chartColors.theme["warning"],
      },
    },

  }
  parseOptions(defaults, options)
}

export function parseOptions(parent, options) {
  for (let item in options) {
    if (typeof options[item] !== "object") {
      parent[item] = options[item];
    } else {
      parseOptions(parent[item], options[item]);
    }
  }
}

