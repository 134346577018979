/*eslint-disable*/
import React, {useEffect} from "react";
// react library for routing
import {useNavigate, useParams} from "react-router-dom";
// reactstrap components
// core components
import {useProjectState} from "../../../context/project/ProjectIndex";
import {useAuthState} from "../../../context/auth/AuthIndex";
import {Landing} from "../../../components/Landing/Landing";
import i18n from "i18next";

const HomeView = ({language}) => {
  const projectState = useProjectState()
  const authState = useAuthState()
  const navigate = useNavigate()
  const routerParams = useParams();

  useEffect(() => {
    if (routerParams.language) {
      i18n.changeLanguage(routerParams.language)
    } else if (language !== undefined && language !== null && language !== '') {
      i18n.changeLanguage(language)
    }

    let seconds = Math.floor(new Date().getTime() / 1000)
    if (authState?.token?.exp > seconds) {
      if (projectState.companyId === null || projectState.companyId === undefined) {
        navigate(`/admin/companies/`);
      } else if (projectState.projectId === null || projectState.projectId === undefined) {
        navigate(`/admin/companies/${projectState.companyId}`);
      } else {
        navigate(`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}`);
      }
    }
  }, [projectState, authState])

  return (
    <>
      <Landing/>
    </>);
}

export default HomeView;
