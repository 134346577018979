import React, {useReducer} from 'react';
import {initialTableFilters, TableFiltersReducer} from "./TableFiltersReducer";

const TableFiltersStateContext = React.createContext();
const TableFiltersDispatchContext = React.createContext();

export function useTableFiltersState() {
  const context = React.useContext(TableFiltersStateContext);
  if (context === undefined) {
    throw new Error('useTableFiltersState must be used within a CanvasProvider');
  }

  return context;
}

export function useTableFiltersDispatch() {
  const context = React.useContext(TableFiltersDispatchContext);
  if (context === undefined) {
    throw new Error('useTableFiltersDispatch must be used within a CanvasProvider');
  }

  return context;
}

export const TableFiltersProvider = ({children}) => {
  const [TableFilters, dispatch] = useReducer(TableFiltersReducer, initialTableFilters);

  return (
    <TableFiltersStateContext.Provider value={TableFilters}>
      <TableFiltersDispatchContext.Provider value={dispatch}>
        {children}
      </TableFiltersDispatchContext.Provider>
    </TableFiltersStateContext.Provider>
  );
};
