import {Link} from "react-router-dom";
import {Button, Card, CardBody, CardFooter, CardHeader, FormGroup, Input, Row} from "reactstrap";
import React, {useEffect, useState} from "react";
import {DataSourceStepButtonGroup} from "../DataSourceStepButtonGroup/DataSourceStepButtonGroup";
import {useProjectState} from "../../../context/project/ProjectIndex";
import {DataSources} from "../../../services/api/DataSources";
import {apiErrorRaised} from "../../../context/api_error/ApiErrorActions";
import {useApiErrorDispatch} from "../../../context/api_error/ApiErrorContext";
import {v4 as uuid4} from "uuid";
import {useNavigate} from "react-router";
import {DataSourceConnectionTypeSelector} from "../DataSourceSettings/DataSourceConnectionTypeSelector";
import {CREDENTIALS_API_KEY, CREDENTIALS_API_SECRET_KEY, CREDENTIALS_ACCESS_TOKEN, CREDENTIALS_SESSION_SCOPE, CREDENTIALS_SESSION_SHOP} from "../../Connection/ShopifyConnectionCredentialKeys";
import {ConnectionMarketIdSelector} from "../ConnectionMarketIdSelector";
import classNames from "classnames";

export const DataSourceNewApiConnection = ({order}) => {
  const navigate = useNavigate()
  const projectState = useProjectState()
  const dispatch = useApiErrorDispatch();
  const [isValidConnection, setIsValidConnection] = useState(false)
  const [invalidCredentials, setInvalidCredentials] = useState(false)
  const [isCheckingConnection, setIsCheckingConnection] = useState(false)
  const [type, setType] = useState('')
  const [availableMarkets, setAvailableMarkets] = useState([])
  const [credentials, setCredentials] = useState({
    [CREDENTIALS_API_KEY]: '',
    [CREDENTIALS_API_SECRET_KEY]: '',
    [CREDENTIALS_ACCESS_TOKEN]: '',
    [CREDENTIALS_SESSION_SHOP]: '',
    [CREDENTIALS_SESSION_SCOPE]: '',
  })
  const [name, setName] = useState('')
  const [selectedMarket, setSelectedMarket] = useState({})
  const [combineField, setCombineField] = useState('')
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('credentials')) {
      setType('shopify')
      setCredentials(JSON.parse(localStorage.getItem('credentials')))
    }
  }, []);

  const handleNext = () => {
    setSubmitted(true)

    if (localStorage.getItem('credentials')) {
      localStorage.removeItem('credentials')
    }

    if (name) {
      let id = uuid4()
      const importProperties = []
      if (type === 'shopify' && selectedMarket !== {}) {
        importProperties.push({name: 'market_id', value: selectedMarket.value})
      }

      let data = {
        name: name,
        url: 'https://' + credentials[CREDENTIALS_SESSION_SHOP],
        mapping: [],
        dynamicProperties: [],
        type: 'Cyberclick\\Optimizer\\DataSource\\Domain\\Connection\\Connection',
        order: order,
        locale: projectState.locale,
        status: 0,
        combineField: combineField === '' ? null : combineField,
        importProperties: importProperties,
        data: {
          platform: type,
          credentials: JSON.stringify(credentials)
        }
      }
      DataSources.put(projectState.companyId, projectState.projectId, id, data).then((r) => {
        if (r?.status < 400) {
          navigate(`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/data_sources/${id}/mapping`)
        } else {
          apiErrorRaised(dispatch, r?.response)
        }
      });
    }
  }

  const handleNameChange = (e) => {
    const {value} = e.target;
    setName(value);
  }

  const handleMarketIdChange = (e) => {
    setSelectedMarket(e);
  }

  const handleCombieFieldChange = (e) => {
    const {value} = e.target;
    setCombineField(value);
  }

  const handleTypeChange = (type) => {
    setType(type)
  }

  const handleCredentialsChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value
    });
  }

  const handleValidateConnection = () => {
    setIsCheckingConnection(true)
    let data = {
      platform: type,
        credentials: JSON.stringify(credentials)
    }

    DataSources.getShopifyMarkets(projectState.companyId, projectState.projectId, data).then((r) => {
      if (r?.status === 200) {
        setIsValidConnection(true)
        setInvalidCredentials(false)

        let options = []
        r.data.nodes.map(market => {
          if (!market.enabled || market.webPresence === null) {
            return
          }

          let urlOption = market.webPresence?.rootUrls?.filter(
            urlOption => urlOption.locale === projectState.locale.split('_')[0]
          )

          let option = {
            value: market.id.split('/').at(-1),
            label: market.name + ' ' + (market.primary ? ' (Primary)' : ''),
            url: urlOption[0]?.url ?? 'No URL available for the current locale'
          }
          options.push(option)
        })
        setAvailableMarkets(options)
      } else {
        setIsValidConnection(false)
        setInvalidCredentials(true)
      }
      setIsCheckingConnection(false)
    });
  }

  return <>
    <div className={'d-flex flex-column'}>
      <div className={'d-flex flex-row justify-content-between'}>
        <div className={'d-flex flex-row'}>
          <Link
            to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/data_sources/new/select_type`}>
            <Button className={'btn-icon-only text-dark'}>
              <span className={'fa fa-arrow-left'}/>
            </Button>
          </Link>
          <h1 className={'ms-4'}>New</h1>
        </div>
        <DataSourceStepButtonGroup step={'settings'}/>
      </div>
      <div className={'mt-4'}>
        <Card className={'flex-grow-1 d-flex flex-column'}>
          <DataSourceConnectionTypeSelector typeValue={type} handleTypeChange={handleTypeChange}/>
          <div className={'ms-5 me-5 d-flex flex-row'}>
            <FormGroup
              className="mb-3 flex-basis-50">
              <label
                className="form-control-label"
              >
                Name
              </label>
              <Input
                placeholder="Name"
                type="text"
                valid={submitted && name.length > 0}
                invalid={submitted && !name}
                name="name"
                value={name}
                onChange={handleNameChange}
              />
              <div className="invalid-feedback">
                Please provide a name
              </div>
            </FormGroup>
          </div>

          {
            order !== 1 ?
              <div className={'d-flex flex-row ms-5 me-5'}>
                <FormGroup
                  className="mb-3 flex-basis-50">
                  <label
                    className="form-control-label"
                  >
                    Combine Field
                  </label>
                  <Input
                    placeholder="Field"
                    type="text"
                    name="combineField"
                    value={combineField}
                    onChange={handleCombieFieldChange}
                  /> </FormGroup>
              </div> : <></>
          }

          <div className={'d-flex flex-column ms-5 me-5'}>
            <Card className={'w-100'}>
              <CardHeader>
                <h2>Credentials</h2>
              </CardHeader>
              <CardBody>
                <Row>
                  <FormGroup
                    className="mb-3 flex-basis-50">
                    <label className="form-control-label"> Api Key </label>
                    <Input
                      placeholder=""
                      type="text"
                      name={CREDENTIALS_API_KEY}
                      value={credentials[CREDENTIALS_API_KEY]}
                      invalid={submitted && !credentials[CREDENTIALS_API_KEY]}
                      onChange={handleCredentialsChange}
                    />
                    <div className="invalid-feedback">
                      Please provide a value
                    </div>
                  </FormGroup>
                  <FormGroup
                    className="mb-3 flex-basis-50">
                    <label className="form-control-label"> Api Secret Key </label>
                    <Input
                      placeholder=""
                      type="text"
                      name={CREDENTIALS_API_SECRET_KEY}
                      value={credentials[CREDENTIALS_API_SECRET_KEY]}
                      invalid={submitted && !credentials[CREDENTIALS_API_SECRET_KEY]}
                      onChange={handleCredentialsChange}
                    />
                    <div className="invalid-feedback">
                      Please provide a value
                    </div>
                  </FormGroup>
                  <FormGroup
                    className="mb-3 flex-basis-50">
                    <label className="form-control-label"> Access Token </label>
                    <Input
                      placeholder=""
                      type="text"
                      name={CREDENTIALS_ACCESS_TOKEN}
                      value={credentials[CREDENTIALS_ACCESS_TOKEN]}
                      invalid={submitted && !credentials[CREDENTIALS_ACCESS_TOKEN]}
                      onChange={handleCredentialsChange}
                    />
                    <div className="invalid-feedback">
                      Please provide a value
                    </div>
                  </FormGroup>
                  <FormGroup
                    className="mb-3 flex-basis-50">
                    <label className="form-control-label"> Session Shop </label>
                    <Input
                      placeholder=""
                      type="text"
                      name={CREDENTIALS_SESSION_SHOP}
                      value={credentials[CREDENTIALS_SESSION_SHOP]}
                      invalid={submitted && !credentials[CREDENTIALS_SESSION_SHOP]}
                      onChange={handleCredentialsChange}
                    />
                    <div className="invalid-feedback">
                      Please provide a value
                    </div>
                  </FormGroup>
                  <FormGroup
                    className="mb-3 flex-basis-50">
                    <label className="form-control-label"> Session Scope </label>
                    <Input
                      placeholder=""
                      type="text"
                      name={CREDENTIALS_SESSION_SCOPE}
                      value={credentials[CREDENTIALS_SESSION_SCOPE]}
                      invalid={submitted && !credentials[CREDENTIALS_SESSION_SCOPE]}
                      onChange={handleCredentialsChange}
                    />
                    <div className="invalid-feedback">
                      Please provide a value
                    </div>
                  </FormGroup>
                </Row>

                {invalidCredentials ?
                  <div className="invalid-credentials m-2 text-red">
                    Invalid credentials. Review the fields and try again
                  </div> : <></>
                }
                <div className={'d-flex flex-basis-50 justify-content-end'}>
                  <Button color={isValidConnection ? 'success' : 'primary'} className={'btn-icon ms-2'}
                          onClick={handleValidateConnection}>
                  <span className={classNames({
                    'fa fa-solid me-2': true,
                    'fa-arrows-rotate': !isValidConnection && !isCheckingConnection,
                    'fa-check': isValidConnection && !isCheckingConnection
                  })}/>
                    {
                      isCheckingConnection === true ? <span className="spinner-border spinner-border-lg me-1"/> : <></>
                    }
                    {
                      isCheckingConnection ? '' :isValidConnection ? 'Valid' : 'Validate credentials'
                    }
                  </Button>
                </div>
              </CardBody>
            </Card>
          </div>

          {isValidConnection && type === 'shopify' ? <div className={'d-flex flex-column ms-5 me-5'}>
            <Card className={'w-100'}>
              <CardHeader>
                <h2>Shopify Market</h2>
              </CardHeader>
              <CardBody>
                <FormGroup className="mb-3 flex-basis-50">
                  <ConnectionMarketIdSelector
                    value={selectedMarket}
                    handleChange={handleMarketIdChange}
                    options={availableMarkets}
                    disabled={isCheckingConnection}
                  />
                </FormGroup>
                <Row>
                  <FormGroup
                    className="mb-3 flex-basis-50">
                    <label
                      className="form-control-label"
                    >
                      Market URL
                    </label>
                    <Input
                      placeholder="Market ID"
                      type="text"
                      name="marketURL"
                      value={selectedMarket?.url}
                      disabled={true}
                    />
                  </FormGroup>
                </Row>
              </CardBody>
            </Card>
          </div> : <></>}

          <CardFooter>
            <div className={'d-flex flex-row justify-content-end'}>
              <Button color="primary" type="button" onClick={handleNext} hidden={!isValidConnection}>
              Next
                </Button>
              </div>
            </CardFooter>
        </Card>
      </div>
    </div>
  </>
}
