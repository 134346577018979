import {format} from "date-fns";
import {enUS} from "date-fns/locale";

export const formatFloat = (number, multiplier = true) => {
  if (number === null || number === 'null') {
    return null;
  }

  if (multiplier) {
    number = number / 1000;
  }
  return parseFloat(number).toFixed(2);
}

export const metrics = [
  {label: "Products", value: "products", type: 'number'},
  {label: "Price", value: "price", type: 'money'},
  {label: "Sale Price", value: "sale_price", type: 'money'},
  //{label: "View Product", value: "view_product", type: 'number'},
  //{label: "View Product Values", value: "view_product_values", type: 'money'},
  {label: "Add To Cart", value: "add_to_cart", type: 'number'},
  {label: "Add To Cart Values", value: "add_to_cart_values", type: 'money'},
  //{label: "Initiate Checkout", value: "initiate_checkout", type: 'number'},
  //{label: "Initiate Checkout values", value: "initiate_checkout_values", type: 'money'},
  {label: "Purchases", value: "purchases", type: 'number'},
  {label: "Purchase values", value: "purchase_values", type: 'money'},
]

export const getMetricsObjects = (metricsArray) => {
  const metricsObjects = []
  metricsArray.forEach((metricValue) => {
    metricsObjects.push(metrics.find(x => x.value === metricValue))
  })
  return metricsObjects
}

export const changeTimeZone = (date, timeZone) => {
  if (typeof date === 'string') {
    return new Date(
      new Date(date).toLocaleString('en-US', {
        timeZone,
      }),
    );
  }

  return new Date(
    date.toLocaleString('en-US', {
      timeZone,
    }),
  );
}

export const setQueryParams = (navigate, parameters) => {
  const queryParameters = new URLSearchParams(window.location.search)

  Object.entries(parameters).forEach((param) => {
    const [paramName, paramValue] = param;
    if (paramValue === null) {
      queryParameters.delete(paramName)
      return;
    }

    if (Array.isArray(paramValue)) {
      queryParameters.delete(paramName)
      paramValue.forEach((param) => {
        queryParameters.append(paramName, param)
      })
      return
    }
    queryParameters.set(paramName, paramValue)
  })

  navigate('?' + queryParameters.toString(), {replace: true})
}

export const isoStringToDate = (stringDate) => {
  return new Date(stringDate + 'T00:00:00')
}

export const getDates = (startDate, endDate) => {
  const dates = [];
  let currentDate = new Date(startDate);

  while (currentDate <= new Date(endDate)) {
    const formatedDate = format(currentDate, 'yyyy-MM-dd');
    dates.push(formatedDate);
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
}

const getXTimeUnit = (startDate, endDate) => {
  let difference = endDate - startDate;
  let daysDifference = difference / (1000 * 60 * 60 * 24);

  if (daysDifference < 15) {
    return 'day'
  }

  if (daysDifference < 60) {
    return 'week'
  }

  return 'month'
}

const getXAxis = (axis, startDate, endDate) => {
  return {
    ...axis, x: {
      type: 'time',
      time: {
        unit: getXTimeUnit(startDate, endDate),
        displayFormats: {
          day: 'Y-MM-dd'
        },
        tooltipFormat: 'Y-MM-dd'
      },
      min: startDate,
      max: endDate,
      adapters: {
        date: {
          locale: enUS,
        },
      },
      grid: {
        display: false
      },
      border: {
        display: false,
      }
    }
  }
}

const getYAxis = (axis, metrics, locale, currency, stacked) => {
  metrics.forEach((metric, i) => {
    axis = {
      ...axis,
      [`y${i}`]: {
        type: 'linear',
        display: true,
        stacked: stacked,
        position: i === 0 ? 'left' : 'right',
        beginAtZero: true,
        border: {
          dash: [4, 8],
          display: false,
        },
        grid: {
          display: i === 0,
        },
        ticks: metric.type === 'money' ? {
          callback: function (value, index, ticks) {
            return new Intl.NumberFormat(locale.replace('_', '-'), {
              style: 'currency',
              currency: currency,
              maximumFractionDigits: 0
            }).format(value)
          },
        } : {}
      },
    }
  })
  return axis
}

export const getAxis = (startDate, endDate, metrics, locale, currency, stacked = false) => {
  let axis = {}
  axis = getXAxis(axis, startDate, endDate)
  axis = getYAxis(axis, metrics, locale, currency, stacked)
  return axis
}
