import React, {useState} from 'react';
import {Button} from "reactstrap";
import {FeedExports} from "../../services/api/FeedExports";
import {useProjectState} from "../../context/project/ProjectIndex";
import {closeModal, openModal, useModalDispatch} from "../../context/modal/ModalIndex";
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {useNavigate} from "react-router-dom";

const ProjectRenderButton = ({id}) => {
  const projectState = useProjectState()
  const navigate = useNavigate()
  const modalDispatcher = useModalDispatch()
  const apiErrorDispatcher = useApiErrorDispatch()
  const [isLoading, setLoading] = useState(false)

  const handleClick = () => {
    openModal(modalDispatcher, {
      title: 'Delete FeedExport',
      message: 'Do you want to delete this feed export?',
      onConfirm: () => {
        setLoading(true)
        FeedExports.delete(projectState.companyId, projectState.projectId, id).then((r) => {
          if (r?.status < 400) {
            setLoading(false)
            navigate(`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/`)
          } else {
            apiErrorRaised(apiErrorDispatcher, r?.response)
          }
          closeModal(modalDispatcher)
        });
      },
      onCancel: () => closeModal(modalDispatcher),
      onBackdropClick: () => closeModal(modalDispatcher),
      buttonColor: 'danger',
      buttonText: 'Delete',
      buttonIcon: 'fa-trash'
    })
  }

  return (
    <>
      <Button className="btn-outline-danger" color={'danger'} onClick={handleClick}>
        {
          isLoading ?
            <span className="spinner-border spinner-border-sm me-1"/>
            : <>
              <span className="fa fa-trash"/> Delete
            </>
        }
      </Button>
    </>
  );
}

export default ProjectRenderButton;
