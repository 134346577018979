import React, {useState} from 'react';
import {Button, Input} from "reactstrap";

const KeyValueForm = ({addKeyValue}) => {
  const [submitted, setSubmitted] = useState(false)
  const [invalid, setInvalid] = useState(false)
  const cleanUserInput = {
    name: '',
    value: '',
  };

  const [userInput, setUserInput] = useState(cleanUserInput)

  const handleChange = (e) => {
    const {name, value} = e.target;
    setUserInput(userInput => ({...userInput, [name]: value}));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (userInput.name === undefined || userInput.name === null || userInput.value === undefined || userInput.value === null) {
      return null;
    }
    
    if (userInput.name.length > 0 && userInput.value.length > 0) {
      if (addKeyValue(userInput)) {
        setUserInput(cleanUserInput);
        setSubmitted(false)
        setInvalid(false)
      } else {
        setInvalid(true)
      }
    }
  }
  return (
    <>
      <tr>
        <td>
          <Input
            placeholder="Name"
            name="name"
            valid={submitted && userInput.name.length > 0 && !invalid}
            invalid={submitted && !userInput.name || invalid}
            value={userInput.name}
            onChange={handleChange}
          />
          <div className="invalid-feedback">
            Name should not be an used one
          </div>
        </td>
        <td>
          <Input
            placeholder="Value"
            name="value"
            valid={submitted && userInput.value.length > 0}
            invalid={submitted && !userInput.value}
            value={userInput.value}
            onChange={handleChange}
          />
        </td>
        <td>
          <Button
            className={'btn-icon-only'} color={'primary'}
            onClick={handleSubmit}
          >
            <span className="fa-solid fa-plus-circle"/>
          </Button>
        </td>
      </tr>
    </>
  );
};

export default KeyValueForm;
