import {TemplateFilterOptions} from "./TemplateFilterOptions";
import {FeedExportFilterOptions} from "./FeedExportFilterOptions";
import {ProductFilterOptions} from "./ProductFilterOptions";
import {DataSourceFilterOptions} from "./DataSourceFilterOptions";

export const FilterOptionsFactory = (entity) => {
  switch (entity) {
    case 'template':
      return TemplateFilterOptions
    case 'feed_export':
      return FeedExportFilterOptions
    case 'data_source':
      return DataSourceFilterOptions
    case 'product':
      return ProductFilterOptions
    default:
      return [
        {value: 'name', label: 'Name', type: 'string'},
        {value: 'sku', label: 'Product Id', type: 'string'},
        {value: 'brand', label: 'Brand', type: 'string'},
        {value: 'type', label: 'Product Type', type: 'string'},
        {value: 'category', label: 'Product Category', type: 'string'},
        {value: 'salePrice', label: 'Sale Price', type: 'money'},
        {value: 'price', label: 'Price', type: 'money'},
        {value: 'title', label: 'Name', type: 'string'},
        {value: 'properties', label: 'Custom Properties', type: 'properties'},
      ]
  }
}
