import {useProjectState} from "../../context/project/ProjectIndex";
import {UncontrolledAlert} from "reactstrap";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";

export const HeaderNotifications = () => {
  const projectState = useProjectState()
  const [state, setState] = useState({
    activeAlert: false,
    noProductsAlert: false,
  })

  const invalidUrl = !/^\/admin\/companies\/[\w]{8}-[\w]{4}-[\w]{4}-[\w]{4}-[\w]{12}$/.test(window.location.pathname) && !/^\/admin\/companies\/$/.test(window.location.pathname)
  useEffect(() => {
    let newState = {
      activeAlert: false,
      noProductsAlert: false,
    }
    newState.activeAlert = !(projectState.projectStatus === 'ACTIVE' || !invalidUrl || !window.location.href.includes('/admin/') || !window.location.href.includes('/auth/'));
    newState.noProductsAlert = !(projectState.projectProductsDetected !== 0 || !invalidUrl || !window.location.href.includes('/admin/') || !window.location.href.includes('/auth/'));
    setState(newState)
  }, [projectState]);

  return (
    <>
      {
        state.activeAlert ? <UncontrolledAlert color="warning" className={'mb-0'}>
          <span className="fa fa-warning me-2"/>
          <span className="alert-text">
                    <strong>Project Paused - </strong> This project is paused. Go to <strong>Project -> Settings</strong> to activate it
                  </span>
        </UncontrolledAlert> : <></>
      }
      {
        state.noProductsAlert ? <UncontrolledAlert color="danger" className={'mb-0'}>
          <span className="fa fa-warning me-2"/>
          <span className="alert-text">
              <strong>Project has no products - </strong> This project doesn't have products. Add a new Data Source.<strong>Feed Manager -> Data Sources</strong>
            <Link
              to={`/admin/companies/${projectState?.companyId}/projects/${projectState?.projectId}/data_sources/`}>
              <span className={'ms-3 fa-solid fa-arrow-up-right-from-square'}></span>
              </Link>
          </span>
        </UncontrolledAlert> : <></>
      }
    </>
  )
}
