import {Button, Modal} from "reactstrap";
import React, {useState} from "react";
import {useProjectState} from "../../context/project/ProjectIndex";
import {TemplateImport} from "./Import/TemplateImport";
import {apiErrorRaised} from "../../context/api_error/ApiErrorActions";
import {useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {Templates} from "../../services/api/Templates";
import {v4 as uuid4} from "uuid";
import {useImportState} from "../Import/Context/ImportIndex";

export const TemplateImportModal = ({showModal, setShowModal, setCreatedTemplate}) => {
  const projectState = useProjectState()
  const dispatch = useApiErrorDispatch()
  const importState = useImportState()
  const [isLoading, setLoading] = useState(false)

  const handleImport = () => {
    setLoading(true)

    let templateId = uuid4();
    Templates.import(projectState.companyId, projectState.projectId, importState.selected_object, templateId).then((r) => {
      if (r?.status < 400) {
        setCreatedTemplate(true)
        setShowModal(false)
        setLoading(false)
      } else {
        apiErrorRaised(dispatch, r?.response)
      }
      setLoading(false);
    })
  }

  return (
    <>
      <Modal
        size="xl"
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={() => setShowModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Import Template
          </h6>
          <button
            aria-label="Close"
            className="btn-close"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
          </button>
        </div>
        <div className="modal-body d-flex flex-row">
          <TemplateImport/>
        </div>
        <div className="modal-footer d-flex flex-row justify-content-between">
          <Button
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
          <Button color="primary" type="button" onClick={handleImport}>
            {isLoading &&
              <span className="spinner-border spinner-border-sm me-1"/>}
            Import
          </Button>
        </div>
      </Modal>
    </>
  );
};
