import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {useProjectState} from "../../context/project/ProjectIndex";
import {FeedExports} from "../../services/api/FeedExports";
import {Button, ButtonGroup} from "reactstrap";
import {FeedExportPlatformImage} from "./FeedExportSettings/FeedExportPlatformImage";
import classNames from "classnames";
import NotificationAlert from "../Notification/NotificationAlert";
import {closeModal, openModal} from "../../context/modal/ModalActions";
import {useModalDispatch} from "../../context/modal/ModalContext";

const FeedExportItem = ({data}) => {
  const projectState = useProjectState()
  const dispatch = useApiErrorDispatch();
  const [feedExport, setFeedExport] = useState(feedExportFromData(data));
  const [isRequestExportLoading, setRequestExportLoading] = useState(false)
  const notificationAlertRef = React.useRef(null);
  const modalDispatcher = useModalDispatch()

  const handleRequestExportClick = () => {
    openModal(modalDispatcher, {
      title: 'Request export',
      message: `Do you want to generate feed export: ${feedExport.name}?`,
      onConfirm: () => {
        setRequestExportLoading(true)
        FeedExports.requestExport(projectState.companyId, projectState.projectId, feedExport.id).then((r) => {
          setRequestExportLoading(false);
          if (r === undefined || r.status > 399) {
            apiErrorRaised(dispatch, r?.response)
          }
        });
        closeModal(modalDispatcher)
      },
      onCancel: () => closeModal(modalDispatcher),
      onBackdropClick: () => closeModal(modalDispatcher),
      buttonText: 'Yes'
    })
  }

  function feedExportFromData(data) {
    data.url = process.env.REACT_APP_CDN_URL + 'feeds/' + projectState.projectId + '/' + data.filename + '.' + data.format
    if (data.last_time === null || data.last_time === undefined) {
      data.last_time = ''
    } else {
      let d = new Date(data.last_time)
      data.last_time = d.toLocaleString(projectState.locale.replace('_', '-'), {timeZone: projectState.timezone})
    }
    return data
  }

  useEffect(() => {
    setFeedExport(feedExportFromData(data))
  }, [data])

  const handleToggle = (e) => {
    const {name, checked} = e.target;
    if (checked) {
      FeedExports.activate(projectState.companyId, projectState.projectId, feedExport.id).then((r) => {
        if (r?.status < 400) {
          setFeedExport(feedExport => ({...feedExport, [name]: checked}))
        } else {
          apiErrorRaised(dispatch, r?.response)
        }
      });
    } else {
      FeedExports.disable(projectState.companyId, projectState.projectId, feedExport.id).then((r) => {
        if (r?.status < 400) {
          setFeedExport(feedExport => ({...feedExport, [name]: checked}))
        } else {
          apiErrorRaised(dispatch, r?.response)
        }
      });
    }
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(feedExport.url);
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {"Feed Export URL"}

          </span>
          <br/>
          <span data-notify="message">
            {feedExport.url} copied
          </span>
        </div>
      ),
      type: 'success',
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  }

  return (
    <>
      <NotificationAlert ref={notificationAlertRef}/>
      <tr>
        <td>
          <Link
            to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/${feedExport.id}/edit`}>
            <p className={classNames({
              'text-center m-0': true,
              'text-gray': !feedExport.status
            })}>{feedExport.name}</p>
          </Link>
        </td>
        <td className={'feed_export_item'}>
          <FeedExportPlatformImage platform={feedExport.mapping.platform} status={feedExport.status}/>
        </td>
        <td width={"48%"} className='overflow-auto text-break'>
          <ButtonGroup aria-label="Settings" role="group" className='w-100'>
            <Button disabled={!feedExport.status} className='p-3'>
              <Link
                to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/${feedExport.id}/edit`}>
                Settings
              </Link>
            </Button>
            <div className='bg-lavender-mist d-flex align-items-center'>
              <div className="vertical-line"></div>
              {/* Línea vertical */}
            </div>
            <Button disabled={!feedExport.status} className='p-3'>
              <Link
                to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/${feedExport.id}/mapping`}>
                Mapping
              </Link>
            </Button>
            <div className='bg-lavender-mist d-flex align-items-center'>
              <div className="vertical-line"></div>
              {/* Línea vertical */}
            </div>
            <Button disabled={!feedExport.status} className='p-3'>
              <Link
                to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/${feedExport.id}/rules`}>
                Rules
              </Link>
            </Button>
            <div className='bg-lavender-mist d-flex align-items-center'>
              <div className="vertical-line"></div>
              {/* Línea vertical */}
            </div>
            <Button disabled={!feedExport.status} className='p-3'>
              <Link
                to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/${feedExport.id}/schedule_params`}>
                Schedule & URL Params
              </Link>
            </Button>
            <div className='bg-lavender-mist d-flex align-items-center'>
              <div className="vertical-line"></div>
              {/* Línea vertical */}
            </div>
            <Button disabled={!feedExport.status} className='p-3'>
              <Link
                to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/${feedExport.id}/finish`}>
                Finish
              </Link>
            </Button>
          </ButtonGroup>
        </td>
        <td className='overflow-auto text-break' width={"25%"}>
          <div className='d-flex justify-content-center'>
            <div className={'feed_export_item_actions'}>
              <div className={'feed_export_item_actions_toggle'}>
                <label className="custom-toggle me-1">
                  <input type="checkbox" name="status" checked={feedExport.status} onChange={handleToggle}/>
                  <span className="custom-toggle-slider rounded-circle"/>
                </label>
              </div>
              <div className={'feed_export_item_actions_buttons'}>
                <div className='tooltip-hover'>
                  <Button onClick={handleCopy} className={'btn-icon-only'}
                          color={feedExport.status ? 'primary' : 'secondary'}>
                    <span className="fa-solid fa-copy"/>
                  </Button>
                  <span className="tooltiptext tooltip-bg">Copy</span>
                </div>
                <div className='tooltip-hover'>
                  <a href={feedExport.url} download target="_blank" rel="noreferrer" className={'ms-2'}>
                    <Button className={'btn-icon-only'} color={feedExport.status ? 'primary' : 'secondary'}>
                      <span className="fa-solid fa-file-download"/>
                    </Button>
                  </a>
                  <span className="tooltiptext">Download</span>
                </div>
                <div className='tooltip-hover'>
                  <Button
                    onClick={handleRequestExportClick}
                    className='ms-3 btn-icon-only'
                    color={feedExport.status ? 'primary' : 'secondary'}
                  >
                    {
                      isRequestExportLoading ?
                        <span className="spinner-border spinner-border-sm me-1"/>
                        :
                        <span className="fa-solid fa-file-export"/>
                    }
                  </Button>
                  <span className="tooltiptext">Request Export</span>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </>
  )
}

export default FeedExportItem;
