import React, {useEffect, useState} from 'react';
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {Button, Input, InputGroup} from "reactstrap";
import {Assets} from "../../services/api/Assets";
import classnames from "classnames";
import AssetNewModal from "./AssetNewModal";
import Paginator from "../Pagination/Paginator";
import {AssetsGrid} from "./AssetsGrid";
import {PlaceholderAssetsGrid} from "./PlaceholderAssetsGrid";

const AssetList = ({companyId, projectId}) => {
  const dispatch = useApiErrorDispatch();
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setLoading] = useState(false)

  const [showModal, setShowModal] = useState(false);
  const [createdAsset, setCreatedAsset] = useState(false);

  const [debounceTimer, setDebounceTimer] = useState(null);
  const [pagination, setPagination] = useState({})
  const [filters, setFilters] = useState([])
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)

  const handleClickCreate = () => {
    setShowModal(true);
  }

  const searchItems = (event) => {
    const term = event.target.value;
    setSearchInput(term);

    clearTimeout(debounceTimer);
    const newTimer = setTimeout(() => {
      setFilters([
        {field: 'name', operator: 'CONTAINS', value: term}
      ])
      setOffset(0)
    }, 500);
    setDebounceTimer(newTimer);
  }

  useEffect(() => {
    setLoading(true)
    const params = {
      order: [{order_by: 'name', order_type: 'asc'}],
      limit: limit,
      offset: offset,
      filters: filters
    }

    Assets.index(companyId, projectId, params).then((r) => {
      setCreatedAsset(false)
      if (r?.status < 400) {
        setLoading(false)
        setFilteredResults(r?.data.assets)
        setPagination(r?.data.pagination)
      } else {
        setLoading(false)
        apiErrorRaised(dispatch, r?.response)
      }
    });
  }, [createdAsset, setCreatedAsset, dispatch, companyId, projectId, limit, offset, filters]);

  const handlePaginatorClick = (e, offset, limit) => {
    e.preventDefault();
    setLimit(limit)
    setOffset(offset)
  }

  return (
    <>
      <div className={'view_header'}>
        <div className={'title'}>
          <h1>Assets</h1>
        </div>
        <div className={'button_actions'}>
          <InputGroup
            className={classnames("input-group-merge", "me-3")}
          >
            <Input
              placeholder="Search"
              type="text"
              value={searchInput}
              onChange={searchItems}
            />
          </InputGroup>
          <Button color={'primary'} onClick={handleClickCreate}>Upload</Button>
        </div>
      </div>

      <div className={'mt-3'}>
        {
          isLoading ?
          <PlaceholderAssetsGrid/>
          :
          <AssetsGrid assets={filteredResults} isModalSelector={false}/>
        }
      </div>

      <div className={'mt-3 mb-3'}>
        <Paginator
          pagination={pagination}
          handleClick={handlePaginatorClick}
          offset={offset}
          limit={limit}
        />
      </div>
      <AssetNewModal
        showModal={showModal}
        setShowModal={setShowModal}
        setCreatedAsset={setCreatedAsset}
      />
    </>);
}

export default AssetList;
