import en_landing from "../../translations/en/landing.json";
import pt_landing from "../../translations/pt/landing.json";
import es_landing from "../../translations/es/landing.json";
import es_auth from "../../translations/es/auth.json";
import es_legal from "../../translations/es/legal.json";
import pt_legal from "../../translations/pt/legal.json";
import en_legal from "../../translations/en/legal.json";
import en_auth from "../../translations/en/auth.json";
import pt_auth from "../../translations/pt/auth.json";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";


const options = {
  // order and from where user language should be detected
  order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

  // keys or params to lookup language from
  supportedLngs: ['es', 'en', 'pt'],
  lookupQuerystring: 'lng',
  lookupCookie: 'language',
  lookupLocalStorage: 'language',
  lookupSessionStorage: 'language',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: 'feedest.io',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: {path: '/', sameSite: 'strict'},

  // optional conversion function to use to modify the detected language code
  convertDetectedLanguage: (lng) => (lng.replace('-', '_').split('_'))[0]
}

const resources = {
  en: {
    landing: en_landing, auth: en_auth, legal: en_legal
  }, es: {
    landing: es_landing, auth: es_auth, legal: es_legal
  }, pt: {
    landing: pt_landing, auth: pt_auth, legal: pt_legal
  }
}
i18n.use(LanguageDetector).use(initReactI18next).init({
  resources, fallbackLng: "es", debug: false, interpolation: {
    escapeValue: false,
  }, detection: options,
});


export default i18n
