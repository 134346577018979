import {Button, Col, Modal, Row} from "reactstrap";
import React from "react";
import {useCanvasDispatch, useCanvasState} from "../../../context/canvas/CanvasIndex";
import {removeCanvasObject} from "../../../context/canvas/CanvasActions";

export const CanvasActionRemoveModal = ({showModal, setShowModal}) => {
  const canvasState = useCanvasState();
  const canvasDispatch = useCanvasDispatch();

  const handleClick = () => {
    removeCanvasObject(canvasDispatch, canvasState.selectedObject.id);
    setShowModal(false);
  }

  const text = () => {
    switch (canvasState.selectedObject?.object?.type.split(/[\\ ]+/).pop()) {
      case 'TemplateImageObject':
        return 'Image';
      case 'TemplateTwoPriceObject':
        return 'Two price';
      case 'TemplateTextObject':
        return 'Text';
      default:
        return ''
    }
  }

  return (
    <>
      <Modal
        size="m"
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={() => setShowModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Remove Template Object
          </h6>
          <button
            aria-label="Close"
            className="btn-close"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
          </button>
        </div>
        <div className="modal-body">
          <Row className="">
            <Col>
              <p> Do you want remove this {text()}?</p>
            </Col>
          </Row>
        </div>
        <div className="modal-footer d-flex flex-row justify-content-around">
          <Button
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
          <Button color="danger" type="button" onClick={handleClick}>
            Remove
          </Button>
        </div>
      </Modal>
    </>
  );
};
