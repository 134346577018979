import React, {useEffect, useState} from "react";
import classNames from "classnames";
import {chartColors} from "../../../utils/Chart";
import {
  removeMetric,
  selectMetric,
  useAnalyticsDispatch,
  useAnalyticsState
} from "../../../context/analytics/AnalyticsIndex";
import {metrics, setQueryParams} from "../AnalyticsUtils";
import {useNavigate} from "react-router-dom";

export const AnalyticsChartMetricSelector = ({index}) => {
  const analyticsState = useAnalyticsState()
  const analyticsDispatch = useAnalyticsDispatch()
  const options = metrics;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options.find((option) => option.value === analyticsState.metrics[index].value));
  const navigate = useNavigate()
  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = option => () => {
    setSelectedOption(option);
    selectMetric(analyticsDispatch, index, option)
    setIsOpen(false);
  };

  useEffect(() => {
    setQueryParams(navigate, {
      'metrics[]' : analyticsState.metrics.map(function(d) { return d['value']; })
    })
  }, [analyticsState.metrics]);

  const handleRemoveMetricClick = () => {
    removeMetric(analyticsDispatch, index)
  }

  return (
    <>
      <div className={'d-flex flex-column position-relative ms-4'}>
        <div onClick={toggling}
             className={'analytics_pill ps-3 pe-3 pt-2 pb-2 d-flex flex-row align-items-center justify-content-between rounded-4'}
             style={{'backgroundColor': chartColors.primary, cursor: 'pointer', color: 'white'}}
        >
          <span className={classNames({
            'fa-solid fa fa-2x ': true,
            'fa-minus': index === 0,
            'fa-ellipsis': index === 1,
          })}/>
          <span>{selectedOption.label}</span>
          <span className={classNames({
            'fa-solid ms-2 ': true,
            'fa-chevron-down': toggling,
            'fa-chevron-up': !toggling,
          })}/>


          {

            index !== 0 ? <div> <span className={'fa-solid fa-times end-0 top-0 pt-1 pe-1'}
                                onClick={handleRemoveMetricClick}/> </div>: <></>
          }

        </div>
        {isOpen && (
          <div className={'d-flex flex-column mt-5 bg-white analytics_metric_selector w-100'}>
            {options.map(option => (
              <div onClick={onOptionClicked(option)} key={Math.random()} className={'p-3'}>
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
