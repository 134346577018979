import {Card, CardBody} from "reactstrap";
import React, {useEffect, useRef, useState} from "react";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  DoughnutController,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip
} from "chart.js";
import {chartColors, chartOptions} from "../../utils/Chart";
import {useProjectState} from "../../context/project/ProjectIndex";
import {Chart} from "react-chartjs-2";
import {ProductDiagnostic} from "../../services/api/ProductDiagnostic";
import {useAnalyticsState} from "../../context/analytics/AnalyticsIndex";
import {getAxis, getDates} from "../Analytics/AnalyticsUtils";
import {format} from "date-fns";
import {apiErrorRaised} from "../../context/api_error/ApiErrorActions";
import {useApiErrorDispatch} from "../../context/api_error/ApiErrorContext";

export const ProductDiagnosticHistory = ({platform, destination, ...props}) => {
  const projectState = useProjectState()
  const analyticsState = useAnalyticsState()
  const [data, setData] = useState({
    labels: [],
    datasets: []
  })
  const dispatch = useApiErrorDispatch();
  const [diagnostics, setDiagnostics] = useState([])
  const [isLoading, setLoading] = useState(false)
  ChartJS.register(
    DoughnutController,
    Tooltip,
    CategoryScale,
    LinearScale,
    BarElement,
    LineController,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    TimeScale,
    ArcElement,
    Filler,
  );
  const chartRef = useRef(null)
  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      chartOptions()
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [platform, destination, analyticsState.dates]);

  const fetchData = () => {
    setLoading(true);
    ProductDiagnostic.platform_status(
      projectState.companyId,
      projectState.projectId,
      {
        startDate: format(analyticsState.dates.start_date, 'yyyy-MM-dd'),
        endDate: format(analyticsState.dates.end_date, 'yyyy-MM-dd'),
        platform: platform,
        destination: destination,
      },
    ).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        setDiagnostics(
          r.data.map(diagnostic => ({
            ...diagnostic,
            invalid: diagnostic.totalProducts - diagnostic.validProducts,
            valid: diagnostic.validProducts,
            total: diagnostic.totalProducts,
          }))
        )
      } else {
        if (r?.response !== undefined) {
          apiErrorRaised(dispatch, r?.response)
        } else {
          apiErrorRaised(dispatch, {
            status: r.status,
            statusText: r.name,
            data: {
              messge: r.message,
            }
          })
        }
      }
    });
  }

  useEffect(() => {
    if (diagnostics.length > 0) {
      parseDiagnostics()
    }
  }, [diagnostics]);

  const parseDiagnostics = () => {
    let dates = getDates(analyticsState.dates.start_date, analyticsState.dates.end_date)
    let emptyValues = Object.fromEntries(dates.map((date) => [date,
      {
        valid: 0,
        total: 0,
        invalid: 0,
      }
    ]))
    let fillData = diagnostics.reduce((result, diagnostic) => {
      const {date} = diagnostic
      result[date]['valid'] = diagnostic.valid
      result[date]['invalid'] = diagnostic.invalid
      result[date]['total'] = diagnostic.total
      return result
    }, emptyValues)

    let parsedDiagnostics = dates.map((date) => {
      let data = {
        data: date
      }
      data['valid'] = fillData[date]['valid']
      data['invalid'] = fillData[date]['invalid']
      data['total'] = fillData[date]['total']
      return data;
    })
    setData({
      labels: dates,
      datasets: [
        {
          yAxisID: `y0`,
          data: parsedDiagnostics?.map(diagnostic => diagnostic['invalid']),
          label: 'Invalid Products',
          borderColor: chartColors.invalidProducts,
          backgroundColor: chartColors.invalidProducts,
        },
        {
          yAxisID: `y0`,
          label: 'Valid Products',
          data: parsedDiagnostics.map(diagnostic => diagnostic['valid']),
          borderColor: chartColors.validProducts,
          backgroundColor: chartColors.validProducts,
        },
      ]
    });
  }

  const options = {
    tension: 0.3,
    fill: "origin",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        position: 'nearest',
        enabled: true,
        titleColor: chartColors.white,
        backgroundColor: '#3F3949',
        titleFont: {
          family: 'NunitoRegular',
          size: 16
        },
        bodyFont: {
          family: 'NunitoRegular',
          size: 16
        },
        titleSpacing: 4,
        padding: 10,
        usePointStyle: true,
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            label = '  ' + label

            if (label) {
              label += ': ';
            }
            label += new Intl.NumberFormat(projectState.locale.replace('_', '-')).format(context.parsed.y);
            return label;
          },
        },
      }
    },
    scales: getAxis(analyticsState.dates.start_date, analyticsState.dates.end_date, ['1'], projectState.locale, projectState.projectCurrency, true),
  }


  return (
    <>
      <Card {...props}>
        <CardBody className={'d-flex flex-column'}>
          <div className={'d-flex flex-column flex-basis-25 p-3'}>
            <h4 className={'text-center text-dusty-violet'}>History</h4>
            {
              isLoading === true ?
                <div className={'d-flex align-items-center justify-content-center h-100'}>
                  <span className="spinner-border spinner-border-xl"/>
                </div>
                :
                <div style={{minHeight: '20rem'}}>
                  <Chart
                    type={'line'}
                    ref={chartRef}
                    data={data}
                    options={options}
                    id="export_history"
                    className="export_history_canvas"
                  /></div>
            }
          </div>
        </CardBody>
      </Card>
    </>
  )
}
