import React from 'react';
import {Badge, Card, CardBody, CardFooter, CardHeader, CardImg} from "reactstrap";
import {useProjectState} from "../../../context/project/ProjectIndex";
import {Link} from "react-router-dom";
import classNames from "classnames";

const TemplateProductPreview = ({product, isLoading}) => {
  const projectState = useProjectState()
  const parsePrice = (price) => {
    if (price === undefined || price === null) {
      return '-'
    }
    const formatter = new Intl.NumberFormat(projectState.locale.replace('_', '-'), {
      style: 'currency',
      currency: price.currency,
    });

    return formatter.format(price.amount / 1000)
  }
  return (
    <>
      <Card className={'product-card'}>
        <CardHeader className={'d-flex flex-column w-100'}>
          <Badge color="" className="badge-stroke pt-0 pb-3 product-card_header_badge">
            <i className={classNames({
              'bg-gray': product.status === 0,
              'bg-success': product.status === 1,
              'bg-danger': product.status === 2,
              'bg-warning': product.status === 3,
            })}/>
          </Badge>
          <h3 className={'product-card_header_text'}>
            <Link
              to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/products/${product.id}`}>
              {product.title}
            </Link>
          </h3>
        </CardHeader>
        <CardBody>
          <CardImg src={product.image.url}/>
        </CardBody>
        <CardFooter className={'d-flex flex-row w-100 justify-content-between'}>
          <p className={'text-dusty-violet'}>{parsePrice(product.price)}</p>
          <p className={'text-dusty-violet'}>{parsePrice(product.salePrice)}</p>
        </CardFooter>
      </Card>
    </>
  );
}

export default TemplateProductPreview;
