import React, {useEffect, useState} from "react";
import {Badge} from "reactstrap";
import classNames from "classnames";
import {selectObject, useImportDispatch, useImportState} from "../../Import/Context/ImportIndex";

export const RuleGroupImportRuleGroupItem = ({ruleGroup}) => {
  const importState = useImportState()
  const importDispatch = useImportDispatch()

  const [badgeInformation, setBadgeInformation] = useState({
    'name': '',
    'color': '',
  })


  useEffect(() => {

    switch (ruleGroup.render_status) {
      case 'PUBLISHED':
        setBadgeInformation({
          'name': 'Published',
          'color': 'success'
        })
        break;
      case 'DRAFT':
        setBadgeInformation({
          'name': 'Draft',
          'color': 'warning'
        })
        break;
      case 'EXPIRED':
        setBadgeInformation({
          'name': 'Expired',
          'color': 'danger'
        })
        break;
      case 'SCHEDULED':
        setBadgeInformation({
          'name': 'Scheduled',
          'color': 'info'
        })
        break;
      default:
        setBadgeInformation({
          'name': '',
          'color': '',
        })
    }
  }, [ruleGroup.render_status])

  const handleSelectRuleGroup = (ruleGroupId) => {
    selectObject(importDispatch, ruleGroupId)
  }

  return (
    <>
      <div role={"button"} className={classNames({
        'p-2 me-4 text-primary d-flex flex-row': true,
        'bg-primary': importState.selected_object === ruleGroup.id,
        'text-white': importState.selected_object === ruleGroup.id
      })}
           onClick={() => handleSelectRuleGroup(ruleGroup.id)}>
        <div>
          <Badge className="badge-md me-1" color={badgeInformation.color}>
            {badgeInformation.name}
          </Badge>
        </div>
        <p role={"button"}>{ruleGroup.name}</p>
      </div>
    </>
  )
}
