import React from 'react';
import {Button, ButtonGroup} from "reactstrap";
import {useProjectState} from "../../../context/project/ProjectIndex";
import {Link} from "react-router-dom";

export const DataSourceStepButtonGroup = ({id, step}) => {
  const projectState = useProjectState()
  const idIsNotPresent = () => {
    return id === null || id === undefined || id === ''
  }

  return (
    <>
      <ButtonGroup aria-label="Settings" role="group">
        <Button active={step === 'settings'} className={"fe-menu-btn  border border-lavender-mist"}>
          {
            !idIsNotPresent() ? <Link
                to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/data_sources/${id}/edit`}>
                Settings
              </Link> :
              'Settings'
          }
        </Button>
        <div className='bg-lavender-mist d-flex align-items-center'>
            <div className="vertical-line"></div> {/* Línea vertical */}
            </div>
        <Button disabled={idIsNotPresent()} active={step === 'mapping'}
                className={"fe-menu-btn  border border-lavender-mist"}>
          {!idIsNotPresent() ? <Link
            to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/data_sources/${id}/mapping`}>
            Mapping
          </Link> : 'Mapping'}
        </Button>
        <div className='bg-lavender-mist d-flex align-items-center'>
            <div className="vertical-line"></div> {/* Línea vertical */}
            </div>
        <Button disabled={idIsNotPresent()} active={step === 'details'}
                className={"fe-menu-btn  border border-lavender-mist"}>
          {!idIsNotPresent() ? <Link
            to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/data_sources/${id}/details`}>
            Details
          </Link> : 'Details'}
        </Button>
      </ButtonGroup>
    </>
  )
}
