export const ruleActions = [
    {label: 'do nothing', value: 'NOTHING'},
    {label: 'remove', value: 'REMOVE'},
    {label: 'set to value', value: 'SET'},
    {label: 'append value', value: 'APPEND'},
    {label: 'prepend value', value: 'PREPEND'},
    {label: 'combine value', value: 'COMBINE'},
    {label: 'copy value', value: 'COPY'},
    {label: 'search for value', value: 'FIND'},
    {label: 'modify text', value: 'TEXT_TRANSFORM'},
]

