import React, {useEffect} from "react";
import {Container,} from "reactstrap";
import {useProjectDispatch} from "../../../context/project/ProjectContext";
import {useParams} from "react-router-dom";
import {updateCompanyAndProjectBasedOnUrl} from "../../../context/project/ProjectActions";
import {FeedExportRules} from "../../../components/FeedExports/FeedExportRules/FeedExportRules";
import {RulesProvider} from "../../../context/rules/RulesIndex";

export default function FeedExportRulesView() {
  const projectDispatch = useProjectDispatch()
  const routerParams = useParams();
  useEffect(() => {
    updateCompanyAndProjectBasedOnUrl(projectDispatch, routerParams.companyId, routerParams.projectId)
  }, [])

  return (
    <>
      <Container className="mt-5" fluid>
        <RulesProvider>
          <FeedExportRules
            companyId={routerParams.companyId}
            projectId={routerParams.projectId}
            id={routerParams.id}
          />
        </RulesProvider>
      </Container>
    </>
  );
};
