let project = localStorage.getItem("projectData")
  ? JSON.parse(localStorage.getItem("projectData"))
  : {};

export const initialState = {
  companyId: project?.companyId,
  companyName: project?.companyName,
  companyFeatures: project?.companyFeatures,
  projectId: project?.projectId,
  projectName: project?.projectName,
  projectStatus: project?.projectStatus,
  projectCurrency: project?.projectCurrency,
  projectProductsDetected: project?.projectProductsDetected,
  locale: project?.locale,
  timezone: project?.timezone,
  properties: project?.properties,
  role: project?.role,
};

const save = () => {
  localStorage.setItem('projectData', JSON.stringify(project));
};

export const ProjectReducer = (initialState, action) => {
  switch (action.type) {
    case "COMPANY_SELECTED":
      project = {
        ...project,
        companyId: action.payload.companyId,
        companyName: action.payload.companyName,
        features: action.payload.companyFeatures,
      }
      break;
    case "ROLE_UPDATED":
      project = {
        ...project,
        role: action.payload.userRole
      }
      break;
    case "PROJECT_SELECTED":
      project = {
        ...project,
        projectId: action.payload.id,
        projectName: action.payload.name,
        projectStatus: action.payload.status,
        locale: action.payload.locale,
        timezone: action.payload.timezone,
        properties: action.payload.properties,
        projectCurrency: action.payload.currency,
        projectProductsDetected: action.payload.productsDetected,
      }
      break;
    case "UPDATE_COMPANY_AND_PROJECT":
      project = {
        ...project,
        companyId: action.payload.companyId,
        projectId: action.payload.projectId,
        projectName: action.payload.projectName,
        projectStatus: action.payload.projectStatus,
        projectCurrency: action.payload.projectCurrency,
        projectProductsDetected: action.payload.productsDetected,
        locale: action.payload.locale,
        timezone: action.payload.timezone,
        properties: action.payload.properties
      }
      break;
    case "UPDATE_COMPANY":
      project = {
        ...project,
        companyId: action.payload.companyId,
        features: action.payload.companyFeatures,
        projectId: '',
        projectProductsDetected: 0,
        locale: '',
        properties: [],
      }
      break;
    case "CLEAR_PROJECT_STATE":
      project = {
        ...project,
        companyId: null,
        companyName: null,
        features: null,
        projectId: null,
        projectName: null,
        locale: null,
        properties: null,
        projectStatus: null,
        projectProductsDetected: null,
      }
      break;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
  save();
  return project;
};
