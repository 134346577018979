import React, {useReducer} from 'react';
import {initialState, TemplateReducer} from './TemplateReducer';

const TemplateStateContext = React.createContext();
const TemplateDispatchContext = React.createContext();

export function useTemplateState() {
  const context = React.useContext(TemplateStateContext);
  if (context === undefined) {
    throw new Error('useTemplateState must be used within a TemplateProvider');
  }

  return context;
}

export function useTemplateDispatch() {
  const context = React.useContext(TemplateDispatchContext);
  if (context === undefined) {
    throw new Error('useTemplateDispatch must be used within a TemplateProvider');
  }

  return context;
}

export const TemplateProvider = ({children}) => {
  const [Template, dispatch] = useReducer(TemplateReducer, initialState);

  return (
    <TemplateStateContext.Provider value={Template}>
      <TemplateDispatchContext.Provider value={dispatch}>
        {children}
      </TemplateDispatchContext.Provider>
    </TemplateStateContext.Provider>
  );
};
