export const addFilter = (tableFilters, data) => {
  if (data.type === 'string' && (data.operator === 'CONTAINS' || data.operator === 'NOT_CONTAINS')) {
    data = {...data, value: `%${data.value}%`};
  }
  if (data.type === 'money') {
    data = {...data, value: (data.value * 1000).toString()}
  }
  if (data.type === 'properties') {
    data = {...data, field: `properties.${data.property}`, value: `%${data.value}%`}
  }
  let found = tableFilters.findIndex(filter => filter.id === data.id);

  if (found === -1) {
    return [
      ...tableFilters,
      data,
    ]
  } else {
    return tableFilters.map(filter => filter.id === data.id ? data : filter)
  }
};
