import React, {useCallback, useState} from 'react'
import {useNavigate} from "react-router-dom";
import {canvasSave, useCanvasDispatch, useCanvasState} from "../../../context/canvas/CanvasIndex";
import {apiErrorRaised, useApiErrorDispatch} from "../../../context/api_error/ApiErrorIndex";
import {useProjectState} from "../../../context/project/ProjectIndex";
import {Templates} from "../../../services/api/Templates";
import ReactBSAlert from "react-bootstrap-sweetalert";

export const CanvasActions = ({companyId, projectId, id}) => {
  const navigate = useNavigate()
  const apiErrorDispatch = useApiErrorDispatch()
  const canvasState = useCanvasState()
  const projectState = useProjectState()
  const canvasDispatch = useCanvasDispatch()
  const [alert, setAlert] = useState(null)

  const handleClick = useCallback(() => {
    let current = canvasState.objects.map((object) => ({
      ...object,
      visible: null,
      selected: null
    }))
    let old = canvasState.objectsBeforeSave.map((object) => ({
      ...object,
      visible: null,
      selected: null
    }))
    if ((canvasState.template.render_status === 'DRAFT') && (JSON.stringify(current) === JSON.stringify(old) || window.confirm("Do you want to clase this tab, there are unsaved changes") === true)) {
      navigate(`/admin/companies/${companyId}/projects/${projectId}/templates/${id}`)
    }
    if (canvasState.template.render_status === 'EXPIRED' || canvasState.template.render_status === 'PUBLISHED' || canvasState.template.render_status === 'SCHEDULED') {
      navigate(`/admin/companies/${companyId}/projects/${projectId}/templates/${id}`)
    }
  }, [canvasState])

  const handleSave = useCallback(() => {
    let canvasObjects = canvasState.objects.map((canvasObject) => {
      return canvasObject.object;
    })
    let validationErrors = checkValidationErrors()
    if (validationErrors.length > 0) {
      setAlert(
        <ReactBSAlert
          danger
          style={{display: "block", marginTop: "-100px", width: '52em'}}
          title={'You have errors on your template'}
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="danger"
          confirmBtnText="Close"
          btnSize=""
        >
          <div className={'p-4 d-flex flex-column align-items-start'}>
            {
              validationErrors.map((validationError, i) =>
                <small key={i} className={'p-2'}>{validationError.message}</small>
              )
            }
          </div>
        </ReactBSAlert>
      );
      return
    }

    if (canvasObjects.length > 0 && canvasState.template.render_status === 'DRAFT') {
      Templates.put_batch(projectState.companyId, projectState.projectId, canvasState.template.id, canvasObjects).then((r) => {
        if (r?.status < 400) {
          canvasSave(canvasDispatch)
        } else {
          apiErrorRaised(apiErrorDispatch, r?.response)
        }
      });
    }
  }, [canvasState])

  const checkValidationErrors = () => {
    let validationErrors = []
    canvasState.objects.forEach((object) => {
      if (object?.errors?.length > 0) {
        validationErrors = [...validationErrors, ...object.errors]
      }
    })
    return validationErrors
  }

  return (
    <>
      {alert}
      <div className={'canvas_actions_back p-2'}>
          <span className="fa fa-2x fa-arrow-left text-primary cursor-pointer"
                onClick={handleClick}
          />
      </div>
      <div className={'p-2'}>
          <span className="fa fa-regular fa-2x fa-floppy-disk text-primary cursor-pointer"
                onClick={handleSave}
          />
      </div>
    </>
  )
}
