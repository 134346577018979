import facebook from "./FeedExportFacebookProperties";
import bing from "./FeedExportBingProperties";
import pinterest from "./FeedExportPinterestProperties";
import googleLocalInventory from "./FeedExportGoogleLocalInventoryProperties";
import google from "./FeedExportGoogleProperties";
import tradeDoubler from "./FeedExportTradeDoublerProperties";
import awin from "./FeedExportAwinProperties";
import criteo from "./FeedExportCriteoProperties";
import googleStudio from "./FeedExportGoogleStudioProperties";
import tiktokProperties from "./FeedExportTiktokProperties";
import metaLocalizedCatalogs from "./FeedExportMetaLocalizedCatalogsProperties";

const handlePlatformMapping = (currentPlatform) => {
  switch (currentPlatform) {
    case "facebook":
      return facebook
    case "bing":
      return bing
    case "pinterest":
      return pinterest
    case "googleLocalInventory":
      return googleLocalInventory
    case "tradedoubler":
      return tradeDoubler
    case "awin":
      return awin
    case "googleStudio":
      return googleStudio
    case "criteo":
      return criteo
    case "tiktok":
      return tiktokProperties
    case "metaLocalizedCatalogs":
      return metaLocalizedCatalogs
    case "custom":
      return []
    default:
      return google
  }
}

export default handlePlatformMapping;
