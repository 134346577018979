import React from 'react';
import FilterView from "./FilterView";


export default function FilterFactoryView({filter, filterIdx}) {
  const mystyle = {
    display: "flex",
    flexFlow: "column wrap",
    justifyContent: "flex-start",
  };
  return (
    <div className={'filter-container'} style={mystyle}>
      <FilterView filter={filter} key={filterIdx + 'factory'}/>
    </div>
  );
}
