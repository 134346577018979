import {Card, Col, Container, Row} from "reactstrap";
import React from "react";
import {useTranslation} from "react-i18next";

export const LandingTestimonials = () => {
  const {t} = useTranslation('landing')
  return (
    <>
      <div className="py-6">
        <Row className="justify-content-center text-center pt-5 pb-5 px-2">
          <Col>
            <h2 className={'landing_header'}>
              {t('TESTIMONIALS_WHAT_THEY_SAY')}
            </h2>
          </Col>
        </Row>
        <Container>
          <div className="d-flex gap-3 responsive custom-p1">
            {/* CARD 1 */}
            <Card className="col-sm card-lift--hover">
              <div className="p-5 d-flex flex-column flex-grow-1 ">
                <div className="text-testimonial">
                  <p className="mt-4">
                    <em>“{t('TESTIMONIALS_1_DESCRIPTION')}”</em>
                  </p>
                </div>
                <div className="testimonial d-flex pt-5 mt-auto gap-2">
                  <div className="author-info text-primary">
                    <p className="m-0"><strong>Marta Gil</strong></p>
                    <p>{t('TESTIMONIALS_1_POSITION')}</p>
                  </div>
                </div>
              </div>
            </Card>
            {/* CARD 2 */}
            <Card className="col-sm card-lift--hover">
              <div className="p-5 d-flex flex-column flex-grow-1 ">
                <div className="text-testimonial">
                  <p className="mt-4">
                    <em>“{t('TESTIMONIALS_2_DESCRIPTION')}”</em>
                  </p>
                </div>
                <div className="testimonial d-flex mt-auto gap-2">
                  <div className="author-info text-primary">
                    <p className="m-0"><strong>Asier Delgado</strong></p>
                    <p>{t('TESTIMONIALS_2_POSITION')}</p>
                  </div>
                </div>
              </div>
            </Card>
            {/* CARD 3 */}
            <Card className="col-sm card-lift--hover">
              <div className="p-5 d-flex flex-column flex-grow-1 ">
                <div className="text-testimonial">
                  <p className="mt-4">
                    <em>“{t('TESTIMONIALS_3_DESCRIPTION')}”</em>
                  </p>
                </div>
                <div className="testimonial d-flex mt-auto gap-2">
                  <div className="author-info text-primary">
                    <p className="m-0"><strong>Ariadna Obré</strong></p>
                    <p>{t('TESTIMONIALS_3_POSITION')}</p>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </Container>
      </div>
    </>
  )
}
