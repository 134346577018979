import React, {useEffect, useState} from "react";
import Select from "react-select";

export const SharedRuleGroupsSelect = ({defaultRuleGroup, ruleGroups, parentCallback, disabled, label}) => {
  const [ruleGroup, setRuleGroup] = useState(defaultRuleGroup);
  const handleChange = (e) => {
    setRuleGroup(e.value);
  }

  useEffect(() => {
    parentCallback(label, ruleGroup);
  }, [ruleGroup, parentCallback])

  return (
    <>
      <div className={'d-flex flex-row'}>
        <Select className={'flex-basis-25'}
                value={ruleGroups.find(listRuleGroup => listRuleGroup.value === ruleGroup)}
                onChange={handleChange}
                options={ruleGroups}
                isDisabled={disabled}
        />
      </div>
    </>
  );
}
