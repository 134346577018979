import React, {useEffect} from "react";
// react library for routing
import {Outlet, useLocation, useParams} from "react-router-dom";

// core components
import IndexNavbar from "../components/Navbars/IndexNavbar";
import IndexFooter from "../components/Footers/IndexFooter";
import {useTranslation} from "react-i18next";

export default function AuthLayout() {
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  const {i18n} = useTranslation()
  const routerParams = useParams();
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
    document.body.classList.add("bg-secondary");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.remove("bg-default");
    };
  });
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  useEffect(() => {
    i18n.changeLanguage(routerParams.language)
  }, []);

  return (
    <>
      <div className="main-content" ref={mainContentRef}>
        <IndexNavbar logo={{
          innerLink: `/`,
          imgSrc: "assets/img/brand/feedest_purple.svg",
          imgAlt: "logo",
        }}/>
        <Outlet/>
      </div>
      <IndexFooter/>
    </>
  );
}
