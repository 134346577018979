import React, {useEffect, useState} from 'react';
import {ProjectProvider} from "./context/project/ProjectContext";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import AuthLayout from "./layouts/AuthLayout";
import {AuthProvider} from "./context/auth/AuthContext";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {apiErrorClosed} from "./context/api_error/ApiErrorActions";
import {useApiErrorDispatch, useApiErrorState} from "./context/api_error/ApiErrorIndex";
import {ModalProvider} from "./context/modal/ModalContext";
import Login from "./components/Login/Login";
import SignUpThanks from "./components/SignUp/SignUpThanks";
import SignUp from "./components/SignUp/SignUp";
import ForgotPasswordThanks from "./components/ForgotPassword/ForgotPasswordThanks";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ResetPasswordThanks from "./components/ResetPassword/ResetPasswordThanks";
import ResetPasswordView from "./views/pages/resetPassword/ResetPasswordView";
import {GlobalLayout} from "./layouts/GlobalLayout";
import ConfirmationView from "./views/pages/confirmation/ConfirmationView";
import CompaniesView from "./views/pages/companies/CompaniesView";
import CompanyView from "./views/pages/companies/CompanyView";
import AdminLayout from "./layouts/AdminLayout";
import CanvasLayout from "./layouts/CanvasLayout";
import UsersView from "./views/pages/users/UsersView";
import CanvasView from "./views/pages/canvas/CanvasView";
import ProjectView from "./views/pages/project/ProjectView";
import FeedsView from "./views/pages/feeds/FeedsView";
import FeedExportsView from "./views/pages/feedExports/FeedExportsView";
import ProductsView from "./views/pages/products/ProductsView";
import TemplatesView from "./views/pages/templates/TemplatesView";
import AssetsView from "./views/pages/assets/AssetsView";
import FeedExportNewView from "./views/pages/feedExports/FeedExportNewView";
import FeedExportMappingView from "./views/pages/feedExports/FeedExportMappingView";
import FeedExportRulesView from "./views/pages/feedExports/FeedExportRulesView";
import FeedExportSettingsView from "./views/pages/feedExports/FeedExportSettingsView";
import FeedExportDetailView from "./views/pages/feedExports/FeedExportDetailView";
import FeedExportHistoryView from "./views/pages/feedExports/FeedExportHistoryView";
import FeedView from "./views/pages/feeds/FeedView";
import FeedExportScheduleView from "./views/pages/feedExports/FeedExportScheduleView";
import ProductView from "./views/pages/products/ProductView";
import TemplateView from "./views/pages/templates/TemplateView";
import {UserProfileView} from "./views/pages/users/UserProfileView";
import {ProductDiagnosticView} from "./views/pages/product_diagnostic/ProductDiagnosticView";
import {ProductIssuesView} from "./views/pages/product_issues/ProductIssuesView";
import RuleGroupsView from "./views/pages/rule_groups/RuleGroupsView";
import RuleGroupView from "./views/pages/rule_groups/RuleGroupView";
import {AnalyticsView} from "./views/pages/analytics/AnalyticsView";
import {EventsManagerView} from "./views/pages/events_manager/EventsManagerView";
import {DataSourceSettingsView} from "./views/pages/feeds/DataSourceSettingsView";
import {DataSourceMappingView} from "./views/pages/feeds/DataSourceMappingView";
import {DataSourceNewFeedView} from "./views/pages/feeds/DataSourceNewFeedView";
import {DataSourceNewApiConnectionView} from "./views/pages/feeds/DataSourceNewApiConnectionView";
import {DataSourceNewSelectTypeView} from "./views/pages/feeds/DataSourceNewSelectTypeView";
import {HeaderNotifications} from "./components/Notification/HeaderNotifications";
import {ProjectSettingsView} from "./views/pages/project/ProjectSettingsView";
import {AnalyticsProvider} from "./context/analytics/AnalyticsContext";
import HomeView from "./views/pages/landing/HomeView";
import './services/internationalization/i18'
import TermsAndConditionsView from "./views/pages/landing/TermsAndConditionsView";
import CookiesPolicyView from "./views/pages/landing/CookiesPolicyView";
import PrivacyPolicyView from "./views/pages/landing/PrivacyPolicyView";
import {NotFoundError} from "./views/pages/NotFoundError";
import {EventsManagerProvider} from "./context/events_manager/EventsManagerContext";
import {ShopifyConnectionSaveCredentialsView} from "./views/pages/connection/ShopifyConnectionSaveCredentialsView";
import {ShopifyConnectionCreateView} from "./views/pages/connection/ShopifyConnectionCreateView";

const App = () => {
  const error = useApiErrorState()
  const dispatch = useApiErrorDispatch();

  const [alert, setAlert] = useState(false)

  useEffect(() => {
    if (error.visible) {
      setAlert(
        <ReactBSAlert
          danger
          style={{display: "block", marginTop: "-100px"}}
          title={error.statusText}
          onConfirm={() => apiErrorClosed(dispatch)}
          onCancel={() => apiErrorClosed(dispatch)}
          confirmBtnBsStyle="danger"
          confirmBtnText="Back"
          btnSize=""
        >
          {error.message}
        </ReactBSAlert>
      );
    } else {
      setAlert(null)
    }
  }, [error, dispatch])

  return (
    <>
      {alert}
      <AuthProvider>
        <ProjectProvider>
          <ModalProvider>
            <BrowserRouter>
              <HeaderNotifications/>
              <Routes>
                <Route index path="/" element={<HomeView/>}/>
                <Route index path="/es" element={<HomeView language={'es'}/>}/>
                <Route index path="/pt" element={<HomeView language={'pt'}/>}/>
                <Route index path="/en" element={<HomeView language={'en'}/>}/>
                <Route index path="/:language/terms-and-conditions" element={<TermsAndConditionsView/>}/>
                <Route index path="/:language/cookies-policy" element={<CookiesPolicyView/>}/>
                <Route index path="/:language/privacy-policy" element={<PrivacyPolicyView/>}/>
                <Route index path="/shopify/save_credentials" element={<ShopifyConnectionSaveCredentialsView/>}/>
                <Route path="/auth" element={<AuthLayout/>}>
                  <Route path='/auth/login' element={<Login/>}/>
                  <Route path='/auth/activation/:userId/:confirmation' element={<ConfirmationView/>}/>
                  <Route path='/auth/register/:invitation?' element={<SignUp/>}/>
                  <Route path='/auth/register/thanks' element={<SignUpThanks/>}/>
                  <Route path='/auth/forgot_password' element={<ForgotPassword/>}/>
                  <Route path='/auth/forgot_password/thanks' element={<ForgotPasswordThanks/>}/>
                  <Route path='/auth/reset_password/:userId/:confirmation' element={<ResetPasswordView/>}/>
                  <Route path='/auth/reset_password/thanks' element={<ResetPasswordThanks/>}/>
                </Route>
                <Route element={<ProtectedRoute path="/admin"/>}>
                  <Route path="/admin" element={<GlobalLayout/>}>
                    <Route path="companies" element={<CompaniesView/>}/>
                    <Route path="companies/:companyId" element={<CompanyView/>}/>
                    <Route path="companies/:companyId/projects/:projectId/settings" element={<ProjectSettingsView/>}/>
                    <Route path="companies/:companyId/projects/new" element={<ProjectSettingsView/>}/>
                    <Route path="companies/:companyId/users" element={<UsersView/>}/>
                    <Route path="user/profile" element={<UserProfileView/>}/>
                    <Route path="shopify/create_connection" element={<ShopifyConnectionCreateView/>}/>
                  </Route>
                  <Route path="/admin" element={<CanvasLayout/>}>
                    <Route path="companies/:companyId/projects/:projectId/templates/:id/canvas"
                           element={<CanvasView/>}/>
                  </Route>
                  <Route path="/admin" element={<AdminLayout/>}>
                    <Route path="companies/:companyId/projects/:projectId" element={<ProjectView/>}/>

                    <Route path="companies/:companyId/projects/:projectId/data_sources" element={<FeedsView/>}/>
                    <Route path="companies/:companyId/projects/:projectId/data_sources/new/select_type"
                           element={<DataSourceNewSelectTypeView/>}/>
                    <Route path="companies/:companyId/projects/:projectId/data_sources/new/feed"
                           element={<DataSourceNewFeedView/>}/>
                    <Route path="companies/:companyId/projects/:projectId/data_sources/new/api_connection"
                           element={<DataSourceNewApiConnectionView/>}/>
                    <Route path="companies/:companyId/projects/:projectId/data_sources/:id/edit"
                           element={<DataSourceSettingsView/>}/>
                    <Route path="companies/:companyId/projects/:projectId/data_sources/:id/mapping"
                           element={<DataSourceMappingView/>}/>
                    <Route path="companies/:companyId/projects/:projectId/data_sources/:id/details"
                           element={<FeedView/>}/>

                    <Route path="companies/:companyId/projects/:projectId/feed_exports" element={<FeedExportsView/>}/>
                    <Route path="companies/:companyId/projects/:projectId/feed_exports/new"
                           element={<FeedExportNewView/>}/>
                    <Route path="companies/:companyId/projects/:projectId/feed_exports/:id/mapping"
                           element={<FeedExportMappingView/>}/>
                    <Route path="companies/:companyId/projects/:projectId/feed_exports/:id/rules"
                           element={<FeedExportRulesView/>}/>
                    <Route path="companies/:companyId/projects/:projectId/feed_exports/:id/schedule_params"
                           element={<FeedExportScheduleView/>}/>
                    <Route path="companies/:companyId/projects/:projectId/feed_exports/:id/edit"
                           element={<FeedExportSettingsView/>}/>
                    <Route path="companies/:companyId/projects/:projectId/feed_exports/:id/finish"
                           element={<FeedExportDetailView/>}/>
                    <Route path="companies/:companyId/projects/:projectId/feed_exports/:feedExportId/history/:id"
                           element={<FeedExportHistoryView/>}/>

                    <Route path="companies/:companyId/projects/:projectId/products" element={<ProductsView/>}/>
                    <Route path="companies/:companyId/projects/:projectId/products/:id" element={<ProductView/>}/>

                    <Route path="companies/:companyId/projects/:projectId/rule_groups" element={<RuleGroupsView/>}/>
                    <Route path="companies/:companyId/projects/:projectId/rule_groups/:id"
                           element={<RuleGroupView/>}/>

                    <Route path="companies/:companyId/projects/:projectId/templates" element={<TemplatesView/>}/>
                    <Route path="companies/:companyId/projects/:projectId/templates/:id" element={<TemplateView/>}/>

                    <Route path="companies/:companyId/projects/:projectId/assets" element={<AssetsView/>}/>

                    <Route path="companies/:companyId/projects/:projectId/analytics"
                           element={<AnalyticsProvider><AnalyticsView/></AnalyticsProvider>}/>

                    <Route path="companies/:companyId/projects/:projectId/events_manager"
                           element={<EventsManagerProvider><EventsManagerView/></EventsManagerProvider>}/>

                    <Route path="companies/:companyId/projects/:projectId/product_diagnostic"
                           element={<ProductDiagnosticView/>}/>
                    <Route
                      path="companies/:companyId/projects/:projectId/product_diagnostic/:productDiagnosticId/product_issues"
                      element={<ProductIssuesView/>}/>
                  </Route>
                </Route>
                <Route path="*" element={<NotFoundError/>}/>
              </Routes>
            </BrowserRouter>
          </ModalProvider>
        </ProjectProvider>
      </AuthProvider>
    </>
  )
}

export default App;
