import {v4 as uuid4} from "uuid";

export function remove(dispatch, id) {
  dispatch({
    type: 'URL_PARAMETERS_DELETE',
    payload: id
  });
}

export function update(dispatch, urlParam) {
  dispatch({
    type: 'URL_PARAMETERS_UPDATE',
    payload: urlParam
  });
}

export function add(dispatch, urlParam) {
  dispatch({
    type: 'URL_PARAMETERS_ADD',
    payload: {
      ...urlParam,
      id: uuid4(),
    }
  })
}
