import React, {useEffect, useState} from "react";
import {apiErrorRaised} from "../../../../context/api_error/ApiErrorActions";
import {useApiErrorDispatch} from "../../../../context/api_error/ApiErrorContext";
import {loadRules, useRuleImportDispatch, useRuleImportState} from "./Context/FeedExportImportRuleIndex";
import {Rules} from "../../../../services/api/Rules";
import {FeedExportImportRulesItem} from "./FeedExportImportRulesItem";
import Paginator from "../../../Pagination/Paginator";
import {TableFilters} from "../../../TableFilters/TableFilters";
import {useTableFiltersState} from "../../../../context/table_filters/TableFiltersContext";

export const FeedExportImportRulesList = () => {
  const [isLoading, setLoading] = useState(false)
  const dispatch = useApiErrorDispatch();
  const ruleImportState = useRuleImportState()
  const ruleImportDispatch = useRuleImportDispatch()
  const [pagination, setPagination] = useState({})
  const [offset, setOffset] = useState(0)
  const tableFilters = useTableFiltersState()
  const [limit, setLimit] = useState(10)

  const handleClick = (e, offset, limit) => {
    e.preventDefault();
    setLimit(limit)
    setOffset(offset)
  }

  useEffect(() => {
    if (ruleImportState.selected_company === '' || ruleImportState.selected_project === '' || ruleImportState.selected_rule_group === '') {
      return
    }
    setLoading(true)
    Rules.index(
      ruleImportState.selected_company,
      ruleImportState.selected_project,
      ruleImportState.selected_rule_group,
      {filters: tableFilters, limit: limit, offset: offset}).then((r) => {
      if (r?.status < 400) {
        loadRules(ruleImportDispatch, r.data?.rules)
        setPagination(r.data?.pagination);
      } else {
        apiErrorRaised(dispatch, r?.response)
      }
      setLoading(false);
    })
  }, [ruleImportState.selected_company, ruleImportState.selected_project, ruleImportState.selected_rule_group, limit, offset, tableFilters])

  return (
    <>
      <h5>Rules</h5>
      <TableFilters entity={'ruleGroup'} isCollapsed={false}/>
      <div className={'d-flex flex-column justify-content-between flex-grow-1'}>
        <div className={'d-flex flex-column'}>
          {
            isLoading ? <span className="spinner-border spinner-border-sm me-1"/> :
              ruleImportState.rules && ruleImportState.rules.map((rule, i) => {
                return (
                  <FeedExportImportRulesItem key={i} rule={rule}/>
                )
              })
          }
        </div>
        {
          ruleImportState.rules.length > 0 && <Paginator
            pagination={pagination}
            handleClick={handleClick}
            offset={offset}
            limit={limit}
            showRowOptions={false}
          />
        }
      </div>
    </>
  )
}
