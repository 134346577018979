export const objectToggleVisibility = (canvas, id) => {
  return {
    ...canvas,
    objects: canvas.objects.map((canvasObject) => {
      if (canvasObject.object.id === id) {
        return {
          ...canvasObject,
          visible: !canvasObject.visible,
        }
      }
      return canvasObject;
    })
  };
};
