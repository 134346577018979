import React, {useEffect, useState} from "react";
import {CardBody, CardHeader, Col, Row} from "reactstrap";
import {addFilter, useTableFiltersDispatch} from "../../context/table_filters/TableFiltersIndex";
import {TableFilterEditProperties} from "./TableFilterEditProperties";
import {TableFilterEditMoney} from "./TableFilterEditMoney";
import {TableFilterEditString} from "./TableFilterEditString";
import {TableFilterEditInteger} from "./TableFilterEditInteger";

export const TableFilterEdit = ({filter, closeDialog}) => {
  const [component, setComponent] = useState(null);
  const dispatch = useTableFiltersDispatch();

  const applyFilter = (filter) => {
    addFilter(dispatch, filter)
    closeDialog()
  }
  const renderDialog = () => {
    switch (filter.type) {
      case 'string':
        setComponent(
          <TableFilterEditString
            filter={filter}
            applyFilter={applyFilter}
          />
        );
        break;
      case 'money':
        setComponent(
          <TableFilterEditMoney
            filter={filter}
            applyFilter={applyFilter}
          />
        );
        break;
      case 'properties':
        setComponent(
          <TableFilterEditProperties
            filter={filter}
            applyFilter={applyFilter}
          />
        );
        break;
      case 'integer':
        setComponent(
          <TableFilterEditInteger
            filter={filter}
            applyFilter={applyFilter}
          />
        );
    }
  }

  useEffect(() => {
    renderDialog()
  }, [filter.type])

  return (
    <>
      <CardHeader>
        <Row className="align-items-center">
          <Col className="col-md-8">
            <h5 className="h3 mb-0">{filter.label}</h5>
          </Col>
          <Col className="col-md-4 text-right" onClick={() => closeDialog()}>
            <span className="fas fa-times"/>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {component}
      </CardBody>
    </>
  )
}
