import React, {useEffect, useState} from 'react';
import {Button, Col, Row, Table} from "reactstrap";
import {DataSources} from "../../services/api/DataSources";
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import DataSourceDeleteButton from "./DataSourceDeleteButton";
import {Link} from "react-router-dom";
import {DataSourceStepButtonGroup} from "./DataSourceStepButtonGroup/DataSourceStepButtonGroup";
import {useProjectState} from "../../context/project/ProjectIndex";

const FeedDetail = ({companyId, projectId, dataSourceId}) => {
  const projectState = useProjectState()
  const apiErrorDispatch = useApiErrorDispatch();
  const dispatch = useApiErrorDispatch();
  const [isLoading, setLoading] = useState(false)
  const [dataSource, setDataSource] = useState({});

  useEffect(() => {
    setLoading(true);
    DataSources.get(companyId, projectId, dataSourceId).then((r) => {
      if (r?.status < 400) {
        setDataSource(r.data);
        setLoading(false);
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    });
  }, [dataSourceId, dispatch, apiErrorDispatch]);

  if (isLoading) {
    return <div className="error">
      <p>Loading</p>
    </div>
  }
  return (
    <>
      <div className={'d-flex flex-column'}>
        <div className={'d-flex flex-row justify-content-between'}>
          <div className={'d-flex flex-row'}>
            <Link
              to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/data_sources/`}>
              <Button className={'btn-icon-only text-dark'}>
                <span className={'fa fa-arrow-left'}/>
              </Button>
            </Link>
            <h1 className={'ms-4'}>Details</h1>
          </div>
          <DataSourceStepButtonGroup id={dataSourceId} step={'details'}/>
        </div>
      </div>
      <div className={'mt-2 d-flex flex-row justify-content-end'}>
        <DataSourceDeleteButton id={dataSourceId}/>
      </div>
      <Row className="py-3 align-items-center">
        <Col>
          <Row>
            <Col sm="3">
              <small className="text-uppercase text-muted font-weight-bold">
                Name
              </small>
            </Col>
            <Col sm="9">
              <h1 className="mb-0">{dataSource.name}</h1>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col sm="3">
              <small className="text-uppercase text-muted font-weight-bold">
                Url
              </small>
            </Col>
            <Col sm="9">
              <p>
                {dataSource.url}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="py-3 align-items-center">
        <Col sm="3">
          <small className="text-uppercase text-muted font-weight-bold">
            Locale
          </small>
        </Col>
        <Col sm="9">
          <p>
            {dataSource.locale}
          </p>
        </Col>
      </Row>
      <Row className="py-3 align-items-center">
        <Col sm="3">
          <small className="text-uppercase text-muted font-weight-bold">
            Status
          </small>
        </Col>
        <Col sm="9">
          <p>
            {dataSource.status === true ? 'ACTIVE' : 'PAUSED'}
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <small className="text-uppercase text-muted font-weight-bold">
            Mapping
          </small>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
            <tr>
              <th scope="col" className={'text-center'}>Origin</th>
              <th scope="col" className={'text-center'}>Destination</th>
            </tr>
            {
              dataSource.mapping && dataSource?.mapping.map((dataSource, key) => {
                return (<tr key={key}>
                  <td>{dataSource.origin}</td>
                  <td>{dataSource.destination}</td>
                </tr>)
              })
            }
            </thead>
            <tbody>
            </tbody>
          </Table>
        </Col>
        <Col md={3}>
          <small className="text-uppercase text-muted font-weight-bold">
            Dynamic Properties
          </small>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
            <tr>
              <th scope="col" className={'text-center'}>Name</th>
            </tr>
            {
              dataSource.dynamicProperties && dataSource?.dynamicProperties.map((dynamicProperty, key) => {
                return (<tr key={key}>
                  <td>{dynamicProperty}</td>
                </tr>)
              })
            }
            </thead>
            <tbody>
            </tbody>
          </Table>
        </Col>
        <Col md={3}>
          <small className="text-uppercase text-muted font-weight-bold">
            Import Properties
          </small>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
            <tr>
              <th scope="col" className={'text-left'}>Key</th>
              <th scope="col" className={'text-left'}>Value</th>
            </tr>
            </thead>
            <tbody>
            {
              dataSource.importProperties && Object.entries(dataSource?.importProperties).map(([key, value]) => {
                return (<tr key={key}>
                  <td>{key}</td>
                  <td>{value}</td>
                </tr>)
              })
            }
            </tbody>
          </Table>
        </Col>
        <Col md={3}>
          <small className="text-uppercase text-muted font-weight-bold">
            Type
          </small>
          <p>
            {dataSource.data?.extension || dataSource.data?.platform}
          </p>
          <small className="text-uppercase text-muted font-weight-bold">
            Order
          </small>
          <p>
            {dataSource.order}
          </p>
        </Col>
      </Row>
    </>
  );
}

export default FeedDetail;
