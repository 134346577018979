import React, {useState} from "react";
import classnames from "classnames";
import {Collapse, Container} from "reactstrap";
import {CanvasObjectInfoSidebarDetailPriceContent} from "./CanvasObjectInfoSidebarDetailPriceContent";
import {CanvasObjectInfoSidebarDetailPricePosition} from "./CanvasObjectInfoSidebarDetailPricePosition";
import {CanvasObjectInfoSidebarDetailColor} from "../Common/CanvasObjectInfoSidebarDetailColor";
import {CanvasObjectInfoSidebarDetailOrigin} from "../Common/CanvasObjectInfoSidebarDetailOrigin";
import {CanvasObjectInfoSidebarDetailSize} from "../Common/CanvasObjectInfoSidebarDetailSize";

export const CanvasObjectInfoSidebarDetailTwoPriceCommon = () => {
  const [collapse, setCollapse] = useState(false);

  const handleCollapse = () => {
    setCollapse(!collapse);
  }
  return (
    <>
      <div onClick={handleCollapse}
           className={'canvas_sidebar_header d-flex flex-row justify-content-between align-items-center p-3'}>
        <h3 className="mb-0">Common Info</h3>
        <span className={classnames({
          'fa': true,
          'fa-chevron-up': !collapse,
          'fa-chevron-down': collapse,
        })}/>
      </div>
      <Container className="">
        <Collapse isOpen={!collapse} className={'mb-2'}>
          <CanvasObjectInfoSidebarDetailOrigin/>
          <CanvasObjectInfoSidebarDetailSize/>
          <CanvasObjectInfoSidebarDetailPriceContent
            title={'Price'}
            property={'priceContent'}
          />
          <CanvasObjectInfoSidebarDetailPriceContent
            title={'Sale Price'}
            property={'offerContent'}
          />
          <CanvasObjectInfoSidebarDetailColor
            title={'Background Color'}
            property={'backgroundColor'}
          />
          <CanvasObjectInfoSidebarDetailPricePosition/>
        </Collapse>
      </Container>
    </>
  );
};
