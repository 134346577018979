import React from "react";
import {TemplateImportTemplatesList} from "./TemplateImportTemplatesList";
import {TableFiltersProvider} from "../../../context/table_filters/TableFiltersIndex";
import {ImportProjectsList} from "../../Import/ImportProjectsList";
import {ImportCompaniesList} from "../../Import/ImportCompaniesList";

export const TemplateImport = () => {
  return (
    <>
      <div className={'flex-basis-20 d-flex flex-column'}>
        <ImportCompaniesList/>
      </div>
      <div className={'flex-basis-30 d-flex flex-column'}>
        <ImportProjectsList/>
      </div>
      <div className={'flex-grow-1 d-flex flex-column'}>
        <TableFiltersProvider>
          <TemplateImportTemplatesList/>
        </TableFiltersProvider>
      </div>
    </>
  )
}
