import React from "react";
import {useTranslation} from "react-i18next";

export const LandingDataHistory = () => {
  const {t} = useTranslation('landing')
  return (
    <>
      <div className="">
        <div className={'d-flex responsive'}>
          <div className="col-6 h-50 custom-w100">
            <div className="image-fix">
              <img
                alt="..."
                className="img-fluid"
                src={require("assets/img/landing/Mac_3.png")}
              />
            </div>
          </div>
          <div className="col-6 p-6 d-flex flex-column justify-content-center custom-w100 custom-p3">
            <div className="mb-4 d-flex gap-2">
                  <span className="text-feed-manager d-flex flex-column justify-content-center">
                  <img
                    alt="..."
                    width='40px'
                    className="my-auto img-fluid"
                    src={require("assets/img/landing/data.png")}
                  />
                  </span>
              <h3 className="m-0 title">{t('DATA_HISTORY')}</h3>
            </div>
            <div className="text-row-lp">
              <p>{t('DATA_HISTORY_DESCRIPTION_1')}</p>
              <ul className="text-lists-pink">
                <li>{t('DATA_HISTORY_NUMBER_OF_PRODUCTS')}</li>
                <li>{t('DATA_HISTORY_AVERAGE_TICKET')}</li>
                <li>{t('DATA_HISTORY_PRICES')}</li>
                <li>{t('DATA_HISTORY_AVAILABILITY')}</li>
                <li>{t('DATA_HISTORY_PURCHASES')}</li>
                <li>{t('DATA_HISTORY_INCOME')}</li>
              </ul>
              <p>{t('DATA_HISTORY_DESCRIPTION_2')}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
