import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardHeader} from "reactstrap";
import {useProjectState} from "../../../context/project/ProjectIndex";
import {apiErrorRaised, useApiErrorDispatch} from "../../../context/api_error/ApiErrorIndex";
import {Templates} from "../../../services/api/Templates";
import Paginator from "../../Pagination/Paginator";
import {TemplateInfoItem} from "./TemplateInfoItem";

export const TemplatesInfo = ({showPagination}) => {
  const projectState = useProjectState();
  const dispatch = useApiErrorDispatch();
  const [templates, setTemplates] = useState([])
  const [isLoading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(6)
  const [pagination, setPagination] = useState({})

  const handleClick = (e, offset, limit) => {
    e.preventDefault();
    setLimit(limit)
    setOffset(offset)
  }

  useEffect(() => {
    setLoading(true);
    let params = {
      filters: [{field: 'renderStatus', operator: '=', value: 'PUBLISHED'}, {
        field: 'status',
        operator: '=',
        value: '1'
      },],
      order: [
        {
          order_by: 'priority',
          order_type: 'desc'
        }
      ],
      limit: limit,
      offset: offset,
    };
    Templates.index(projectState.companyId, projectState.projectId, params).then((r) => {
      setLoading(false)
      if (r?.status < 400) {
        setTemplates(r.data.templates);
        setPagination(r.data?.pagination);
      } else {
        apiErrorRaised(dispatch, r?.response)
      }
    })
  }, [projectState.companyId, projectState.projectId, limit, offset,])

  return (
    <>
      <Card>
        <CardHeader className={'text-dusty-violet'}>
          ACTIVE TEMPLATES
        </CardHeader>
        <CardBody className={'d-flex flex-column'}>
          <div className={'template-previews-grid-container'}>
            <div className={'template-previews-cards__list'}>
              {
                isLoading ?
                  <span className="spinner-border spinner-border-sm me-1"/>
                  :
                  templates && templates.length > 0 ? templates.map((template, i) =>
                    <TemplateInfoItem template={template} key={i}/>
                  ) : <>
                    <div>
                      <h2>There are not active templates on this project</h2>
                    </div>
                  </>
              }
            </div>
          </div>
          {
            showPagination ?
              <Paginator
                pagination={pagination}
                handleClick={handleClick}
                offset={offset}
                limit={limit}
              /> : <></>
          }
        </CardBody>
      </Card>
    </>
  );
}
