import React, {useState} from 'react';
import TemplateDetailEditableModal from "./TemplateDetailEditableModal";
import {useTemplateState} from "../../../context/template/TemplateIndex";
import {useProjectState} from "../../../context/project/ProjectIndex";
import {formatUtcDateIntoTimezone} from "../../../utils/DateTime";

export const TemplateDetailEditable = ({name, type}) => {
  const [showModal, setShowModal] = useState(false);
  const templateState = useTemplateState()
  const projectState = useProjectState()

  const renderValue = () => {
    if (type !== 'datetime-local') {
      return templateState[name];
    }
    if (templateState[name] === null || templateState[name] === '' || templateState[name] === undefined) {
      return '(Not set)';
    }
    return formatUtcDateIntoTimezone(templateState[name], "yyyy-MM-dd HH:mm", projectState.timezone)
  }

  return (
    <>
      <div className={'d-flex flex-row align-items-center'}>
        <h2 className='m-0'>&nbsp;{renderValue()}</h2>
        <span className={'fa-solid fa-pen-to-square ms-4 pencil-button -cursor-pointer'}
              onClick={() => setShowModal(true)}></span>
      </div>
      <TemplateDetailEditableModal
        name={name}
        type={type}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  )
}
