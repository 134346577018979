export function setDates(dispatch, data) {
  dispatch(
    {
      type: 'SET_DATES',
      payload: {
        start_date: data.start_date,
        end_date: data.end_date,
      }
    }
  )
}

export function setDatesWithComparation(dispatch, data) {
  dispatch(
    {
      type: 'SET_DATES_WITH_COMPARATION',
      payload: {
        start_date: data.start_date,
        end_date: data.end_date,
        start_date_comparation: data.start_date_comparation,
        end_date_comparation: data.end_date_comparation,
      }
    }
  )
}

export function updateDimensions(dispatch, data) {
  dispatch(
    {
      type: 'UPDATE_DIMENSIONS',
      payload: data
    }
  )
}

export function addFilter(dispatch, data) {
  dispatch(
    {
      type: 'ADD_FILTER',
      payload: data
    }
  )
}

export function removeFilter(dispatch, id) {
  dispatch(
    {
      type: 'REMOVE_FILTER',
      payload: id
    }
  )
}

export function selectRows(dispatch, data) {
  dispatch(
    {
      type: 'SELECT_ROWS',
      payload: data
    }
  )
}

export function selectMetric(dispatch, index, metric) {
  dispatch(
    {
      type: 'SELECT_METRIC',
      payload: {
        index: index,
        metric: metric
      }
    }
  )
}

export function addMetric(dispatch, metric) {
  dispatch(
    {
      type: 'ADD_METRIC',
      payload: {
        metric: metric
      }
    }
  )
}

export function removeMetric(dispatch, index) {
  dispatch(
    {
      type: 'REMOVE_METRIC',
      payload: {
        index: index
      }
    }
  )
}
