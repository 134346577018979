import {useTranslation} from "react-i18next";
import React, {useEffect} from "react";
import IndexNavbar from "../Navbars/IndexNavbar";
import IndexFooter from "../Footers/IndexFooter";
import {Container} from "reactstrap";
import {scrollToTargetDiv} from "../Landing/scrollUtils";

export const PrivacyPolicy = () => {
  const {t} = useTranslation('legal')

  useEffect(() => {
    scrollToTargetDiv('app')
  }, []);


  return (<>
    <IndexNavbar logo={{
      innerLink: `/`, imgSrc: "assets/img/brand/feedest_purple.svg", imgAlt: "logo",
    }}/>
    <Container className={'pt-5'}>
      <h1 className={'display-2 text-primary pb-3'}>{t('PRIVACY_TITLE')}</h1>
      <h3>1. {t('PRIVACY_RESPONSIBLE_TITLE')}</h3>
      <p>{t('PRIVACY_RESPONSIBLE_CONTENT')}<a href="mailto:dpo@cyberclick.net" rel="noopener">dpo@cyberclick.net</a></p>
      <h3 className={'pt-3'}>2. {t('PRIVACY_PURPOSES_TITLE')}</h3>
      <p>{t('PRIVACY_PURPOSES_CONTENT')}</p>
      <ul>
        <li>
          {t('PRIVACY_PURPOSES_LIST_ITEM_1')}
        </li>
        <li>
          {t('PRIVACY_PURPOSES_LIST_ITEM_2')}
        </li>
        <li>
          {t('PRIVACY_PURPOSES_LIST_ITEM_3')}
        </li>
        <li>
          {t('PRIVACY_PURPOSES_LIST_ITEM_4')}
        </li>
        <li>
          {t('PRIVACY_PURPOSES_LIST_ITEM_5')}
        </li>
        <li>
          {t('PRIVACY_PURPOSES_LIST_ITEM_6')}
        </li>
      </ul>
      <h3 className={'pt-3'}>3. {t('PRIVACY_RECIPIENTS_TITLE')}</h3>
      <p>{t('PRIVACY_RECIPIENTS_CONTENT')}</p>
      <h3>4. {t('PRIVACY_RIGHTS_TITLE')}</h3>
      <p>
        {t('PRIVACY_RIGHTS_CONTENT_1')}
        <a href="mailto:dpo@cyberclick.net" rel="noopener">dpo@cyberclick.net</a>
        {t('PRIVACY_RIGHTS_CONTENT_2')}
      </p>
      <p>{t('PRIVACY_DELEGATE_CONTENT')}</p>
    </Container>
    <IndexFooter/>
  </>)
}
