import React, {useReducer} from 'react';
import {initialState, FeedExportImportRuleReducer} from "./FeedExportImportRuleReducer";

const RuleImportStateContext = React.createContext();
const RuleImportDispatchContext = React.createContext();

export function useRuleImportState() {
  const context = React.useContext(RuleImportStateContext);
  if (context === undefined) {
    throw new Error('useRuleImportState must be used within a RuleImportStateProvider');
  }

  return context;
}

export function useRuleImportDispatch() {
  const context = React.useContext(RuleImportDispatchContext);
  if (context === undefined) {
    throw new Error('useRuleImportDispatch must be used within a RuleImportStateProvider');
  }

  return context;
}

export const RuleImportProvider = ({children}) => {
  const [ruleGroupImport, dispatch] = useReducer(FeedExportImportRuleReducer, initialState);

  return (
    <RuleImportStateContext.Provider value={ruleGroupImport}>
      <RuleImportDispatchContext.Provider value={dispatch}>
        {children}
      </RuleImportDispatchContext.Provider>
    </RuleImportStateContext.Provider>
  );
};
