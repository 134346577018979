import React, {useEffect, useState} from 'react';
import {Badge, Button, Card, CardBody, CardHeader} from "reactstrap";
import TemplateDetailActions from "./TemplateDetailActions";
import {useProjectDispatch} from "../../../context/project/ProjectContext";
import {useApiErrorDispatch} from "../../../context/api_error/ApiErrorContext";
import {Templates} from "../../../services/api/Templates";
import TemplateRenderStatusBadge from "./TemplateDetailActions/TemplateRenderStatusBadge";
import {apiErrorRaised} from "../../../context/api_error/ApiErrorActions";
import Conditions from "../../Conditions/Conditions";
import {useTemplateDispatch, useTemplateState} from "../../../context/template/TemplateIndex";
import {
  templateActivated,
  templateConditionsUpdated,
  templateDisabled,
  templateLoaded
} from "../../../context/template/TemplateActions";
import TemplatePreview from "../Preview/TemplatePreview";
import {TableFiltersProvider} from "../../../context/table_filters/TableFiltersContext";
import {TemplateDetailEditable} from "./TemplateDetailEditable";
import {Link} from "react-router-dom";
import {ProductImagesCarroussel} from "../../Products/ProductImagesCarroussel";

const TemplateDetail = ({companyId, projectId, id}) => {
  const dispatch = useProjectDispatch();
  const dispatchApiError = useApiErrorDispatch();
  const templateState = useTemplateState()
  const templateDispatcher = useTemplateDispatch()

  const [isLoading, setLoading] = useState(false)

  const handleToggle = (e) => {
    const {checked} = e.target;
    if (checked) {
      Templates.activate(companyId, projectId, templateState.id).then((r) => {
        if (r?.status < 400) {
          templateActivated(templateDispatcher)
        } else {
          apiErrorRaised(dispatchApiError, r?.response)
        }
      });
    } else {
      Templates.disable(companyId, projectId, templateState.id).then((r) => {
        if (r?.status < 400) {
          templateDisabled(templateDispatcher)
        } else {
          apiErrorRaised(dispatchApiError, r?.response)
        }
      });
    }
  }

  const handleChangeConditions = (newConditions) => {
    if (newConditions) {
      Templates.put(companyId, projectId, templateState.id, {
        ...templateState,
        id: undefined,
        conditions: newConditions,
      }).then((r) => {
        if (r?.status < 400) {
          templateConditionsUpdated(templateDispatcher, newConditions)
        } else {
          apiErrorRaised(dispatchApiError, r?.response)
        }
      })
    }
  }

  useEffect(() => {
    setLoading(true);
    Templates.get(companyId, projectId, id).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        templateLoaded(templateDispatcher, r.data)
      } else {
        apiErrorRaised(dispatchApiError, r?.response)
      }
    });
  }, [id, dispatch, dispatchApiError, companyId, projectId, templateDispatcher]);

  return (
    <>
      <div className={'d-flex flex-row'}>
        <div className={''}>{
          <Link
            to={`/admin/companies/${companyId}/projects/${projectId}/templates/`}>
            <Button className={'btn-icon-only text-dark'}>
              <span className={'fa fa-arrow-left'}/>
            </Button>
          </Link>
        }</div>
        <div className={'d-flex flex-row justify-content-between flex-grow-1 ms-4'}>
          <TemplateDetailEditable
            name={'name'}
            value={templateState.name}
            type={'text'}
          />
          <div className={'d-flex flex-row'}>
            {
              templateState.status === 2 ? <>
                  {templateState.renderStatus === 'PUBLISHED' ? <Badge className="badge-lg" color="success">
                    Published
                  </Badge> : <Badge className="badge-lg" color="danger">
                    DELETED
                  </Badge>}</> :
                <label className="custom-toggle me-1">
                  <input type="checkbox" name="status" checked={templateState.status}
                         onChange={handleToggle}/>
                  <span className="custom-toggle-slider rounded-circle"/>
                </label>
            }
          </div>
        </div>
      </div>
      <div className={'d-flex flex-row mt-3'}>
        {
          isLoading ?
            <span className="spinner-border spinner-border-sm me-1"/>
            :
            <Card className="shadow d-flex flex-basis-75">
              <CardHeader>
                <h3 className={'mb-0'}>Information</h3>
              </CardHeader>
              <CardBody>
                <div className={'d-flex flex-row'}>
                  <div className={'d-flex flex-column flex-basis-25'}>
                    <div className='mb-2'>
                      <TemplateRenderStatusBadge/>
                    </div>
                    <div className={'d-flex flex-row'}>
                      <h2 className="m-0">
                        Priority:
                      </h2>
                      <TemplateDetailEditable
                        name={'priority'}
                        value={templateState.priority}
                        type={'text'}
                      />
                    </div>
                    <div className={'d-flex flex-row'}>
                      <h2 className="m-0">
                        Start Date:
                      </h2>
                      <TemplateDetailEditable
                        name={'start_date'}
                        type={'datetime-local'}
                      />
                    </div>
                    <div className={'d-flex flex-row'}>
                      <h2 className="m-0">
                        End Date:
                      </h2>
                      <TemplateDetailEditable
                        name={'end_date'}
                        type={'datetime-local'}
                      />
                    </div>
                    <div className={'d-flex flex-column flex-wrap-wrap'}>
                      <Conditions
                        conditions={templateState.conditions}
                        finishEditCallback={handleChangeConditions}
                      />
                    </div>
                  </div>

                </div>
              </CardBody>
            </Card>
        }
        <div className={'ms-3 flex-basis-25'}>
          <TemplateDetailActions/>
          <div className={'my-4'}>
            <h2 className={'text-center mb-2'}>Previews</h2>
            {
              templateState?.previews?.length > 0 ?
                <ProductImagesCarroussel images={templateState.previews} showThumbs={true}/> : <></>
            }
          </div>
        </div>
      </div>

      <TableFiltersProvider>
        <TemplatePreview templateId={templateState.id}
        />
      </TableFiltersProvider>
    </>
  );
}

export default TemplateDetail;
