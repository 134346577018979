import React, {useEffect} from 'react'
import {Card, CardBody, CardHeader, Col, FormGroup, Input, Row} from "reactstrap";
import {FeedExportRuleQueries} from "./FeedExportRuleQueries/FeedExportRuleQueries";
import {FeedExportRuleActions} from "./FeedExportRuleActions/FeedExportRuleActions";
import {fetch, updateName, updateStatus} from "../../../context/rule/RuleActions";
import {useRuleDispatch, useRuleState} from "../../../context/rule/RuleIndex";
import {update, useRulesDispatch, useRulesState} from "../../../context/rules/RulesIndex";

export const FeedExportRuleDetail = () => {
  const rulesState = useRulesState()
  const rulesDispatch = useRulesDispatch()
  const ruleState = useRuleState()
  const ruleDispatch = useRuleDispatch()

  const handleToggle = (e) => {
    const {checked} = e.target;
    updateStatus(ruleDispatch, checked === true ? 'ACTIVE' : 'PAUSED')
  }

  const handleChange = (e) => {
    const {value} = e.target
    updateName(ruleDispatch, value)
  }

  useEffect(() => {
    fetch(ruleDispatch, rulesState.selected)
  }, [rulesState.selected])

  useEffect(() => {
    update(rulesDispatch, ruleState)
  }, [ruleState])

  return (
    <>
      <Card>
        {
          ruleState === undefined ? '' : <CardHeader>
            <div className='d-flex flex-row align-items-center gap-3'>
              <div>
                <label
                  className="form-control-label"
                >
                  Name
                </label>
             </div>
              <div className='w-100'>
                <div>
                  <Input
                    placeholder="Name"
                    type="text"
                    name="name"
                    value={ruleState?.name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div>
                <label className="custom-toggle me-1">
                  <input type="checkbox" name="status" checked={ruleState?.status === 'ACTIVE'}
                         onChange={handleToggle}/>
                  <span className="custom-toggle-slider rounded-circle"/>
                </label>
              </div>
            </div>
          </CardHeader>
        }
        <CardBody>
          <div>
            <div>
              <FeedExportRuleQueries/>
            </div>
          </div>
          <div>
            <div>
              <FeedExportRuleActions type={'actions'}/>
            </div>
          </div>
          <div>
            <div>
              <FeedExportRuleActions type={'else_actions'}/>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  )
}
