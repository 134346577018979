import {format, utcToZonedTime} from "date-fns-tz";

export const formatUtcDateIntoTimezone = (date, pattern, timezone) => {
  if (!date) {
    return '';
  }
  return format(
    utcToZonedTime(
      new Date(date), timezone
    ),
    pattern,
    {
      timeZone: timezone
    }
  )
}
