import React, {useEffect} from "react";
import {Container,} from "reactstrap";
import {FeedExportSettings} from "../../../components/FeedExports/FeedExportSettings/FeedExportSettings";
import {useProjectDispatch} from "../../../context/project/ProjectContext";
import {useParams} from "react-router-dom";
import {updateCompanyAndProjectBasedOnUrl} from "../../../context/project/ProjectActions";

export default function FeedExportSettingsView() {
  const projectDispatch = useProjectDispatch()
  const routerParams = useParams();
  useEffect(() => {
    updateCompanyAndProjectBasedOnUrl(projectDispatch, routerParams.companyId, routerParams.projectId)
  }, [])
  return (
    <>
      <Container className="mt-5" fluid>
        <FeedExportSettings id={routerParams.id}/>
      </Container>
    </>
  );
};
