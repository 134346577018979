import {useTranslation} from "react-i18next";
import React, {useEffect} from "react";
import IndexNavbar from "../Navbars/IndexNavbar";
import IndexFooter from "../Footers/IndexFooter";
import {Container} from "reactstrap";
import {scrollToTargetDiv} from "../Landing/scrollUtils";

export const CookiesPolicy = () => {
  const {t} = useTranslation('legal')

  useEffect(() => {
    scrollToTargetDiv('app')
  }, []);

  return (
    <>
      <IndexNavbar logo={{
        innerLink: `/`, imgSrc: "assets/img/brand/feedest_purple.svg", imgAlt: "logo",
      }}/>
      <Container className={'pt-5'}>
        <h1 className={'display-2 text-primary pb-3'}>{t('COOKIE_POLICY_TITLE')}</h1>
        <p>{t('COOKIE_POLICY_INTRO_1')}</p>
        <p>{t('COOKIE_POLICY_INTRO_2')}</p>

        <h2>{t('COOKIE_POLICY_COOKIE_DEFINITION_TITILE')}</h2>
        <p>{t('COOKIE_POLICY_COOKIE_DEFINITION_1')}</p>
        <p>{t('COOKIE_POLICY_COOKIE_DEFINITION_2')}</p>
        <p>{t('COOKIE_POLICY_COOKIE_DEFINITION_3')}</p>
        <p>{t('COOKIE_POLICY_COOKIE_DEFINITION_4')}</p>

        <ul>
          <li><strong>{t('COOKIE_POLICY_DEFINITION_LIST_ANALYTICS_TITLE')}</strong> {t('COOKIE_POLICY_DEFINITION_LIST_ANALYTICS')}
          </li>
          <li><strong>{t('COOKIE_POLICY_DEFINITION_LIST_SOCIALS_TITLE')}</strong>{t('COOKIE_POLICY_DEFINITION_LIST_SOCIALS')}
          </li>
          <li><strong>{t('COOKIE_POLICY_DEFINITION_LIST_AFFILIATE_TITLE')}</strong>{t('COOKIE_POLICY_DEFINITION_LIST_AFFILIATE')}
          </li>
          <li><strong>{t('COOKIE_POLICY_DEFINITION_LIST_MARKETING_TITLE')}</strong>{t('COOKIE_POLICY_DEFINITION_LIST_MARKETING')}
          </li>
          <li><strong>{t('COOKIE_POLICY_DEFINITION_LIST_TECH_TITLE')}</strong>{t('COOKIE_POLICY_DEFINITION_LIST_TECH')}
          </li>
          <li><strong>{t('COOKIE_POLICY_DEFINITION_LIST_PERSONALIZATION_TITLE')}</strong>{t('COOKIE_POLICY_DEFINITION_LIST_PERSONALIZATION')}
          </li>
          <li><strong>{t('COOKIE_POLICY_DEFINITION_LIST_THIRD_TITLE')}</strong>{t('COOKIE_POLICY_DEFINITION_LIST_THIRD')}
          </li>
        </ul>
        <h2>{t('COOKIE_POLICY_TYPES_TITLE')}</h2>
        <p className={'pt-3'}><strong>{t('COOKIE_POLICY_TYPES_1_TITLE')}</strong></p>
        <p>{t('COOKIE_POLICY_TYPES_1_CONTENT')}</p>
        <p>{t('COOKIE_POLICY_TYPES_1_LINK_PREVIOUS')}
          <a href="https://support.google.com/analytics/answer/6004245" target="_blank" rel="noopener">{t('COOKIE_POLICY_TYPES_LINK_TEXT')}</a>.</p>
        <p className={'pt-3'}><strong>{t('COOKIE_POLICY_TYPES_2_TITLE')}</strong></p>
        <p>{t('COOKIE_POLICY_TYPES_2_CONTENT')}</p>
        <p>{t('COOKIE_POLICY_TYPES_2_LINK_PREVIOUS')}
          <a href="//www.hubspot.com/legal/privacy-policy" target="_blank" rel="noopener">{t('COOKIE_POLICY_TYPES_LINK_TEXT')}</a>.</p>
        <p>{t('COOKIE_POLICY_COOKIE_MANAGEMENT_TITLE')}</p>
        <h2>{t('COOKIE_POLICY_COOKIE_BROWSERS_PC_TITLE')}</h2>
        <ul>
          <li><strong>{t('COOKIE_POLICY_COOKIE_BROWSERS_PC_1_TITLE')}</strong>{t('COOKIE_POLICY_COOKIE_BROWSERS_PC_1_CONTENT')}
          </li>
          <li><strong>{t('COOKIE_POLICY_COOKIE_BROWSERS_PC_2_TITLE')}</strong>{t('COOKIE_POLICY_COOKIE_BROWSERS_PC_2_CONTENT')}
          </li>
          <li><strong>{t('COOKIE_POLICY_COOKIE_BROWSERS_PC_3_TITLE')}</strong>{t('COOKIE_POLICY_COOKIE_BROWSERS_PC_3_CONTENT')}
          </li>
          <li><strong>{t('COOKIE_POLICY_COOKIE_BROWSERS_PC_4_TITLE')}</strong>{t('COOKIE_POLICY_COOKIE_BROWSERS_PC_4_CONTENT')}
          </li>
          <li><strong>{t('COOKIE_POLICY_COOKIE_BROWSERS_PC_5_TITLE')}</strong>{t('COOKIE_POLICY_COOKIE_BROWSERS_PC_5_CONTENT')}
          </li>
        </ul>
        <p>{t('COOKIE_POLICY_COOKIE_BROWSERS_PC_CONTENT')}</p>
        <h2>{t('COOKIE_POLICY_COOKIE_BROWSERS_MOBILE_TITLE')}</h2>
        <ul>
          <li><strong>{t('COOKIE_POLICY_COOKIE_BROWSERS_MOBILE_1_TITLE')}</strong>{t('COOKIE_POLICY_COOKIE_BROWSERS_MOBILE_1_CONTENT')}
          </li>
          <li><strong>{t('COOKIE_POLICY_COOKIE_BROWSERS_MOBILE_2_TITLE')}</strong>{t('COOKIE_POLICY_COOKIE_BROWSERS_MOBILE_2_CONTENT')}
          </li>
          <li><strong>{t('COOKIE_POLICY_COOKIE_BROWSERS_MOBILE_3_TITLE')}</strong>{t('COOKIE_POLICY_COOKIE_BROWSERS_MOBILE_3_CONTENT')}
          </li>
        </ul>
        <p>{t('COOKIE_POLICY_COOKIE_BROWSERS_MOBILE_CONTENT')}</p>
      </Container>
      <IndexFooter/>
    </>
  )
}
