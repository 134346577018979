import React from 'react';
import ProductItemRow from "./ProductItemRow.js";
import {Table} from "reactstrap";
import {PlaceholderTable} from "../PlaceholderTable/PlaceholderTable";

const ProductsTable = ({products, isLoading}) => {
  return (
    <>
      {
        isLoading === true ? <PlaceholderTable rows={20}/> :
          <Table className="align-items-center table-flush">
            <thead className="thead-light ">
            <tr>
              <th scope="col"></th>
              <th scope="col">Name</th>
              <th scope="col" className='text-center'>Status</th>
              <th scope="col">Sku</th>
              <th scope="col">Price</th>
              <th scope="col">Sale price</th>
              <th scope="col">Brand</th>
              <th scope="col">Category</th>
              <th scope="col">Url</th>
            </tr>
            </thead>
            <tbody className='list'>
            {
              products && products.map((product, i) =>
                <ProductItemRow key={i} data={product}/>
              )}
            </tbody>
          </Table>
      }
    </>
  )
}


export default ProductsTable;
