import {useCanvasDispatch, useCanvasState} from "../../../../../context/canvas/CanvasIndex";
import {updateData} from "../../../../../context/canvas/CanvasActions";
import {Input} from "reactstrap";
import React from "react";

export const CanvasObjectInfoSidebarDetailAlign = ({title, property}) => {
  const canvasState = useCanvasState()
  const canvasDispatch = useCanvasDispatch()
  const templateObject = canvasState.selectedObject

  const handleAlign = (e) => {
    const {name, value} = e.target;
    let horizontal = templateObject?.object.data[property].horizontal
    let vertical = templateObject?.object.data[property].vertical

    switch (name) {
      case 'horizontal':
        horizontal = value;
        break;
      case 'vertical':
        vertical = value;
        break;
      default:
    }
    updateData(
      canvasDispatch,
      templateObject?.id,
      property,
      {
        horizontal: horizontal,
        vertical: vertical,
      }
    )
  }

  return (
    <>
      <div className={'d-flex flex-column'}>
        <strong className={'mt-1'}>{title}</strong>
        <div className={'d-flex flex-row mt-1'}>
          <div className={'d-flex flex-column flex-grow-1 m-2'}>
            <strong>Horizontal</strong>
            <Input
              type="select"
              name="horizontal"
              value={templateObject?.object.data[property]?.horizontal ?? ''}
              className={'form-control'}
              onChange={handleAlign}
            >
              <option value="left">Left</option>
              <option value="center">Center</option>
              <option value="right">Right</option>
            </Input>
          </div>
          <div className={'d-flex flex-column flex-grow-1 m-2'}>
            <strong>Vertical</strong>
            <Input
              type="select"
              name="vertical"
              value={templateObject?.object.data[property]?.vertical ?? ''}
              className={'form-control'}
              onChange={handleAlign}
            >
              <option value="top">Top</option>
              <option value="center">Center</option>
              <option value="bottom">Bottom</option>
            </Input>
          </div>
        </div>
      </div>
    </>
  )
}
