import {Col, Row} from "reactstrap";
import React from "react";
import {LandingFeedManagement} from "./Functionalities/LandingFeedManagement";
import {LandingDesigner} from "./Functionalities/LandingDesigner";
import {LandingDataHistory} from "./Functionalities/LandingDataHistory";
import {useTranslation} from "react-i18next";

export const LandingFunctionalities = () => {
  const {t} = useTranslation('landing')

  return (
    <>
      <div className="functionalities custom-ov-hidden">
        <Row className="justify-content-center text-center pt-5 pb-5 px-2">
          <Col>
            <h2 className={'landing_header'}>
              {t('FUNCTIONALITIES')}
            </h2>
          </Col>
        </Row>
        <LandingFeedManagement/>
        <LandingDesigner/>
        <LandingDataHistory/>
      </div>
    </>
  )
}
