import {Button, Col, Input, Modal, Row} from "reactstrap";
import React, {useEffect, useState} from "react";

export const RuleGroupNameEditableModal = ({name, setName, showModal, setShowModal}) => {
  const [isLoading, setLoading] = useState(false)
  const [state, setState] = useState(name)

  useEffect(() => {
    setName(name);
  }, [name])

  const handleChange = (e) => {
    const {value} = e.target;
    setState(value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setName(state)
    setShowModal(false)
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={() => setShowModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Name
          </h6>
          <button
            aria-label="Close"
            className="btn-close"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col>
              <Input
                placeholder="Rule Group name"
                type="text"
                defaultValue={name}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button
            className="me-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
          <Button color="primary" type="button"
                  onClick={handleSubmit}>
            {isLoading &&
              <span className="spinner-border spinner-border-sm me-1"/>}
            Save
          </Button>
        </div>
      </Modal>
    </>
  );
};
