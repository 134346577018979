import React, {useCallback, useEffect} from 'react'
import CanvasDesign from "./CanvasDesign";
import {CanvasSidebar} from "./CanvasSidebar";
import {CanvasTemplateObjectAddActions} from "./CanvasActions/CanvasTemplateObjectAddActions";
import {useCanvasState} from "../../context/canvas/CanvasIndex";
import {useNavigate} from "react-router-dom";

export const Canvas = ({companyId, projectId, id}) => {
  const canvasState = useCanvasState()
  const navigate = useNavigate()

  const hasUnsavedChanges = useCallback(() => {
    let current = canvasState.objects.map((object) => ({
      ...object,
      visible: null,
      selected: null
    }))
    let old = canvasState.objectsBeforeSave.map((object) => ({
      ...object,
      visible: null,
      selected: null
    }))
    return JSON.stringify(current) !== JSON.stringify(old)
  }, [canvasState])

  useEffect(() => {
    const handleUnload = (e) => {
      e.preventDefault();
      if (hasUnsavedChanges() && canvasState.template.render_status === 'DRAFT') {
        const mensaje = "Do you want to close this tab? There are unsaved changes";
        e.returnValue = mensaje;
        return mensaje;
      }
    }
    window.addEventListener("beforeunload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    }
  }, [canvasState.objects, canvasState.objectsBeforeSave]);

  useEffect(() => {
    const handlePopstate = (e) => {
      e.preventDefault()
      if (hasUnsavedChanges() && canvasState.template.render_status === 'DRAFT') {
        if (window.confirm('Do you want to close this tab? There are unsaved changes')) {
          navigate(`/admin/companies/${companyId}/projects/${projectId}/templates/${id}`)
        }
      } else {
        navigate(`/admin/companies/${companyId}/projects/${projectId}/templates/${id}`)
      }
    };

    window.history.pushState(null, null, window.location.href);
    window.onpopstate = handlePopstate;

    return () => {
      window.onpopstate = null;
    };
  }, [canvasState.objects, canvasState.objectsBeforeSave]);

  return (
    <>
      <div className={'canvas_box vh-100'}>
        <CanvasTemplateObjectAddActions/>
        <CanvasDesign
          companyId={companyId}
          projectId={projectId}
          id={id}
        />
        <CanvasSidebar/>
      </div>
    </>
  )
}
