import React, {useEffect, useState} from "react";
import {
  selectRule,
  useRuleImportDispatch,
  useRuleImportState
} from "./Context/FeedExportImportRuleIndex";
import {Badge} from "reactstrap";
import classNames from "classnames";

export const FeedExportImportRulesItem = ({rule}) => {
  const ruleImportState = useRuleImportState()
  const ruleImportDispatch = useRuleImportDispatch()

  const [badgeInformation, setBadgeInformation] = useState({
    'name': '',
    'color': '',
  })


  useEffect(() => {

    switch (rule.render_status) {
      case 'PUBLISHED':
        setBadgeInformation({
          'name': 'Published',
          'color': 'success'
        })
        break;
      case 'DRAFT':
        setBadgeInformation({
          'name': 'Draft',
          'color': 'warning'
        })
        break;
      case 'EXPIRED':
        setBadgeInformation({
          'name': 'Expired',
          'color': 'danger'
        })
        break;
      case 'SCHEDULED':
        setBadgeInformation({
          'name': 'Scheduled',
          'color': 'info'
        })
        break;
      default:
        setBadgeInformation({
          'name': '',
          'color': '',
        })
    }
  }, [rule.render_status])

  const handleSelectRule = (ruleId) => {
    let selected = ruleImportState.selected_rules.some((selected_rule) => selected_rule === ruleId);
    let selected_rules = ruleImportState.selected_rules.map(selected_rule => selected_rule);

    if (selected) {
      selected_rules = ruleImportState.selected_rules.filter((selected_rule) => selected_rule !== ruleId);
    } else {
      selected_rules = [...selected_rules, ruleId];
    }

    selectRule(ruleImportDispatch, selected_rules)
  }

  return (
    <>
      <div role={"button"} className={classNames({
        'p-2 me-4 text-primary d-flex flex-row': true,
        'bg-primary': ruleImportState.selected_rules.some((selected_rule) => selected_rule === rule.id),
        'text-white': ruleImportState.selected_rules.some((selected_rule) => selected_rule === rule.id)
      })}
           onClick={() => handleSelectRule(rule.id)}>
        <div>
          <Badge className="badge-md me-1" color={badgeInformation.color}>
            {badgeInformation.name}
          </Badge>
        </div>
        <p role={"button"}>{rule.name}</p>
      </div>
    </>
  )
}
