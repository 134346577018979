import {Badge} from "reactstrap";
import React from "react";

export const PlaceholderTableBody = ({rows, columns, firstEmpty}) => {
  const renderRows = () => {
    const tableRows = [];
    for (let i = 0; i < rows; i++) {
      const tableColumns = [];
      for (let j = 0; j < columns; j++) {
        if (j === 0 && firstEmpty === true) {
          tableColumns.push(
            <td key={`cell-${i}-${j}`}>
              <div className="avatar rounded-circle bg-light" alt=""/>
            </td>
          );
          continue;
        }
        tableColumns.push(
          <td key={`cell-${i}-${j}`}>
            <Badge color="" className="badge-stroke mx-2">
              <i className='bg-light'/>
            </Badge>
          </td>
        );
      }
      tableRows.push(
        <tr key={`row-${i}`}>{tableColumns}</tr>
      );
    }
    return tableRows;
  };
  return (
    <>
      <tbody className={'list'}>
      {
        renderRows()
      }
      </tbody>
    </>
  )
}
