import React, {useEffect, useState} from 'react'
import {FormGroup, Input} from "reactstrap";

export const FeedExportDestination = ({destination, submitted, setDestination, disabled}) => {

  const [state, setState] = useState()

  const handleChange = (e) => {
    const {value} = e.target;
    setState(value);
    setDestination(value);
  }

  useEffect(() => {
    setState(destination);
    setDestination(destination);
  }, [destination])

  return (
    <>
      <FormGroup
        className="mb-3">
        <label
          className="form-control-label"
        >
          FeedExport Destination
        </label>
        <Input
          placeholder="Destination"
          disabled={disabled}
          type="text"
          valid={submitted && state.length > 0}
          invalid={submitted && !state}
          name="destination"
          value={state || ''}
          onChange={handleChange}
        />
        {submitted}
        <div className="invalid-feedback">
          Provide a Feed Export Destination
        </div>
      </FormGroup>
    </>
  )
}
