import {BASE_PRIORITY} from "../CanvasReducer";

export const canvasLoad = (canvas, objects) => {
  return {
    ...canvas,
    selectedObject: null,
    objects: objects.map((object, id) => {
      return {
        id: object.id,
        object: object,
        index: BASE_PRIORITY - id,
        selected: false,
        visible: true,
        errors: [],
      }
    }),
    objectsBeforeSave: objects.map((object, id) => {
      return {
        id: object.id,
        object: object,
        index: BASE_PRIORITY - id,
        selected: false,
        visible: true,
        errors: [],
      }
    }),
  }
}

export const canvasTemplateLoad = (canvas, template) => {
  return {
    ...canvas,
    template: template,
  }
}
