import React, {useState} from "react";
import classnames from "classnames";
import {Collapse, Container} from "reactstrap";
import {CanvasObjectInfoSidebarDetailColor} from "../Common/CanvasObjectInfoSidebarDetailColor";
import {CanvasObjectInfoSidebarDetailFontSource} from "../Text/CanvasObjectInfoSidebarDetailFontSource";
import {CanvasObjectInfoSidebarDetailPriceData} from "./CanvasObjectInfoSidebarDetailPriceData";
import {CanvasObjectInfoSidebarDetailAngle} from "../Common/CanvasObjectInfoSidebarDetailAngle";
import {CanvasObjectInfoSidebarDetailAlign} from "../Common/CanvasObjectInfoSidebarDetailAlign";
import {CanvasObjectInfoSidebarDetailMargin} from "../Common/CanvasObjectInfoSidebarDetailMargin";
import {CanvasObjectInfoSidebarDetailPriceLine} from "./CanvasObjectInfoSidebarDetailPriceLine";
import {CanvasObjectInfoSidebarDetailPadding} from "../Common/CanvasObjectInfoSidebarDetailPadding";
import {CanvasObjectInfoSidebarDetailPercentageSize} from "./CanvasObjectInfoSidebarDetailPercentageSize";

export const CanvasObjectInfoSidebarDetailTwoPriceStrickedPrice = () => {
  const [collapse, setCollapse] = useState(true);

  const handleCollapse = () => {
    setCollapse(!collapse);
  }
  return (
    <>
      <div onClick={handleCollapse}
           className={'canvas_sidebar_header d-flex flex-row justify-content-between align-items-center p-3'}>
        <h3 className="mb-0">Stricked Price Info</h3>
        <span className={classnames({
          'fa': true,
          'fa-chevron-up': !collapse,
          'fa-chevron-down': collapse,
        })}/>
      </div>
      <Container className="">
        <Collapse isOpen={!collapse} className={'mb-2'}>
          <CanvasObjectInfoSidebarDetailPercentageSize
            title={'Size'}
            property={'strickedPricePercentageSize'}
          />
          <CanvasObjectInfoSidebarDetailFontSource
            title={'Font'}
            property={'strickedPriceFont'}
          />
          <CanvasObjectInfoSidebarDetailPriceData
            title={'Price Digits'}
            property={'strickedPriceData'}
          />
          <CanvasObjectInfoSidebarDetailAngle
            title={'Angle'}
            property={'strickedPriceAngle'}
          />
          <CanvasObjectInfoSidebarDetailColor
            title={'Color'}
            property={'strickedPriceColor'}
          />
          <CanvasObjectInfoSidebarDetailAlign
            title={'Align'}
            property={'strickedPriceAlign'}
          />
          <CanvasObjectInfoSidebarDetailPadding
            title={'Padding'}
            property={'strickedPricePadding'}
          />
          <CanvasObjectInfoSidebarDetailMargin
            title={'Margin'}
            property={'strickedPriceMargin'}
          />
          <CanvasObjectInfoSidebarDetailColor
            title={'Inner Background Color'}
            property={'strickedPriceInnerBackgroundColor'}
          />
          <CanvasObjectInfoSidebarDetailPriceLine/>
        </Collapse>
      </Container>
    </>
  );
};
