import DataTable from "react-data-table-component";
import React, {useEffect, useState} from "react";
import {ProductDiagnosticIssueTypologiesTableHeaders} from "./ProductDiagnosticIssueTypologiesTableHeaders";
import {useProjectState} from "../../../context/project/ProjectIndex";
import {Card, CardBody, CardHeader, CardTitle} from "reactstrap";
import {useAnalyticsState} from "../../../context/analytics/AnalyticsIndex";
import {ProductDiagnostic} from "../../../services/api/ProductDiagnostic";

export const ProductDiagnosticIssueTypologiesTable = ({platform, destination}) => {
  const analyticsState = useAnalyticsState()

  const [isLoading, setLoading] = useState(false)
  const customStyles = {
    rows: {
      style: {
        fontSize: '14px',
        color: '#42325D',
        fontWeight: 'bold'
      },
    },
    headRow: {
      style: {
        backgroundColor: '#F6F9FC',
        color: '#8F88AA',
        fontSize: '10px',
        fontWeight: 'bold'
      },
    },
  };

  const projectState = useProjectState();
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setLoading(true);

    ProductDiagnostic.issue_typologies(
      projectState.companyId,
      projectState.projectId,
      {
        platform: platform,
        destination: destination
      },
    ).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        if (r.data.issueTypologies.length) {
          parseIssueTypologies(r.data.issueTypologies);
          setColumns(ProductDiagnosticIssueTypologiesTableHeaders(r.data.headers, projectState, projectState.locale));
        } else {
          setData([]);
        }
      }
    });
  }, [platform, destination, analyticsState.dates]);

  const parseIssueTypologies = (issueTypologies) => {
    setData(issueTypologies.map(function (item) {
        return {
          ...item,
          'date': item?.date?.getTime(),
        }
      })
    );
  }

  return (
    <>
      <div className={'d-flex flex-row mb-1 justify-content-between'}>
        <Card className={'flex-fill'}>
          <CardHeader className={'d-flex flex-row justify-content-between'}>
            <CardTitle className={'align-self-left'}>Issues</CardTitle>
          </CardHeader>
          <CardBody>
            <DataTable
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={[25, 50, 100, 200, 500]}
              progressPending={isLoading}
              dense
              data={data}
              columns={columns}
              customStyles={customStyles}
            />
          </CardBody>
        </Card>
      </div>
    </>
  )
}
