import {updateImageContent, useCanvasDispatch, useCanvasState} from "../../../../../context/canvas/CanvasIndex";
import {Button, FormGroup, Input} from "reactstrap";
import React, {useEffect, useState} from "react";
import {selectCanvasAsset} from "../../../../../context/canvas/selectCanvasAsset/SelectCanvasAssetActions";
import {useSelectCanvasAssetDispatch} from "../../../../../context/canvas/selectCanvasAsset/SelectCanvasAssetContext";
import {AssetModalSelector} from "../../../../Assets/ModalSelector/AssetModalSelector";
import {Assets} from "../../../../../services/api/Assets";
import {useProjectState} from "../../../../../context/project/ProjectIndex";
import {clearValidationError, setValidationError} from "../../../../../context/canvas/CanvasActions";
import classNames from "classnames";
import Select from "react-select";

const baseOptions = [
  {value: '{{image}}', label: 'Main Image'},
  {value: '{{aditionalImages.0}}', label: 'Extra Image 1'},
  {value: '{{aditionalImages.1}}', label: 'Extra Image 2'},
  {value: '{{aditionalImages.2}}', label: 'Extra Image 3'},
  {value: '{{aditionalImages.3}}', label: 'Extra Image 4'},
  {value: '{{aditionalImages.4}}', label: 'Extra Image 5'},
]

export const CanvasObjectInfoSidebarDetailImageSource = () => {
  const canvasState = useCanvasState()
  const canvasDispatch = useCanvasDispatch()
  const projectState = useProjectState()
  const templateObject = canvasState.selectedObject
  const selectCanvasAssetDispatch = useSelectCanvasAssetDispatch()
  const [showModal, setShowModal] = useState(false)
  const [state, setState] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [options, setOptions] = useState(baseOptions)

  useEffect(() => {
    setOptions([...baseOptions, ...projectState.properties.map(property => ({
      value: `properties.${property}`,
      label: `Custom - ${property}`,
    }))])
  }, [projectState.properties]);

  useEffect(() => {
    if (templateObject?.object.data.content?.imageContent?.type === 'asset') {
      if (templateObject?.object.data.content?.imageContent?.value === '{{image}}' || templateObject?.object.data.content?.imageContent?.value === undefined || templateObject.object.data.content?.imageContent?.value === null) {
        setState({...state, src: null, name: ''})
        setValidationError(canvasDispatch, templateObject?.id, {
          code: 'ASSET_NOT_SELECTED', message: `You need to select a image to object: ${templateObject.id}`,
        })
        return
      }
      setLoading(true)
      Assets.get(projectState.companyId, projectState.projectId, templateObject?.object.data.content?.imageContent?.value).then((r) => {
        setState({...state, src: r.data?.external_url, name: r.data?.name})
        setLoading(false)
      })
    } else {
      if (templateObject?.object.data.content?.imageContent?.value === '{{image}}') {
        setState({...state, src: canvasState.product?.image?.url, name: '{{image}}'})
      }
    }
    clearValidationError(canvasDispatch, templateObject?.id, 'ASSET_NOT_SELECTED')
  }, [templateObject?.object?.data?.content?.imageContent, canvasState.product])

  const handleImageSourceType = (e) => {
    const {value} = e.target;
    updateImageContent(canvasDispatch, templateObject?.id, {
      type: value,
      value: value === 'asset' ? templateObject?.object.data.content.imageContent?.value : '{{image}}',
      format: {
        type: 'image'
      }
    })
  }

  const onShowModal = () => {
    selectCanvasAsset(selectCanvasAssetDispatch, {
      id: templateObject?.object.data.content.imageContent?.value, name: ''
    })
    setShowModal(true);
  }

  const renderImageSourceValue = () => {
    switch (templateObject?.object.data.content?.imageContent?.type) {
      case 'asset':
        return (<>
          <div className={classNames({
            'position-relative': true,
            'outline_error': templateObject.errors?.filter((error) => error.code === 'ASSET_NOT_SELECTED').length > 0
          })}>
            <div className={'d-flex flex-row justify-content-between'}>
              <div className={'d-flex flex-column justify-content-center'} style={{maxWidth: '75%'}}>
                <img src={state.src ?? require('assets/img/canvas/empty.jpg')} className={'p-3'}
                     style={{maxHeight: '8rem'}}/>
                <div className={'d-flex justify-content-center'}>
                  <small style={{
                    textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'
                  }}>{state.name}</small>
                </div>
              </div>
              <div className={'d-flex align-items-center'}>
                <Button onClick={onShowModal} className={'m-2'} color={'primary'}>
                  <span className={'fa fa-solid fa-repeat'}/>
                </Button>
              </div>
            </div>
            {isLoading ? <div
              className={'position-absolute top-0 w-100 h-100 bg-blur d-flex justify-content-center align-items-center'}
              style={{zIndex: 1000}}>
              <span className="spinner-border spinner-border-lg"/>
            </div> : <></>}
          </div>
        </>)
      case 'property':
        return (<FormGroup>
          <Select
            className={'m-2 image_source_selector'}
            name={'image_source'}
            value={options.find(option => option.value === templateObject?.object.data.content?.imageContent?.value)}
            options={options}
            onChange={handleImageSourceProperty}
          />
        </FormGroup>)
      default:
        return (<></>)
    }
  }

  const handleImageSourceProperty = (e) => {
    const {value} = e;
    updateImageContent(canvasDispatch, templateObject?.id, {
      type: 'property', value: value, format: {
        type: 'image'
      }
    })
  }

  return (<>
    <div className={'d-flex flex-column'}>
      <strong className={'mt-1'}>Image Source</strong>
      <Input type="select" name="type"
             value={templateObject?.object.data.content?.imageContent?.type ?? ''}
             onChange={handleImageSourceType}
             className={'m-2'}>
        <option value="asset">Asset</option>
        <option value="property">Property</option>
      </Input>
      {renderImageSourceValue()}
    </div>
    <AssetModalSelector
      showModal={showModal}
      setShowModal={setShowModal}
      canvasObjectId={templateObject?.id}
      assetType={'image'}
    />
  </>)
}
