import React, {useEffect, useState} from 'react';
import {Badge, Col} from "reactstrap";
import {useTemplateState} from "../../../../context/template/TemplateIndex";

const TemplateRenderStatusBadge = () => {
  const templateState = useTemplateState()
  const [badgeInformation, setBadgeInformation] = useState({
    'name': '',
    'color': '',
  })

  useEffect(() => {
    switch (templateState.renderStatus) {
      case 'PUBLISHED':
        setBadgeInformation({
          'name': 'Published',
          'color': 'success'
        })
        break;
      case 'DRAFT':
        setBadgeInformation({
          'name': 'Draft',
          'color': 'warning'
        })
        break;
      case 'EXPIRED':
        setBadgeInformation({
          'name': 'Expired',
          'color': 'danger'
        })
        break;
      case 'SCHEDULED':
        setBadgeInformation({
          'name': 'Scheduled',
          'color': 'info'
        })
        break;
      default:
        setBadgeInformation({
          'name': '',
          'color': ''
        })
    }
  }, [templateState.renderStatus])

  return (
    <>
      <Col className="pull-right">
        <Badge className="badge-lg" color={badgeInformation.color}>
          {badgeInformation.name}
        </Badge>
      </Col>
    </>
  );
}

export default TemplateRenderStatusBadge;
