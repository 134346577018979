import {useProjectDispatch} from "../../../context/project/ProjectContext";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {updateCompanyAndProjectBasedOnUrl} from "../../../context/project/ProjectActions";
import {Container} from "reactstrap";
import {DataSourceNewApiConnection} from "../../../components/Feeds/DataSourceNew/DataSourceNewApiConnection";
import {DataSources} from "../../../services/api/DataSources";
import {apiErrorRaised} from "../../../context/api_error/ApiErrorActions";
import {useApiErrorDispatch} from "../../../context/api_error/ApiErrorContext";

export const DataSourceNewApiConnectionView = () => {
  const projectDispatch = useProjectDispatch()
  const [order, setOrder] = useState(1);
  const routerParams = useParams();
  const dispatch = useApiErrorDispatch();

  useEffect(() => {
    updateCompanyAndProjectBasedOnUrl(projectDispatch, routerParams.companyId, routerParams.projectId)
  }, [])

  useEffect(() => {
    DataSources.index(routerParams.companyId, routerParams.projectId).then((r) => {
      if (r?.status < 400) {
        let order = 1;
        if (r.data.length > 0) {
          order = Math.max.apply(Math, r.data.map(function (dataSource) {
            return dataSource.order;
          })) + 1
        }
        setOrder(order)
      } else {
        apiErrorRaised(dispatch, r?.response)
      }
    })
  }, [])

  return (
    <>
      <Container className="mt-5" fluid>
        <DataSourceNewApiConnection order={order}
        />
      </Container>
    </>
  );
};
