import React, {useEffect, useState} from 'react';
import {Button, Row} from "reactstrap";
import FilterEditor from "./FilterEditor";
import {updateFilter, useFilterDispatch, useFilterState} from "../../context/filter/FilterIndex";
import {v4 as uuidv4} from 'uuid';
import FilterFactoryView from "./FilterFactoryView";

export default function Filters({fields, conditions, finishEditCallback}) {

  const filters = useFilterState();
  const [isEditing, setIsEditing] = useState(false)
  const dispatch = useFilterDispatch();
  const [filtersWithId, setFiltersWithId] = useState(false)

  useEffect(() => {
    updateFilter(
      dispatch,
      {
        fields: fields,
        conditions: []
      }
    )

    const reduceLogicFilter = (filter) => {
      if (filter.hasOwnProperty('type')) {
        const id_left = reduceLogicFilter(filter.left)
        const id_right = reduceLogicFilter(filter.right)

        filter = {
          ...filter,
          left: id_left,
          right: id_right,
        }
      }
      return {...filter, id: uuidv4()}
    }

    let finalFilters = []

    conditions?.map((filter) => {
      let newFilter = reduceLogicFilter(filter)
      finalFilters.push(newFilter)
      return true;
    });

    updateFilter(
      dispatch,
      {
        fields: fields,
        conditions: finalFilters
      }
    )

    setFiltersWithId(true)


  }, [dispatch, conditions, fields])

  const handleNewFilter = (newFilter) => {
    if (newFilter !== undefined) {
      updateFilter(
        dispatch,
        {
          ...filters,
          conditions: [...filters.conditions, newFilter]
        }
      )
      setIsEditing(false)
    }
  };

  const handleDelete = (filterToDel) => {
    const newArray = filters.conditions.filter(obj => obj.id !== filterToDel.id);
    updateFilter(
      dispatch,
      {
        ...filters,
        conditions: newArray
      }
    )
  }


  return (
    <>
      {isEditing ?
        <FilterEditor fields={fields} saveCallback={handleNewFilter}/>
        :
        <>
          {filtersWithId && filters.conditions
            .map((filter, i) => <><Row onClick={() => {
              handleDelete(filter)
            }}> Delete</Row><FilterFactoryView filter={filter} key={filter.id + 'prim'} filterIdx={filter.id}/></>)
            .reduce((acc, x) => acc === null ? x : <>{acc} AND {x}</>, null)
          }

          <Row>
            <Button size={"sm"} className="btn-icon-only btn btn-terciary btn btn-white" onClick={() => {
              handleNewFilter({id: uuidv4()})
            }}><span className="fa-solid fa-plus fs-3"></span></Button>
            <Button size={"sm"} className="btn-icon-only btn btn-terciary btn btn-white" onClick={() => {
              finishEditCallback(filters.conditions)
            }}><i className={"fa fa-save"}/></Button>
          </Row>
        </>
      }
    </>
  )
}

