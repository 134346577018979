export const EventsManagerTableHeaders = [
  {
    name: "Status".toUpperCase(),
    selector: row => row.status,
    sortable: true,
    grow: 0.25
  },
  {
    name: "Event".toUpperCase(),
    selector: row => row.event_name,
    sortable: true
  },
  {
    name: "Params".toUpperCase(),
    selector: row => row.params
  },
  {
    name: "Date".toUpperCase(),
    selector: row => row.date,
    sortable: true
  }
];
