export const initialState = [];

export const UrlParametersReducer = (initialState, action) => {
  let index;
  switch (action.type) {
    case "URL_PARAMETERS_DELETE":
      index = initialState.findIndex(prop => prop.id === action.payload)
      if (index > -1) {
        return [
          ...initialState.slice(0, index),
          ...initialState.slice(index + 1),
        ]
      }
      return initialState
    case "URL_PARAMETERS_ADD":
      return [
        ...initialState,
        action.payload
      ]
    case "URL_PARAMETERS_UPDATE":
      index = initialState.findIndex(prop => prop.id === action.payload)
      if (index > -1) {
        return [
          ...initialState.slice(0, index),
          action.payload,
          ...initialState.slice(index + 1)
        ]
      }
      return initialState
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
