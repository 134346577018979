import React, {useEffect} from "react";
import {TermsAndConditions} from "../../../components/Legal/TermsAndConditions";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

const TermsAndConditionsView = () => {
  const {i18n} = useTranslation()
  const routerParams = useParams();

  useEffect(() => {
    i18n.changeLanguage(routerParams.language)
  }, []);

  return (
    <>
      <TermsAndConditions/>
    </>);
}

export default TermsAndConditionsView;
