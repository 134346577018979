import React, {useEffect, useState} from 'react';
import {Rnd} from "react-rnd";
import {resizeObject, selectCanvasObject} from "../../../context/canvas/CanvasActions";
import {useCanvasDispatch, useCanvasState} from "../../../context/canvas/CanvasIndex";
import classNames from "classnames";
import {ConvertRGBtoHex} from "../CanvasUtils";
import {Assets} from "../../../services/api/Assets";
import {useProjectState} from "../../../context/project/ProjectIndex";

const CanvasImage = ({position}) => {
  const projectState = useProjectState()
  const canvasDispatch = useCanvasDispatch();
  const canvasState = useCanvasState();
  const templateObject = canvasState.objects[position];
  const [state, setState] = useState(
    {
      src: '',
    }
  );

  useEffect(() => {
    if (templateObject.object.data.content?.imageContent?.type === 'asset') {
      if (templateObject.object.data.content?.imageContent?.value === '{{image}}' || templateObject.object.data.content?.imageContent?.value === undefined || templateObject.object.data.content?.imageContent?.value === null) {
        setState({...state, src: null})
        return
      }
      Assets.get(projectState.companyId, projectState.projectId, templateObject.object.data.content?.imageContent?.value).then((r) => {
        setState({...state, src: r.data?.external_url})
      })
    } else {
      if (templateObject.object.data.content?.imageContent?.value === '{{image}}') {
        setState({...state, src: canvasState.product?.image?.url})
      } else {
        let parts = templateObject.object.data.content?.imageContent?.value.replace(/{{|}}/g, '').split('.')
        if (parts === undefined) {
          return;
        }
        if (canvasState.product.hasOwnProperty(parts[0])) {
          if (canvasState.product[parts[0]].hasOwnProperty(parts[1])) {
            setState({...state, src: canvasState?.product[parts[0]][parts[1]]?.url})
          }
        }
      }
    }
  }, [templateObject.object.data.content?.imageContent?.type, templateObject.object.data.content?.imageContent?.value, canvasState.product])

  const onDragStop = (e, d) => {
    resizeObject(
      canvasDispatch,
      templateObject.id,
      parseInt(d.x),
      parseInt(d.y),
      parseInt(templateObject.object.data.size.x),
      parseInt(templateObject.object.data.size.y),
    )
  }

  const onResizeStop = (e, direction, ref, delta, pos) => {
    resizeObject(
      canvasDispatch,
      templateObject.id,
      parseInt(pos.x),
      parseInt(pos.y),
      parseInt(ref.offsetWidth),
      parseInt(ref.offsetHeight),
    )
  }

  const handleClick = () => {
    selectCanvasObject(canvasDispatch, templateObject.id);
  }
  return (
    <>
      <Rnd className={classNames({
        canvas_object: true,
        canvas_image_horizontal_left: templateObject.object.data?.align?.horizontal === 'left',
        canvas_image_horizontal_right: templateObject.object.data?.align?.horizontal === 'right',
        canvas_image_horizontal_center: templateObject.object.data?.align?.horizontal === 'center',
        canvas_image_vertical_top: templateObject.object.data?.align?.vertical === 'top',
        canvas_image_vertical_bottom: templateObject.object.data?.align?.vertical === 'bottom',
        canvas_image_vertical_center: templateObject.object.data?.align?.vertical === 'center',
        hide: !templateObject.visible,
        selected: templateObject.selected,
        canvas_object_hover: !templateObject.selected && templateObject?.errors?.length === 0,
        error: templateObject?.errors?.length > 0
      })}
           style={{
             zIndex: templateObject.index,
             backgroundColor: ConvertRGBtoHex(
               templateObject.object.data.backgroundColor?.red ?? 255,
               templateObject.object.data.backgroundColor?.green ?? 0,
               templateObject.object.data.backgroundColor?.blue ?? 0,
               templateObject.object.data.backgroundColor?.alpha ?? 255,
             ),
             cursor: 'pointer',
           }}
           position={{
             x: templateObject.object.data.origin.x,
             y: templateObject.object.data.origin.y,
           }}
           size={{
             width: templateObject.object.data.size.x,
             height: templateObject.object.data.size.y,
           }}
           onDragStop={onDragStop}
           onResizeStop={onResizeStop}
           onResizeStart={handleClick}
           onDragStart={handleClick}
           onClick={handleClick}
           enableResizing={templateObject.selected}
           resizeHandleClasses={{
             topLeft: templateObject.selected ? 'resize-handle' : '',
             topRight: templateObject.selected ? 'resize-handle' : '',
             bottomLeft: templateObject.selected ? 'resize-handle' : '',
             bottomRight: templateObject.selected ? 'resize-handle' : '',
           }}
           dragGrid={canvasState.stickToGrid === true ? [16, 16] : [1, 1]}
           resizeGrid={canvasState.stickToGrid === true ? [16, 16] : [1, 1]}
      >
        <img
          src={state.src ?? require('assets/img/canvas/empty.jpg')}
          draggable={false}
          alt=""
          className={classNames({
            canvas_image_fit: templateObject.object.data.displayTransformation === 'fit',
            canvas_image_fill: templateObject.object.data.displayTransformation === 'fill',
          })}
          style={{
            paddingTop: templateObject.object.data.padding?.top + 'px',
            paddingBottom: templateObject.object.data.padding?.bottom + 'px',
            paddingLeft: templateObject.object.data.padding?.left + 'px',
            paddingRight: templateObject.object.data.padding?.right + 'px',
          }}
        />
      </Rnd>
    </>
  )
}

export default CanvasImage;
