import React from 'react'
import {Container} from "reactstrap";
import {UserProfile} from "../../../components/Users/UserProfile";

export const UserProfileView = () => {
  return (
    <>
      <Container className="mt-7">
        <UserProfile/>
      </Container>
    </>
  )
}
