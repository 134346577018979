

const pinterestProperties = [

  {destination: 'id', origin: 'sku', isRequired: true},
  {destination: 'title', origin: 'title', isRequired: true},
  {destination: 'description', origin: 'description', isRequired: true},
  {destination: 'availability', origin: 'availability', isRequired: true},
  {destination: 'condition', origin: 'condition', isRequired: true},
  {destination: 'link', origin: 'link', isRequired: true},
  {destination: 'image_link', origin: 'image', isRequired: true},
  {destination: 'brand', origin: 'brand', isRequired: true},
  {destination: 'gtin', origin: 'gtin', isRequired: true},
  {destination: 'mpn', origin: 'mpn', isRequired: true},
  {destination: 'price', origin: 'price', isRequired: true},
  {destination: 'sale_price', origin: 'salePrice', isRequired: false},
  {destination: 'additional_image_link', origin: '', isRequired: false},
  {destination: 'age_group', origin: '', isRequired: false},
  {destination: 'color', origin: '', isRequired: false},
  {destination: 'gender', origin: '', isRequired: false},
  {destination: 'item_group_id', origin: '', isRequired: false},
  {destination: 'google_product_category', origin: 'category', isRequired: false},
  {destination: 'fb_product_category', origin: '', isRequired: false},
  {destination: 'material', origin: '', isRequired: false},
  {destination: 'pattern', origin: '', isRequired: false},
  {destination: 'product_type', origin: 'type', isRequired: false},
  {destination: 'sale_price_effective_date', origin: '', isRequired: false},
  {destination: 'shipping', origin: '', isRequired: false},
  {destination: 'shipping_weight', origin: '', isRequired: false},
  {destination: 'size', origin: '', isRequired: false},
  {destination: 'custom_label_0', origin: '', isRequired: false},
  {destination: 'custom_label_1', origin: '', isRequired: false},
  {destination: 'custom_label_2', origin: '', isRequired: false},
  {destination: 'custom_label_3', origin: '', isRequired: false},
  {destination: 'custom_label_4', origin: '', isRequired: false},
  {destination: 'visibility', origin: '', isRequired: false},
  {destination: 'material', origin: '', isRequired: false},
  {destination: 'identifier_exists', origin: '', isRequired: false},
  {destination: 'energy_efficiency_class', origin: '', isRequired: false},

]


export default pinterestProperties;
