import {Button, Col, Input, Modal, Row} from "reactstrap";
import React, {useEffect, useState} from "react";
import {templateUpdated, useTemplateDispatch, useTemplateState} from "../../../context/template/TemplateIndex";
import {Templates} from "../../../services/api/Templates";
import {useProjectState} from "../../../context/project/ProjectIndex";
import {apiErrorRaised, useApiErrorDispatch} from "../../../context/api_error/ApiErrorIndex";
import {formatUtcDateIntoTimezone} from "../../../utils/DateTime";
import {zonedTimeToUtc} from "date-fns-tz";

const TemplateDetailEditableModal = ({name, type, showModal, setShowModal}) => {
  const projectState = useProjectState()
  const templateState = useTemplateState()
  const templateDispatcher = useTemplateDispatch()
  const dispatch = useApiErrorDispatch();
  const [isLoading, setLoading] = useState(false)
  const [state, setState] = useState(templateState[name])

  useEffect(() => {
    setState(templateState[name]);
  }, [templateState, name])

  const handleChange = (e) => {
    const {value} = e.target;
    setState(value);
  }

  const parseValue = () => {
    if (type !== 'datetime-local') {
      return state;
    }
    if (state === null || state === '') {
      return null;
    }
    return zonedTimeToUtc(
      new Date(state), projectState.timezone
    )
  }

  const defaultValue = () => {
    if (type !== 'datetime-local') {
      return state;
    }
    if (state === null || state === '' || state === undefined) {
      return null;
    }
    return formatUtcDateIntoTimezone(state, "yyyy-MM-dd HH:mm", projectState.timezone)
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let payload = {
      ...templateState,
      id: undefined,
    }
    payload[name] = parseValue()
    Templates.put(projectState.companyId, projectState.projectId, templateState.id, payload).then((r) => {
      if (r?.status < 400) {
        templateUpdated(templateDispatcher, name, payload[name])
        setShowModal(false)
      } else {
        setLoading(false);
        apiErrorRaised(dispatch, r?.response)
      }
    });
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={() => setShowModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Update
          </h6>
          <button
            aria-label="Close"
            className="btn-close"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
          </button>
        </div>
        <div className="modal-body">
          <Row>
            <Col>
              <Input
                placeholder={name}
                type={type}
                name={name}
                defaultValue={defaultValue()}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button
            className="me-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
          <Button color="primary" type="button"
                  onClick={handleSubmit}>
            {isLoading &&
              <span className="spinner-border spinner-border-sm me-1"/>}
            Save
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default TemplateDetailEditableModal;
