import React, {useEffect, useState} from "react";
import {Companies} from "../../../../services/api/Companies";
import {apiErrorRaised} from "../../../../context/api_error/ApiErrorActions";
import {useApiErrorDispatch} from "../../../../context/api_error/ApiErrorContext";
import {
  loadCompanies,
  selectCompany,
  useRuleImportDispatch,
  useRuleImportState
} from "./Context/FeedExportImportRuleIndex";
import classNames from "classnames";
import Paginator from "../../../Pagination/Paginator";
import {useAuthState} from "../../../../context/auth/AuthContext";

export const FeedExportImportRulesCompaniesList = () => {
  const [isLoading, setLoading] = useState(false)
  const dispatch = useApiErrorDispatch();
  const ruleGroupImportState = useRuleImportState()
  const ruleGroupImportDispatch = useRuleImportDispatch()
  const [pagination, setPagination] = useState({})
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  const authState = useAuthState()

  useEffect(() => {
    setLoading(true)
    Companies.index(limit, offset, null).then((r) => {
      setLoading(false)
      if (r?.status < 400) {
        loadCompanies(ruleGroupImportDispatch, r?.data.companies)
        setPagination(r.data?.pagination);
      } else {
        setLoading(false)
        apiErrorRaised(dispatch, r?.response)
      }
    })
  }, [limit, offset])

  const handleClick = (e, offset, limit) => {
    e.preventDefault();
    setLimit(limit)
    setOffset(offset)
  }


  const handleSelectCompany = (companyId) => {
    selectCompany(ruleGroupImportDispatch, companyId)
  }

  return (
    <>
      <h5>Companies</h5>
      <div className={'d-flex flex-column'}>
        {
          isLoading ? <span className="spinner-border spinner-border-sm me-1"/> :
            ruleGroupImportState.companies && ruleGroupImportState.companies.map((company, i) => {
              return (
                <div role={"button"} className={classNames({
                  'p-2 me-4 text-primary': true,
                  'bg-primary': ruleGroupImportState.selected_company === company.id,
                  'text-white': ruleGroupImportState.selected_company === company.id
                })}
                     key={i}
                     onClick={() => handleSelectCompany(company.id)}>
                  {company.name}
                </div>
              )
            })
        }
      </div>
      {
        ruleGroupImportState.companies.length > 0 && authState.user.role === 'admin' && <Paginator
          pagination={pagination}
          handleClick={handleClick}
          offset={offset}
          limit={limit}
          showRowOptions={false}
        />
      }
    </>
  )
}
