/*eslint-disable*/
import React from "react";

// reactstrap components
import {Col, Container, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

function IndexFooter() {
  const {t, i18n} = useTranslation('landing')
  return (
    <>
      <footer className="py-4" id="footer-main">
        <Container>
          <Row className="align-items-center justify-content-lg-between">
            <Col lg="6">
              <div className="copyright text-left text-lg-left">
                © {new Date().getFullYear()}{" "}
                Feedest by
                <a
                  className="font-weight-bold ms-1"
                  href="https://cyberclick.es"
                  target="_blank"
                >
                  Cyberclick
                </a>
              </div>
            </Col>
            <Col lg="6">
              <div className="d-flex justify-content-end gap-3">
                <Link
                  to={'/' + i18n.language + '/privacy-policy'}
                  className="font-weight-bold ms-1">
                  {t('PRIVACY_POLICY')}
                </Link>
                <Link
                  to={'/' + i18n.language + '/cookies-policy'}
                  className="font-weight-bold ms-1">
                  {t('COOKIES_POLICY')}
                </Link>
                <Link
                  to={'/' + i18n.language + '/terms-and-conditions'}
                  className="font-weight-bold ms-1">
                  {t('LEGAL_WARNING')}
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default IndexFooter;
