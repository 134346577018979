import React from "react";
// nodejs library that concatenates classes
// reactstrap components
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import {useTranslation} from "react-i18next";

const ForgotPasswordThanks = () => {
  const {t} = useTranslation('auth')
  return (
    <>
      <AuthHeader
        title={t('FORGOT_PASSWORD_TITLE')}
        lead={t('FORGOT_PASSWORD_DESCRIPTION')}
      />
    </>
  );
}

export default ForgotPasswordThanks;
