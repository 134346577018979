import React from "react";
import {useTranslation} from "react-i18next";

export const LandingDesigner = () => {
  const {t} = useTranslation('landing')
  return (
    <>
      <div className="spacing-fix">
        <div className={'d-flex responsive'}>
          <div className="col-6 p-6 d-flex flex-column justify-content-center custom-order custom-p3">
            <div className="mb-4 d-flex gap-2">
                  <span className="text-feed-manager d-flex flex-column justify-content-center">
                  <img
                    alt="..."
                    width='40px'
                    className="my-auto img-fluid"
                    src={require("assets/img/landing/canva.png")}
                  />
                  </span>
              <h3 className="m-0 title">{t('DESIGNER')}</h3>
            </div>
            <div className="text-row-lp">
              <p>{t('DESIGNER_DESCRIPTION')}</p>
            </div>
          </div>
          <div className="col-6 d-flex justify-content-end customer-order-first custom-w100">
            <div className="image-fix right">
              <img
                alt="..."
                className="img-fluid"
                src={require("assets/img/landing/Mac_2.png")}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
