import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {DataSources} from "../../services/api/DataSources";
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {useProjectState} from "../../context/project/ProjectIndex";
import classNames from "classnames";
import {Button, ButtonGroup, Tooltip} from "reactstrap";

const FeedItem = ({feedData}) => {
  const projectState = useProjectState()
  const apiErrorDispatch = useApiErrorDispatch();
  const [feed, setFeed] = useState(feedData);
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const notificationAlertRef = React.useRef(null);

  useEffect(() => {
    setFeed(feedData)
  }, [feedData])

  const handleCopy = () => {
    navigator.clipboard.writeText(feed.url);
    setTooltipOpen(true)
    setInterval(() => {
      setTooltipOpen(false)
    }, 2000)
  }

  const handleToggle = (e) => {
    const {name, checked} = e.target;
    if (checked) {
      DataSources.activate(projectState.companyId, projectState.projectId, feed.id).then((r) => {
        if (r?.status < 400) {
          setFeed(feed => ({...feed, [name]: checked}))
        } else {
          apiErrorRaised(apiErrorDispatch, r?.response)
        }
      });
    } else {
      DataSources.disable(projectState.companyId, projectState.projectId, feed.id).then((r) => {
        if (r?.status < 400) {
          setFeed(feed => ({...feed, [name]: checked}))
        } else {
          apiErrorRaised(apiErrorDispatch, r?.response)
        }
      });
    }
  }

  return (
    <>
      <tr>
        <td className={'pt-4'}>
          <Link
            to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/data_sources/${feed.id}/edit`}>
            <p className={classNames({
              'text-left': true,
              'ms-4': true,
              'text-gray': !feed.status
            })}>
              {feed.name}
            </p>
          </Link>
        </td>
        <td>
          <p className={classNames({
            'text-left': true,
            'ps-4': true,
            'text-gray': !feed.status
          })}>
            <span id='copy' className={classNames({
              'fa fa-2x fa-copy me-3 text-primary': true,
              'text-gray': !feed.status
            })} onClick={handleCopy}
            />
            <Tooltip
              placement="top"
              target="copy"
              trigger="manual"
              isOpen={tooltipOpen}
              delay={{"show": 0, "hide": 100}}
            >
              Copied!
            </Tooltip>
            {feed.url}
          </p>
        </td>
        <td width={"48%"} className='overflow-auto text-break'>
          <div className='d-flex justify-content-center'>
          <ButtonGroup aria-label="Settings" role="group" className='w-75'>
            <Button disabled={!feed.status} className='p-3'>
              <Link
                to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/data_sources/${feed.id}/edit`}>
                Settings
              </Link>
            </Button>
            <div className='bg-lavender-mist d-flex align-items-center'>
            <div className="vertical-line"></div> {/* Línea vertical */}
            </div>
            <Button disabled={!feed.status} className='p-3'>
              <Link
                to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/data_sources/${feed.id}/mapping`}>
                Mapping
              </Link>
            </Button>
            <div className='bg-lavender-mist d-flex align-items-center'>
            <div className="vertical-line"></div> {/* Línea vertical */}
            </div>
            <Button disabled={!feed.status} className='p-3'>
              <Link
                to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/data_sources/${feed.id}/details`}>
                Details
              </Link>
            </Button>
          </ButtonGroup>
          </div>
        </td>
        <td>
          <label className="custom-toggle ms-auto me-auto">
            <input type="checkbox" name="status" checked={feed.status} onChange={handleToggle}/>
            <span className="custom-toggle-slider rounded-circle"/>
          </label>
        </td>
      </tr>
    </>
  )
}

export default FeedItem;
