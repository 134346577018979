export const initialState = {
  companies: [],
  selected_company: '',
  projects: [],
  selected_project: '',
  feed_exports: [],
  selected_rule_group: '',
  rules: [],
  selected_rules: [],
};

export const FeedExportImportRuleReducer = (initialState, action) => {
  switch (action.type) {
    case "LOAD_COMPANIES":
      return {
        ...initialState,
        companies: action.payload,
        selected_company: '',
        projects: [],
        selected_project: '',
        feed_exports: [],
        selected_rule_group: '',
        rules: [],
        selected_rules: [],
      }
    case "SELECT_COMPANY":
      return {
        ...initialState,
        selected_company: action.payload,
        projects: [],
        selected_project: '',
        feed_exports: [],
        selected_rule_group: '',
        rules: [],
        selected_rules: [],
      }
    case "LOAD_PROJECTS":
      return {
        ...initialState,
        projects: action.payload,
        selected_project: '',
        feed_exports: [],
        selected_rule_group: '',
        rules: [],
        selected_rules: [],
      }
    case "SELECT_PROJECT":
      return {
        ...initialState,
        selected_project: action.payload,
      }
    case "LOAD_FEED_EXPORTS":
      return {
        ...initialState,
        feed_exports: action.payload,
        selected_rule_group: '',
        rules: [],
        selected_rules: [],
      }
    case "SELECT_FEED_EXPORT":
      return {
        ...initialState,
        selected_rule_group: action.payload,
      }
    case "LOAD_RULES":
      return {
        ...initialState,
        rules: action.payload,
      }
    case "SELECT_RULE":
      return {
        ...initialState,
        selected_rules: action.payload,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
