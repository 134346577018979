import React, {useEffect, useState} from "react";
import {apiErrorRaised} from "../../../../context/api_error/ApiErrorActions";
import {useApiErrorDispatch} from "../../../../context/api_error/ApiErrorContext";
import {
  loadProjects,
  selectProject,
  useRuleImportDispatch,
  useRuleImportState
} from "./Context/FeedExportImportRuleIndex";
import {Projects} from "../../../../services/api/Projects";
import classNames from "classnames";
import {Badge} from "reactstrap";

export const FeedExportImportRulesProjectsList = () => {
  const [isLoading, setLoading] = useState(false)
  const dispatch = useApiErrorDispatch();
  const ruleImportState = useRuleImportState()
  const ruleImportDispatch = useRuleImportDispatch()

  useEffect(() => {
    if (ruleImportState.selected_company === '') {
      return
    }
    setLoading(true)
    Projects.index(ruleImportState.selected_company).then((r) => {
      if (r?.status < 400) {
        loadProjects(ruleImportDispatch, r.data.sort(function (a, b) {
          if (a.status === 'ACTIVE') {
            return -1;
          } else {
            return 1
          }
        }))
      } else {
        apiErrorRaised(dispatch, r?.response)
      }
      setLoading(false);
    })
  }, [ruleImportState.selected_company])


  const handleSelectProject = (projectId) => {
    selectProject(ruleImportDispatch, projectId)
  }

  return (
    <>
      <h5>Projects</h5>
      <div className={'d-flex flex-column'}>
        {
          isLoading ? <span className="spinner-border spinner-border-sm me-1"/> :
            ruleImportState.projects && ruleImportState.projects.map((project, i) => {
              return (
                <div role={"button"} className={classNames({
                  'p-2 me-4 text-primary': true,
                  'bg-primary': ruleImportState.selected_project === project.id,
                  'text-white': ruleImportState.selected_project === project.id
                })}
                     key={i}
                     onClick={() => handleSelectProject(project.id)}>
                  <Badge color="" className="badge-dot badge-lg pt-0 pb-0 product-card_header_badge">
                    <i className={classNames({
                      'bg-gray': project.status === 'DISABLED',
                      'bg-success': project.status === 'ACTIVE',
                    })}/>
                  </Badge>
                  {project.name} {project.status === 'DISABLED' ? <small>inactive</small> : <></>}
                </div>
              )
            })
        }
      </div>
    </>
  )
}
