import React, {useEffect} from "react";
import {CanvasProvider} from "../../../context/canvas/CanvasIndex";
import {useProjectDispatch} from "../../../context/project/ProjectContext";
import {useParams} from "react-router-dom";
import {updateCompanyAndProjectBasedOnUrl} from "../../../context/project/ProjectActions";
import {Canvas} from "../../../components/Canvas/Canvas";

const CanvasView = () => {
  const projectDispatch = useProjectDispatch()
  const routerParams = useParams();
  useEffect(() => {
    updateCompanyAndProjectBasedOnUrl(projectDispatch, routerParams.companyId, routerParams.projectId)
  }, [])

  return (
    <>
      <>
        <div className={'canvas_container'}>
          <CanvasProvider>
            <Canvas
              companyId={routerParams.companyId}
              projectId={routerParams.projectId}
              id={routerParams.id}
            />
          </CanvasProvider>
        </div>
      </>
    </>
  );
};

export default CanvasView;
