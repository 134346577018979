import {useTranslation} from "react-i18next";
import React, {useEffect} from "react";
import IndexNavbar from "../Navbars/IndexNavbar";
import IndexFooter from "../Footers/IndexFooter";
import {Container} from "reactstrap";
import {scrollToTargetDiv} from "../Landing/scrollUtils";

export const TermsAndConditions = () => {
  const {t} = useTranslation('legal')

  useEffect(() => {
    scrollToTargetDiv('app')
  }, []);

  return (
    <>
      <IndexNavbar logo={{
        innerLink: `/`, imgSrc: "assets/img/brand/feedest_purple.svg", imgAlt: "logo",
      }}/>
      <Container className={'pt-5'}>
        <h1 className={'display-2 text-primary pb-3'}>{t('LEGAL_NOTICE_TITLE')}</h1>
        <p>{t('LEGAL_NOTICE_INTRO_1')}</p>
        <p>{t('LEGAL_NOTICE_INTRO_2')}</p>
        <ol type={'a'}>
          <li aria-level="1">{t('LEGAL_NOTICE_INTRO_2_LIST_ITEM_1')}</li>
          <li aria-level="1">{t('LEGAL_NOTICE_INTRO_2_LIST_ITEM_2')}</li>
        </ol>
        <h3 className={'pt-3'}>{t('LEGAL_NOTICE_CONTENT_1_TITLE')}</h3>
        <p>{t('LEGAL_NOTICE_CONTENT_1_CONTENT')}</p>

        <ul>
          <li><strong>{t('LEGAL_NOTICE_CONTENT_1_LIST_1_TITLE')}</strong>
            CYBERCLICK AGENT, S.L. ({t('LEGAL_NOTICE_CONTENT_1_LIST_1_CONTENT')}, “<strong>CYBERCLICK</strong>”).
          </li>
          <li><strong>{t('LEGAL_NOTICE_CONTENT_1_LIST_2_TITLE')}</strong> Moll de Barcelona, s/n, World Trade Center
            Edificio Norte 2ª planta, 08039 - Barcelona.
          </li>
          <li><strong>{t('LEGAL_NOTICE_CONTENT_1_LIST_3_TITLE')}</strong> B-62084959.</li>
          <li><strong>{t('LEGAL_NOTICE_CONTENT_1_LIST_4_TITLE')}</strong><a
            href="mailto:info@cyberclick.net">info@cyberclick.net</a>.
          </li>
          <li><strong>{t('LEGAL_NOTICE_CONTENT_1_LIST_5_TITLE')}</strong>+34 93 508 82 34.</li>
          <li><strong>{t('LEGAL_NOTICE_CONTENT_1_LIST_6_TITLE')}</strong>{t('LEGAL_NOTICE_CONTENT_1_LIST_6_CONTENT')}</li>
        </ul>
        <h3 className={'pt-3'}>{t('LEGAL_NOTICE_CONTENT_2_TITLE')}</h3>
        <p>{t('LEGAL_NOTICE_CONTENT_2_CONTENT')}</p>

        <h3 className={'pt-3'}>{t('LEGAL_NOTICE_CONTENT_3_TITLE')}</h3>
        <p>{t('LEGAL_NOTICE_CONTENT_3_CONTENT')}</p>

        <p>{t('LEGAL_NOTICE_CONTENT_3_CONTENT_LIST_TITLE')}</p>

        <ol>
          <li>{t('LEGAL_NOTICE_CONTENT_3_CONTENT_LIST_1')}</li>
          <li>{t('LEGAL_NOTICE_CONTENT_3_CONTENT_LIST_2')}</li>
          <li>{t('LEGAL_NOTICE_CONTENT_3_CONTENT_LIST_3')}</li>
          <li>{t('LEGAL_NOTICE_CONTENT_3_CONTENT_LIST_4')}</li>
          <li>{t('LEGAL_NOTICE_CONTENT_3_CONTENT_LIST_5')}</li>
          <li>{t('LEGAL_NOTICE_CONTENT_3_CONTENT_LIST_6')}</li>
          <li>{t('LEGAL_NOTICE_CONTENT_3_CONTENT_LIST_7')}</li>
          <li>{t('LEGAL_NOTICE_CONTENT_3_CONTENT_LIST_8')}</li>
          <li>{t('LEGAL_NOTICE_CONTENT_3_CONTENT_LIST_9')}</li>
          <li>{t('LEGAL_NOTICE_CONTENT_3_CONTENT_LIST_10')}</li>
        </ol>

        <h3 className={'pt-3'}>{t('LEGAL_NOTICE_CONTENT_4_TITLE')}</h3>
        <p>{t('LEGAL_NOTICE_CONTENT_4_CONTENT_1')}</p>

        <p>{t('LEGAL_NOTICE_CONTENT_4_CONTENT_2')}</p>

        <h3 className={'pt-3'}>{t('LEGAL_NOTICE_CONTENT_5_TITLE')}</h3>
        <p>{t('LEGAL_NOTICE_CONTENT_5_CONTENT_1')}</p>

        <p>{t('LEGAL_NOTICE_CONTENT_5_CONTENT_2')}</p>
        <h3 className={'pt-3'}>{t('LEGAL_NOTICE_CONTENT_6_TITLE')}</h3>
        <p>{t('LEGAL_NOTICE_CONTENT_6_CONTENT')}</p>

        <ol type={'a'}>
          <li>{t('LEGAL_NOTICE_CONTENT_6_LIST_1')} Moll de Barcelona, s/n, World Trade Center Edificio Norte 2ª planta, 08039 -
            Barcelona.
          </li>
          <li>{t('LEGAL_NOTICE_CONTENT_6_LIST_2')}<strong><a
            href="mailto:info@cyberclick.net">info@cyberclick.net</a></strong>.
          </li>
          <li>{t('LEGAL_NOTICE_CONTENT_6_LIST_3')}
          </li>
        </ol>

        <h3 className={'pt-3'}>{t('LEGAL_NOTICE_CONTENT_7_TITLE')}</h3>
        <p>{t('LEGAL_NOTICE_CONTENT_7_CONTENT')}</p>

        <h3 className={'pt-3'}>{t('LEGAL_NOTICE_CONTENT_8_TITLE')}</h3>
        <p>{t('LEGAL_NOTICE_CONTENT_8_CONTENT')}</p>
      </Container>
      <IndexFooter/>
    </>
  )
}
