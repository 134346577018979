import React from "react";
import {AssetsItemGrid} from "./AssetsItemGrid";
import {AssetModalSelectorElement} from "./ModalSelector/AssetModalSelectorElement";

export const AssetsGrid = ({assets, isModalSelector}) => {
    return (
        <>
            <div className={'assets-grid-container'}>
                <div className={'assets-cards__list'}>
                  {
                    assets && assets.map((asset, i) => {
                      return isModalSelector ?
                          <AssetModalSelectorElement key={i} asset={asset}/> :
                          <AssetsItemGrid key={i} asset={asset}/>

                    })
                  }
                </div>
            </div>
        </>
    )
}
