import {get} from './config/base';
import {handleError, handleResponse} from "./config/response";
import {urlParser} from "./config/utils";

const url = 'companies/{companyId}/projects/{projectId}/templates/{id}/template_objects/';

export const TemplateObjects = {
  index: (companyId, projectId, id, params = null) =>
    get(urlParser(url, companyId, projectId, id), {params: params})
      .then(handleResponse)
      .catch(handleError),
}
