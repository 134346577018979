import React, {useState} from 'react';
import {Col, Row} from "reactstrap";
import FilterEditor from "./FilterEditor";
import {updateFilter} from "../../context/filter/FilterActions";
import {useFilterDispatch, useFilterState} from "../../context/filter/FilterIndex";
import {v4 as uuidv4} from "uuid";
import FilterLogicView from "./FilterLogic";

export default function FilterView({filter, nestingLevel = 0}) {
  const [editMode, setEditMode] = useState(false)
  const filters = useFilterState();
  const dispatch = useFilterDispatch();

  const mystyle = {
    display: "flex",
    borderStyle: "dashed",
    flexFlow: "row wrap",
    padding: "10px",
    margin: "10px 10px 10px"
  };

  const update = (newFilter, newFilterId, filtersToLoop) => {
    return filtersToLoop.map((settedFilter) => {

      if (settedFilter.id === newFilterId) {
        return newFilter
      } else if (settedFilter.hasOwnProperty('type')) {
        const newLeft = update(newFilter, newFilterId, [settedFilter.left])[0]
        const newRight = update(newFilter, newFilterId, [settedFilter.right])[0]

        return {left: newLeft, right: newRight, type: settedFilter.type}
      }

      return settedFilter
    })
  }

  const handleEdit = (filterEdit) => {
    if (filterEdit !== undefined) {
      const filtersNewSet = update(filterEdit, filterEdit.id, filters.conditions)
      updateFilter(
        dispatch,
        {
          ...filters,
          conditions: filtersNewSet
        }
      )
      setEditMode(false)
    }
  }

  const transformToLogic = (filter, type) => {
    const newFilter = {
      left: filter,
      right: {
        id: uuidv4()
      },
      type: type,
      id: uuidv4()
    }
    const results = update(newFilter, filter.id, filters.conditions)
    updateFilter(
      dispatch,
      {
        ...filters,
        conditions: results
      }
    )
  }


  return (
    <>
      {
        filter.hasOwnProperty('type') ?
          <FilterLogicView filter={filter} key={filter.id + 'logic'} nestingLevel={nestingLevel}/>
          :
          <>
            {editMode &&
            <FilterEditor fields={filters.fields} saveCallback={handleEdit} filterId={filter.id}/>
            }
            {!editMode &&
            <div className={'filter-container__filter'} style={mystyle}>
              <Col className={'filter-container__filter--field'}> {filter.field} </Col>
              <Col className={'filter-container__filter--operator'}> {filter.operator} </Col>
              <Col className={'filter-container__filter--value'}> {filter.value} </Col>
              <Col style={{textAlign: "right"}} onClick={() => {
                setEditMode(true)
              }}>EDIT</Col>
              {
                nestingLevel < 2 &&
                <Row className={'filter-container__logic--type-active'}>
                  <Col onClick={() => {
                    transformToLogic(filter, "AND")
                  }}> AND </Col>
                  <Col onClick={() => {
                    transformToLogic(filter, "OR")
                  }}> OR </Col>
                </Row>
              }
            </div>
            }
          </>
      }
    </>
  );
}
