import React, {useEffect, useState} from 'react';
import {useProjectState} from "../../../context/project/ProjectIndex";
import {Button, FormGroup, Input, Modal} from "reactstrap";
import {apiErrorRaised, useApiErrorDispatch} from "../../../context/api_error/ApiErrorIndex";
import {Users} from "../../../services/api/Users";
import {closeModal, openModal} from "../../../context/modal/ModalActions";
import {useModalDispatch} from "../../../context/modal/ModalIndex";
import {UserAccess} from "../../../services/api/UserAccess";

const TransferOwnershipModal = ({showModal, setShowModal, setTransferedOwnership}) => {
  const projectState = useProjectState()
  const apiErrorDispatch = useApiErrorDispatch();
  const [isLoading, setLoading] = useState(false)
  const modalDispatcher = useModalDispatch()
  const [users, setUsers] = useState([])
  const [transferOwnerCommand, setTransferOwnerCommand] = useState(
    {
      old_user_id: '',
      new_user_id: '',
      new_role: '',
    }
  )

  useEffect(() => {
    setLoading(true);
    Users.index(projectState.companyId).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        let notOwnerUsers = r.data.filter(user => user.role !== 'owner')
        setUsers(notOwnerUsers)
        setTransferOwnerCommand({
          ...transferOwnerCommand,
          old_user_id: (r.data.filter(user => user.role === 'owner'))[0].user_id,
          new_user_id: notOwnerUsers[0].user_id,
          new_role: 'admin'
        })
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    });
  }, [apiErrorDispatch]);

  const handleChange = (e) => {
    setTransferOwnerCommand({...transferOwnerCommand, [e.target.name]: e.target.value})
  }

  const handleOwnerTransferClick = () => {
    setShowModal(false)
    openModal(modalDispatcher, {
      title: 'Transfer company ownership',
      message: 'Are you sure do you want to transfer company owner? This action cannot be undone',
      onConfirm: () => {
        setLoading(true)
        UserAccess.transferOwnership(projectState.companyId, transferOwnerCommand).then((r) => {
          if (r?.status < 400) {
            setLoading(false)
            setTransferedOwnership(true)
          } else {
            apiErrorRaised(apiErrorDispatch, r?.response)
          }
          closeModal(modalDispatcher)
        });
      },
      onCancel: () => closeModal(modalDispatcher),
      onBackdropClick: () => closeModal(modalDispatcher),
      buttonColor: 'danger',
      buttonText: 'Transfer',
      buttonIcon: 'fa-right-left'
    })
  }


  return (
    <>
      <Modal
        size="md"
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={() => setShowModal(false)}
      >
        <div className="modal-header">
          <button
            aria-label="Close"
            className="btn-close"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
          </button>
        </div>
        <div className="modal-body d-flex flex-column pt-0 pb-0">
          <h6 className="modal-title end text-center mb-4">
            Transfer ownership
          </h6>
          <FormGroup
            className="mb-3">
            <label
              className="form-control-label"
            >
              New Owner
            </label>
            <Input
              placeholder="Role"
              type="select"
              className={'form-control'}
              name="new_user_id"
              value={transferOwnerCommand.new_user_id}
              onChange={handleChange}
            >
              {
                users && users.map((user, i) => {
                  return <option key={i} value={user.id}>{user.email}</option>
                })
              }
            </Input>
            <div className="invalid-feedback">
              Please provide a name
            </div>
          </FormGroup>
          <FormGroup
            className="mb-3">
            <label
              className="form-control-label"
            >
              New role for old owner
            </label>
            <Input
              placeholder="Role"
              type="select"
              className={'form-control'}
              name="new_role"
              value={transferOwnerCommand.new_role}
              onChange={handleChange}
            >
              <option value={'admin'}>Admin</option>
              <option value={'colaborator'}>Colaborator</option>
              <option value={'viewer'}>Viewer</option>
            </Input>
            <div className="invalid-feedback">
              Please provide a name
            </div>
          </FormGroup>
        </div>
        <div className="modal-footer d-flex flex-row justify-content-between">
          <Button
            className={'d-flex justify-content-center'}
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
          <Button color="danger" type="button" className={'d-flex justify-content-center'}
                  onClick={handleOwnerTransferClick}>
            {isLoading &&
              <span className="spinner-border spinner-border-sm me-1"/>}
            Transfer Owner
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default TransferOwnershipModal;
