const errors = ['expired_token', 'invalid_token'];

const errorShouldRaiseModal = (errorCode) => {
  return !errors.includes(errorCode);
}

export function apiErrorRaised(dispatch, error) {
  if (errorShouldRaiseModal(error?.response?.data?.code)) {
    dispatch({type: 'API_ERROR_OCCURRED', payload: error});
  }
}

export function apiErrorClosed(dispatch) {
  dispatch({type: 'API_ERROR_CLOSED'});
}
