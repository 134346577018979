import React, {useReducer} from 'react';
import {FilterReducer, initialState} from "./FilterReducer";

const FilterStateContext = React.createContext();
const FilterDispatchContext = React.createContext();

export function useFilterState() {
  const context = React.useContext(FilterStateContext);
  if (context === undefined) {
    throw new Error('useFilterState must be used within a FilterProvider');
  }

  return context;
}

export function useFilterDispatch() {
  const context = React.useContext(FilterDispatchContext);
  if (context === undefined) {
    throw new Error('useFilterDispatch must be used within a FilterProvider');
  }

  return context;
}

export const FilterProvider = ({children}) => {
  const [filter, dispatch] = useReducer(FilterReducer, initialState);

  return (
    <FilterStateContext.Provider value={filter}>
      <FilterDispatchContext.Provider value={dispatch}>
        {children}
      </FilterDispatchContext.Provider>
    </FilterStateContext.Provider>
  );
};
