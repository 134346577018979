import React, {useEffect} from 'react';

export const HubSpotForm = ({formId, portalId}) => {
  useEffect(() => {
    if (formId === null) {
      return
    }
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId,
          formId,
          target: `#hubspot-form-${formId}`,
        });
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [formId, portalId]);

  return <div id={`hubspot-form-${formId}`}/>;
};

