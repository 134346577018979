import React from 'react'

export const DataSourceMappingPropertiesSelector = ({name, item, availableProperties, handleChange}) => {
  return (
    <>
      <select name={name}
              className="form-control"
              value={item}
              onChange={handleChange}>
        <option value=''></option>
        {
          availableProperties.map((property, i) => {
            return (<option value={property.value} disabled={property.disabled} key={i}>{property.label}</option>)
          })
        }
      </select>
    </>
  )
}
