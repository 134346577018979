export function addFilter(dispatch, data) {
  dispatch(
    {
      type: 'ADD_FILTER',
      payload: data
    }
  )
}

export function removeFilter(dispatch, id) {
  dispatch(
    {
      type: 'REMOVE_FILTER',
      payload: id
    }
  )
}
