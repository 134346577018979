import React, {useReducer} from 'react';
import {initialState, RulesReducer} from './RulesReducer';

const RulesStateContext = React.createContext();
const RulesDispatchContext = React.createContext();

export function useRulesState() {
  const context = React.useContext(RulesStateContext);

  return context;
}

export function useRulesDispatch() {
  const context = React.useContext(RulesDispatchContext);
  if (context === undefined) {
    throw new Error('useRulesDispatch must be used within a RulesProvider');
  }

  return context;
}

export const RulesProvider = ({children}) => {
  const [Rules, dispatch] = useReducer(RulesReducer, initialState);

  return (
    <RulesStateContext.Provider value={Rules}>
      <RulesDispatchContext.Provider value={dispatch}>
        {children}
      </RulesDispatchContext.Provider>
    </RulesStateContext.Provider>
  );
};
