import {subDays} from "date-fns";

export const initialState = {
  dates: {
    start_date: subDays((new Date()).setHours(24, 0, 0, 0), 0),
    end_date: subDays((new Date()).setHours(0, 0, 0, 0), 0),
  }
};

export class EventsManagerReducer {

}
