export function fetchFilter(dispatch, filterData) {
  let filterFetched = {
    conditions: filterData.conditions,
    fields: filterData.fields
  }
  dispatch({type: 'FILTERS_FETCHED', payload: filterFetched});
}

export function updateFilter(dispatch, filterData) {

  dispatch({type: 'FILTERS_UPDATED', payload: filterData});
}
