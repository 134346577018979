export const objectMoveResize = (canvas, id, x, y, width, height) => {
  let selectedObject = canvas.objects.find(canvasObject => canvasObject.object.id === id);
  selectedObject = {
    ...selectedObject,
    object: {
      ...selectedObject.object,
      data: {
        ...selectedObject.object.data,
        origin: {
          x: x,
          y: y,
        },
        offerorigin: {
          x: x,
          y: y,
        },
        size: {
          x: width,
          y: height,
        }
      }
    }
  }
  return {
    ...canvas,
    selectedObject: selectedObject,
    objects: canvas.objects.map((canvasObject) => {
      if (canvasObject.object.id !== id) {
        return canvasObject;
      }
      return selectedObject;
    })
  }
};
