import {Badge} from "reactstrap";
import React from "react";

export const PlaceholderTableHead = ({columns, firstEmpty}) => {
  const renderHeader = () => {
    const tableHeader = [];
    for (let i = 0; i < columns; i++) {
      if (i === 0 && firstEmpty === true) {
        tableHeader.push(
          <th key={`cell-${i}`}></th>
        );
        continue;
      }
      tableHeader.push(
        <th key={`cell-${i}`}>
          <Badge color="" className="badge-stroke mx-2">
            <i className='bg-light'/>
          </Badge>
        </th>
      );
    }
    return tableHeader;
  };
  return (
    <>
      <thead className="thead-light ">
      <tr>
        {renderHeader()}
      </tr>
      </thead>
    </>
  )
}
