import React, {useEffect} from "react";
import {Container,} from "reactstrap";
import TemplateDetail from "../../../components/Templates/TemplateDetail/TemplateDetail";
import {useProjectDispatch} from "../../../context/project/ProjectContext";
import {useParams} from "react-router-dom";
import {updateCompanyAndProjectBasedOnUrl} from "../../../context/project/ProjectActions";
import {TemplateProvider} from "../../../context/template/TemplateIndex";

const TemplateView = () => {
  const projectDispatch = useProjectDispatch()
  const routerParams = useParams();
  useEffect(() => {
    updateCompanyAndProjectBasedOnUrl(projectDispatch, routerParams.companyId, routerParams.projectId)
  }, [])
  return (
    <>
      <Container className="mt-5" fluid>
        <TemplateProvider>
          <TemplateDetail
            companyId={routerParams.companyId}
            projectId={routerParams.projectId}
            id={routerParams.id}
          />
        </TemplateProvider>
      </Container>
    </>
  );
};

export default TemplateView;
