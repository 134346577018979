import React from "react";
import {Button, Card, CardBody, CardHeader} from "reactstrap";
import {useNavigate} from "react-router";
import {selectProject, useProjectDispatch, useProjectState} from "../../context/project/ProjectIndex";
import classNames from "classnames";
import {Link} from "react-router-dom";

export const CompanyProjectElement = ({project}) => {
  const navigate = useNavigate()
  const projectDispatch = useProjectDispatch()
  const projectState = useProjectState()
  const handleClick = () => {
    selectProject(projectDispatch, project.id, project.name, project.locale, project.timezone, project.properties,
      project.status, project.currency, project.products_detected)
    navigate(`/admin/companies/${projectState.companyId}/projects/${project.id}`)
  }
  return (
    <>
      <div className={'col-md-3 d-flex align-items-stretch'}>
        <Card className={classNames({
          'project_card_disabled': project.status === 'DISABLED',
          'project_card': true,
        })}>
          <CardHeader className={classNames({
            'project_card_disabled': project.status === 'DISABLED',
            'pt-3 pb-1 ps-4': true,
          })}>
            <div className={'d-flex justify-content-between align-items-center'}>
              <h3 className={'mb-0'}>{project.name}</h3>
              <Link
                to={`/admin/companies/${projectState.companyId}/projects/${project.id}/settings?from=${window.location.pathname}`}>
                <Button
                  className={'btn-icon-only'} color={'primary'}
                >
                  <span className="fa-solid fa-gear"/>
                </Button>
              </Link>
            </div>
          </CardHeader>
          <CardBody className="d-flex">
            <div className="d-flex flex-grow-1 justify-content-end">
              <div className={'d-flex justify-content-end'}>
                <Button
                  color={project.status === 'DISABLED' ? 'project_card_button_disabled' : 'primary'}
                  className={classNames({
                    'btn-primary': true,
                    'project_card_button_disabled': project.status === 'DISABLED',
                    'mt-auto': true,
                  })}
                  onClick={handleClick}>
                  Access
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};
