import React, {useEffect, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Table} from "reactstrap";
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {FeedExports} from "../../services/api/FeedExports";
import FeedExportDeleteButton from "./FeedExportDeleteButton";
import googleImage from "../../assets/img/icons/common/google.png";
import bingImage from "../../assets/img/icons/common/bing.png";
import facebookImage from "../../assets/img/icons/common/facebook.png";
import awinImage from "../../assets/img/icons/common/awin.png";
import googleLocalInventoryImage from "../../assets/img/icons/common/googleLocalInventory.png";
import pinterestImage from "../../assets/img/icons/common/pinterest.png";
import tradeDoublerImage from "../../assets/img/icons/common/doubler.png";
import criteoImage from "../../assets/img/icons/common/criteo.png";
import googleStudioImage from "../../assets/img/icons/common/google_studio.png";
import tiktokImage from "../../assets/img/icons/common/tiktok.png";
import customFeedImage from "../../assets/img/icons/common/custom.png";
import metaLocalizedCatalogsImage from "../../assets/img/icons/common/metaLocalizedCatalogs.png";

import FeedExportHistoryChart from "./FeedExportHistorys/FeedExportHistoryChart";
import {FeedExportStepButtonGroup} from "./FeedExportStepButtonGroup/FeedExportStepButtonGroup";
import {Link} from "react-router-dom";
import {useProjectState} from "../../context/project/ProjectIndex";
import {closeModal, openModal} from "../../context/modal/ModalActions";
import {useModalDispatch} from "../../context/modal/ModalContext";

const FeedExportDetail = ({companyId, projectId, id}) => {
  const apiErrorDispatch = useApiErrorDispatch();
  const projectState = useProjectState()
  const [isLoading, setLoading] = useState(false)
  const [isRequestExportLoading, setRequestExportLoading] = useState(false)
  const [feedExport, setFeedExport] = useState();
  const [feedExportPlatformImage, setFeedExportPlatformImage] = useState('')
  const modalDispatcher = useModalDispatch()
  const dispatch = useApiErrorDispatch();

  const handleRequestExportClick = () => {
    openModal(modalDispatcher, {
      title: 'Request export',
      message: `Do you want to generate feed export: ${feedExport.name}?`,
      onConfirm: () => {
        setRequestExportLoading(true)
        FeedExports.requestExport(companyId, projectId, id).then((r) => {
          setRequestExportLoading(false);
          if (r === undefined || r.status > 399) {
            apiErrorRaised(dispatch, r?.response)
          }
        });
        closeModal(modalDispatcher)
      },
      onCancel: () => closeModal(modalDispatcher),
      onBackdropClick: () => closeModal(modalDispatcher),
      buttonText: 'Yes'
    })
  }

  const handleToggle = (e) => {
    const {name, checked} = e.target;
    if (checked) {
      FeedExports.activate(companyId, projectId, feedExport.id).then((r) => {
        if (r?.status < 400) {
          setFeedExport(feedExport => ({...feedExport, [name]: checked}))
        } else {
          apiErrorRaised(apiErrorDispatch, r?.response)
        }
      });
    } else {
      FeedExports.disable(companyId, projectId, feedExport.id).then((r) => {
        if (r?.status < 400) {
          setFeedExport(feedExport => ({...feedExport, [name]: checked}))
        } else {
          apiErrorRaised(apiErrorDispatch, r?.response)
        }
      });
    }
  }


  useEffect(() => {
    setLoading(true);
    FeedExports.get(companyId, projectId, id).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        r.data.url = process.env.REACT_APP_CDN_URL + 'feeds/' + projectState.projectId + '/' + r.data.filename + '.' + r.data.format
        setFeedExport(r.data);
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    });
  }, []);

  useEffect(() => {
    switch (feedExport?.mapping?.platform) {
      case 'google':
        setFeedExportPlatformImage(googleImage)
        break;
      case 'bing':
        setFeedExportPlatformImage(bingImage)
        break;
      case 'facebook':
        setFeedExportPlatformImage(facebookImage)
        break;
      case 'metaLocalizedCatalogs':
        setFeedExportPlatformImage(metaLocalizedCatalogsImage)
        break;
      case 'googleLocalInventory':
        setFeedExportPlatformImage(googleLocalInventoryImage)
        break;
      case 'pinterest':
        setFeedExportPlatformImage(pinterestImage)
        break;
      case 'tradedoubler':
        setFeedExportPlatformImage(tradeDoublerImage)
        break;
      case 'awin':
        setFeedExportPlatformImage(awinImage)
        break;
      case 'criteo':
        setFeedExportPlatformImage(criteoImage)
        break;
      case 'googleStudio':
        setFeedExportPlatformImage(googleStudioImage)
        break;
      case 'tiktok':
        setFeedExportPlatformImage(tiktokImage)
        break;
      case 'custom':
        setFeedExportPlatformImage(customFeedImage)
        break;
    }
  }, [feedExport])

  return (
    <>
      <div className={'d-flex flex-column'}>
        <div className={'d-flex flex-row justify-content-between'}>
          <div className={'d-flex flex-row'}>
            <Link
              to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/`}>
              <Button className={'btn-icon-only text-dark'}>
                <span className={'fa fa-arrow-left'}/>
              </Button>
            </Link>
            <h1 className={'ms-4'}>Detail</h1>
          </div>
          <FeedExportStepButtonGroup id={id} step={'finish'}/>
        </div>
        <div className={'d-flex flex-row mt-4'}>
          <Card className={'flex-grow-1'}>
            <CardHeader className={'d-flex flex-row justify-content-between'}>
              <h3>Rules</h3>
              <div className={'d-flex flex-row'}>
                <label className="custom-toggle me-5 align-self-center">
                  <input type="checkbox" name="status" checked={feedExport?.status} onChange={handleToggle}/>
                  <span className="custom-toggle-slider rounded-circle"/>
                </label>
                <a href={feedExport?.url} download target="_blank" rel="noreferrer" className={'me-2'}>
                  <Button className={'btn-icon'} color={feedExport?.status ? 'primary' : 'secondary'}>
                    <span className="me-1 fa fa-download"/>Download
                  </Button>
                </a>
                <Button
                  onClick={handleRequestExportClick}
                  color={'primary'}
                  className={'btn-icon'}
                >
                  {isRequestExportLoading &&
                    <span className="spinner-border spinner-border-sm me-1"/>}
                  <span className="me-1 fa fa-file-export"/>Export
                </Button>
                <FeedExportDeleteButton id={id}/>
              </div>
            </CardHeader>
            <CardBody className={'d-flex flex-column px-6'}>
              <div className='d-flex flex-row mt-4 gap-5 align-items-start'>
                <Card className='w-50 flex-grow-1'>
                  <CardHeader className="d-flex justify-content-between">
                    <p className='m-0'>Information</p>
                  </CardHeader>
                  <CardBody className="accordion-content">
                    <div className={'d-flex flex-row'}>
                      <div className={'flex-grow-1 d-flex flex-column'}>
                        <div className={'pb-3 flex-basis-20 align-content-center'}>
                          <img
                            className="platform-image  img-fluid"
                            alt="..."
                            width="130px"
                            src={feedExportPlatformImage}/>
                        </div>
                        <p className='mb-2'>Id: {feedExport?.id}</p>
                        <p className='mb-2'>Filename: {feedExport?.filename}</p>
                        <p className='mb-2'>Format: {feedExport?.format}</p>
                        <p className='mb-2'>Locale: {feedExport?.locale}</p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card className='w-50 flex-grow-1'>
                  <CardHeader className="d-flex justify-content-between">
                    <p className="m-0">Schedule</p>
                  </CardHeader>
                  <CardBody className="accordion-content">
                    <small className="text-uppercase text-muted font-weight-bold">
                      Schedule
                    </small>
                    <pre>
                    {JSON.stringify(feedExport?.schedule, null, 2)}
                </pre>
                  </CardBody>
                </Card>
              </div>
              <div className={'d-flex flex-row mt-4'}>
                <div className={'flex-basis-50 me-4'}>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className='thead-light'>
                    <tr>
                      <th scope='col'>Origin</th>
                      <th scope='col'>Destination</th>
                      <th scope='col'>Format</th>
                    </tr>
                    </thead>
                    <tbody className='list'>
                    {
                      feedExport?.mapping?.mapping && feedExport.mapping.mapping.map((mapa, i) => {
                        return (
                          <tr key={i}>
                            <td>{mapa.origin}</td>
                            <td>{mapa.destination}</td>
                            <td>{mapa.format}</td>
                          </tr>)
                      })
                    }
                    </tbody>
                  </Table>
                </div>
                <div className={'flex-grow-1'}>
                  <FeedExportHistoryChart
                    id={id}
                    companyId={companyId}
                    projectId={projectId}
                  />
                </div>
              </div>
              <small className="text-uppercase text-muted font-weight-bold mt-5">
                Url Parameters
              </small>
              <pre>
                  {JSON.stringify(feedExport?.url_parameters, null, 2)}
                </pre>
            </CardBody>
          </Card>
        </div>
        <div className={'d-flex flew-row justify-content-between'}>
          <Link
            to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/${id}/settings`}>
            <Button color={'secondary'}>Back</Button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default FeedExportDetail;

