import {useCanvasDispatch, useCanvasState} from "../../../../../context/canvas/CanvasIndex";
import {Button} from "reactstrap";
import React, {useEffect, useState} from "react";
import {selectCanvasAsset} from "../../../../../context/canvas/selectCanvasAsset/SelectCanvasAssetActions";
import {useSelectCanvasAssetDispatch} from "../../../../../context/canvas/selectCanvasAsset/SelectCanvasAssetContext";
import {AssetModalSelector} from "../../../../Assets/ModalSelector/AssetModalSelector";
import {Assets} from "../../../../../services/api/Assets";
import {useProjectState} from "../../../../../context/project/ProjectIndex";
import {clearValidationError, setValidationError} from "../../../../../context/canvas/CanvasActions";
import classNames from "classnames";

export const CanvasObjectInfoSidebarDetailFontSource = ({title, property}) => {
  const canvasState = useCanvasState()
  const projectState = useProjectState()
  const templateObject = canvasState.selectedObject
  const canvasDispatch = useCanvasDispatch()
  const selectCanvasAssetDispatch = useSelectCanvasAssetDispatch()
  const [showModal, setShowModal] = useState(false)
  const [state, setState] = useState('')
  const [isLoading, setLoading] = useState(false)

  const onShowModal = () => {
    selectCanvasAsset(selectCanvasAssetDispatch, templateObject?.object.data[property])
    setShowModal(true);
  }

  useEffect(() => {

    let templateObjectType = templateObject?.object.type.split(/[\\ ]+/).pop()
    if (templateObjectType === 'TemplateImageObject') {
      return
    }
    if (templateObjectType === 'TemplateTextObject' && (property === 'strickedPriceFont' || property === 'finalPriceFont')) {
      return
    }

    if (templateObjectType === 'TemplateTwoPriceObject' && (property === 'font')) {
      return
    }

    if (templateObject?.object.data[property] === null || templateObject?.object.data[property] === undefined || templateObject?.object.data[property] === '') {
      setValidationError(canvasDispatch, templateObject?.id, {
        code: `${property.toUpperCase()}_FONT_NOT_SELECTED`,
        message: `You need to select a font to property ${property} to object ${templateObject?.id}`,
      })
      return
    }
    clearValidationError(canvasDispatch, templateObject?.id, `${property.toUpperCase()}_FONT_NOT_SELECTED`)
    setLoading(true)
    Assets.get(projectState.companyId, projectState.projectId, templateObject?.object.data[property]).then((r) => {
      setState(r.data?.name)
      setLoading(false)
    })
  }, [templateObject?.object.data[property]]);

  return (
    <>
      <div className={'d-flex flex-column'}>
        <strong className={'mt-1'}>{title}</strong>
        <div className={classNames({
          'position-relative': true,
          'outline_error': templateObject?.errors?.filter((error) => error.code === `${property.toUpperCase()}_FONT_NOT_SELECTED`).length > 0
        })}>
          <div className={'d-flex flex-row justify-content-between'}>
            <div className={'d-flex flex-column justify-content-center'} style={{maxWidth: '75%'}}>
              <div className={'p-3 d-flex justify-content-center'}>
                <span className="ni ni-5x ni-caps-small"/>
              </div>
              <div className={'d-flex justify-content-center p-3'}>
                <small style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap'
                }}>{state}</small>
              </div>
            </div>
            <div className={'d-flex align-items-center'}>
              <Button onClick={onShowModal} className={'m-2'} color={'primary'}>
                <span className={'fa fa-solid fa-repeat'}/>
              </Button>
            </div>
          </div>
          {
            isLoading ?
              <div
                className={'position-absolute top-0 w-100 h-100 bg-blur d-flex justify-content-center align-items-center'}
                style={{zIndex: 1000}}>
                <span className="spinner-border spinner-border-lg"/>
              </div>
              : <></>
          }
        </div>
      </div>
      <AssetModalSelector
        showModal={showModal}
        setShowModal={setShowModal}
        type={property}
        canvasObjectId={templateObject?.id}
        assetType={'font'}
      />
    </>
  )
}
