import React from "react";

import AuthHeader from "components/Headers/AuthHeader.js";
import {useTranslation} from "react-i18next";

const ResetPasswordThanks = () => {
  const {t} = useTranslation('auth')
  return (
    <>
      <AuthHeader
        title={t('RESET_PASSWORD_TITLE')}
        lead={t('RESET_PASSWORD_DESCRIPTION')}
      />
    </>
  );
}

export default ResetPasswordThanks;
