import {useApiErrorDispatch} from "../../../context/api_error/ApiErrorContext";
import React, {useEffect, useState} from "react";
import {DataSources} from "../../../services/api/DataSources";
import {apiErrorRaised} from "../../../context/api_error/ApiErrorActions";
import {Button, Card, CardBody, CardFooter, CardHeader, FormGroup, Input, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {useProjectState} from "../../../context/project/ProjectIndex";
import {DataSourceStepButtonGroup} from "../DataSourceStepButtonGroup/DataSourceStepButtonGroup";
import {DataSourceXMLNodes} from "./DataSourceXMLNodes";
import {DataSourceTypeImage} from "./DataSourceTypeImage";
import {useNavigate} from "react-router";
import {
  CREDENTIALS_ACCESS_TOKEN,
  CREDENTIALS_API_KEY,
  CREDENTIALS_API_SECRET_KEY,
  CREDENTIALS_SESSION_SCOPE,
  CREDENTIALS_SESSION_SHOP
} from "../../Connection/ShopifyConnectionCredentialKeys";
import classNames from "classnames";
import {ConnectionMarketIdSelector} from "../ConnectionMarketIdSelector";

export const DataSourceSettings = ({id}) => {
  const navigate = useNavigate()
  const projectState = useProjectState()
  const dispatch = useApiErrorDispatch();
  const [dataSource, setDataSource] = useState({})
  const [isSaveLoading, setSaveLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [isValidUrl, setIsValidUrl] = useState(true)
  const [isCheckingUrl, setCheckingUrl] = useState(false)
  const [xmlNodes, setXmlNodes] = useState({})
  const [selectedNode, setSelectedNode] = useState('')
  const [credentials, setCredentials] = useState({
    [CREDENTIALS_API_KEY]: '',
    [CREDENTIALS_API_SECRET_KEY]: '',
    [CREDENTIALS_ACCESS_TOKEN]: '',
    [CREDENTIALS_SESSION_SHOP]: '',
    [CREDENTIALS_SESSION_SCOPE]: '',
  })
  const [isValidConnection, setIsValidConnection] = useState(false)
  const [invalidCredentials, setInvalidCredentials] = useState(false)
  const [isCheckingConnection, setIsCheckingConnection] = useState(false)
  const [availableMarkets, setAvailableMarkets] = useState([])
  const [selectedMarket, setSelectedMarket] = useState({})

  useEffect(() => {
    DataSources.get(projectState.companyId, projectState.projectId, id).then((r) => {
      if (r?.status < 400) {
        setDataSource(r.data)
      } else {
        apiErrorRaised(dispatch, r?.response)
      }
    });
  }, [id])

  const handleNext = () => {
    setSubmitted(true)
    if (dataSource.name) {
      const importProperties = []
      for (let [key, value] of Object.entries(dataSource.importProperties)) {
        if (key === 'key' && dataSource.data?.extension === 'xml') {
          value = selectedNode
        }
        if (key === 'market_id' && dataSource.data?.platform === 'shopify') {
          value = selectedMarket.value
        }
        importProperties.push({name: key, value: value})
      }

      if (dataSource.data?.credentials) {
        dataSource.data.credentials = credentials
      }
      let data = {
        name: dataSource.name,
        url: dataSource.url,
        mapping: dataSource.mapping,
        dynamicProperties: dataSource.dynamicProperties,
        type: dataSource.type,
        order: dataSource.order,
        locale: dataSource.locale,
        status: dataSource.status === false ? 0 : 1,
        combineField: dataSource.combineField === '' ? null : dataSource.combineField,
        importProperties: importProperties,
        data: dataSource.data
      }

      if (dataSource.data?.extension) {
        if (!dataSource.data.credentials.username || !dataSource.data.credentials.password) {
          data.data.credentials = null
        }
      }
      setSaveLoading(true);

      DataSources.put(projectState.companyId, projectState.projectId, id, data).then((r) => {
        setSaveLoading(false);
        if (r?.status < 400) {
          navigate(`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/data_sources/${id}/mapping`)
        } else {
          apiErrorRaised(dispatch, r?.response)
        }
      });
    }
  }

  const handleValidateConnection = () => {
    setIsCheckingConnection(true)
    let data = {
      credentials: JSON.stringify(credentials)
    }

    DataSources.getShopifyMarkets(projectState.companyId, projectState.projectId, data).then((r) => {
      if (r?.status === 200) {
        setIsValidConnection(true)
        setInvalidCredentials(false)

        let options = []
        r.data.nodes.map(market => {
          if (!market.enabled || market.webPresence === null) {
            return
          }

          let urlOption = market.webPresence.rootUrls.filter(
            urlOption => urlOption.locale === projectState.locale.split('_')[0]
          )

          let option = {
            value: market.id.split('/').at(-1),
            label: market.name + ' ' + (market.primary ? ' (Primary)' : ''),
            url: urlOption[0]?.url ?? 'No URL available for the current locale'
          }

          if(option.value === dataSource.importProperties?.market_id) {
            setSelectedMarket(option)
          }
          options.push(option)
        })
        setAvailableMarkets(options)
      } else {
        setIsValidConnection(false)
        setInvalidCredentials(true)
      }
      setIsCheckingConnection(false)
    });
  }

  const handleMarketIdChange = (e) => {
    setSelectedMarket(e);
  }

  useEffect(() => {
    setIsValidUrl(false)
  }, [dataSource.url])

  const handleChange = (e) => {
    const {name, value} = e.target;
    if (name === 'url') {
      setIsValidUrl(false)
    }
    setDataSource(dataSource => ({...dataSource, [name]: value}));
  }

  const handleValidateUrl = () => {
    if (isValidUrl) {
      return
    }
    let params = {
      url: dataSource.url,
    }
    if (dataSource.data?.credentials?.username && dataSource.data?.credentials?.password) {
      params.username = dataSource.data.credentials.username
      params.password = dataSource.data.credentials.password
    }
    setCheckingUrl(true)
    DataSources.getXMLStructure(projectState.companyId, projectState.projectId, params).then((r) => {
      setCheckingUrl(false)
      if (r?.status < 400) {
        setIsValidUrl(true)
        setXmlNodes(r.data)
      } else {
        apiErrorRaised(dispatch, r?.response)
      }
    });
  }

  const handleFeedCredentialsChange = (e) => {
    const {name, value} = e.target;
    setDataSource(dataSource => (
      {
        ...dataSource,
        data: {
          ...dataSource.data,
          credentials: {
            ...dataSource.data.credentials,
            [name]: value,
          }
        }
      })
    );
  }

  const handleCredentialsChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value
    });
  }

  useEffect(() => {
    if (dataSource.data?.credentials) {
      setCredentials(dataSource.data?.credentials)
    }
  }, [dataSource.data?.credentials]);

  useEffect(() => {
    setSelectedMarket(dataSource.importProperties?.market_id ?? null)
  }, [dataSource.importProperties]);

  return (
    <>
      <div className={'d-flex flex-column'}>
        <div className={'d-flex flex-row justify-content-between'}>
          <div className={'d-flex flex-row'}>
            <Link
              to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/data_sources/`}>
              <Button className={'btn-icon-only text-dark'}>
                <span className={'fa fa-arrow-left'}/>
              </Button>
            </Link>
            <h1 className={'ms-4'}>Settings</h1>
          </div>
          <DataSourceStepButtonGroup step={'settings'} id={id}/>
        </div>
        <div className={'mt-4'}>
          <Card className={'flex-grow-1 d-flex flex-column'}>
            <DataSourceTypeImage
              type={dataSource.data?.extension ? dataSource.data?.extension : dataSource.data?.platform}
              status={dataSource.status}/>
            <div className={'d-flex flex-row'}>
              <div className={'d-flex flex-column flex-basis-50'}>
                <div className={'ms-5 me-5 d-flex flex-row'}>
                  <FormGroup
                    className="mb-3  flex-grow-1">
                    <label
                      className="form-control-label"
                    >
                      Name
                    </label>
                    <Input
                      placeholder="Name"
                      type="text"
                      valid={submitted && dataSource.name.length > 0}
                      invalid={submitted && !dataSource.name}
                      name="name"
                      value={dataSource.name}
                      onChange={handleChange}
                    />
                    <div className="invalid-feedback">
                      Please provide a name
                    </div>
                  </FormGroup>
                </div>
                <div className={'ms-5 me-5 d-flex flex-row justify-content-center'}>
                  <div className="d-flex flex-row flex-grow-1">
                    <FormGroup
                      className="mb-3 flex-grow-1">
                      <label
                        className="form-control-label"
                      >
                        Url
                      </label>
                      <Input
                        placeholder="https://mycompany.com/myfeed"
                        type="text"
                        valid={submitted && dataSource.url.length > 0}
                        invalid={submitted && !dataSource.url}
                        name="url"
                        value={dataSource.url}
                        onChange={handleChange}
                      />
                      <div className="invalid-feedback">
                        Please provide a valid url
                      </div>
                    </FormGroup>
                  </div>
                  {
                    dataSource.data?.extension === 'xml' ? <div className={'ms-2 align-self-center'}>
                      <Button color={isValidUrl ? 'success' : 'primary'} className={'btn-icon'}
                              onClick={handleValidateUrl}>
                  <span className={classNames({
                    'fa fa-lg fa-solid me-2': true,
                    'fa-arrows-rotate': !isValidUrl && !isCheckingUrl,
                    'fa-check': isValidUrl && !isCheckingUrl
                  })}/>
                        {
                          isCheckingUrl === true ? <span className="spinner-border spinner-border-md me-1"/> : <></>
                        }
                        {
                          isValidUrl ? 'Valid' : 'Validate'
                        }
                      </Button></div> : <></>
                  }
                </div>
              </div>
              {
                dataSource.data?.extension ?
                  <div className={'d-flex flex-column flex-basis-50'}>
                    <div className={'ms-5 me-5 d-flex flex-row'}>
                      <FormGroup
                        className="mb-3  flex-grow-1">
                        <label
                          className="form-control-label"
                        >
                          Username
                        </label>
                        <Input
                          placeholder="Username"
                          type="text"
                          name="username"
                          value={dataSource.data?.credentials?.username}
                          onChange={handleFeedCredentialsChange}
                        />
                      </FormGroup>
                    </div>
                    <div className={'ms-5 me-5 d-flex flex-row'}>
                      <FormGroup
                        className="mb-3  flex-grow-1">
                        <label
                          className="form-control-label"
                        >
                          Password
                        </label>
                        <Input
                          placeholder="Password"
                          type="password"
                          name="password"
                          value={dataSource.data?.credentials?.password}
                          onChange={handleFeedCredentialsChange}
                        />
                        <div className="invalid-feedback">
                          Please provide a name
                        </div>
                      </FormGroup>
                    </div>
                  </div>
                  : <></>
              }

            </div>
            {
              dataSource.data?.extension === 'xml' && isValidUrl ? <div className={'d-flex flex-column ms-5 me-5'}>
                <div className={'d-flex flex-row'}>
                  <Card>
                    <CardHeader>
                      <h2>Select your XML Node</h2>
                    </CardHeader>
                    <CardBody>
                      <DataSourceXMLNodes xml={xmlNodes} setSelectedNode={setSelectedNode}/>
                    </CardBody>
                  </Card>
                </div>
              </div> : <></>
            }
            {
              dataSource.order !== 1 ?
                <div className={'d-flex flex-row ms-5 me-5'}>
                  <FormGroup
                    className="mb-3 flex-basis-50">
                    <label
                      className="form-control-label"
                    >
                      Combine Field
                    </label>
                    <Input
                      placeholder="Field"
                      type="text"
                      name="combineField"
                      value={dataSource.combineField}
                      onChange={handleChange}
                    /> </FormGroup>
                </div> : <></>
            }
            {
              dataSource.data?.credentials && !dataSource.data?.extension ?
                <div>
                  <div className={'d-flex flex-column ms-5 me-5'}>
                    <Card className={'w-100'}>
                      <CardHeader>
                        <h2>Credentials</h2>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <FormGroup
                            className="mb-3 flex-basis-50">
                            <label className="form-control-label"> Api Key </label>
                            <Input
                              placeholder=""
                              type="text"
                              name={CREDENTIALS_API_KEY}
                              value={credentials[CREDENTIALS_API_KEY]}
                              invalid={submitted && !credentials[CREDENTIALS_API_KEY]}
                              onChange={handleCredentialsChange}
                            />
                            <div className="invalid-feedback">
                              Please provide a value
                            </div>
                          </FormGroup>
                          <FormGroup
                            className="mb-3 flex-basis-50">
                            <label className="form-control-label"> Api Secret Key </label>
                            <Input
                              placeholder=""
                              type="text"
                              name={CREDENTIALS_API_SECRET_KEY}
                              value={credentials[CREDENTIALS_API_SECRET_KEY]}
                              invalid={submitted && !credentials[CREDENTIALS_API_SECRET_KEY]}
                              onChange={handleCredentialsChange}
                            />
                            <div className="invalid-feedback">
                              Please provide a value
                            </div>
                          </FormGroup>
                          <FormGroup
                            className="mb-3 flex-basis-50">
                            <label className="form-control-label"> Access Token </label>
                            <Input
                              placeholder=""
                              type="text"
                              name={CREDENTIALS_ACCESS_TOKEN}
                              value={credentials[CREDENTIALS_ACCESS_TOKEN]}
                              invalid={submitted && !credentials[CREDENTIALS_ACCESS_TOKEN]}
                              onChange={handleCredentialsChange}
                            />
                            <div className="invalid-feedback">
                              Please provide a value
                            </div>
                          </FormGroup>
                          <FormGroup
                            className="mb-3 flex-basis-50">
                            <label className="form-control-label"> Session Shop </label>
                            <Input
                              placeholder=""
                              type="text"
                              name={CREDENTIALS_SESSION_SHOP}
                              value={credentials[CREDENTIALS_SESSION_SHOP]}
                              invalid={submitted && !credentials[CREDENTIALS_SESSION_SHOP]}
                              onChange={handleCredentialsChange}
                            />
                            <div className="invalid-feedback">
                              Please provide a value
                            </div>
                          </FormGroup>
                          <FormGroup
                            className="mb-3 flex-basis-50">
                            <label className="form-control-label"> Session Scope </label>
                            <Input
                              placeholder=""
                              type="text"
                              name={CREDENTIALS_SESSION_SCOPE}
                              value={credentials[CREDENTIALS_SESSION_SCOPE]}
                              invalid={submitted && !credentials[CREDENTIALS_SESSION_SCOPE]}
                              onChange={handleCredentialsChange}
                            />
                            <div className="invalid-feedback">
                              Please provide a value
                            </div>
                          </FormGroup>
                        </Row>

                        {invalidCredentials ?
                          <div className="invalid-credentials m-2 text-red">
                            Invalid credentials. Review the fields and try again
                          </div> : <></>
                        }
                        <div className={'d-flex flex-basis-50 justify-content-end'}>
                          <Button color={isValidConnection ? 'success' : 'primary'} className={'btn-icon ms-2'}
                                  onClick={handleValidateConnection}>
                            <span className={classNames({
                              'fa fa-solid me-2': true,
                              'fa-arrows-rotate': !isValidConnection && !isCheckingConnection,
                              'fa-check': isValidConnection && !isCheckingConnection
                            })}/>
                            {
                              isCheckingConnection === true ? <span className="spinner-border spinner-border-lg me-1"/> : <></>
                            }
                            {
                              isCheckingConnection ? '' :isValidConnection ? 'Valid' : 'Validate credentials'
                            }
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  {isValidConnection && dataSource.data?.platform === 'shopify' ?
                    <div className={'d-flex flex-column ms-5 me-5'}>
                      <Card className={'w-100'}>
                        <CardHeader>
                          <h2>Shopify Market</h2>
                        </CardHeader>
                        <CardBody>
                          <FormGroup className="mb-3 flex-basis-50">
                            <ConnectionMarketIdSelector
                              value={selectedMarket}
                              handleChange={handleMarketIdChange}
                              options={availableMarkets}
                              disabled={isCheckingConnection}
                            />
                          </FormGroup>
                          <Row>
                            <FormGroup
                              className="mb-3 flex-basis-50">
                              <label
                                className="form-control-label"
                              >
                                Market URL
                              </label>
                              <Input
                                placeholder="Market ID"
                                type="text"
                                name="marketURL"
                                value={selectedMarket?.url}
                                disabled={true}
                              />
                            </FormGroup>
                          </Row>
                        </CardBody>
                      </Card>
                    </div> : <></>
                  }
                </div>
                : <></>

            }

            <CardFooter>
              <div className={'d-flex flex-row justify-content-end'}>
                <Button color="primary" type="button" disabled={!isValidUrl && dataSource.data?.extension === 'xml'}
                        onClick={handleNext}
                        hidden={dataSource.data?.platform === 'shopify' && !isValidConnection}>
                  {isSaveLoading === true ? <span className="spinner-border spinner-border-lg me-1"/> : <></>}
                  Next
                </Button>
              </div>
            </CardFooter>
          </Card>
        </div>
      </div>
    </>
  );
}
