import React, {useEffect, useState} from 'react';
import {Button, Col, Input, Row} from "reactstrap";
import {remove, update, useUrlParametersDispatch} from "../../../../context/url_parameters/UrlParametersIndex";

export const FeedExportUrlParameter = ({value}) => {
  const urlParametersDispatch = useUrlParametersDispatch()
  const [urlParam, setUrlParam] = useState(value)

  const handleDelete = () => {
    remove(urlParametersDispatch, value.id)
  }

  const handleChange = (e) => {
    const {name, value} = e.target;
    setUrlParam({...urlParam, [name]: value});
  }

  const handleToggle = (e) => {
    const {name, checked} = e.target;
    setUrlParam({...urlParam, [name]: checked});
  }

  useEffect(() => {
    update(urlParametersDispatch, urlParam)
  }, [urlParam])

  return (
    <>
      <div className="d-flex align-items-center gap-3">
        <div className='col-md-5'>
          <Input
            placeholder="name"
            name="name"
            value={urlParam.name}
            onChange={handleChange}
          />
        </div>
        <div className='col-md-3'>
          <Input
            placeholder="Value"
            name="value"
            value={urlParam.value}
            onChange={handleChange}
          />
        </div>
        <div className='col-md-2'>
          <label className="custom-toggle me-1">
            <input type="checkbox" name="override" checked={urlParam.override} onChange={handleToggle}/>
            <span className="custom-toggle-slider rounded-circle"/>
          </label></div>
        <div className='col-md-2'>
          <Button
            id={urlParam.id}
            className="btn-icon-only bg-red-danger"
            divor={'danger'}
            onClick={handleDelete}>
            <span className="fa fa-trash text-white"/>
          </Button>
        </div>
      </div>
    </>
  )
}
