import {get} from "./config/base";
import {urlParser} from "./config/utils";
import {handleError, handleResponse} from "./config/response";

const url = 'companies/{companyId}/projects/{projectId}/events_manager/';

export const EventsManager = {
  recentEvents: (companyId, projectId, params = null) =>
    get(`${urlParser(url, companyId, projectId)}recent_events`, {params: params})
      .then(handleResponse)
      .catch(handleError),
}
