import React, {useEffect} from 'react';
import {FeedExportUrlParametersList} from "./FeedExportUrlParametersList";
import {FeedExportUrlParameterForm} from "./FeedExportUrlParameterForm";
import {useUrlParametersState} from "../../../../context/url_parameters/UrlParametersIndex";


export const FeedExportUrlParameters = ({urlParameters, onChangeCallback}) => {
  const urlParametersState = useUrlParametersState()

  useEffect(() => {
    onChangeCallback(urlParametersState)
  }, [urlParametersState])

  return (
    <>
      <FeedExportUrlParametersList urlParameters={urlParameters}/>
      <FeedExportUrlParameterForm onChangeCallback={onChangeCallback}/>
    </>
  )
}
