const routes = [
  {
    icon: "fa-solid fa-house text-black",
    path: "/companies/:companyId/projects/:projectId",
    name: "Dashboard",
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Feed Manager",
    icon: "fa-solid fa-file text-feed-manager",
    state: "feedManagerCollapse",
    views: [
      {
        path: "/companies/:companyId/projects/:projectId/data_sources",
        name: "Data Sources",
        miniName: "D",
        layout: "/admin",
      },
      {
        path: "/companies/:companyId/projects/:projectId/feed_exports",
        name: "Feed Exports",
        miniName: "F",
        layout: "/admin",
      },
      {
        path: "/companies/:companyId/projects/:projectId/products",
        name: "Products",
        miniName: "P",
        layout: "/admin",
      },
      {
        path: "/companies/:companyId/projects/:projectId/rule_groups",
        name: "Shared Rule Groups",
        miniName: "S",
        layout: "/admin",
      },
      {
        path: "/companies/:companyId/projects/:projectId/product_diagnostic",
        name: "Health",
        miniName: "Diag",
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Designer",
    icon: "fa-solid fa-palette text-designer",
    state: "designerCollapse",
    views: [
      {
        path: "/companies/:companyId/projects/:projectId/templates",
        name: "Templates",
        miniName: "D",
        layout: "/admin",
      },
      {
        path: "/companies/:companyId/projects/:projectId/assets",
        name: "Assets",
        miniName: "A",
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Data History",
    icon: "fa-solid fa-chart-pie text-data-history",
    state: "dataHistoryCollapse",
    views: [
      {
        path: "/companies/:companyId/projects/:projectId/analytics",
        name: "Overview",
        miniName: "O",
        layout: "/admin",
      },
      {
        path: "/companies/:companyId/projects/:projectId/events_manager",
        name: "Events Manager",
        miniName: "EM",
        layout: "/admin",
      },
    ]
  }
];

export default routes;
