import React from 'react'
import {internalProperties} from "./FeedExportPlatformProperties/FeedExportInternalProperties";
import {useProjectState} from "../../../context/project/ProjectIndex";

const FeedExportInternalPropertiesSelector = ({name, item, handleChange, isRequired}) => {
  const projectState = useProjectState()
  return (
    <>
      <select name={name}
              className={isRequired && (item === '' || item === undefined) ? 'form-control is-invalid' : 'form-control'}
              value={item}
              onChange={handleChange}
              required={isRequired}
      >
        <option value=''></option>
        {
          internalProperties.map((internalProperty, i) => {
            return (<option value={internalProperty} key={i}>{internalProperty}</option>)
          })
        }
        {
          projectState.properties.map((property, i) => {
            return (<option value={`properties.${property}`} key={i}>Custom - {property}</option>)
          })
        }
      </select>
      <div className="invalid-feedback">
        Please map this property
      </div>
    </>
  )
}

export default FeedExportInternalPropertiesSelector;
