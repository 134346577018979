import {objectAdd} from "./ReducerActions/ObjectAdd";
import {objectRemove} from "./ReducerActions/ObjectRemove";
import {canvasLoad, canvasTemplateLoad} from "./ReducerActions/CanvasLoad";
import {objectMoveResize} from "./ReducerActions/ObjectMoveResize";
import {objectSelect} from "./ReducerActions/ObjectSelect";
import {objectToggleVisibility} from "./ReducerActions/ObjectToggleVisibility";
import {objectUpdateImageContent} from "./ReducerActions/ObjectUpdateImageContent";
import {objectUpdateFontContent} from "./ReducerActions/ObjectUpdateFontContent";
import {objectUpdateConditions} from "./ReducerActions/ObjectUpdateConditions";
import {canvasObjectsReorder} from "./ReducerActions/CanvasObjectsReorder";
import {objectUpdateData} from "./ReducerActions/ObjectUpdateData";
import {canvasSave} from "./ReducerActions/CanvasSave";
import {setValidationError} from "./ReducerActions/SetValidationError";
import {clearValidationError} from "./ReducerActions/ClearValidationError";

export const BASE_PRIORITY = 900;

export const initialCanvas = {
  selectedObject: {},
  objects: [],
  template: {},
  grid: false,
  stickToGrid: false,
  product: {},
  objectsBeforeSave: [],
  validationErrors: [],
}

export const CanvasReducer = (initialCanvas, action) => {
  switch (action.type) {
    case "CANVAS_TOGGLE_GRID":
      initialCanvas = {
        ...initialCanvas,
        grid: !initialCanvas.grid
      }
      break;
    case "CANVAS_TOGGLE_STICK_TO_GRID":
      initialCanvas = {
        ...initialCanvas,
        stickToGrid: !initialCanvas.stickToGrid
      }
      break;
    case "CANVAS_SELECT_PRODUCT":
      initialCanvas = {
        ...initialCanvas,
        product: action.payload
      }
      break;
    case "CANVAS_LOADED":
      initialCanvas = canvasLoad(initialCanvas, action.payload);
      break;
    case "CANVAS_TEMPLATE_LOADED":
      initialCanvas = canvasTemplateLoad(initialCanvas, action.payload);
      break;
    case 'CANVAS_OBJECTS_REORDER':
      initialCanvas = canvasObjectsReorder(initialCanvas, action.payload);
      break;
    case "OBJECT_MOVED":
    case "OBJECT_RESIZED":
      initialCanvas = objectMoveResize(
        initialCanvas,
        action.payload.id, action.payload.x, action.payload.y, action.payload.width, action.payload.height,
        action.payload.horizontalAlign, action.payload.verticalAlign, action.payload.displayTransformation
      );
      break;
    case "OBJECT_SELECTED":
      initialCanvas = objectSelect(initialCanvas, action.payload)
      break;
    case "OBJECT_TOGGLE_VISIBILITY":
      initialCanvas = objectToggleVisibility(initialCanvas, action.payload)
      break;
    case "OBJECT_UPDATE_IMAGE_CONTENT":
      initialCanvas = objectUpdateImageContent(initialCanvas, action.payload.id, action.payload.imageContent)
      break;
    case "OBJECT_UPDATE_FONT":
      initialCanvas = objectUpdateFontContent(initialCanvas, action.id, action.payload.type, action.payload.assetId)
      break;
    case "OBJECT_ADD":
      initialCanvas = objectAdd(initialCanvas, action.payload);
      break;
    case "OBJECT_REMOVE":
      initialCanvas = objectRemove(initialCanvas, action.payload);
      break;
    case "TEMPLATE_OBJECT_UPDATE_DATA":
      initialCanvas = objectUpdateData(initialCanvas, action.id, action.property, action.payload);
      break;
    case "OBJECT_UPDATE_CONDITIONS":
      initialCanvas = objectUpdateConditions(initialCanvas, action.id, action.payload);
      break;
    case "CANVAS_SAVE":
      initialCanvas = canvasSave(initialCanvas)
      break;
    case "CANVAS_SET_VALIDATION_ERROR":
      initialCanvas = setValidationError(initialCanvas, action.id, action.payload)
      break;
    case "CANVAS_CLEAR_VALIDATION_ERROR":
      initialCanvas = clearValidationError(initialCanvas, action.id, action.payload)
      break;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }

  return initialCanvas;
};
