import {get} from './config/base';
import {handleError, handleResponse} from "./config/response";
import {urlParser} from "./config/utils";

const url = 'companies/{companyId}/projects/{projectId}/product_diagnostic/{productDiagnosticId}/product_issues';

export const ProductIssues = {
  index: (companyId, projectId, productDiagnosticId, limit, offset, filters) =>
    get(urlParser(url, companyId, projectId, '', productDiagnosticId), {
      params: {
        limit: limit,
        offset: offset,
        filters: filters
      }
    })
      .then(handleResponse)
      .catch(handleError),
}
