import React from 'react'
import {useRuleState} from "../../../../context/rule/RuleIndex";
import {FeedExportRuleAction} from "./FeedExportRuleAction";

export const FeedExportRuleActions = ({type}) => {
  const ruleState = useRuleState()
  return (
    <>
      {
        ruleState?.[type] && ruleState?.[type].map((query, i) => {
          return <FeedExportRuleAction key={i} position={i} type={type}/>
        })
      }
    </>
  )
}
