import React from 'react'
import {FeedExportRuleQuery} from "./FeedExportRuleQuery";
import {useRuleState} from "../../../../context/rule/RuleIndex";

export const FeedExportRuleQueries = () => {
  const ruleState = useRuleState()

  return (
    <>
      {
        ruleState?.queries && ruleState?.queries.map((query, i) => {
          return <FeedExportRuleQuery key={i} position={i}/>
        })
      }
    </>
  )
}
