import {Button, FormGroup, Input, InputGroup, Modal} from "reactstrap";
import React, {useEffect, useState} from "react";
import {apiErrorRaised, useApiErrorDispatch} from "../../../context/api_error/ApiErrorIndex";
import {Assets} from "../../../services/api/Assets";
import {updateImageContent, useCanvasDispatch} from "../../../context/canvas/CanvasIndex";
import {useSelectCanvasAssetState} from "../../../context/canvas/selectCanvasAsset/SelectCanvasAssetIndex";
import {useProjectState} from "../../../context/project/ProjectIndex";
import {updateFontContent} from "../../../context/canvas/CanvasActions";
import AssetNewModal from "../AssetNewModal";
import Paginator from "../../Pagination/Paginator";
import {AssetsGrid} from "../AssetsGrid";

export const AssetModalSelector = ({showModal, setShowModal, canvasObjectId, type, assetType}) => {
  const projectState = useProjectState()
  const dispatch = useApiErrorDispatch();
  const canvasDispatch = useCanvasDispatch();
  const selectCanvasAssetState = useSelectCanvasAssetState();
  const [filteredResults, setFilteredResults] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [showAlternateModal, setShowAlternateModal] = useState(false);
  const [createdAsset, setCreatedAsset] = useState(false);

  const [isLoading, setLoading] = useState(false)

  const [debounceTimer, setDebounceTimer] = useState(null);
  const [pagination, setPagination] = useState({})
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(8)
  const [filters, setFilters] = useState([{field: 'type', operator: '=', value: assetType}])


  const searchItems = (event) => {
    const term = event.target.value;
    setSearchInput(term);

    clearTimeout(debounceTimer);
    const newTimer = setTimeout(() => {
      setFilters([
        {field: 'type', operator: '=', value: assetType},
        {field: 'name', operator: 'CONTAINS', value: term}
      ])
      setOffset(0)
    }, 500);
    setDebounceTimer(newTimer);
  }

  const onNewClick = () => {
    setShowAlternateModal(true);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    switch (assetType) {
      case 'image':
        updateImageContent(canvasDispatch, canvasObjectId, {
          type: 'asset',
          value: selectCanvasAssetState,
          format: {
            type: assetType
          }
        })
        break;
      case 'font':
        updateFontContent(canvasDispatch, canvasObjectId, type, selectCanvasAssetState)
        break;
    }
    setShowModal(false)
  }

  useEffect(() => {
    let isCanceled = false;
    let params = {
      order: [
        {
          order_by: 'createdOn',
          order_type: 'desc'
        },
      ],
      limit: limit,
      offset: offset,
      filters: filters
    };
    Assets.index(projectState.companyId, projectState.projectId, params).then((r) => {
      if (!isCanceled) {
        setLoading(false);
        if (r?.status < 400) {
          setFilteredResults(r.data.assets)
          setPagination(r?.data.pagination)
        } else {
          apiErrorRaised(dispatch, r?.response)
        }
      }
    });

    return () => {
      isCanceled = true;
    }
  }, [dispatch, createdAsset, limit, offset, filters]);

  const handlePaginatorClick = (e, offset, limit) => {
    e.preventDefault();
    setLimit(limit)
    setOffset(offset)
  }

  return (
    <>
      <Modal
        size="xl"
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={() => setShowModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Select Asset
          </h6>
          <button
            aria-label="Close"
            className="btn-close"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
          </button>
        </div>
        <div className={'modal-body asset-modal-selector-body'}>
          <div className={'view_header mx-2'}>
            <div className={'me-3 w-100'}>
              <FormGroup>
                <InputGroup>
                  <Input
                      placeholder="Asset Name"
                      type="text"
                      value={searchInput}
                      onChange={searchItems}
                  />
                </InputGroup>
              </FormGroup>
            </div>
            <div className={'d-flex flex-column mb-3'}>
              <Button className={'btn-success'} onClick={onNewClick}>Upload</Button>
            </div>
          </div>

          <div className={'mt-5'}>
            <AssetsGrid assets={filteredResults} isModalSelector={true}/>
          </div>
        </div>
        <div className="modal-footer justify-content-between">
          <Button
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
          <div className={'mt-3 mb-3'}>
            <Paginator
                pagination={pagination}
                handleClick={handlePaginatorClick}
                offset={offset}
                limit={limit}
                numRows={[8,16,32,72,120,240]}
            />
          </div>
          <Button color="primary" type="button" onClick={handleSubmit}>
            {isLoading &&
              <span className="spinner-border spinner-border-sm me-1"/>}
            Save changes
          </Button>
        </div>
      </Modal>
      <AssetNewModal
        showModal={showAlternateModal}
        setShowModal={setShowAlternateModal}
        setCreatedAsset={setCreatedAsset}
      />
    </>
  );
};
