import React, {useEffect, useState} from "react";
import {Card, Col, Collapse, Input} from "reactstrap";
import {TableFilterEdit} from "./TableFilterEdit";
import {FilterOptionsFactory} from "./FilterOptions/FilterOptionsFactory";

export const TableFilterInput = ({entity}) => {
  const SELECTING = 'selecting';
  const EDITING = 'editing';
  const [collapse, setCollapse] = useState(true);
  const [status, setStatus] = useState(SELECTING);
  const [state, setState] = useState({})
  const [filteredOptions, setFilteredOptions] = useState([])

  const handleCollapse = () => {
    setCollapse(!collapse);
    setStatus(SELECTING);
  }

  const filterOptions = FilterOptionsFactory(entity)

  useEffect(() => {
    setFilteredOptions(filterOptions)
  }, [])

  const handleInputChange = (e) => {
    const {value} = e.target
    if (value !== '') {
      const filteredResults = filterOptions.filter((item) => {
        return item.value.includes(value)
      })
      setFilteredOptions(filteredResults)
    } else {
      setFilteredOptions(filterOptions)
    }
  }

  const handleOnFilterItemClick = (filterOption) => {
    setState(state => ({...state, option: filterOption}))
    setStatus(EDITING);
  }

  useEffect(() => {
    renderSelector()
  }, [status])

  const renderSelector = (e) => {
    if (status === SELECTING) {
      return (<>
        <div className={'d-flex flex-column mb-2 mt-2'}>
          {
            filteredOptions.map((filterOption, key) => (
              <div className="ps-3 pe-3 pt-2 pb-2 filter_selector_item" key={key}
                   onClick={() => handleOnFilterItemClick(filterOption)}>{filterOption.label}
              </div>
            ))
          }
        </div>
      </>);
    }

    return (<>
      <TableFilterEdit
        filter={{field: state.option.value, value: '', label: state.option.label, type: state.option.type}}
        closeDialog={closeDialog}
      />
    </>);
  }

  const closeDialog = () => {
    setCollapse(!collapse);
    setStatus(SELECTING);
  }

  return (
    <>
      <Col className="filter_input_row">
        <Input type="text" placeholder="Add Filter" className="input_property_filter" onClick={handleCollapse}
               onChange={handleInputChange}
        />
        <Collapse isOpen={!collapse} className="filter_selector">
          <Card>{renderSelector()}</Card>
        </Collapse>
      </Col>
    </>
  )
}
