import {addFilter} from "./ReducerActions/Filters/AddFilter";
import {removeFilter} from "./ReducerActions/Filters/RemoveFilter";

export const initialTableFilters = [];

export const TableFiltersReducer = (initialTableFilters, action) => {
  switch (action.type) {
    case "ADD_FILTER":
      initialTableFilters = addFilter(initialTableFilters, action.payload);
      break;
    case "REMOVE_FILTER":
      initialTableFilters = removeFilter(initialTableFilters, action.payload);
      break;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
  return initialTableFilters;
};
