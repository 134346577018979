import React, {useEffect, useState} from 'react';
import {Badge, Button, Card, CardBody, CardHeader} from "reactstrap";
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {Products} from "../../services/api/Products";
import {ProductData} from "../../services/api/ProductData";
import {Link} from "react-router-dom";
import {useProjectState} from "../../context/project/ProjectIndex";
import {ProductImagesCarroussel} from "./ProductImagesCarroussel";
import {ProductDatumView} from "./ProductDatum";
import {closeModal, openModal} from "../../context/modal/ModalActions";
import {useModalDispatch} from "../../context/modal/ModalIndex";

const ProductDetail = ({companyId, projectId, id}) => {
  const projectState = useProjectState()
  const apiErrorDispatch = useApiErrorDispatch();
  const [isLoadingProductData, setLoadingProductData] = useState(false)
  const [product, setProduct] = useState({status: 0});
  const [productData, setProductData] = useState([]);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [createdOn, setCreatedOn] = useState([]);
  const [updatedOn, setUpdatedOn] = useState([]);
  const modalDispatcher = useModalDispatch()


  useEffect(() => {
    Products.detail(companyId, projectId, id).then((r) => {
      if (r?.status < 400) {
        setProduct(r.data);
        let createdOnDate = new Date(r.data.created_on);
        let updatedOnDate = new Date(r.data.updated_on);
        setCreatedOn(createdOnDate.toLocaleString(projectState.locale.replace('_', '-'), {timeZone: projectState.timezone}));
        setUpdatedOn(updatedOnDate.toLocaleString(projectState.locale.replace('_', '-'), {timeZone: projectState.timezone}));
        setAdditionalImages(r.data.additional_images)
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    });

  }, [id, apiErrorDispatch]);

  useEffect(() => {
    if (product.sku === undefined) {
      return
    }
    setLoadingProductData(true);
    ProductData.get(companyId, projectId, product.sku, {'withDataSourceName': true}).then((r) => {
      setLoadingProductData(false);
      if (r?.status < 400) {
        r.data.forEach((datum) => {
          let date = new Date(datum.updatedOn);
          let day = date.toLocaleDateString(projectState.locale.replace('_', '-'), {timeZone: projectState.timezone});
          let time = date.toLocaleTimeString(projectState.locale.replace('_', '-'), {timeZone: projectState.timezone});
          datum.updatedOn = `${day} ${time}`
        })
        setProductData(r.data);
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    });
  }, [product.sku]);

  const parsePrice = (price) => {
    if (price === undefined || price === null) {
      return '-'
    }
    const formatter = new Intl.NumberFormat(projectState.locale.replace('_', '-'), {
      style: 'currency',
      currency: price.currency,
    });

    return formatter.format(price.amount / 1000)
  }

  const determineBadgeColor = () => {
    switch (product.status) {
      case 0:
        return 'gray'
      case 1:
        return 'success'
      case 2:
        return 'danger'
      case 3:
        return 'warning'
      case 4:
        return 'purple'
    }
  }

  const determineBadgeText = () => {
    switch (product.status) {
      case 0:
        return 'Disabled'
      case 1:
        return 'Active'
      case 2:
        return 'Deleted'
      case 3:
        return 'Manually Disabled'
      case 4:
        return 'Excluded'
    }
  }

  const isManuallyDisabled = () => {
    return product.status === 3
  }

  const getActionTextForModalBadge = () => {
    return isManuallyDisabled() ? 'Revert manually disabled' : 'Manually disable'
  }

  const getQuestionTextForModalBadge = () => {
    return isManuallyDisabled() ? 'revert manually disabled status in this product? It will be set to disabled.' : 'manually disable this product?'
  }

  const handleToggle = () => {
    openModal(modalDispatcher, {
      title: getActionTextForModalBadge(),
      message: 'Do you want to ' + getQuestionTextForModalBadge(),
      onConfirm: () => {
        if (isManuallyDisabled()) {
          Products.revertManuallyDisable(companyId, projectId, id).then((r) => {
            if (r?.status < 400) {
              setProduct({...product, status: 0})
            } else {
              apiErrorRaised(apiErrorDispatch, r?.response)
            }
            closeModal(modalDispatcher)
          });
        } else {
          Products.manuallyDisable(companyId, projectId, id).then((r) => {
            if (r?.status < 400) {
              setProduct({...product, status: 3})
            } else {
              apiErrorRaised(apiErrorDispatch, r?.response)
            }
            closeModal(modalDispatcher)
          });
        }
      },
      onCancel: () => closeModal(modalDispatcher),
      onBackdropClick: () => closeModal(modalDispatcher),
      buttonColor: isManuallyDisabled() ? 'disabled' : 'warning',
      buttonText: getActionTextForModalBadge()
    })
  }

  return (
    <>
      <div className={'d-flex flex-row'}>
        <div className={''}>{
          <Link
            to={`/admin/companies/${companyId}/projects/${projectId}/products/`}>
            <Button className={'btn-icon-only text-dark'}>
              <span className={'fa fa-arrow-left'}/>
            </Button>
          </Link>
        }</div>
        <div className={'d-flex flex-row justify-content-between flex-grow-1 ms-4'}>
          <h1>{product.title}</h1>
        </div>
        <div className={'d-flex flex-wrap flex-column align-content-center'}>
          <Badge className="badge-lg status" color={determineBadgeColor()}>
            {determineBadgeText()}
          </Badge>
          <div className={'d-flex flex-wrap align-content-center mt-2'}>
            <div className={'small'}>Manually disabled</div>
            <label className="custom-toggle ms-2">
              <input type="checkbox" name="status" checked={isManuallyDisabled()}
                     onChange={handleToggle}/>
              <span className="custom-toggle-slider rounded-circle"/>
            </label>
          </div>
        </div>
      </div>
      <Card>
        <CardHeader className={'bg-secondary'}></CardHeader>
        <CardBody className={'d-flex flex-column'}>
          <div className={'d-flex flex-row'}>
            <div className={'d-flex flex-column m-3'}>
              <div className={'d-flex flex-column mb-3'}>
                <p>IMAGE</p>
                <img src={product?.image?.url} className="product-image-detail" alt=""/>
              </div>
              <div className={'d-flex flex-column mt-3'}>
                <p>RENDERED IMAGE</p>
                <img src={product?.renderedImage?.url ?? require('assets/img/no_image_available.png')}
                     className="product-image-detail" alt=""/>
              </div>
              <div className={'d-flex flex-column mt-3 flex-grow-0'}>
                <p>ADDITIONAL IMAGES</p>
                {
                  additionalImages.length > 0 ?
                    <ProductImagesCarroussel images={additionalImages} showThumbs={false}/> : <></>
                }
              </div>
            </div>
            <div className={'d-flex flex-column flex-grow-1 m-3'}>
              <div className={'d-flex flex-row'}>
                <div className={'flex-grow-1 w-50 m-3'}>
                  <Card>
                    <CardHeader
                      className="d-flex justify-content-between">
                      <p className='m-0'>Basic Info</p>
                    </CardHeader>
                    <CardBody className={'d-flex flex-column'}>
                      <div>
                        <b>Id:</b>
                        <p>{product.id}</p>
                      </div>
                      <div>
                        <b>SKU:</b>
                        <p>{product.sku}</p>
                      </div>
                      <div>
                        <b>Name:</b>
                        <p>{product.name}</p>
                      </div>
                      <div>
                        <b>Link:</b>
                        <p><Link to={product.url} target={"_blank"}>{product.url}</Link></p>
                      </div>
                      <div className={'d-flex flex-row'}>
                        <div className={'flex-grow-1 w-50'}>
                          <b>Price:</b>
                          <p>{parsePrice(product.price)}</p>
                        </div>
                        <div className={'flex-grow-1 w-50'}>
                          <b>Sale Price:</b>
                          <p>{parsePrice(product.salePrice)}</p>
                        </div>
                      </div>
                      <div>
                        <b>Condition:</b>
                        <p>{product.condition}</p>
                      </div>
                      <div>
                        <b>Brand:</b>
                        <p>{product.brand}</p>
                      </div>
                      <div>
                        <b>Type:</b>
                        <p>{product.type}</p>
                      </div>
                      <div>
                        <b>Category:</b>
                        <p>{product.category}</p>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className={'flex-grow-1 w-50 m-3'}>
                  <Card className={''}>
                    <CardHeader
                      className="d-flex justify-content-between">
                      <p className='m-0'>Other Info</p>
                    </CardHeader>
                    <CardBody className={'d-flex flex-column'}>
                      <div>
                        <b>Availability:</b>
                        <p>{product.availability}</p>
                      </div>
                      <div>
                        <b>Color:</b>
                        <p>{product.color}</p>
                      </div>
                      <div>
                        <b>Gender:</b>
                        <p>{product.gender}</p>
                      </div>
                      <div>
                        <b>Size:</b>
                        <p>{product.size}</p>
                      </div>
                      <div>
                        <b>Material:</b>
                        <p>{product.material}</p>
                      </div>
                      <div>
                        <b>Identifier Exists:</b>
                        <p>{product.identifierExists}</p>
                      </div>
                      <div>
                        <b>MPN:</b>
                        <p>{product.mpn}</p>
                      </div>
                      <div>
                        <b>GTIN:</b>
                        <p>{product.gtin}</p>
                      </div>
                      <div>
                        <b>Energy Efficiency Class:</b>
                        <p>{product.energyEfficiencyClass}</p>
                      </div>
                      <div>
                        <b>Weight:</b>
                        <p>{!product?.weight?.measure ? 'Empty' : (product?.weight?.measure / 1000)} {product?.weight?.unit}</p>
                      </div>
                      <div>
                        <b>Shipping:</b>
                        <p>{parsePrice(product.shippingPrice)}</p>
                      </div>
                      <div>
                        <b>Created On:</b>
                        <p>{createdOn}</p>
                      </div>
                      <div>
                        <b>Updated On:</b>
                        <p>{updatedOn}</p>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
              <div className={'m-3 mt-0'}>
                <Card>
                  <CardHeader className="d-flex justify-content-between">
                    <p className='m-0'>Description</p>
                  </CardHeader>
                  <CardBody>{product.description}</CardBody>
                </Card>
              </div>
            </div>
          </div>
          <div className={'d-flex flex-row'}>
            <Card className={'flex-grow-1 w-50 m-3'}>
              <CardHeader className={'bg-gray-table text-center'}><b>Custom Properties</b></CardHeader>
              <CardHeader
                className={'bg-gray-table d-flex flex-row justify-content-center p-2'}>
                <div
                  className={'products-table-title d-flex flex-grow-1 w-50 ms-3 align-items-center fw-semibold py-1'}>NAME
                </div>
                <div
                  className={'products-table-title d-flex flex-grow-1 w-50 justify-content-center ms--3 fw-semibold py-1'}>VALUE
                </div>
              </CardHeader>
              <CardBody>
                {
                  product.properties && Object.entries(product?.properties).map(([key, value]) => {
                    return (
                      <div key={key} className={'d-flex flex-row  justify-content-center mb-1 pt-1 pb-1 border-bottom'}>
                        <div key={key + '-name'} className={'products-table-values flex-grow-1 w-50 products-table-values'}>{key}</div>
                        <div key={key + '-value'}
                             className={'products-table-values d-flex flex-grow-1 w-50 text-center justify-content-center'}>{value}</div>
                      </div>
                    )
                  })
                }
              </CardBody>
            </Card>
            <Card className={'flex-grow-1 w-50 m-3'}>
              <CardHeader className={'bg-gray-table text-center'}><b>Inventories</b></CardHeader>
              <CardHeader
                className={'bg-gray-table d-flex flex-row justify-content-center p-2'}>
                <div className={'products-table-title flex-grow-1 w-50 ms-3 fw-semibold py-1 '}>STORE ID</div>
                <div
                  className={'products-table-title d-flex flex-grow-1 w-50 justify-content-center fw-semibold ms--3 py-1'}>#
                  OF ITEMS
                </div>
              </CardHeader>
              <CardBody>
                {
                  product?.inventories && product?.inventories.map((inventory) => {
                    return (
                      <div key={inventory.store_id} className={'d-flex flex-row  justify-content-center mb-1 pt-1 pb-1 border-bottom'}>
                        <div key={inventory.store_id + '-store'} className={'products-table-values flex-grow-1 w-50'}>{inventory.store_id}</div>
                        <div key={inventory.store_id + '-quantity'}
                             className={'products-table-values d-flex flex-grow-1 w-50 justify-content-center text-center'}>{inventory.quantity}</div>
                      </div>
                    )
                  })
                }
              </CardBody>
            </Card>
          </div>
          <Card className={'m-3'}>
            <CardHeader className='bg-gray-table'>Product Errors</CardHeader>
            <CardHeader
              className={'bg-gray-table d-flex flex-row justify-content-center p-2'}>
              <div className={'products-table-title flex-grow-1 ms-3 fw-semibold py-1'}>SEVERITY</div>
              <div className={'products-table-title flex-grow-1 ms-3 fw-semibold align-items-center'}>NAME</div>
              <div className={'products-table-title flex-grow-1 ms-3 fw-semibold'}>MESSAGE</div>
            </CardHeader>
            <CardBody>
              {
                product?.errors && product?.errors.map((error) => {
                  return (
                    <div key={error.name} className={'d-flex flex-row  justify-content-center mb-1 pt-1 pb-1 border-bottom'}>
                      <div key={error.name + '-severity'} className={'flex-grow-1'}>
                        <Badge className="badge-lg me-2" color={error.severity.toLowerCase()}>
                          {error.severity}
                        </Badge>
                      </div>
                      <div key={error.name + '-name'} className={'flex-grow-1'}>{error.name}</div>
                      <div key={error.name + '-message'} className={'flex-grow-1'}>{error.message}</div>
                    </div>
                  )
                })
              }
            </CardBody>
          </Card>
          <Card className={'m-3'}>
            <CardHeader className={'bg-gray-table text-left'}><b>Original Product Data</b></CardHeader>
            {
              productData?.length > 0 ?
                Object.entries(productData).map(([key, productDatum]) => {
                    return (
                      <Card key={key} className={'m-2'}>
                        <CardHeader key={key + '-datasource'}
                                    className={'bg-gray-table d-flex flex-row justify-content-center p-2'}>
                          <div key={key + '-ds_name'}
                               className={'d-flex flex-grow-1 w-50 ms-3 align-items-center py-1'}>{productDatum.dataSourceName}
                          </div>
                          <div key={key + '-date'}
                               className={'products-table-title d-flex flex-grow-1 w-50 justify-content-end align-items-center fw-semibold py-1 me-3'}>
                            UPDATED ON: {productDatum.updatedOn}
                          </div>
                        </CardHeader>
                        <CardHeader key={key + '-header'}
                                    className={'bg-gray-table d-flex flex-row justify-content-center p-2'}>
                          <div key={key + '-name'}
                               className={'products-table-title d-flex flex-grow-1 w-25 ms-3 align-items-center fw-semibold py-1'}>NAME
                          </div>
                          <div key={key + '-value'}
                               className={'products-table-title d-flex flex-grow-1 w-75 justify-content-left fw-semibold py-1'}>VALUE
                          </div>
                        </CardHeader>
                        <CardBody key={key + '-body'}>
                          <ProductDatumView key={productDatum.id} productDatum={productDatum}/>
                        </CardBody>
                      </Card>

                    )
                  }) :
                  isLoadingProductData ?
                    <span className="spinner-border spinner-border-lg m-5"/> :
                    <CardBody>No data for this product</CardBody>
              }
          </Card>

        </CardBody>
      </Card>
    </>
  );
}

export default ProductDetail;
