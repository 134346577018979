import React, {useCallback, useEffect, useState} from 'react'
import {Button, Card, CardBody, CardHeader, FormGroup, Input, Label} from "reactstrap";
import {FeedExportStepButtonGroup} from "../FeedExportStepButtonGroup/FeedExportStepButtonGroup";
import {useProjectState} from "../../../context/project/ProjectContext";
import {useApiErrorDispatch} from "../../../context/api_error/ApiErrorContext";
import {FeedExports} from "../../../services/api/FeedExports";
import {apiErrorRaised} from "../../../context/api_error/ApiErrorActions";
import Schedule from "../../Schedule/Schedule";
import {FeedExportUrlParameters} from "./FeedExportUrlParams/FeedExportUrlParameters";
import {UrlParametersProvider} from "../../../context/url_parameters/UrlParametersIndex";
import {Link, useNavigate} from "react-router-dom";

export const FeedExportScheduleUrlParams = ({id}) => {
  const projectState = useProjectState()
  const navigate = useNavigate()
  const [feedExport, setFeedExport] = useState({})
  const [isLoading, setLoading] = useState(false)
  const apiErrorDispatch = useApiErrorDispatch()
  const [scheduleComponent, setScheduleComponent] = useState(null)
  const [urlParametersComponent, setUrlParametersComponent] = useState(null)

  useEffect(() => {
    setLoading(true)
    FeedExports.get(projectState.companyId, projectState.projectId, id).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        setFeedExport(r.data)
        setScheduleComponent(
          <Schedule
            interval={r.data.schedule.interval}
            hour={r.data.schedule.hour}
            minute={r.data.schedule.minute}
            interval_count={r.data.schedule.interval_count}
            timezone={r.data.schedule.timezone}
            parentCallback={handleScheduleChange}
          />
        )
        setUrlParametersComponent(
          <UrlParametersProvider>
            <FeedExportUrlParameters
              urlParameters={r.data.url_parameters}
              onChangeCallback={handleChangeUrlParameters}
            />
          </UrlParametersProvider>
        )
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    });
  }, [id])

  const handleChange = (e) => {
    const {name, value} = e.target;
    setFeedExport({...feedExport, [name]: value});
  }

  const handleScheduleChange = useCallback((schedule) => {
    setFeedExport(feedExport => ({
      ...feedExport,
      schedule: schedule
    }))
  }, [feedExport.schedule]);

  const handleChangeUrlParameters = useCallback((newUrlParameters) => {
    if (newUrlParameters) {
      setFeedExport(feedExport => ({
        ...feedExport, 'url_parameters': newUrlParameters
      }));
    }
  }, [feedExport.url_parameters])

  const handleClick = () => {
    let data = {
      filename: feedExport.filename,
      type: feedExport.type,
      format: feedExport.format,
      mapping: feedExport.mapping,
      schedule: {
        ...feedExport.schedule,
        hour: parseInt(feedExport.schedule.hour),
        minute: parseInt(feedExport.schedule.minute),
      },
      url_parameters: feedExport.url_parameters.map(urlParam => {
        return {...urlParam, override: urlParam.override ? 'OVERRIDE' : 'MAINTAIN'}
      }),
      rule_group_id: feedExport.rule_group_id,
      shared_rule_groups: feedExport.shared_rule_groups,
    }

    FeedExports.update(projectState.companyId, projectState.projectId, id, data).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        navigate(`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/${id}/finish`)
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    });
  }

  return (
    <>
      <div className={'d-flex flex-column'}>
        <div className={'d-flex flex-row justify-content-between'}>
          <div className={'d-flex flex-row'}>
            <Link
              to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/`}>
              <Button className={'btn-icon-only text-dark'}>
                <span className={'fa fa-arrow-left'}/>
              </Button>
            </Link>
            <h1 className={'ms-4'}>{feedExport.name}: Schedule</h1>
          </div>
          <FeedExportStepButtonGroup id={id} step={'schedule'}/>
        </div>
        <div className={'d-flex flex-row mt-4'}>
          <Card className={'flex-grow-1'}>
            <CardHeader>
              <h3>Schedule and params</h3>
            </CardHeader>
            <CardBody className={'d-flex flex-column'}>
              <div className={'flex-basis-70'}>
                {scheduleComponent}
              </div>
              <div className={'d-flex flex-column mt-3'}>
                <h2>File information</h2>
                <div className={'d-flex flex-row'}>
                  <FormGroup className="mb-3 flex-basis-20">
                    <Label htmlFor="format" className="form-control-label">File format</Label>
                    <Input
                      type="select"
                      className="form-control"
                      name="format"
                      value={feedExport?.format}
                      onChange={handleChange}>

                      <option value='' name=''></option>
                      <option value='tsv' name='tsv'>tsv</option>
                      <option value='csv' name='tsv'>csv</option>
                    </Input>
                  </FormGroup>
                </div>
              </div>
              <div className={'d-flex flex-column mt-3'}>
                <h2>Url Parameters</h2>
                <div className={'d-flex flex-row'}>
                  <div className={'flex-basis-50'}>
                    {urlParametersComponent}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className={'d-flex flew-row justify-content-between'}>
          <Link
            to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/${id}/rules`}>
            <Button color={'secondary'}>Back</Button>
          </Link>
          <Button color={'primary'} onClick={handleClick}>
            Next
          </Button>
        </div>
      </div>
    </>
  )
}
