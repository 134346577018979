import React from 'react'

export const ruleOperators = [
  {label: 'contains', value: 'CONTAINS'},
  {label: 'doesn\'t contains', value: 'NOT_CONTAINS'},
  {label: 'is equal to', value: '='},
  {label: 'is not equal to', value: '!='},
  {label: 'starts with', value: 'STARTS_WITH'},
  {label: 'ends with', value: 'ENDS_WITH'},
  {label: 'is null', value: 'IS NULL'},
  {label: 'is not null', value: 'IS NOT NULL'},
  {label: 'is greater than', value: '>'},
  {label: 'is greater or equal than', value: '>='},
  {label: 'is less than', value: '<'},
  {label: 'is less or equal than', value: '<='},
  {label: 'array contains', value: 'ARRAY_CONTAINS'},
  {label: 'less than (days) ago', value: 'LESS_THAN_DAYS_AGO'},
  {label: 'more than (days) ago', value: 'MORE_THAN_DAYS_AGO'},
  {label: 'less than (days) left', value: 'LESS_THAN_DAYS_LEFT'},
  {label: 'more than (days) left', value: 'MORE_THAN_DAYS_LEFT'},
]

