import React, {useEffect, useState} from 'react'
import classNames from "classnames";
import xmlImage from "../../../assets/img/icons/data_source_type/xml.png";
import csvImage from "../../../assets/img/icons/data_source_type/csv.png";
import tsvImage from "../../../assets/img/icons/data_source_type/tsv.png";
import shopifyImage from "../../../assets/img/icons/data_source_type/shopify.png";
import spreadsheetImage from "../../../assets/img/icons/data_source_type/spreadsheet.png";

export const DataSourceTypeImage = ({type, status}) => {
  const [image, setImage] = useState()
  useEffect(() => {
    switch (type) {
      case 'xml':
        setImage(xmlImage)
        break;
      case 'csv':
        setImage(csvImage)
        break;
      case 'tsv':
        setImage(tsvImage)
        break;
      case 'spreadsheet':
        setImage(spreadsheetImage)
        break;
      case 'shopify':
        setImage(shopifyImage)
    }
  }, [type])
  return (
    <>
      <div className='text-left m-5'>
        <img
          className={classNames({
            "platform-image img-thumbnail img-fluid": true,
            "img-blackwhite": !status
          })}
          alt="..."
          width="130px"
          src={image}/>
      </div>
    </>
  )
}
