import React, {useEffect, useState} from 'react'
import {FormGroup, Input} from "reactstrap";

export const FeedExportName = ({name, submitted, setName, disabled}) => {

  const [state, setState] = useState('')

  const handleChange = (e) => {
    const {value} = e.target;
    setState(value);
  }

  useEffect(() => {
    setName(state)
  }, [state])

  return (
    <>
      <FormGroup
        className="mb-3 flex-basis-25">
        <label
          className="form-control-label"
        >
          Name
        </label>
        <Input
          placeholder="Name"
          disabled={disabled}
          type="text"
          valid={submitted && state.length > 0}
          invalid={submitted && !state}
          name="name"
          value={name || ''}
          onChange={handleChange}
        />
        {submitted}
        <div className="invalid-feedback">
          Provide a Feed Export name
        </div>
      </FormGroup>
    </>
  )
}
