import {updateData, useCanvasDispatch, useCanvasState} from "../../../../../context/canvas/CanvasIndex";
import React from "react";
import {Input} from "reactstrap";

export const CanvasObjectInfoSidebarDetailPercentageSize = ({title, property}) => {
  const canvasState = useCanvasState()
  const canvasDispatch = useCanvasDispatch()
  const templateObject = canvasState.selectedObject

  const handleChange = (e) => {
    const {value} = e.target
    updateData(
      canvasDispatch,
      templateObject?.id,
      property,
      parseInt(value),
    )

    if (property === 'finalPricePercentageSize') {
      updateData(
        canvasDispatch,
        templateObject?.id,
        'strickedPricePercentageSize',
        100 - parseInt(value),
      )
    } else {
      updateData(
        canvasDispatch,
        templateObject?.id,
        'finalPricePercentageSize',
        100 - parseInt(value),
      )
    }
  }

  return (
    <>
      <div className={'d-flex flex-column'}>
        <strong className={'mt-1'}>{title} (%)</strong>
        <Input
          placeholder="50"
          type="number"
          value={templateObject?.object.data[property] ?? 0}
          name="percentage"
          onChange={handleChange}
        />
      </div>
    </>
  )
}
