import React, { useReducer } from 'react';
import { initialState, ModalReducer } from './ModalReducer';
import ModalBase from "../../components/Modal/ModalBase";

const ModalStateContext = React.createContext();
const ModalDispatchContext = React.createContext();

export function useModalState() {
  const context = React.useContext(ModalStateContext);
  if (context === undefined) {
    throw new Error('useModalState must be used within a ModalProvider');
  }

  return context;
}

export function useModalDispatch() {
  const context = React.useContext(ModalDispatchContext);
  if (context === undefined) {
    throw new Error('useModalDispatch must be used within a ModalProvider');
  }

  return context;
}

export const ModalProvider = ({ children }) => {
  const [modalProps, dispatch] = useReducer(ModalReducer, initialState);

  return (
    <ModalStateContext.Provider value={modalProps}>
      <ModalDispatchContext.Provider value={dispatch}>
        <ModalBase/>
        {children}
      </ModalDispatchContext.Provider>
    </ModalStateContext.Provider>
  );
};
