import {Button, Col, Row} from "reactstrap";
import DateRangePicker from "react-date-range/dist/components/DateRangePicker";
import {es} from "date-fns/locale";

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {useEffect, useState} from "react";
import {useProjectState} from "../../context/project/ProjectIndex";
import {
  setDates,
  setDatesWithComparation,
  useAnalyticsDispatch,
  useAnalyticsState
} from "../../context/analytics/AnalyticsIndex";

import {useNavigate} from "react-router-dom";
import {setQueryParams} from "./AnalyticsUtils";

export const DateSelector = ({isLoading = false, ...props}) => {
  const analyticsDispatch = useAnalyticsDispatch();
  const analyticsState = useAnalyticsState();
  const projectState = useProjectState();
  const [collapse, setCollapse] = useState(true);
  const [compare, setCompare] = useState(false);
  const [focusedRange, setFocusedRange] = useState([0, 0]);
  const navigate = useNavigate()

  const [state, setState] = useState({
    dates: {
      startDate: new Date(analyticsState.dates.start_date),
      endDate: new Date(analyticsState.dates.end_date),
      key: 'dates'
    },
    dates_compare: {
      startDate: analyticsState?.compare_dates !== null ? new Date(analyticsState?.compare_dates.start_date) : null,
      endDate: analyticsState?.compare_dates !== null ? new Date(analyticsState?.compare_dates.end_date) : null,
      key: 'dates_compare'
    },
  });
  const [ranges, setRanges] = useState([state.dates]);

  const handleCollapse = () => {
    setCollapse(!collapse);
  }

  const handleCompareChange = (e) => {
    setCompare(e.target.checked);
  }

  const datesToText = () => {
    let text = '';
    ranges.forEach((range) =>
      text += new Intl.DateTimeFormat(projectState.locale.replace('_', '-')).format(range.startDate)
        + " - " + new Intl.DateTimeFormat(projectState.locale.replace('_', '-')).format(range.endDate) + " "
    )
    return text.slice(0, -1);
  }

  const datesToISOString = (date) => {
    const day = ('0' + date.getDate()).slice(-2)
    const month = ('0' + (date.getMonth() + 1)).slice(-2)
    return date.getFullYear() + '-' + month + '-' + day;
  }

  const handleSaveDates = () => {
    if (!compare) {
      setDates(analyticsDispatch, {
        start_date: ranges[0].startDate,
        end_date: ranges[0].endDate,
      });

    } else {
      setDatesWithComparation(analyticsDispatch,
        {
          start_date: ranges[0].startDate,
          end_date: ranges[0].endDate,
          start_date_comparation: ranges[1].startDate,
          end_date_comparation: ranges[1].endDate,
        });
    }
    setCollapse(true)
  }

  useEffect(() => {
    if (compare) {
      setRanges([state.dates, state.dates_compare])
    } else {
      setRanges([state.dates])
    }
  }, [state])

  useEffect(() => {
    setState({
      ...state,
      dates: {
        startDate: analyticsState.dates.start_date,
        endDate: analyticsState.dates.end_date,
        key: 'dates'
      },
      dates_compare: {
        startDate: analyticsState?.compare_dates?.start_date ?? null,
        endDate: analyticsState?.compare_dates?.end_date ?? null,
        key: 'dates_compare'
      }
    })

    setCompare(analyticsState?.compare_dates !== null)
    setQueryParams(navigate, {
      'start_date': datesToISOString(analyticsState.dates.start_date),
      'end_date': datesToISOString(analyticsState.dates.end_date),
      'start_date_comp': analyticsState.compare_dates ? datesToISOString(analyticsState.compare_dates.start_date) : null,
      'end_date_comp': analyticsState.compare_dates ? datesToISOString(analyticsState.compare_dates.end_date) : null
    })
  }, [analyticsState.dates, analyticsState.dates_compare])

  useEffect(() => {
    if (compare) {
      setRanges([state.dates, state.dates_compare])
      setFocusedRange([1, 0])
    } else {
      setRanges([state.dates])
      setFocusedRange([0, 0])
    }
  }, [compare])
  return (
    <>
      <Row {...props}>
        <Col>
          <div className="data_history_date_selector">
            <Button className="btn-icon float-end" color="primary" outline type="button" onClick={handleCollapse}
                    disabled={isLoading}>
                  <span className="btn-inner--icon me-1">
                    <i className="fas fa-calendar"/>
                  </span>
              <span className="btn-inner--text">{datesToText()}</span>
              <span className="btn-inner--icon me-1">
                    <i className={(collapse ? "ni ni-bold-down" : "ni ni-bold-up")}/>
              </span>
            </Button>
            {
              !collapse ? <div className="data_history_date_selector_box mt-5 d-flex flex-column bg-white">
                <DateRangePicker
                  locale={es}
                  focusedRange={focusedRange}
                  onRangeFocusChange={setFocusedRange}
                  onChange={item => setState({...state, ...item})}
                  ranges={ranges}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  direction="horizontal"
                />
                <div className={'d-flex flex-row ms-3'}>
                  <label className="custom-toggle me-1">
                    <input type="checkbox" name="compare" onChange={handleCompareChange} checked={compare}/>
                    <span className="custom-toggle-slider rounded-circle"/>
                  </label>
                  Compare
                </div>
                <div className={'d-flex flex-row align-items-center m-3 justify-content-end'}>
                  <Button outline color={'danger'} onClick={handleCollapse}>Cancel</Button>
                  <Button color={'primary'} onClick={handleSaveDates}>Apply</Button>
                </div>
              </div> : <></>
            }
          </div>
        </Col>
      </Row>
    </>
  )
}
