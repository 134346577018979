import React from "react";
// react library for routing
import {Outlet, useLocation} from "react-router-dom";
// core components

export default function CanvasLayout() {
  const location = useLocation();
  const mainContentRef = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  return (
    <>
      <div className="main-content" ref={mainContentRef}>
        <Outlet/>
      </div>
    </>
  );
}
