import React, {useEffect, useState} from "react";
import {DataSourceTypeItem} from "./DataSourceTypeItem";
import shopifyImage from "../../../assets/img/icons/data_source_type/shopify.png";

export const DataSourceConnectionTypeSelector = ({typeValue, handleTypeChange}) => {
  const [selectedType, setSelectedType] = useState(typeValue)

  useEffect(() => {
    handleTypeChange(selectedType)
  }, [selectedType])

  useEffect(() => {
    if (typeValue !== selectedType) {
      setSelectedType(typeValue)
    }
  }, [typeValue])

  const types = [
    {
      'label': 'Shopify',
      'value': 'shopify',
      'image': shopifyImage,
    }
  ];
  return (
    <>
      <div className={'d-flex flex-row justify-content-center'}>
        {
          types.map((type, id) => {
            return (
              <DataSourceTypeItem
                key={id}
                type={type}
                selectedType={selectedType}
                setSelectedType={setSelectedType}
              />
            )
          })
        }
      </div>
    </>
  )
}

