import {destroy, get, post, put} from "./config/base";
import {handleError, handleResponse} from "./config/response";
import {urlParser} from "./config/utils";

const url = 'companies/{companyId}/users/';

export const UserAccess = {
  index: (companyId) =>
    get(`${urlParser(url, companyId)}`)
      .then(handleResponse)
      .catch(handleError),
  put: (companyId, id, payload) =>
    put(`${urlParser(url, companyId)}${id}`, payload)
      .then(handleResponse)
      .catch(handleError),
  transferOwnership: (companyId, payload) =>
    post(`${urlParser(url, companyId)}transfer_ownership`, payload)
      .then(handleResponse)
      .catch(handleError),
  byUserId: (companyId, userId) =>
    get(`${urlParser(url, companyId)}${userId}`)
      .then(handleResponse)
      .catch(handleError),
  delete: (companyId, id) =>
    destroy(`${urlParser(url, companyId)}${id}`)
      .then(handleResponse)
      .catch(handleError),
}
