export const objectUpdateFontContent = (canvas, id, type, assetId) => {
  let selectedObject = canvas.objects.find(canvasObject => canvasObject.object.id === id);
  selectedObject = {
    ...selectedObject,
    object: {
      ...selectedObject.object,
      data: {
        ...selectedObject.object.data,
        [type]: assetId,
      }
    }
  }

  return {
    ...canvas,
    selectedObject: selectedObject,
    objects: canvas.objects.map((canvasObject) => {
      if (canvasObject.object.id !== id) {
        return canvasObject;
      }

      return selectedObject;
    })
  }
};
