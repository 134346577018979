import React, {useEffect, useState} from "react";
import {toggleCanvasObjectVisibility, useCanvasDispatch} from "../../../context/canvas/CanvasIndex";
import {selectCanvasObject} from "../../../context/canvas/CanvasActions";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import classNames from "classnames";
import {Assets} from "../../../services/api/Assets";
import {useProjectState} from "../../../context/project/ProjectIndex";

export const CanvasObjectSidebarCard = (
  {id, object, isSelected, isVisible, errors}) => {
  const projectState = useProjectState()
  const canvasDispatch = useCanvasDispatch();
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: id});

  const [logo, setLogo] = useState('')
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  useEffect(() => {
    switch (object.type.split(/[\\ ]+/).pop()) {
      case 'TemplateImageObject':
        if (object?.data.content.imageContent.type === 'asset' && object?.data.content.imageContent.value !== '{{image}}') {
          Assets.get(projectState.companyId, projectState.projectId, object?.data.content.imageContent.value).then((r) => {
            setLogo(
              <div
                className={'avatar bg-white d-inline-flex'}
              >
                <img alt='' src={r.data?.external_url} className={''}/>
              </div>
            )
          })
        }
        setLogo(<span className={'fa fa-2x fa-image'}/>)
        break;
      case 'TemplateTwoPriceObject':
        setLogo(<span className={'fa fa-2x fa-tags'}/>)
        break;
      case 'TemplateTextObject':
        setLogo(<span className={'fa fa-2x fa-font'}/>)
        break;
      default:
    }
  }, [object])

  const text = () => {
    switch (object.type.split(/[\\ ]+/).pop()) {
      case 'TemplateImageObject':
        return 'Image';
      case 'TemplateTwoPriceObject':
        return 'Two price';
      case 'TemplateTextObject':
        return 'Text';
      default:
        return ''
    }
  }

  const handleClick = () => {
    selectCanvasObject(canvasDispatch, object.id);
  }

  const handleVisibilityToggle = () => {
    toggleCanvasObjectVisibility(canvasDispatch, object.id);
  }

  return (
    <div ref={setNodeRef} style={style}>
      <div className={classNames({
        object_sidebar_box: true,
        selected: isSelected,
        error: errors?.length > 0,
        'bg-white': true,
      })} onClick={handleClick}>
        <div className={'object_sidebar_item_visibility align-items-center justify-content-center d-flex flex-column'}>
          <span className={"fas fa-eye " + (!isVisible ? "text-muted" : "")}
                onClick={handleVisibilityToggle}
          />
        </div>
        <div className={'object_sidebar_item_logo'}>
          {logo}
        </div>

        <div className={'object_sidebar_item_text'}>
          <p className="mb-0 small">
            {text()}
          </p>
        </div>
        <div className={classNames({
          'object_sidebar_item_handle pe-4': true,
          'selected': isSelected
        })}>
          <span className={'feed_export_drag_button fa fa-bars'} {...attributes} {...listeners}/>
        </div>
      </div>
    </div>
  );
};
