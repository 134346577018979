import {destroy, get, put} from './config/base';
import {handleError, handleResponse} from "./config/response";
import {urlParser} from "./config/utils";

const url = 'companies/{companyId}/projects/{projectId}/data_sources/';

export const DataSources = {
  index: (companyId, projectId, params = null) =>
    get(urlParser(url, companyId, projectId), {params: params})
      .then(handleResponse)
      .catch(handleError),
  get: (companyId, projectId, id) =>
    get(`${urlParser(url, companyId, projectId)}${id}`)
      .then(handleResponse)
      .catch(handleError),
  put: (companyId, projectId, id, payload) =>
    put(`${urlParser(url, companyId, projectId)}${id}`, payload)
      .then(handleResponse)
      .catch(handleError),
  update: (companyId, projectId, id, payload) =>
    put(`${urlParser(url, companyId, projectId)}${id}/update`, payload)
      .then(handleResponse)
      .catch(handleError),
  activate: (companyId, projectId, id) =>
    put(`${urlParser(url, companyId, projectId)}${id}/activate`)
      .then(handleResponse)
      .catch(handleError),
  disable: (companyId, projectId, id) =>
    put(`${urlParser(url, companyId, projectId)}${id}/disable`)
      .then(handleResponse)
      .catch(handleError),
  delete: (companyId, projectId, id) =>
    destroy(`${urlParser(url, companyId, projectId)}${id}`)
      .then(handleResponse)
      .catch(handleError),
  getFields: (companyId, projectId, id) =>
    get(`${urlParser(url, companyId, projectId)}${id}/fields`)
      .then(handleResponse)
      .catch(handleError),
  getXMLStructure: (companyId, projectId, params) =>
    get(`${urlParser(url, companyId, projectId)}xml_structure`, {params: params})
      .then(handleResponse)
      .catch(handleError),
  getShopifyMarkets: (companyId, projectId, credentials) =>
    get(`${urlParser(url, companyId, projectId)}connection_markets/shopify`, {params: credentials})
      .then(handleResponse)
      .catch(handleError),
}
