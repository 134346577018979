import React, {useEffect, useState} from "react";
import {DataSourceTypeItem} from "./DataSourceTypeItem";
import feedImage from "../../../assets/img/icons/data_source_type/feedIcon.png";
import apiImage from "../../../assets/img/icons/data_source_type/apiIcon.png";

export const DataSourceTypeSelector = ({typeValue, handleTypeChange}) => {
  const [selectedType, setSelectedType] = useState(typeValue)

  useEffect(() => {
    handleTypeChange(selectedType)
  }, [selectedType])

  useEffect(() => {
    if (typeValue !== selectedType) {
      setSelectedType(typeValue)
    }
  }, [typeValue])

  const types = [
    {
      'label': 'Feed',
      'value': 'feed',
      'image': feedImage,
    },
    {
      'label': 'API Connection',
      'value': 'connection',
      'image': apiImage,
    }
  ];
  return (
    <>
      <div className={'d-flex flex-row justify-content-center'}>
        {
          types.map((type, id) => {
            return (
              <DataSourceTypeItem
                key={id}
                type={type}
                selectedType={selectedType}
                setSelectedType={setSelectedType}
              />
            )
          })
        }
      </div>
    </>
  )
}

