import React, {useEffect, useState} from "react";
// react component that copies the given text inside your clipboard
// reactstrap components
import {Container,} from "reactstrap";
// core components
import UserList from "../../../components/Users/UserList";
import {useProjectDispatch} from "../../../context/project/ProjectContext";
import {useParams} from "react-router-dom";
import {updateCompanyBasedOnUrl} from "../../../context/project/ProjectActions";

const UsersView = () => {
  const projectDispatch = useProjectDispatch()
  const routerParams = useParams();
  useEffect(() => {
    updateCompanyBasedOnUrl(projectDispatch, routerParams.companyId)
  }, [])
  const [createdAuthUser, setCreatedAuthUser] = useState(false);

  return (
    <>
      <Container className="mt-7">
        <UserList
          companyId={routerParams.companyId}
          createdAuthUser={createdAuthUser}
          setCreatedAuthUser={setCreatedAuthUser}
        />
      </Container>
    </>
  );
};

export default UsersView;
