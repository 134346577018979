export const AnalyticsTableHeaders = (headers, customGroups, locale, currency) => {
  const finalHeaders = [];
  const options = {
    "date": {
      name: "Date".toUpperCase(),
      selector: row => row.date,
      sortable: true,
      format: row => new Intl.DateTimeFormat(locale.replace('_', '-')).format(row.date)
    },
    "products": {
      name: "Products".toUpperCase(),
      selector: row => row.products,
      format: row => new Intl.NumberFormat(locale.replace('_', '-')).format(row.products),
      sortable: true,
      omit: headers.includes('sku')
    },
    "type": {
      name: "Type".toUpperCase(),
      selector: row => row.type,
      sortable: true,
      reorder: true,
      grow: 2
    },
    "availability": {
      name: "Availability".toUpperCase(),
      selector: row => row.availability,
      sortable: true,
      reorder: true,
      grow: 2
    },
    "top_level_type": {
      name: "Top Level Type".toUpperCase(),
      selector: row => row.top_level_type,
      sortable: true,
      reorder: true,
      grow: 2,
    },
    "brand": {
      name: "Brand".toUpperCase(),
      selector: row => row.brand,
      sortable: true,
      reorder: true,
      grow: 2
    },
    "custom_group_0": {
      name: customGroups['custom_group_0']?.toUpperCase(),
      selector: row => row.custom_group_0,
      sortable: true,
      reorder: true,
      grow: 2
    },
    "custom_group_1": {
      name: customGroups['custom_group_1']?.toUpperCase(),
      selector: row => row.custom_group_1,
      sortable: true,
      reorder: true,
      grow: 2
    },
    "custom_group_2": {
      name: customGroups['custom_group_2']?.toUpperCase(),
      selector: row => row.custom_group_2,
      sortable: true,
      reorder: true,
      grow: 2
    },
    "custom_group_3": {
      name: customGroups['custom_group_3']?.toUpperCase(),
      selector: row => row.custom_group_3,
      sortable: true,
      reorder: true,
      grow: 2
    },
    "custom_group_4": {
      name: customGroups['custom_group_4']?.toUpperCase(),
      selector: row => row.custom_group_4,
      sortable: true,
      reorder: true,
      grow: 2
    },
    "price": {
      name: "Price".toUpperCase(),
      selector: row => row.price,
      sortable: true,
      format: row => new Intl.NumberFormat(locale.replace('_', '-'), {
        style: 'currency',
        currency: currency,
      }).format(row.price),
      right: "true",
    },
    "purchases": {
      name: "Purchases".toUpperCase(),
      selector: row => row.purchases,
      format: row => new Intl.NumberFormat(locale.replace('_', '-')).format(row.purchases),
      sortable: true,
      right: "true",
    },
    "purchase_values": {
      name: "Purchases total amount".toUpperCase(),
      selector: row => row.purchase_values,
      format: row => row.purchase_values !== null ? new Intl.NumberFormat(locale.replace('_', '-'), {
        style: 'currency',
        currency: currency,
      }).format(row.purchase_values) : "-",
      sortable: true,
      right: "true",
    },
    "add_to_cart": {
      name: "Add To Carts".toUpperCase(),
      selector: row => row.add_to_cart,
      format: row => new Intl.NumberFormat(locale.replace('_', '-')).format(row.add_to_cart),
      sortable: true,
      right: "true",
    },
    "add_to_cart_values": {
      name: "Add To Cart total amount".toUpperCase(),
      selector: row => row.add_to_cart_values,
      format: row => row.add_to_cart_values !== null ? new Intl.NumberFormat(locale.replace('_', '-'), {
        style: 'currency',
        currency: currency,
      }).format(row.add_to_cart_values) : "-",
      sortable: true,
      right: "true",
    },
    "sale_price": {
      name: "Sale Price".toUpperCase(),
      selector: row => row.sale_price,
      sortable: true,
      format: row => row.sale_price !== null ? new Intl.NumberFormat(locale.replace('_', '-'), {
        style: 'currency',
        currency: currency,
      }).format(row.sale_price) : "-",
      right: "true",
    },
  }
  headers.forEach(function (item) {
    finalHeaders.push(options[item]);
  });

  return finalHeaders
}
