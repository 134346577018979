export function load(dispatch, payload) {
  dispatch({
    type: 'LOAD',
    payload: payload
  })
}

export function update(dispatch, payload) {
  dispatch({
    type: 'UPDATE_RULE',
    payload: payload
  })
}

export function select(dispatch, payload) {
  dispatch({
    type: 'SELECT_RULE',
    payload: payload
  })
}

export function deleteRule(dispatch, payload) {
  dispatch({
    type: 'DELETE_RULE',
    payload: payload
  })
}

export function clearRules(dispatch, payload) {
  dispatch({
    type: 'CLEAR_RULES',
    payload: payload
  })
}

export function add(dispatch, payload) {
  dispatch({
    type: 'ADD_RULE',
    payload: {
      id: payload.id,
      rule_group_id: payload.rule_group_id,
      name: payload.name,
      description: '',
      order: 100,
      status: 'ACTIVE',
      else_actions: [],
      actions: [
        {
          field: 'all',
          action: 'NOTHING',
          value: null,
        }
      ],
      queries: [
        {
          condition: {
            field: 'all',
            operator: '=',
            value: '',
          },
          or_queries: []
        }
      ]
    }
  })
}
