import React, {useEffect, useRef, useState} from "react";
import {useProjectState} from "../../context/project/ProjectContext";
import {useApiErrorDispatch} from "../../context/api_error/ApiErrorContext";
import {chartColors, chartOptions} from "../../utils/Chart";
import {Button, Card, CardBody} from "reactstrap";
import {Chart} from "react-chartjs-2";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip
} from "chart.js";
import {Analytics} from "../../services/api/Analytics";
import {apiErrorRaised} from "../../context/api_error/ApiErrorActions";
import {addMetric, useAnalyticsDispatch, useAnalyticsState} from "../../context/analytics/AnalyticsIndex";
import {formatFloat, getAxis, getDates, metrics} from "./AnalyticsUtils";
import {AnalyticsChartMetricSelector} from "./AnalyticsChart/AnalyticsChartMetricSelector";
import {format} from "date-fns";

export const AnalyticsChart = ({isLoading, setLoading}) => {
  const chartRef = useRef(null)
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineController,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    TimeScale,
  );

  const projectState = useProjectState()
  const analyticsState = useAnalyticsState()
  const analyticsDispatch = useAnalyticsDispatch()
  const dispatch = useApiErrorDispatch();
  const [insights, setInsights] = useState([])
  const [dates, setDates] = useState([])
  const [data, setData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      chartOptions()
    }
  }, [])

  useEffect(() => {
    fetchData()
    setDates(getDates(analyticsState.dates.start_date, analyticsState.dates.end_date))
  }, [analyticsState.dates, analyticsState.dimensions])

  const fetchData = () => {
    setLoading(true)
    let dimensions = [...analyticsState.dimensions]
    if (!dimensions.includes('date')) {
      dimensions.push('date')
    }
    Analytics.insights(projectState.companyId, projectState.projectId, {
      'start_date': format(analyticsState.dates.start_date, 'yyyy-MM-dd'),
      'end_date': format(analyticsState.dates.end_date, 'yyyy-MM-dd'),
      'dimensions': dimensions,
    }).then((r) => {
      setLoading(false)
      if (r?.status < 400) {
        setInsights(
          r.data.map(insight => ({
            ...insight,
            date: insight.date,
            price: formatFloat(insight.price),
            sale_price: formatFloat(insight.sale_price),
            view_product_values: formatFloat(insight.view_product_values),
            add_to_cart_values: formatFloat(insight.add_to_cart_values),
            initiate_checkout_values: formatFloat(insight.initiate_checkout_values),
            purchase_values: formatFloat(insight.purchase_values),
            purchases: insight.purchases,
          }))
        )
      } else {
        if (r?.response !== undefined) {
          apiErrorRaised(dispatch, r?.response)
        } else {
          apiErrorRaised(dispatch, {
            status: r.status,
            statusText: r.name,
            data: {
              messge: r.message,
            }
          })
        }
      }
    })
  }

  const groupBy = (arr) => {
    return arr.reduce((acc, curr) => {
      let keys = []
      for (let i = 0; i < analyticsState.dimensions.length; i++) {
        if (analyticsState.dimensions[i] === 'date') {
          continue;
        }
        if (curr[analyticsState.dimensions[i]] === '' || curr[analyticsState.dimensions[i]] === null) {
          keys.push('(not set)')
        } else {
          keys.push(curr[analyticsState.dimensions[i]])
        }
      }
      const key = keys.join('-')
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(curr);
      return acc;
    }, {});
  }

  useEffect(() => {
    if (isLoading) {
      return
    }
    parseInsights()
  }, [insights, isLoading, analyticsState.selected_rows, analyticsState.metrics])

  const emptyValues = () => {
    return Object.fromEntries(dates.map((date) => [date,
      analyticsState.metrics.reduce((result, metric) => {
        result[metric.value] = null;
        return result;
      }, {})]))
  }

  const parseInsights = () => {
    let sets = Object.entries(groupBy(insights)).filter(([key, set], index) => analyticsState.selected_rows.includes(key))
    let dataSets = [].concat(...sets.map(([key, set], index) => {
      let fillData = set.reduce((result, insight) => {
        const {date} = insight;
        analyticsState.metrics.forEach((metric) => {
          result[date][metric.value] = insight[metric.value]
        })
        return result;
      }, emptyValues());
      let finalData = dates.map((date) => {
          let data = {
            data: date
          }
          analyticsState.metrics.forEach((metric) => {
            data[metric.value] = fillData[date][metric.value]
          })
          return data;
        }
      );
      return analyticsState.metrics.map((metric, i) => {
        return {
          label: `${labelByMetric(metric)} ${key}`,
          data: finalData.map(insight => insight[metric.value]),
          yAxisID: `y${i}`,
          borderDash: i !== 0 ? [5, 5] : [],
          keyIndex: index
        }
      })
    }));
    setData({
      labels: getDates(analyticsState.dates.start_date, analyticsState.dates.end_date),
      datasets: dataSets.map((dataSet, index) => {
        return {
          ...dataSet,
          tension: 0.3,
          radius: 2,
          borderColor: chartColors.metrics[dataSet.keyIndex % 5],
          backgroundColor: chartColors.metrics[dataSet.keyIndex % 5],
          borderWidth: 4,
        }
      })
    })
  }

  const labelByMetric = (selectedMetric) => {
    return metrics.find((metric => metric.value === selectedMetric.value)).label
  }

  const handleClickAddMetric = () => {
    addMetric(analyticsDispatch, metrics[1])
  }


  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        position: 'nearest',
        enabled: false,
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            label = '  ' + label

            if (label) {
              label += ': ';
            }
            if (context.dataset.label.includes('Price') || context.dataset.label.includes('values')) {
              label += new Intl.NumberFormat(projectState.locale.replace('_', '-'), {
                style: 'currency',
                currency: projectState.projectCurrency
              }).format(context.parsed.y);
            } else {
              label += new Intl.NumberFormat(projectState.locale.replace('_', '-')).format(context.parsed.y);
            }
            return label;
          },
        },
        // Disable the on-canvas tooltip
        external: function (context) {
          // Tooltip Element
          let tooltipEl = document.getElementById('chartjs-tooltip');

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<div class="d-flex flex-column ms-3 me-3 mt-2 mb-2 text-white rounded-5 analytics-tooltip"></div>';
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          // Set Text
          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map(getBody);

            let innerHtml = '<div class="title">';

            titleLines.forEach(function (title) {
              innerHtml += '' + title + '';
            });
            innerHtml += '</div>' +
              '<div class="d-flex flex-column">';

            bodyLines.forEach(function (body, i) {
              let style = 'color:#FFFFFF';
              let bodyParts = body[0].split(':')
              style += '; border-width: 2px';
              innerHtml += '<div class="item d-flex flex-row">' +
                '<span style="color:' + chartColors.metrics[tooltipModel.dataPoints[i].dataset.keyIndex % 5] + '" class="fa fa-2x ' + (i % 2 === 0 ? 'fa-minus' : 'fa-ellipsis') + ' me-2"></span>' +
                '<span class="align-self-center" style="' + style + '">' + bodyParts[0] + ': <span style="font-weight: bold">' + bodyParts[1] + '</span></span></div>';
            });
            innerHtml += '</div>';

            let divRoot = tooltipEl.querySelector('div');
            divRoot.innerHTML = innerHtml;
          }

          const position = context.chart.canvas.getBoundingClientRect();
          const bodyFont = 'NunitoRegular'

          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltipEl.style.font = bodyFont;
          tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
          tooltipEl.style.pointerEvents = 'none';
        }
      }
    },
    scales: getAxis(analyticsState.dates.start_date, analyticsState.dates.end_date, analyticsState.metrics, projectState.locale, projectState.projectCurrency),
  }
  return (
    <>
      <Card>
        <div className={'d-flex flex-row pt-3'}>
          {
            analyticsState.metrics && analyticsState.metrics.map((metric, i) => {
              return (<AnalyticsChartMetricSelector key={i} index={i}/>);
            })
          }
          {
            analyticsState.metrics && analyticsState.metrics.length === 1 ?
              <Button className={'ms-4 mt-1 btn-icon-only btn-rounded'} color={'primary'}
                      onClick={handleClickAddMetric}>
                <span className={'fa-solid fa-plus-circle'}/>
              </Button> : <></>
          }

        </div>
        <CardBody>
          <div className="chart">
            {
              isLoading === true ?
                <div className={'d-flex align-items-center justify-content-center h-100'}>
                  <span className="spinner-border spinner-border-xl"/>
                </div>
                :
                <Chart
                  ref={chartRef}
                  type={'line'}
                  data={data}
                  options={options}
                  id="chart-products"
                  className="chart-canvas"
                />
            }
          </div>
        </CardBody>
      </Card>
    </>
  )
}
