import React, {useEffect, useRef, useState} from "react";
import {useProjectState} from "../../../context/project/ProjectContext";
import {useApiErrorDispatch} from "../../../context/api_error/ApiErrorContext";
import {chartColors, chartOptions} from "../../../utils/Chart";
import {Card, CardBody, CardHeader} from "reactstrap";
import {Chart} from "react-chartjs-2";
import {enUS} from "date-fns/locale";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip
} from "chart.js";
import {Analytics} from "../../../services/api/Analytics";
import {apiErrorRaised} from "../../../context/api_error/ApiErrorActions";
import {useAnalyticsState} from "../../../context/analytics/AnalyticsIndex";
import {formatFloat, metrics} from "../../Analytics/AnalyticsUtils";
import {format, startOfDay, subDays, subMonths} from "date-fns";

export const ProjectDetailProductsChart = () => {
  const chartRef = useRef(null)
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineController,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    TimeScale,
  );

  const projectState = useProjectState()
  const analyticsState = useAnalyticsState()
  const dispatch = useApiErrorDispatch();
  const [isLoading, setLoading] = useState(false)
  const [insights, setInsights] = useState([])
  const [maxPurchaseScale, setMaxPurchaseScale] = useState(0)
  const [startDate] = useState(startOfDay(subDays(subMonths((new Date()).setHours(0, 0, 0, 0), 1), 2)))
  const [endDate] = useState(startOfDay(subDays((new Date()).setHours(0, 0, 0, 0), 1)))
  const [dates, setDates] = useState([])

  const [productsData, setProductsData] = useState({
    labels: [],
    datasets: []
  })

  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      chartOptions()
    }
  }, [])

  useEffect(() => {
    const chart = productsData;
    if (productsData.datasets.length) {
      setMaxPurchaseScale(Math.max(...productsData.datasets[1].data.map(purchase => purchase)));
    }
  }, [productsData])

  useEffect(() => {
    if (insights.length > 0) {
      parseInsights()
    }
  }, [insights])


  useEffect(() => {
    setDates(getDates(startDate, endDate))
    fetchData()
  }, [startDate, endDate, projectState.features])

  const getDates = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);

    while (currentDate <= new Date(endDate)) {
      const formatedDate = format(currentDate, 'yyyy-MM-dd');
      dates.push(formatedDate);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  const fetchData = () => {
    if (!projectState.features?.data_history) {
      return
    }
    setLoading(true)
    let dimensions = [...analyticsState.dimensions]
    if (!dimensions.includes('date')) {
      dimensions.push('date')
    }

    Analytics.insights(projectState.companyId, projectState.projectId, {
      'start_date': format(startDate, 'yyyy-MM-dd'),
      'end_date': format(endDate, 'yyyy-MM-dd'),
      'dimensions': dimensions,
    }).then((r) => {
      setLoading(false)
      if (r?.status < 400) {
        setInsights(
          r.data.map(insight => ({
            ...insight,
            date: insight.date,
            price: formatFloat(insight.price),
            sale_price: formatFloat(insight.sale_price),
            view_product_values: formatFloat(insight.view_product_values),
            add_to_cart_values: formatFloat(insight.add_to_cart_values),
            initiate_checkout_values: formatFloat(insight.initiate_checkout_values),
            purchases: insight.purchases,
            purchase_values: formatFloat(insight.purchase_values)
          }))
        )
      } else {
        if (r?.response !== undefined) {
          apiErrorRaised(dispatch, r?.response)
        } else {
          apiErrorRaised(dispatch, {
            status: r.status,
            statusText: r.name,
            data: {
              message: r.message,
            }
          })
        }
      }
    })
  }

  const groupBy = (arr) => {
    return arr.reduce((acc, curr) => {
      let keys = []
      for (let i = 0; i < analyticsState.dimensions.length; i++) {
        if (analyticsState.dimensions[i] === 'date') {
          continue;
        }
        if (curr[analyticsState.dimensions[i]] === '' || curr[analyticsState.dimensions[i]] === null) {
          keys.push('(not set)')
        } else {
          keys.push(curr[analyticsState.dimensions[i]])
        }
      }
      const key = keys.join('-')
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(curr);
      return acc;
    }, {});
  }

  const parseInsights = () => {
    let metricsArray = ["products", "purchases", "purchase_values"];
    let selectedRows = [""];
    let emptyValues = Object.fromEntries(dates.map((date) => [date,
      metricsArray.reduce((result, metric) => {
        result[metric] = null;
        return result;
      }, {})]))
    let sets = groupBy(insights)
    let dataSets = [].concat(...Object.entries(sets).map(([key, set], index) => {
      if (!selectedRows.includes(key)) {
        return
      }

      let fillData = set.reduce((result, insight) => {
        const {date} = insight;
        metricsArray.forEach((metric) => {
          result[date][metric] = insight[metric]
        })
        return result;
      }, emptyValues);

      let finalData = dates.map((date) => {
          let data = {
            data: date
          }
          metricsArray.forEach((metric) => {
            data[metric] = fillData[date][metric]
          })
          return data;
        }
      );
      return metricsArray.map((metric, i) => {
        return {
          label: `${labelByMetric(metric)} ${key}`,
          data: finalData.map(insight => insight[metric]),
          yAxisID: axisByMetric(metric)
        }
      })
    }));
    setProductsData({
      labels: dates,
      datasets: dataSets.map((dataSet, index) => {
        return {
          ...dataSet,
          tension: 0.3,
          radius: 2,
          borderColor: chartColors.metrics[index % 5],
          backgroundColor: chartColors.metrics[index % 5],
          borderWidth: 4,
        }
      })
    })
  }

  const axisByMetric = (metric) => {
    if (metric.includes('values')) {
      return 'y1'
    }
    if (metric === 'price' || metric === 'sale_price') {
      return 'y1'
    }

    if (metric === 'purchases') {
      return 'y2'
    }

    return 'y'
  }

  const labelByMetric = (selectedMetric) => {
    return metrics.find((metric => metric.value === selectedMetric)).label
  }

  return (
    <Card>
      <CardHeader className={'text-dusty-violet'}>
        EVOLUTION AND PERFORMANCE
      </CardHeader>
      <CardBody>
        {projectState.features?.data_history ?
          <div className="chart">
            {
              isLoading === true ?
                <div className={'d-flex align-items-center justify-content-center h-100'}>
                  <span className="spinner-border spinner-border-xl"/>
                </div>
                :
                <Chart
                  ref={chartRef}
                  type={'line'}
                  data={productsData}
                  options={{
                    plugins: {
                      legend: {
                        display: false
                      },
                      tooltip: {
                        padding: 10,
                        backgroundColor: '#3F3949',
                        usePointStyle: true,
                        cornerRadius: 10,
                        callbacks: {
                          label: function (context) {
                            let label = context.dataset.label || '';

                            if (label) {
                              label += ': ';
                            }
                            if (context.dataset.label.includes('Price') || context.dataset.label.includes('values')) {
                              label += new Intl.NumberFormat(projectState.locale.replace('_', '-'), {
                                style: 'currency',
                                currency: projectState.projectCurrency
                              }).format(context.parsed.y);
                            } else {
                              label += new Intl.NumberFormat(projectState.locale.replace('_', '-')).format(context.parsed.y);
                            }
                            return label;
                          },
                        }
                      }
                    },
                    scales: {
                      x: {
                        type: 'time',
                        time: {
                          unit: 'week',
                          displayFormats: {
                            day: 'Y-MM-dd'
                          },
                          tooltipFormat: 'Y-MM-dd'
                        },
                        adapters: {
                          date: {
                            locale: enUS,
                          },
                        },
                        grid: {
                          display: false
                        },
                        ticks: {
                          color: '#8F88AA'
                        }
                      },
                      y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        min: 0,
                        border: {
                          dash: [4, 8],
                          display: false,
                        },
                        ticks: {
                          color: '#8F88AA'
                        }
                      },
                      y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        min: 0,
                        grid: {
                          display: false
                        },
                        ticks: {
                          callback: function (value, index, ticks) {
                            return new Intl.NumberFormat(projectState.locale.replace('_', '-'), {
                              style: 'currency',
                              currency: projectState.projectCurrency,
                              maximumFractionDigits: 0
                            }).format(value)
                          },
                          color: '#8F88AA'
                        }
                      },
                      y2: {
                        type: 'linear',
                        display: false,
                        position: 'right',
                        min: 0,
                        max: maxPurchaseScale * 1.20,
                        grid: {
                          display: false
                        },
                        ticks: {
                          callback: function (value, index, ticks) {
                            return value + ' purchases';
                          }
                        }
                      },
                    },
                  }}
                  id="chart-products"
                  className="chart-canvas"
                  width="460px"
                />
            }
          </div> : <>
            <div className={'d-flex p-5 flex-grow-1 justify-content-center align-items-center'}>
              <h2>This company has no access to this feature</h2>
            </div>
          </>
        }
      </CardBody>
    </Card>
  )
}
