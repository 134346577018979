import React, {useEffect, useState} from 'react'
import {FormGroup, Input} from "reactstrap";

export const FeedExportExternalId = ({externalId, submitted, setExternalId, disabled}) => {

  const [state, setState] = useState()

  const handleChange = (e) => {
    const {value} = e.target;
    setState(value);
    setExternalId(value);
  }

  useEffect(() => {
    setState(externalId);
    setExternalId(externalId);
  }, [externalId])

  return (
    <>
      <FormGroup
        className="mb-3">
        <label
          className="form-control-label"
        >
          FeedExport External Id
        </label>
        <Input
          placeholder="External Id"
          disabled={disabled}
          type="text"
          valid={submitted && state.length > 0}
          invalid={submitted && !state}
          name="externalId"
          value={state || ''}
          onChange={handleChange}
        />
        {submitted}
        <div className="invalid-feedback">
          Provide a Feed Export External Id
        </div>
      </FormGroup>
    </>
  )
}
