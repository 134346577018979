import React from 'react';
import {Button} from "reactstrap";
import {closeModal, openModal, useModalDispatch} from "../../../../context/modal/ModalIndex";
import {useProjectState} from "../../../../context/project/ProjectIndex";
import {apiErrorRaised, useApiErrorDispatch} from "../../../../context/api_error/ApiErrorIndex";
import {Templates} from "../../../../services/api/Templates";
import {publishTemplate, useTemplateDispatch, useTemplateState} from "../../../../context/template/TemplateIndex";

const TemplateRenderStatusPublishButton = () => {
  const projectState = useProjectState()
  const templateState = useTemplateState()
  const templateContextDispatcher = useTemplateDispatch()
  const dispatchApiError = useApiErrorDispatch();
  const modalDispatcher = useModalDispatch();

  const handlePublishClick = () => {
    openModal(modalDispatcher, {
      title: 'Publish template',
      message: 'Do you want to publish this template? This action will regenerate all images in the feed. This action cannot be undone',
      onConfirm: () => {
        Templates.publish(projectState.companyId, projectState.projectId, templateState.id).then((r) => {
          if (r?.status < 400) {
            publishTemplate(templateContextDispatcher);
          } else {
            apiErrorRaised(dispatchApiError, r?.response)
          }
          closeModal(modalDispatcher)
        });
      },
      onCancel: () => closeModal(modalDispatcher),
      onBackdropClick: () => closeModal(modalDispatcher),
      buttonColor: 'primary',
      buttonText: 'Publish',
      buttonIcon: 'fa-copy'
    })
  }

  return (
    <>
      {
        templateState.renderStatus === 'DRAFT' ?
          <Button color={'success'} outline onClick={handlePublishClick} className={'m-0 mt-1'}>
            <span className="fa fa-copy"/> Publish
          </Button>
          :
          <></>
      }
    </>
  );
}

export default TemplateRenderStatusPublishButton;
