import React, {useState} from 'react'
import {add, useRulesDispatch, useRulesState} from "../../../context/rules/RulesIndex";
import {Button, Col, FormGroup, Input, Modal, Row} from "reactstrap";
import {v4 as uuid4} from 'uuid'

export const FeedExportRuleAddButton = ({ruleGroupId}) => {
  const rulesState = useRulesState()
  const rulesDispatch = useRulesDispatch();
  const [showModal, setShowModal] = useState(false);

  const [submitted, setSubmitted] = useState(false)
  const [name, setName] = useState('')

  const handleChange = (e) => {
    const {value} = e.target
    setName(value)
  }

  const handleAddClick = () => {
    setShowModal(true)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true)
    if (name) {
      let ruleId = uuid4();
      add(rulesDispatch, {
        id: ruleId,
        name: name,
        rule_group_id: ruleGroupId
      })
      setShowModal(false)
      setName('')
      setSubmitted(false)
    }
  }

  return (
    <>
      <Modal
        size="md"
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={() => setShowModal(false)}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            New Rule
          </h6>
          <button
            aria-label="Close"
            className="btn-close"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
          </button>
        </div>
        <div className="modal-body">
          <Row className="">
            <Col md="12 ">
              <FormGroup
                className="mb-3">
                <label
                  className="form-control-label"
                >
                  Name
                </label>
                <Input
                  placeholder="Name"
                  type="text"
                  valid={submitted && name.length > 0}
                  invalid={submitted && !name}
                  name="name"
                  value={name}
                  onChange={handleChange}
                />
                <div className="invalid-feedback">
                  Please provide a name
                </div>
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Row>
            <Col>
              <Button
                className="ms-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => setShowModal(false)}
              >
                Close
              </Button>
            </Col>
            <Col>
              <Button color="primary" className={'float-right'} type="button"
                      onClick={handleSubmit}>
                Create
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
      <Button onClick={handleAddClick} className={'btn-icon-only btn btn-terciary'} color={'white'}>
        <span className={'fa-solid fa-plus fs-3'}/>
      </Button>
    </>
  );
}
