import React, {useEffect} from "react";
import {Container,} from "reactstrap";
import {ProductDiagnosticViewer} from "../../../components/ProductDiagnostic/ProductDiagnostic";
import {useProjectDispatch} from "../../../context/project/ProjectContext";
import {useParams} from "react-router-dom";
import {updateCompanyAndProjectBasedOnUrl} from "../../../context/project/ProjectActions";
import {AnalyticsProvider} from "../../../context/analytics/AnalyticsIndex";

export const ProductDiagnosticView = () => {
  const projectDispatch = useProjectDispatch()
  const routerParams = useParams();
  useEffect(() => {
    updateCompanyAndProjectBasedOnUrl(projectDispatch, routerParams.companyId, routerParams.projectId)
  }, [])
  return (
    <>
      <Container className="mt-5" fluid>
        <AnalyticsProvider>
          <ProductDiagnosticViewer/>
        </AnalyticsProvider>
      </Container>
    </>
  );
};
