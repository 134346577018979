const tradeDoublerProperties = [

  {destination: 'category', origin: 'category', isRequired: true},
  {destination: 'description', origin: 'description', isRequired: true},
  {destination: 'imageURL', origin: 'image', isRequired: true},
  {destination: 'name', origin: 'title', isRequired: true},
  {destination: 'price', origin: 'price', isRequired: true},
  {destination: 'product id', origin: 'sku', isRequired: true},
  {destination: 'productFeed', origin: '', isRequired: true},
  {destination: 'productURL', origin: 'link', isRequired: true},
  {destination: 'availability', origin: 'availability', isRequired: false},
  {destination: 'brand', origin: 'brand', isRequired: false},
  {destination: 'categories', origin: '', isRequired: false},
  {destination: 'condition', origin: 'condition', isRequired: false},
  {destination: 'deliveryTime', origin: '', isRequired: false},
  {destination: 'ean', origin: 'gtin', isRequired: false},
  {destination: 'fields', origin: '', isRequired: false},
  {destination: 'inStock', origin: '', isRequired: false},
  {destination: 'isbn', origin: '', isRequired: false},
  {destination: 'model', origin: '', isRequired: false},
  {destination: 'previousPrice', origin: '', isRequired: false},
  {destination: 'promoText', origin: '', isRequired: false},
  {destination: 'shippingCost', origin: 'shipping', isRequired: false},
  {destination: 'shortDescription', origin: 'title', isRequired: false},
  {destination: 'size', origin: 'size', isRequired: false},
  {destination: 'sku', origin: 'sku', isRequired: false},
  {destination: 'tdCategoryId', origin: '', isRequired: false},
  {destination: 'upc', origin: '', isRequired: false},
  {destination: 'warranty', origin: '', isRequired: false},
  {destination: 'weight', origin: 'weight', isRequired: false},
]


export default tradeDoublerProperties;
