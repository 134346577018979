import React, {useState} from "react";
import {Collapse, Container} from "reactstrap";
import classnames from "classnames";
import {CanvasObjectInfoSidebarDetailPadding} from "../Common/CanvasObjectInfoSidebarDetailPadding";
import {CanvasObjectInfoSidebarDetailColor} from "../Common/CanvasObjectInfoSidebarDetailColor";
import {CanvasObjectInfoSidebarDetailOrigin} from "../Common/CanvasObjectInfoSidebarDetailOrigin";
import {CanvasObjectInfoSidebarDetailSize} from "../Common/CanvasObjectInfoSidebarDetailSize";
import {CanvasObjectInfoSidebarDetailAlign} from "../Common/CanvasObjectInfoSidebarDetailAlign";
import {CanvasObjectInfoSidebarDetailMargin} from "../Common/CanvasObjectInfoSidebarDetailMargin";
import {CanvasObjectInfoSidebarDetailFontSource} from "./CanvasObjectInfoSidebarDetailFontSource";
import {CanvasObjectInfoSidebarDetailTextFontSize} from "./CanvasObjectInfoSidebarDetailTextFontSize";
import {CanvasObjectInfoSidebarDetailAngle} from "../Common/CanvasObjectInfoSidebarDetailAngle";
import {CanvasObjectInfoSidebarDetailTextContent} from "./CanvasObjectInfoSidebarDetailTextContent";
import {CanvasObjectInfoSidebarDetailTextFitTextOnArea} from "./CanvasObjectInfoSidebarDetailTextFitTextOnArea";

export const CanvasObjectInfoSidebarDetailText = () => {
  const [collapse, setCollapse] = useState(false);

  const handleCollapse = () => {
    setCollapse(!collapse);
  }

  return (
    <>
      <div onClick={handleCollapse}
           className={'canvas_sidebar_header d-flex flex-row justify-content-between align-items-center p-3'}>
        <h3 className="mb-0">Text Info</h3>
        <span className={classnames({
          'fa fa-chevron-up': collapse,
          'fa fa-chevron-down': !collapse,
        })}/>
      </div>
      <Container className="">
        <Collapse isOpen={!collapse}>
          <div className={'d-flex flex-column'}>
            <CanvasObjectInfoSidebarDetailTextContent/>
            <CanvasObjectInfoSidebarDetailFontSource
              title={'Font'}
              property={'font'}
            />
            <CanvasObjectInfoSidebarDetailOrigin/>
            <CanvasObjectInfoSidebarDetailSize/>

            <div className={'d-flex flex-row'}>
              <CanvasObjectInfoSidebarDetailTextFontSize/>
              <CanvasObjectInfoSidebarDetailAngle
                title={'Angle'}
                property={'angle'}
              />
            </div>

            <CanvasObjectInfoSidebarDetailTextFitTextOnArea/>
            <CanvasObjectInfoSidebarDetailColor
              title={'Text Color'}
              property={'color'}
            />
            <CanvasObjectInfoSidebarDetailAlign
              title={'Align'}
              property={'align'}
            />
            <CanvasObjectInfoSidebarDetailPadding
              title={'Padding'}
              property={'padding'}
            />
            <CanvasObjectInfoSidebarDetailMargin
              title={'Margin'}
              property={'margin'}
            />
            <CanvasObjectInfoSidebarDetailColor
              property={'innerBackgroundColor'}
              title={'Inner Background Color'}
            />
            <CanvasObjectInfoSidebarDetailColor
              property={'backgroundColor'}
              title={'Background Color'}
            />
          </div>
        </Collapse>
      </Container>
    </>
  );
};
