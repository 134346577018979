import React, {useEffect, useState} from 'react';
import {Button, Col, FormGroup, Input, Row} from "reactstrap";
import {FilterOperators} from "./FilterOperators";
import FilterFields from "./FilterFields";
import {useProjectState} from "../../context/project/ProjectIndex";

export default function FilterEditor({saveCallback, filterId}) {
  const projectState = useProjectState()
  const [fields, setFields] = useState(FilterFields)

  const [newFilter, setNewFilter] = useState()
  const [save, setSave] = useState(false)

  useEffect(() => {
    if (newFilter !== undefined && save) {

      saveCallback(newFilter)
    }
  }, [save, saveCallback, newFilter])

  useEffect(() => {
    setFields(fields.concat(
      projectState.properties.map(property => ({
        name: property.toLocaleUpperCase(),
        value: `properties.${property}`
      })))
    )
  }, [projectState])


  const handleInputChange = (e) => {
    const {name, value} = e.target;
    setNewFilter({...newFilter, [name]: value, id: filterId})
  }

  return (
    <Col>
      <Row>
        <Col>
          <FormGroup
            className="mb-3">
            <label
              className="form-control-label"
            >
              FilterName
            </label>
            <Input type="select"
                   name="field"
                   id="field"
                   className={'form-control'}
                   onChange={handleInputChange}
            >
              <option disabled selected>SELECT A FIELD</option>

              {
                fields && fields.map((field, i) => {
                  return (
                    <>
                      <option key={i} value={field.value}>{field.name}</option>
                    </>
                  )
                })
              }
            </Input>
            <div className="invalid-feedback">
              Please choose a filter field value
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup
            className="mb-3">
            <label
              className="form-control-label"
            >
              FilterOperator
            </label>
            <Input type="select" name="operator" id="fieldOperator" className={'form-control'}
                   onChange={handleInputChange}>
              <option disabled selected>SELECT AN OPERATOR</option>
              {
                Object.keys(FilterOperators).map((op, i) => {
                  return (
                    <>
                      <option key={i} value={FilterOperators[op]}>{op}</option>
                    </>
                  )
                })
              }
            </Input>
            <div className="invalid-feedback">
              Please choose a filter field value
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup
            className="mb-3">
            <label
              className="form-control-label"
            >
              FilterValue
            </label>
            <Input type="text" name="value" id="fieldValue" onChange={handleInputChange}>
            </Input>
            <div className="invalid-feedback">
              Please choose a filter field value
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Button onClick={() => {
          setSave(true)
        }}>Save</Button>
      </Row>
    </Col>
  )
};
