import React, {useEffect, useState} from 'react';
import KeyValueForm from "./KeyValueForm";
import {Table} from "reactstrap";
import KeyValue from "./KeyValue";

const KeyValues = ({parentCallback, values}) => {
    const [keyValues, setKeyValues] = useState(values)

    const addKeyValue = (userInput) => {
        if (keyValues.find((keyValue) => userInput.name === keyValue.name)) {
            return false
        }
        let copy = [...keyValues];
        copy = [...copy, {name: userInput.name, value: userInput.value}];
        setKeyValues(copy);
        parentCallback(copy)
        return true;
    }

    const handleDelete = (key) => {
        let name = key
        let copy = [...keyValues];
        copy = copy.filter((map) => {
            return map.name !== name
        })
        setKeyValues(copy);
        parentCallback(copy)
    }

    useEffect(() => {
        setKeyValues(values)
    }, [values])

    return (
        <>
            <Table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Value</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {
                    keyValues && keyValues.map(keyValue => {
                        return (
                            <>
                                <KeyValue keyValue={keyValue} handleDelete={handleDelete}
                                          key={keyValue.name + keyValue.value}/>
                            </>
                        )
                    })
                }
                <KeyValueForm addKeyValue={addKeyValue}/>
                </tbody>
            </Table>
        </>
    )
}

export default KeyValues;
