import React, {useEffect, useState} from 'react';
import FeedItem from "./FeedItem.js";
import {Button, Col, Row, Table} from "reactstrap";
import {DataSources} from "../../services/api/DataSources";
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {useTableFiltersState} from "../../context/table_filters/TableFiltersContext";
import {TableFilters} from "../TableFilters/TableFilters";
import {closeModal, openModal} from "../../context/modal/ModalActions";
import {Projects} from "../../services/api/Projects";
import {useModalDispatch} from "../../context/modal/ModalIndex";
import {Link} from "react-router-dom";
import {PlaceholderTable} from "../PlaceholderTable/PlaceholderTable";

const FeedList = ({companyId, projectId}) => {
  const [isLoading, setLoading] = useState(false)
  const [isFiltersCollapsed, setFiltersCollapsed] = useState(true)
  const [feeds, setFeeds] = useState([]);
  const modalDispatcher = useModalDispatch()
  const dispatch = useApiErrorDispatch();
  const tableFilters = useTableFiltersState()

  const toggleCollapsed = () => {
    setFiltersCollapsed(!isFiltersCollapsed)
  }

  const handleImport = () => {
    openModal(modalDispatcher, {
      title: 'Request import products',
      message: 'Do you want to request all products manually?',
      onConfirm: () => {
        Projects.requestImport(companyId, projectId).then((r) => {
          if (r?.status < 400) {
          } else {
            apiErrorRaised(dispatch, r?.response)
          }
          closeModal(modalDispatcher)
        });
      },
      onCancel: () => closeModal(modalDispatcher),
      onBackdropClick: () => closeModal(modalDispatcher),
      buttonText: 'Yes'
    })
  }

  useEffect(() => {
    let params = {
      order: [
        {
          order_by: 'order', order_type: 'asc'
        }
      ],
      filters: tableFilters
    };
    setLoading(true)
    DataSources.index(companyId, projectId, params).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        setFeeds(r.data);
      } else {
        apiErrorRaised(dispatch, r?.response)
      }
    });
  }, [dispatch, tableFilters]);

  return (
    <>
      <div className={'view_header'}>
        <div className={'title'}>
          <h1>Data Sources</h1>
        </div>
        <div className={'button_actions'}>
          <Button outline color="primary"
                  onClick={toggleCollapsed}>
            <span className="fa-filter fa me-1"/>
            Filter
          </Button>
          <Button color={'primary'} onClick={handleImport}>Import</Button>
          <Link
            to={`/admin/companies/${companyId}/projects/${projectId}/data_sources/new/select_type`}>
            <Button color={'primary'}>
              Add
            </Button>
          </Link>
        </div>
      </div>
      <Row className={'mt-3'}>
        <Col>
          <TableFilters entity={'data_source'} isCollapsed={isFiltersCollapsed}/>
          {
            isLoading === true ? <PlaceholderTable rows={20} firstEmpty={false} columns={4}/> :
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                <tr>
                  <th className={'text-left ps-5 col-2'}>Name</th>
                  <th className={'text-left ps-4 col-5'}>Url</th>
                  <th className={'text-left ps-4 col-4'}></th>
                  <th className={'text-center col-1'}>Status</th>
                </tr>
                </thead>
                <tbody>
                {
                  feeds && feeds.map((feed, i) => {
                    return (<FeedItem key={i} feedData={feed}/>);
                  })
                }
                </tbody>
              </Table>
          }

        </Col>
      </Row>
    </>
  );
}

export default FeedList;
