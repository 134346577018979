import React, {useCallback, useEffect, useState} from 'react'
import {Button, Card, CardBody, CardHeader} from "reactstrap";
import {FeedExportStepButtonGroup} from "../FeedExportStepButtonGroup/FeedExportStepButtonGroup";
import {FeedExportName} from "../FeedExportNew/FeedExportName";
import {FeedExportDestination} from "../FeedExportNew/FeedExportDestination";
import {FeedExportExternalId} from "../FeedExportNew/FeedExportExternalId";
import {FeedExportPlatformImage} from "./FeedExportPlatformImage";
import {FeedExports} from "../../../services/api/FeedExports";
import {apiErrorRaised} from "../../../context/api_error/ApiErrorActions";
import {useProjectState} from "../../../context/project/ProjectIndex";
import {useApiErrorDispatch} from "../../../context/api_error/ApiErrorIndex";
import {Link, useNavigate} from "react-router-dom";
import {RuleGroups} from "../../../services/api/RuleGroups";
import {SharedRuleGroupsSelect} from "./SharedRuleGroupsSelect";

export const FeedExportSettings = ({id}) => {

  const [feedExport, setFeedExport] = useState({})
  const [firstSharedRule, setFirstSharedRule] = useState(undefined)
  const [secondSharedRule, setSecondSharedRule] = useState(undefined)
  const [feedExportDiagnosticData, setFeedExportDiagnosticData] = useState({})
  const [feedExportExternalId, setFeedExportExternalId] = useState('')
  const [feedExportDestination, setFeedExportDestination] = useState('')
  const [ruleGroups, setRuleGroups] = useState([])
  const [isLoading, setLoading] = useState(false)
  const projectState = useProjectState()
  const apiErrorDispatch = useApiErrorDispatch()
  const navigate = useNavigate()

  const handleClick = () => {
    let sharedRuleGroups = []
    if (firstSharedRule !== null) {
      sharedRuleGroups.push({rule_group_id: firstSharedRule, order: 1})
      if (secondSharedRule !== null) {
        sharedRuleGroups.push({rule_group_id: secondSharedRule, order: 2})
      }
    }
    let feedExportData = {
      filename: feedExport.filename,
      type: feedExport.type,
      format: feedExport.format,
      mapping: feedExport.mapping,
      schedule: feedExport.schedule,
      url_parameters: feedExport.url_parameters,
      rule_group_id: feedExport.rule_group_id,
      shared_rule_groups: sharedRuleGroups
    }

    FeedExports.update(projectState.companyId, projectState.projectId, id, feedExportData).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        if (feedExportExternalId !== undefined && feedExportDestination !== undefined) {
          let data = {
            externalId: feedExportExternalId,
            destination: feedExportDestination
          }
          FeedExports.putDiagnosticData(projectState.companyId, projectState.projectId, id, data).then((r) => {
            setLoading(false);
            if (r?.status < 400) {
              navigate(`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/${id}/mapping`)
            } else {
              apiErrorRaised(apiErrorDispatch, r?.response)
            }
          });
        } else {
          navigate(`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/${id}/mapping`)
        }
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    });
  }

  useEffect(() => {
    setLoading(true)
    FeedExports.get(projectState.companyId, projectState.projectId, id).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        setFeedExport(r.data)
        let first = r.data.shared_rule_groups.find((ruleGroup) => ruleGroup.order === 1)
        if (first !== undefined) {
          setFirstSharedRule(first.rule_group_id)
        } else {
          setFirstSharedRule(null)
        }
        let second = r.data.shared_rule_groups.find((ruleGroup) => ruleGroup.order === 2)
        if (second !== undefined) {
          setSecondSharedRule(second.rule_group_id)
        } else {
          setSecondSharedRule(null)
        }

        RuleGroups.index(
          projectState.companyId,
          projectState.projectId,
          {limit: 100}).then((r) => {
          setLoading(false);
          if (r?.status < 400) {
            setRuleGroups([{'label': '-', 'value': null}].concat(r.data.rule_groups.map((ruleGroup) => ({
              'label': ruleGroup.name,
              'value': ruleGroup.id
            }))))
          } else {
            apiErrorRaised(apiErrorDispatch, r?.response)
          }
        })
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    });
    FeedExports.getDiagnosticData(projectState.companyId, projectState.projectId, id).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        setFeedExportDiagnosticData(r.data)
      }
    });
  }, [id])

  const renderFirstSharedRule = useCallback(() => {
    if (firstSharedRule === undefined) {
      return <></>
    }
    return <SharedRuleGroupsSelect
      ruleGroups={ruleGroups}
      defaultRuleGroup={firstSharedRule}
      parentCallback={handleChangeSharedRuleGroup}
      label={'first'}
      disabled={false}
    />
  }, [firstSharedRule, ruleGroups])

  const renderSecondSharedRule = useCallback(() => {
    if (secondSharedRule === undefined) {
      return <></>
    }
    return <SharedRuleGroupsSelect
      ruleGroups={ruleGroups.filter((ruleGroup) => ruleGroup.value !== firstSharedRule || ruleGroup.value === null)}
      defaultRuleGroup={secondSharedRule}
      label={'second'}
      parentCallback={handleChangeSharedRuleGroup}
      disabled={firstSharedRule === null}
    />
  }, [firstSharedRule, secondSharedRule, ruleGroups])

  const handleChangeSharedRuleGroup = useCallback((label, sharedRule) => {
    if (label === 'first') {
      setFirstSharedRule(sharedRule);
    }
    if (label === 'second') {
      setSecondSharedRule(sharedRule);
    }
  }, [firstSharedRule, secondSharedRule]);

  return (
    <>
      <div className={'d-flex flex-column'}>
        <div className={'d-flex flex-row justify-content-between'}>
          <div className={'d-flex flex-row'}>
            <Link
              to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/`}>
              <Button className={'btn-icon-only text-dark'}>
                <span className={'fa fa-arrow-left'}/>
              </Button>
            </Link>
            <h1 className={'ms-4'}>{feedExport.name}: Settings</h1>
          </div>
          <FeedExportStepButtonGroup id={id} step={'settings'}/>
        </div>
        <div className={'d-flex flex-row mt-4'}>
          <Card className={'flex-grow-1'}>
            <CardHeader>
              <h3>Settings</h3>
            </CardHeader>
            <CardBody className={'d-flex flex-column'}>
              <h4>Platform</h4>
              <div className={'d-flex flex-row mt-2'}>
                <FeedExportPlatformImage platform={feedExport?.mapping?.platform} status={feedExport.status}/>
              </div>
              <div className={'d-flex flex-row mt-2'}>
                <FeedExportName
                  name={feedExport.name}
                  disabled={true}
                  submitted={false}
                  setName={() => ''}
                />
              </div>
              <div className={'d-flex flex-column mt-3'}>
                <h4>First Shared Rule Group</h4>
                {renderFirstSharedRule()}
              </div>
              <div className={'d-flex flex-column mt-3'}>
                <h4>Second Shared Rule Group</h4>
                {renderSecondSharedRule()}
              </div>
            </CardBody>
          </Card>
          <Card className={'flex-grow-0 ms-2'}>
            <CardHeader>
              <h3>Diagnosis</h3>
            </CardHeader>
            <CardBody>
              <div className={'d-flex flex-column flex-grow-0'}>
                <FeedExportDestination destination={feedExportDiagnosticData.destination}
                                       setDestination={setFeedExportDestination}/>
                <FeedExportExternalId externalId={feedExportDiagnosticData.external_id}
                                      setExternalId={setFeedExportExternalId}/>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className={'d-flex flew-row justify-content-end'}>
          <Button color={'primary'} onClick={handleClick}>
            Next
          </Button>
        </div>
      </div>
    </>
  )
}
