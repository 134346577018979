import {Card, CardBody} from "reactstrap";
import React, {useEffect, useRef, useState} from "react";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  DoughnutController,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip
} from "chart.js";
import {chartColors, chartOptions} from "../../utils/Chart";
import {useProjectState} from "../../context/project/ProjectIndex";
import {Chart} from "react-chartjs-2";
import {ProductDiagnostic} from "../../services/api/ProductDiagnostic";

export const ProductDiagnosticHealthGauge = ({platform, destination, ...props}) => {
  const projectState = useProjectState()
  const [state, setState] = useState([])
  const [isLoading, setLoading] = useState(false)
  ChartJS.register(
    DoughnutController,
    Tooltip,
    CategoryScale,
    LinearScale,
    BarElement,
    LineController,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    TimeScale,
    ArcElement,
  );
  const chartRef = useRef(null)
  const [healthData, setHealthData] = useState({
    datasets: [],
  })

  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      chartOptions()
    }
  }, [])

  useEffect(() => {
    setLoading(true);

    ProductDiagnostic.platform_current_status(
      projectState.companyId,
      projectState.projectId,
      {
        platform: platform,
        destination: destination,
      }
    ).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        setState(r.data.map(productDiagnostic => ({
          ...productDiagnostic
        })));
      }
    });
  }, [platform, destination]);


  useEffect(() => {
    if (state === false) {
      return;
    }

    const chartWidth = document.querySelector('.export_health_canvas')?.getBoundingClientRect().width
    const ctx = document.getElementById('export_health')?.getContext('2d')
    const gradientSegment = ctx?.createLinearGradient(0, 0, chartWidth, 0)
    gradientSegment?.addColorStop(0, chartColors.gauge[0])
    gradientSegment?.addColorStop(0.4, chartColors.gauge[1])
    gradientSegment?.addColorStop(0.7, chartColors.gauge[2])

    let healthDataSet = state.map(productDiagnostic => ({
      label: productDiagnostic.platform,
      data: [productDiagnostic.valid_products, productDiagnostic.total_products - productDiagnostic.valid_products],
      backgroundColor: [
        gradientSegment,
        'rgba(0,0,0,0.2)'
      ],
      borderWidth: 1
    }));

    setHealthData({
      labels: ["Valid", "With issues"],
      datasets: healthDataSet
    });
  }, [state]);

  const gaugeChartText = {
    id: 'gaugeChartText',
    afterDatasetsDraw(chart, args, pluginOptions) {
      const {ctx, data, chartArea: {top, bottom, left, right, width, height}, scales: {r}} = chart
      ctx.save()

      const xCoor = chart.getDatasetMeta(0)?.data[0]?.x
      const yCoor = chart.getDatasetMeta(0)?.data[0]?.y
      const formater = new Intl.NumberFormat(projectState.locale.replace('_', '-'), {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      const score = formater.format(Math.floor(100 * data?.datasets[0]?.data[0] / (data?.datasets[0]?.data[0] + data?.datasets[0]?.data[1])))
      let rating = 0

      if (score < 50) {
        rating = 'Bad'
      } else if (score >= 50 && score <= 70) {
        rating = 'Fair'
      } else if (score > 70 && score <= 90) {
        rating = 'Good'
      } else {
        rating = 'Very Good'
      }

      function textLabel(text, x, y, fontSize, textBaseLine, textAlign,) {
        ctx.font = `${fontSize}px NunitoRegular`
        ctx.fillStyle = '#666'
        ctx.textBaseLine = textBaseLine
        ctx.textAlign = textAlign
        ctx.fillText(text, x, y)
      }

      textLabel('0%', left, yCoor + 20, 15, 'top', 'left')
      textLabel('100%', right, yCoor + 20, 15, 'top', 'right')
      textLabel(score, xCoor, yCoor, 60, 'bottom', 'center')
      textLabel(rating, xCoor, yCoor - 60, 20, 'bottom', 'center')
    }
  }

  return (
    <>
      <Card {...props}>
        <CardBody className={'d-flex flex-column'}>
          <div className={'d-flex flex-column flex-basis-25 p-3'}>
            <h4 className={'text-center text-dusty-violet'}>Current health</h4>
            {
              isLoading === true ?
                <div className={'d-flex align-items-center justify-content-center h-100'}>
                  <span className="spinner-border spinner-border-xl"/>
                </div>
                :
                <div style={{minHeight: '20rem'}}>
                  <Chart
                    type={'doughnut'}
                    ref={chartRef}
                    data={healthData}
                    id="export_health"
                    className="export_health_canvas"
                    options={{
                      aspectRatio: 1.5,
                      cutout: '90%',
                      circumference: 180,
                      rotation: 270,
                      radius: 120,
                      plugins: {
                        legend: {
                          display: false
                        },
                        tooltip: {
                          enabled: false,
                        },
                      },
                    }}
                    plugins={[gaugeChartText]}
                  /></div>
            }
          </div>
        </CardBody>
      </Card>
    </>
  )
}
