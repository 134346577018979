import React from 'react';
import {ProductItemGrid} from "./ProductItemGrid";

export const ProductsGrid = ({products, renderedImage}) => {
  return (
    <>
      <div className={'products-grid-container'}>
        <div className={'products-cards__list'}>
          {
            products && products.map((product, i) => {
              return <ProductItemGrid key={i} data={product} renderedImage={renderedImage}/>
            })
          }
        </div>
      </div>
    </>
  )
}
