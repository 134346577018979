import React from 'react';
import {Card, CardBody} from "reactstrap";
import {ProductImagesCarroussel} from "../../Products/ProductImagesCarroussel";

export const TemplateInfoItem = ({template}) => {

  return (<>
    <Card>
      <CardBody className={'d-flex flex-column'}>
        <h3>{template.name}</h3>
        <h4>{template.active_products} Products</h4>
        <ProductImagesCarroussel images={template.previews} showThumbs={false}/>
      </CardBody>
    </Card>
  </>);
}
