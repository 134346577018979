const googleStudioProperties = [

  {destination: 'Unique_ID', origin: 'sku', isRequired: true},
  {destination: 'Reporting_Label', origin: 'title', isRequired: true},
  {destination: 'Product_SKU', origin: 'sku', isRequired: false},
  {destination: 'Headline', origin: '', isRequired: false},
  {destination: 'Price', origin: 'price', isRequired: false},
  {destination: 'Sale_Price', origin: 'salePrice', isRequired: false},
  {destination: 'Image_URL', origin: 'image', isRequired: false},
  {destination: 'CTA', origin: '', isRequired: false},
  {destination: 'Exit_URL', origin: 'link', isRequired: false},
  {destination: 'Default', origin: '', isRequired: false},
  {destination: 'Active', origin: '', isRequired: false},
  {destination: 'Product_Name', origin: 'title', isRequired: false},
]


export default googleStudioProperties;
