import {Col, ListGroup, ListGroupItem, Row} from "reactstrap";
import React, {useEffect} from "react";
import {FeedExportUrlParameter} from "./FeedExportUrlParameter";
import {
  add,
  useUrlParametersDispatch,
  useUrlParametersState
} from "../../../../context/url_parameters/UrlParametersIndex";

export const FeedExportUrlParametersList = ({urlParameters, handleChange}) => {
  const urlParametersState = useUrlParametersState()
  const urlParametersDispatch = useUrlParametersDispatch()

  useEffect(() => {
    urlParameters.map(parameter => {
      add(urlParametersDispatch, parameter)
    })
  }, [urlParameters])

  return (
    <>
      <div className="d-flex align-items-center gap-3">
        <div className="col-md-5">Name</div>
        <div className="col-md-3">Value</div>
        <div className="col-md-2">Override</div>
        <div className="col-md-2">
        </div>
      </div>
      <ListGroup className="list" flush>
        {
          urlParametersState?.map(parameter => {
            return (
              <ListGroupItem className="px-0" key={parameter.id}>
                <FeedExportUrlParameter value={parameter}/>
              </ListGroupItem>
            )
          })
        }
      </ListGroup>
    </>
  )
}
