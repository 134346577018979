import {useEffect} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {CREDENTIALS_API_KEY, CREDENTIALS_API_SECRET_KEY, CREDENTIALS_ACCESS_TOKEN, CREDENTIALS_SESSION_SCOPE, CREDENTIALS_SESSION_SHOP} from "../../../components/Connection/ShopifyConnectionCredentialKeys";

export const ShopifyConnectionSaveCredentialsView = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const keyMapping = {
    ["api_key"]: CREDENTIALS_API_KEY,
    ["secret_key"]: CREDENTIALS_API_SECRET_KEY,
    ["access_token"]: CREDENTIALS_ACCESS_TOKEN,
    ["shop"]: CREDENTIALS_SESSION_SHOP,
    ["scope"]: CREDENTIALS_SESSION_SCOPE
  }

  useEffect(() => {
    let urlParams = new URLSearchParams(searchParams)
    let paramsObj = {}

    for(const [key, value] of urlParams) {
      paramsObj[keyMapping[key]] = value;
    }

    localStorage.setItem("credentials", JSON.stringify(paramsObj));

    navigate('/admin/shopify/create_connection')

  }, []);
}
