import {get, post, put} from './config/base';
import {handleError, handleResponse} from "./config/response";
import {urlParser} from "./config/utils";

const url = 'companies/{companyId}/projects/';

export const Projects = {
  index: (companyId, params = null) =>
    get(urlParser(url, companyId), {params: params})
      .then(handleResponse)
      .catch(handleError),
  get: (companyId, id) =>
    get(`${urlParser(url, companyId)}${id}`)
      .then(handleResponse)
      .catch(handleError),
  summary: (companyId, id) =>
    get(`${urlParser(url, companyId)}${id}/summary`)
      .then(handleResponse)
      .catch(handleError),
  indexed_properties: (companyId, id) =>
    get(`${urlParser(url, companyId)}${id}/indexed_properties`)
      .then(handleResponse)
      .catch(handleError),
  analytics_settings: (companyId, id) =>
    get(`${urlParser(url, companyId)}${id}/analytics/settings `)
      .then(handleResponse)
      .catch(handleError),
  put_indexed_properties: (companyId, id, payload) =>
    put(`${urlParser(url, companyId)}${id}/indexed_properties`, payload)
      .then(handleResponse)
      .catch(handleError),
  put: (companyId, id, payload) =>
    put(`${urlParser(url, companyId)}${id}`, payload)
      .then(handleResponse)
      .catch(handleError),
  activate: (companyId, id) =>
    post(`${urlParser(url, companyId)}${id}/activate`)
      .then(handleResponse)
      .catch(handleError),
  disable: (companyId, id) =>
    post(`${urlParser(url, companyId)}${id}/disable`)
      .then(handleResponse)
      .catch(handleError),
  requestRender: (companyId, id) =>
    post(`${urlParser(url, companyId)}${id}/request_render`)
      .then(handleResponse)
      .catch(handleError),
  requestImport: (companyId, id) =>
    post(`${urlParser(url, companyId)}${id}/request_import`)
      .then(handleResponse)
      .catch(handleError),
  updateConditions: (companyId, id, payload) =>
    post(`${urlParser(url, companyId)}${id}/update_conditions`, payload)
}
