export const ConvertRGBtoHex = (red, green, blue, alpha = 255) => {
  return "#" + ColorToHex(red) + ColorToHex(green) + ColorToHex(blue) + convertAlphaToHex(alpha);
}

export const ColorToHex = (color) => {
  let hexadecimal = color.toString(16);
  return hexadecimal.length === 1 ? "0" + hexadecimal : hexadecimal;
}


function convertAlphaToHex(alphaDecimal) {
  // Convert alphaDecimal to a value between 0 and 1
  const alpha = alphaDecimal / 127;

  // Calculate the equivalent alpha value in the range of 0 to 255
  const alphaInt = Math.round(alpha * 255);

  // Convert alphaInt to hexadecimal string
  const alphaHex = alphaInt.toString(16).toUpperCase();

  // Pad the hexadecimal value with leading zero if needed
  const paddedAlphaHex = alphaHex.padStart(2, '0');

  return paddedAlphaHex;
}

export const HexToColor = (hex) => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    red: parseInt(result[1], 16),
    green: parseInt(result[2], 16),
    blue: parseInt(result[3], 16)
  } : null;
}

export const ConvertVerticalAlign = (verticalAlign) => {
  if (verticalAlign === 'center') {
    return 'middle';
  }
  return verticalAlign;
}

export const ConvertVerticalAlignToJustifyContent = (verticalAlign) => {
  if (verticalAlign === 'center') {
    return 'center'
  }
  if (verticalAlign === 'top') {
    return 'start'
  }
  if (verticalAlign === 'bottom') {
    return 'end'
  }
}

export const ConvertHorizontalAlignToJustifyContent = (horizontalAlign) => {
  if (horizontalAlign === 'center') {
    return 'center'
  }
  if (horizontalAlign === 'left') {
    return 'start'
  }
  if (horizontalAlign === 'right') {
    return 'end'
  }
}
