import {get, post, put} from './config/base';
import {handleError, handleResponse} from "./config/response";
import {urlParser} from "./config/utils";

const url = 'companies/{companyId}/projects/{projectId}/rule_groups/';

export const RuleGroups = {
  index: (companyId, projectId, params = null) =>
    get(urlParser(url, companyId, projectId), {params: params})
      .then(handleResponse)
      .catch(handleError),
  get: (companyId, projectId, id) =>
    get(`${urlParser(url, companyId, projectId)}${id}`)
      .then(handleResponse)
      .catch(handleError),
  put: (companyId, projectId, id, payload) => {
    return put(`${urlParser(url, companyId, projectId)}${id}`, payload)
      .then(handleResponse)
      .catch(handleError)
  },
  import: (companyId, projectId, oldRuleGroupId, newRuleGroupId) =>
    post(`${urlParser(url, companyId, projectId)}import`, {
      new_rule_group_id: newRuleGroupId,
      old_rule_group_id: oldRuleGroupId
    })
      .then(handleResponse)
      .catch(handleError),
}
