export const initialState = {
  id: '',
  queries: [],
  actions: [],
  else_actions: [],
  status: '',
  name: '',
};

export const RuleReducer = (initialState, action) => {
  switch (action.type) {
    case "UPDATE_STATUS":
      return {
        ...initialState,
        status: action.payload,
      }
    case "UPDATE_NAME":
      return {
        ...initialState,
        name: action.payload,
      }
    case "LOAD":
      initialState = action.payload
      return initialState
    case "UPDATE_ACTION":
      return {
        ...initialState,
        [action.payload.type]: [
          ...initialState[action.payload.type].slice(0, action.payload.position),
          action.payload.action,
          ...initialState[action.payload.type].slice(action.payload.position + 1)
        ]
      }
    case "UPDATE_QUERY_CONDITION":
      return {
        ...initialState,
        queries: [
          ...initialState.queries.slice(0, action.payload.position),
          {...initialState.queries[action.payload.position], condition: action.payload.condition},
          ...initialState.queries.slice(action.payload.position + 1)
        ]
      }
    case "UPDATE_OR_QUERY":
      return {
        ...initialState,
        queries: [
          ...initialState.queries.slice(0, action.payload.position),
          {
            ...initialState.queries[action.payload.position],
            or_queries:
              [
                ...initialState.queries[action.payload.position].or_queries.slice(0, action.payload.or_position),
                action.payload.or_query,
                ...initialState.queries[action.payload.position].or_queries.slice(action.payload.or_position + 1)
              ]
          },
          ...initialState.queries.slice(action.payload.position + 1)
        ]
      }
    case "ADD_ACTION":
      return {
        ...initialState,
        [action.payload.type]: [
          ...initialState[action.payload.type],
          action.payload.action
        ]
      }
    case "ADD_AND_QUERY":
      return {
        ...initialState,
        queries: [
          ...initialState.queries,
          action.payload
        ]
      }
    case "ADD_OR_QUERY":
      return {
        ...initialState,
        queries: [
          ...initialState.queries.slice(0, action.payload.position),
          {
            ...initialState.queries[action.payload.position],
            or_queries: [
              ...initialState.queries[action.payload.position].or_queries,
              action.payload.query
            ]
          },
          ...initialState.queries.slice(action.payload.position + 1)
        ]
      }
    case "DELETE_ACTION":
      return {
        ...initialState,
        [action.payload.type]: [
          ...initialState[action.payload.type].slice(0, action.payload.position),
          ...initialState[action.payload.type].slice(action.payload.position + 1)
        ]
      }
    case "DELETE_QUERY":
      return {
        ...initialState,
        queries: [
          ...initialState.queries.slice(0, action.payload),
          ...initialState.queries.slice(action.payload + 1)
        ]
      }
    case "DELETE_OR_QUERY":
      return {
        ...initialState,
        queries: [
          ...initialState.queries.slice(0, action.payload.position),
          {
            ...initialState.queries[action.payload.position],
            or_queries:
              [
                ...initialState.queries[action.payload.position].or_queries.slice(0, action.payload.or_position),
                ...initialState.queries[action.payload.position].or_queries.slice(action.payload.or_position + 1)
              ]
          },
          ...initialState.queries.slice(action.payload.position + 1)
        ]
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
