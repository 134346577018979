import React, {useEffect, useState} from 'react'
import {Button, Card, CardBody, CardHeader} from "reactstrap";
import {FeedExportStepButtonGroup} from "../FeedExportStepButtonGroup/FeedExportStepButtonGroup";
import {FeedExports} from "../../../services/api/FeedExports";
import {apiErrorRaised} from "../../../context/api_error/ApiErrorActions";
import {useProjectState} from "../../../context/project/ProjectIndex";
import {useApiErrorDispatch} from "../../../context/api_error/ApiErrorIndex";
import {Rules} from "../../../services/api/Rules";
import {FeedExportRulesList} from "./FeedExportRulesList";
import {FeedExportRuleDetail} from "./FeedExportRuleDetail";
import {RuleProvider} from "../../../context/rule/RuleIndex";
import {clearRules, load, useRulesDispatch, useRulesState} from "../../../context/rules/RulesIndex";
import {Link, useNavigate} from "react-router-dom";
import {FeedExportRulesImportModal} from "./FeedExportRulesImportModal";
import {RuleImportProvider} from "./Import/Context/FeedExportImportRuleContext";

export const FeedExportRules = ({id}) => {
  const projectState = useProjectState()
  const apiErrorDispatch = useApiErrorDispatch()
  const rulesState = useRulesState()
  const rulesDispatch = useRulesDispatch()
  const navigate = useNavigate()
  const [ruleGroupId, setRuleId] = useState()
  const [createdRule, setCreatedRule] = useState(false);
  const [showModalImport, setShowModalImport] = useState(false)
  const handleClickImport = () => {
    setShowModalImport(true);
  }

  useEffect(() => {
    FeedExports.get(projectState.companyId, projectState.projectId, id).then((r) => {
      if (r?.status < 400) {
        setRuleId(r.data.rule_group_id)
        Rules.index(projectState.companyId, projectState.projectId, r.data.rule_group_id, {
            order: [{
              order_by: 'order',
              order_type: 'asc'
            }]
          }
        ).then((r) => {
          if (r?.status < 400) {
            load(rulesDispatch, r.data.rules)
          } else {
            apiErrorRaised(apiErrorDispatch, r?.response)
          }
        })
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    });
  }, [id, createdRule])

  const handleBack = () => {
    clearRules(rulesDispatch)
    navigate(`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/${id}/mapping`)
  }

  const handleClick = () => {
    let data = rulesState.rules.map((rule, i) => {
        return {...rule, order: i + 1}
      }
    )
    Rules.put_batch(projectState.companyId, projectState.projectId, ruleGroupId, data).then((r) => {
      if (r?.status < 400) {
        navigate(`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/${id}/schedule_params`)
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    });

  }

  return (
    <>
      <div className={'d-flex flex-column'}>
        <div className={'d-flex flex-row justify-content-between'}>
          <div className={'d-flex flex-row'}>
            <Link
              to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/`}>
              <Button className={'btn-icon-only text-dark'}>
                <span className={'fa fa-arrow-left'}/>
              </Button>
            </Link>
            <h1 className={'ms-4'}>Rules</h1>
          </div>
          <FeedExportStepButtonGroup id={id} step={'rules'}/>
        </div>

        <div className={'d-flex flex-row mt-4'}>
          <Card className={'flex-grow-1'}>
            <CardHeader>
              <div className={'d-flex flex-row justify-content-between'}>
                <h3>Rules</h3>
                <div className={'d-flex flex-row'}>
                  <Button color={'primary'} onClick={handleClickImport}>Import </Button>
                </div>
              </div>
            </CardHeader>
            <CardBody className={'d-flex flex-row'}>
              <div className={'flex-basis-20 me-4'}>
                <FeedExportRulesList ruleGroupId={ruleGroupId}/>
              </div>
              <div className={'flex-grow-1'}>
                <RuleProvider>
                  <FeedExportRuleDetail/>
                </RuleProvider>
              </div>

            </CardBody>
          </Card>
        </div>
        <div className={'d-flex flew-row justify-content-between'}>
          <Link
            to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/${id}/mapping`}>
            <Button color={'secondary'}>Back</Button>
          </Link>
          <Button color={'primary'} onClick={handleClick}>
            Next
          </Button>
        </div>
      </div>
      <RuleImportProvider>
        <FeedExportRulesImportModal
          showModal={showModalImport}
          setShowModal={setShowModalImport}
          setCreatedRule={setCreatedRule}
          ruleGroupId={ruleGroupId}
        >
        </FeedExportRulesImportModal>
      </RuleImportProvider>
    </>
  )
}
