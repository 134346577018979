import {get} from './config/base';
import {handleError, handleResponse} from "./config/response";
import {urlParser} from "./config/utils";

const url = 'companies/{companyId}/projects/{projectId}/feed_exports/{id}/';

export const FeedExportHistorys = {
  index: (companyId, projectId, id, params = null) =>
    get(`${urlParser(url, companyId, projectId, id)}history`, {params: params})
      .then(handleResponse)
      .catch(handleError),
  get: (companyId, projectId, id, feedExportHistoryId) =>
    get(`${urlParser(url, companyId, projectId, id)}history/${feedExportHistoryId}`)
      .then(handleResponse)
      .catch(handleError),
};
