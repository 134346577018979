import {useCanvasDispatch, useCanvasState} from "../../../../../context/canvas/CanvasIndex";
import {updateData} from "../../../../../context/canvas/CanvasActions";
import {Input} from "reactstrap";
import React from "react";

export const CanvasObjectInfoSidebarDetailTextFontSize = () => {
  const canvasState = useCanvasState()
  const canvasDispatch = useCanvasDispatch()
  const templateObject = canvasState.selectedObject

  const handleChange = (e) => {
    const {name, value} = e.target;
    updateData(
      canvasDispatch,
      templateObject?.id,
      name,
      parseInt(value),
    )
  }

  return (
    <>
      <div className={'d-flex flex-column'}>
        <strong className={'mt-1'}>Font Size</strong>
        <div className={'d-flex flex-row mt-1'}>
          <Input
            className={'m-2'}
            placeholder="font size"
            type="number"
            value={templateObject?.object.data.fontSize ?? 12}
            name="fontSize"
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  )
}
