import {useCanvasDispatch, useCanvasState} from "../../../../../context/canvas/CanvasIndex";
import {updateData} from "../../../../../context/canvas/CanvasActions";
import {Input} from "reactstrap";
import React from "react";

export const CanvasObjectInfoSidebarDetailTextContent = () => {
  const canvasState = useCanvasState()
  const canvasDispatch = useCanvasDispatch()
  const templateObject = canvasState.selectedObject

  const handleChange = (e) => {
    const {value} = e.target;
    updateData(
      canvasDispatch,
      templateObject?.id,
      'content',
      {
        ...templateObject?.object.data.content,
        textContent: {
          ...templateObject?.object.data.content.textContent,
          value: value,
          format: value.includes('{{discount}}') ? {type: "money"} : undefined,
        }
      }
    )
  }

  return (
    <>
      <div className={'d-flex flex-column'}>
        <strong className={'mt-1'}>Text</strong>
        <Input
          placeholder="{{price}}"
          value={templateObject?.object.data.content?.textContent?.value ?? ''}
          name="contentValue"
          onChange={handleChange}
        />
      </div>
    </>
  )
}
