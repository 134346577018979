import React, {useReducer} from 'react';
import {ImportReducer, initialState} from "./ImportReducer";

const ImportStateContext = React.createContext();
const ImportDispatchContext = React.createContext();

export function useImportState() {
  const context = React.useContext(ImportStateContext);
  if (context === undefined) {
    throw new Error('useImportState must be used within a ImportStateProvider');
  }

  return context;
}

export function useImportDispatch() {
  const context = React.useContext(ImportDispatchContext);
  if (context === undefined) {
    throw new Error('useImportDispatch must be used within a ImportStateProvider');
  }

  return context;
}

export const ImportProvider = ({children}) => {
  const [importImport, dispatch] = useReducer(ImportReducer, initialState);

  return (
    <ImportStateContext.Provider value={importImport}>
      <ImportDispatchContext.Provider value={dispatch}>
        {children}
      </ImportDispatchContext.Provider>
    </ImportStateContext.Provider>
  );
};
