import {Button, Modal} from "reactstrap";
import {useModalState} from "../../context/modal/ModalContext";
import classNames from "classnames";

const ModalBase = () => {
  const {
    isOpen,
    modalProps: {
      title,
      message,
      onConfirm,
      onCancel,
      onBackdropClick,
      buttonColor,
      buttonText,
      buttonIcon,
    },
  } = useModalState();
  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isOpen}
    >
      <div className="modal-header">
        <button
          aria-label="Close"
          className="btn-close"
          data-dismiss="modal"
          type="button"
          onClick={onBackdropClick}
        >
        </button>
      </div>
      <div className="modal-body d-flex flex-column pt-0 pb-0">
        <h6 className="modal-title end text-center mb-4">
          {title}
        </h6>
        <p className={'text-center'}>{message}</p>
      </div>
      <div className="modal-footer d-flex flex-row justify-content-between">
        <Button
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={onCancel}
        >
          Close
        </Button>
        <Button
          color={buttonColor ? buttonColor : "primary"}
          type="button"
          className="btn-lg"
          onClick={onConfirm}
        >
          <span className={classNames({
            "fa me-2": buttonIcon?.length > 0,
            [`${buttonIcon}`]: true,
          })}></span>
          {buttonText}
        </Button>
      </div>
    </Modal>
  )
}

export default ModalBase;
