import React, {useReducer} from 'react';
import {ApiErrorReducer, initialState} from './ApiErrorReducer';

const ApiErrorStateContext = React.createContext();
const ApiErrorDispatchContext = React.createContext();

export function useApiErrorState() {
  const context = React.useContext(ApiErrorStateContext);
  if (context === undefined) {
    throw new Error('useErrorState must be used within a ApiErrorProvider');
  }

  return context;
}

export function useApiErrorDispatch() {
  const context = React.useContext(ApiErrorDispatchContext);
  if (context === undefined) {
    throw new Error('useErrorDispatch must be used within a ApiErrorProvider');
  }

  return context;
}

export const ApiErrorProvider = ({children}) => {
  const [error, dispatch] = useReducer(ApiErrorReducer, initialState);

  return (
    <ApiErrorStateContext.Provider value={error}>
      <ApiErrorDispatchContext.Provider value={dispatch}>
        {children}
      </ApiErrorDispatchContext.Provider>
    </ApiErrorStateContext.Provider>
  );
};
