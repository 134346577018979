import {Button, FormGroup, Input, Modal} from "reactstrap";
import AssetUpload from "./AssetUpload.js";
import React, {useState} from "react";
import {v4 as uuid4} from "uuid";
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {Assets} from "../../services/api/Assets";
import {useProjectState} from "../../context/project/ProjectIndex";

const AssetNewModal = ({showModal, setShowModal, setCreatedAsset}) => {
  const projectState = useProjectState()
  const dispatch = useApiErrorDispatch();
  const [isLoading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [uploadedAsset, setUploadedAsset] = useState(
    {
      path: '',
      name: '',
      type: '',
    }
  )

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitted(true);
    if (uploadedAsset.name) {
      let id = uuid4();
      let payload = uploadedAsset;
      payload.status = 1
      setLoading(true)
      Assets.put(projectState.companyId, projectState.projectId, id, payload).then((r) => {
        setCreatedAsset(false)
        if (r?.status < 400) {
          setTimeout(() => {
            setLoading(false);
            setCreatedAsset(true)
            setShowModal(false)
            setSubmitted(false)
            setUploadedAsset(
              {
                path: '',
                name: '',
                type: '',
              }
            )
          }, 1000)
        } else {
          setLoading(false);
          apiErrorRaised(dispatch, r?.response)
        }
      });
    }
  }

  const handleChange = (e) => {
    const {name, value} = e.target;
    setUploadedAsset(uploadedAsset => ({...uploadedAsset, [name]: value}));
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={() => setShowModal(false)}
      >
        <div className="modal-header">
          <button
            aria-label="Close"
            className="btn-close"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
          </button>
        </div>
        <div className="modal-body d-flex flex-column pt-0 pb-0">
          <h6 className="modal-title end text-center mb-5">
            Upload Asset
          </h6>
          <AssetUpload setUploadedAsset={setUploadedAsset}/>
          <FormGroup
            className="mb-3 mt-3">
            <label
              className="form-control-label"
            >
              Name
            </label>
            <Input
              placeholder="Name"
              type="text"
              valid={submitted && uploadedAsset.name.length > 0}
              disabled={uploadedAsset.name.length === 0}
              invalid={submitted && !uploadedAsset.name}
              name="name"
              value={uploadedAsset.name}
              onChange={handleChange}
            />
            <div className="invalid-feedback">
              Please provide a valid name.
            </div>
          </FormGroup>
        </div>
        <div className="modal-footer d-flex flex-row justify-content-between">
          <Button
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
          <Button color="primary" type="button"
                  onClick={handleSubmit}>
            {isLoading &&
              <span className="spinner-border spinner-border-sm me-1"/>}
            Upload
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default AssetNewModal;
