let authUser = localStorage.getItem("authUser")
  ? JSON.parse(localStorage.getItem("authUser"))
  : {};

export const initialState = {
  user: authUser?.user,
  token: authUser?.token,
}

const save = () => {
  localStorage.setItem('authUser', JSON.stringify(authUser));
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      authUser = {
        ...authUser,
        user: action.payload.user,
        token: action.payload.auth_token,
      };
      save();
      break;
    case "LOGOUT":
      authUser = {
        ...authUser,
        user: null,
        token: null
      };
      localStorage.clear();
      break;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
  return authUser;
};
