import React from 'react';
import {Button} from "reactstrap";
import {closeModal, openModal, useModalDispatch} from "../../../../context/modal/ModalIndex";
import {useProjectState} from "../../../../context/project/ProjectIndex";
import {apiErrorRaised, useApiErrorDispatch} from "../../../../context/api_error/ApiErrorIndex";
import {Templates} from "../../../../services/api/Templates";
import {templateDeleted, useTemplateDispatch, useTemplateState} from "../../../../context/template/TemplateIndex";
import {useNavigate} from "react-router-dom";

const TemplateDeleteButton = () => {
  const projectState = useProjectState()
  const templateState = useTemplateState()
  const templateContextDispatcher = useTemplateDispatch()
  const dispatchApiError = useApiErrorDispatch();
  const modalDispatcher = useModalDispatch();
  const navigate = useNavigate()

  const handleDeleteClick = () => {
    openModal(modalDispatcher, {
      title: 'Delete template',
      message: 'Do you want to delete this template? This action will regenerate all images related to this template. This action cannot be undone',
      onConfirm: () => {
        Templates.delete(projectState.companyId, projectState.projectId, templateState.id).then((r) => {
          if (r?.status < 400) {
            templateDeleted(templateContextDispatcher);
            navigate(`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/templates`);
          } else {
            apiErrorRaised(dispatchApiError, r?.response)
          }
          closeModal(modalDispatcher)
        });
      },
      onCancel: () => closeModal(modalDispatcher),
      onBackdropClick: () => closeModal(modalDispatcher),
      buttonColor: 'danger',
      buttonText: 'Delete',
      buttonIcon: 'fa-trash'
    })
  }

  return (
    <>
      {
        <Button color={'danger'} outline onClick={handleDeleteClick} className={'m-0 mt-1'}>
          <span className="fa-solid fa-trash-alt"/> Delete
        </Button>
      }
    </>
  );
}

export default TemplateDeleteButton;
