export const initialCanvasAsset = {}

export const SelectCanvasAssetReducer = (initialCanvasAsset, action) => {
  switch (action.type) {
    case "ASSET_SELECTED":
      initialCanvasAsset = action.payload;
      break
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
  return initialCanvasAsset;
};
