import {Card, Col, Container, Row} from "reactstrap";
import React from "react";
import {useTranslation} from "react-i18next";

export const LandingClients = () => {
  const {t} = useTranslation('landing')
  return (
    <>
      <div className="pt-6 pb-4">
        <Row className="justify-content-center text-center pt-5 pb-5 px-2">
          <Col>
            <h2 className={'landing_header'}>
              {t('CLIENTS')}
            </h2>
          </Col>
        </Row>
        <Container>
          <div className="padding-clientes">
            {/* First Row */}
            <div className="row">
              <div className="col d-flex flex-grow-1">
                <Card className="padding-card-clientes">
                  <img
                    alt="Logo Bricodepot"
                    className="my-auto img-fluid"
                    src={require("assets/img/landing/logos/Bricodepot.png")}
                  />
                </Card>
              </div>
              <div className="col d-flex flex-grow-1">
                <Card className="padding-card-clientes">
                  <img
                    alt="Logo Real Plaza"
                    className="my-auto img-fluid"
                    src={require("assets/img/landing/logos/Real_Plaza_logo_2019.png")}
                  />
                </Card>
              </div>
              <div className="col d-flex flex-grow-1">
                <Card className="padding-card-clientes">
                  <img
                    alt="Logo Ilerna"
                    className="my-auto img-fluid"
                    src={require("assets/img/landing/logos/ilerna.png")}
                  />
                </Card>
              </div>
              <div className="col d-flex flex-grow-1">
                <Card className="padding-card-clientes">
                  <img
                    alt="Logo Miin"
                    className="my-auto img-fluid"
                    src={require("assets/img/landing/logos/Miin_Logo.png")}
                  />
                </Card>
              </div>
              <div className="col d-flex flex-grow-1">
                <Card className="padding-card-clientes">
                  <img
                    alt="Logo Conforama"
                    className="my-auto img-fluid"
                    src={require("assets/img/landing/logos/Conforama_logo.png")}
                  />
                </Card>
              </div>

            </div>
            {/* Second Row */}
            <div className="row">
              <div className="col d-flex flex-grow-1">
                <Card className="padding-card-clientes">
                  <img
                    alt="Logo UOC"
                    className="my-auto img-fluid"
                    src={require("assets/img/landing/logos/Small_logo_UOC.png")}
                  />
                </Card>
              </div>
              <div className="col d-flex flex-grow-1">
                <Card className="padding-card-clientes">
                  <img
                    alt="Logo Etnia"
                    className="my-auto img-fluid"
                    src={require("assets/img/landing/logos/etnia_logo.png")}
                  />
                </Card>
              </div>
              <div className="col d-flex flex-grow-1">
                <Card className="padding-card-clientes">
                  <img
                    alt="Logo Aire"
                    className="my-auto img-fluid"
                    src={require("assets/img/landing/logos/aire_logo.png")}
                  />
                </Card>
              </div>
              <div className="col d-flex flex-grow-1">
                <Card className="padding-card-clientes">
                  <img
                    alt="Logo Motocard"
                    className="my-auto img-fluid"
                    src={require("assets/img/landing/logos/logo-motocard-1.png")}
                  />
                </Card>
              </div>
              <div className="col d-flex flex-grow-1">
                <Card className="padding-card-clientes">
                  <img
                    alt="Logo Dormitienda"
                    className="my-auto img-fluid"
                    src={require("assets/img/landing/logos/dormitienda.jpeg")}
                  />
                </Card>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}
