import {useCanvasDispatch, useCanvasState} from "../../../../../context/canvas/CanvasIndex";
import {updateData} from "../../../../../context/canvas/CanvasActions";
import {Input} from "reactstrap";
import React from "react";

export const CanvasObjectInfoSidebarDetailPadding = ({title, property}) => {
  const canvasState = useCanvasState()
  const canvasDispatch = useCanvasDispatch()
  const templateObject = canvasState.selectedObject

  const handlePadding = (e) => {
    const {name, value} = e.target;
    let paddingTop = templateObject?.object.data[property].top
    let paddingLeft = templateObject?.object.data[property].left
    let paddingRight = templateObject?.object.data[property].right
    let paddingBottom = templateObject?.object.data[property].bottom

    switch (name) {
      case 'padding.top':
        paddingTop = value;
        break;
      case 'padding.left':
        paddingLeft = value;
        break;
      case 'padding.right':
        paddingRight = value;
        break;
      case 'padding.bottom':
        paddingBottom = value;
        break;
      default:
    }

    updateData(
      canvasDispatch,
      templateObject?.id,
      property,
      {
        top: parseInt(paddingTop),
        left: parseInt(paddingLeft),
        right: parseInt(paddingRight),
        bottom: parseInt(paddingBottom),
      }
    )
  }

  return (
    <>
      <div className={'d-flex flex-column'}>
        <strong>{title}</strong>
        <div className={'d-flex flex-row justify-content-center'}>
          <div className={'w-25'}>
            <Input
              placeholder="X"
              type="number"
              value={templateObject?.object.data[property]?.top ?? 0}
              name="padding.top"
              onChange={handlePadding}
            />
          </div>
        </div>
        <div className={'d-flex flex-row justify-content-evenly mt-1'}>
          <div className={'w-25'}>
            <Input
              placeholder="X"
              type="number"
              value={templateObject?.object.data[property]?.left ?? 0}
              name="padding.left"
              onChange={handlePadding}
            />
          </div>
          <div className={'w-25 border border-primary border-2'}></div>
          <div className={'w-25'}>
            <Input
              placeholder="X"
              type="number"
              value={templateObject?.object.data[property]?.right ?? 0}
              name="padding.right"
              onChange={handlePadding}
            />
          </div>
        </div>
        <div className={'d-flex flex-row justify-content-center mt-1'}>
          <div className={'w-25'}>
            <Input
              placeholder="X"
              type="number"
              value={templateObject?.object.data[property]?.bottom ?? 0}
              name="padding.bottom"
              onChange={handlePadding}
            />
          </div>
        </div>
      </div>
    </>
  )
}
