import {get} from './config/base';
import {handleError, handleResponse} from "./config/response";
import {urlParser} from "./config/utils";

const url = 'companies/{companyId}/projects/{projectId}/product_diagnostics/';

export const ProductDiagnostic = {
  platform_current_status: (companyId, projectId, params = null) =>
    get(`${urlParser(url, companyId, projectId)}platform_current_status`, {params: params})
      .then(handleResponse)
      .catch(handleError),
  platform_status: (companyId, projectId, params = null) =>
    get(`${urlParser(url, companyId, projectId)}platform_status`, {params: params})
      .then(handleResponse)
      .catch(handleError),
  issue_typologies: (companyId, projectId, params = null) =>
    get(`${urlParser(url, companyId, projectId)}issue_typologies`, {params: params})
      .then(handleResponse)
      .catch(handleError),

}
