import React, {useEffect, useState} from "react";
import {DataSourceTypeItem} from "./DataSourceTypeItem";
import xmlImage from "../../../assets/img/icons/data_source_type/xml.png";
import csvImage from "../../../assets/img/icons/data_source_type/csv.png";
import tsvImage from "../../../assets/img/icons/data_source_type/tsv.png";
import spreadsheetImage from "../../../assets/img/icons/data_source_type/spreadsheet.png";

export const DataSourceFeedTypeSelector = ({typeValue, handleTypeChange}) => {
  const [selectedType, setSelectedType] = useState(typeValue)

  useEffect(() => {
    handleTypeChange(selectedType)
  }, [selectedType])

  useEffect(() => {
    if (typeValue !== selectedType) {
      setSelectedType(typeValue)
    }
  }, [typeValue])

  const types = [
    {
      'label': 'XML',
      'value': 'xml',
      'image': xmlImage,
    },
    {
      'label': 'CSV',
      'value': 'csv',
      'image': csvImage,
    },
    {
      'label': 'TSV',
      'value': 'tsv',
      'image': tsvImage,
    },
    {
      'label': 'Google Spreadsheet',
      'value': 'spreadsheet',
      'image': spreadsheetImage,
    },
  ];
  return (
    <>
      <div className={'d-flex flex-row justify-content-center'}>
        {
          types.map((type, id) => {
            return (
              <DataSourceTypeItem
                key={id}
                type={type}
                selectedType={selectedType}
                setSelectedType={setSelectedType}
              />
            )
          })
        }
      </div>
    </>
  )
}

