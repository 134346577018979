import React, {useState} from 'react'
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {addAction, deleteAction, useRuleDispatch} from "../../../../context/rule/RuleIndex";

export const FeedExportRuleActionAction = ({type, position}) => {
  const ruleDispatch = useRuleDispatch()
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleAddAction = () => {
    addAction(ruleDispatch, type)
  }

  const handleDeleteAction = () => {
    deleteAction(ruleDispatch, {
      position: position,
      type: type
    })
  }

  const handleAddElseAction = () => {
    addAction(ruleDispatch, 'else_actions')
  }

  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={'down'}>
        <DropdownToggle className='btn-icon-only btn-terciary'>
          <span className={'fa-solid fa-plus fs-3'}/>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header> Options</DropdownItem>
          <DropdownItem onClick={handleAddAction}><span className={'fa-solid fa-plus text-designer'}/> And</DropdownItem>
          {
            type !== 'else_actions' ?
              <DropdownItem onClick={handleAddElseAction}><span className={'fa-solid fa-plus text-designer'}/> Else</DropdownItem>
              : ''
          }

          {
            position !== 0 ?
              <DropdownItem onClick={handleDeleteAction}><span className={'fa-solid fa-trash-alt text-warning'}/> Delete</DropdownItem> : ''
          }
        </DropdownMenu>
      </Dropdown>
    </>
  )
}
