import React, {useReducer} from 'react';
import {initialCanvasAsset, SelectCanvasAssetReducer} from './SelectCanvasAssetReducer';

const SelectCanvasAssetStateContext = React.createContext();
const SelectCanvasAssetDispatchContext = React.createContext();

export function useSelectCanvasAssetState() {
  const context = React.useContext(SelectCanvasAssetStateContext);
  if (context === undefined) {
    throw new Error('useCanvasState must be used within a SelectCanvasAssetProvider');
  }

  return context;
}

export function useSelectCanvasAssetDispatch() {
  const context = React.useContext(SelectCanvasAssetDispatchContext);
  if (context === undefined) {
    throw new Error('useCanvasDispatch must be used within a SelectCanvasAssetProvider');
  }

  return context;
}

export const SelectCanvasAssetProvider = ({children}) => {
  const [SelectCanvasAsset, dispatch] = useReducer(SelectCanvasAssetReducer, initialCanvasAsset);

  return (
    <SelectCanvasAssetStateContext.Provider value={SelectCanvasAsset}>
      <SelectCanvasAssetDispatchContext.Provider value={dispatch}>
        {children}
      </SelectCanvasAssetDispatchContext.Provider>
    </SelectCanvasAssetStateContext.Provider>
  );
};
