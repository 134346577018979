import {useCanvasDispatch, useCanvasState} from "../../../../../context/canvas/CanvasIndex";
import {updateData} from "../../../../../context/canvas/CanvasActions";
import React from "react";
import {CanvasColorPicker} from "../../../CanvasColorPicker";

export const CanvasObjectInfoSidebarDetailColor = ({title, property}) => {
  const canvasState = useCanvasState()
  const canvasDispatch = useCanvasDispatch()
  const templateObject = canvasState.selectedObject

  const handleChangeColor = (color) => {
    updateData(
      canvasDispatch,
      templateObject?.id,
      property,
      {
        red: parseInt(color.red),
        blue: parseInt(color.blue),
        green: parseInt(color.green),
        alpha: parseInt(color.alpha),
      },
    )
  }

  return (
    <>
      <div className={'d-flex flex-column'}>
        <strong className={'mt-1'}>{title}</strong>
        <div className={'mt-1'}>
          <CanvasColorPicker
            color={templateObject?.object.data[property] ?? 0}
            onColorChange={handleChangeColor}
          />
        </div>
      </div>
    </>
  )
}
