import {updateData, useCanvasDispatch, useCanvasState} from "../../../../../context/canvas/CanvasIndex";
import React from "react";

export const CanvasObjectInfoSidebarDetailPricePosition = () => {
  const canvasState = useCanvasState()
  const canvasDispatch = useCanvasDispatch()
  const templateObject = canvasState.selectedObject

  const handleToggle = (e) => {
    const {name, checked} = e.target;
    updateData(
      canvasDispatch,
      templateObject?.id,
      name,
      checked ? 1 : 0,
    )
  }

  return (
    <>
      <div className={'d-flex flex-column'}>
        <strong className={'mt-1'}>Position</strong>
        <div className={'d-flex flex-row justify-content-evenly'}>
          <div className="d-flex flex-column">
            <label className="custom-toggle align-self-center mb-1">
              <input type="checkbox"
                     name="offerIsFirst"
                     checked={templateObject?.object.data.offerIsFirst ?? false}
                     onChange={handleToggle}
              />
              <span className="custom-toggle-slider rounded-circle"/>
            </label>
            <small>Offer Is First</small>
          </div>
          <div className="d-flex flex-column">
            <label className="custom-toggle align-self-center mb-1">
              <input type="checkbox"
                     name="oneAboveOther"
                     checked={templateObject?.object.data.oneAboveOther ?? false}
                     onChange={handleToggle}
              />
              <span className="custom-toggle-slider rounded-circle"/>
            </label>
            <small>One above other</small>
          </div>
          <div className="d-flex flex-column">
            <label className="custom-toggle align-self-center mb-1">
              <input type="checkbox"
                     name="onlyFinalPrice"
                     checked={templateObject?.object.data.onlyFinalPrice ?? false}
                     onChange={handleToggle}
              />
              <span className="custom-toggle-slider rounded-circle"/>
            </label>
            <small>Only final price</small>
          </div>
        </div>
      </div>
    </>
  )
}
