import IndexNavbar from "../Navbars/IndexNavbar";
import IndexHeader from "../Headers/IndexHeader";
import IndexFooter from "../Footers/IndexFooter";
import React from "react";
import {LandingFunctionalities} from "./LandingFunctionalities";
import {LandingIntegrations} from "./LandingIntegrations";
import {LandingWhyFeedest} from "./LandingWhyFeedest";
import {LandingClients} from "./LandingClients";
import {LandingTestimonials} from "./LandingTestimonials";
import {LandingForm} from "./LandingForm";
import {LandingCyberclick} from "./LandingCyberclick";

export const Landing = () => {
  return (
    <>
      <IndexNavbar logo={{
        innerLink: `/`, imgSrc: "assets/img/brand/feedest_purple.svg", imgAlt: "logo",
      }}/>
      <div className="main-content landing-page">
        <IndexHeader/>
        <section className={'bg-white'}>
          <LandingFunctionalities/>
          <LandingIntegrations/>
          <LandingWhyFeedest/>
          <LandingClients/>
          <LandingTestimonials/>
          <LandingForm/>
          <LandingCyberclick/>
        </section>
      </div>
      <IndexFooter/>
    </>
  )
}
