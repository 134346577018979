import React, {useReducer} from 'react';
import {AnalyticsReducer, initialState} from "./AnalyticsReducer";
import {useSearchParams} from "react-router-dom";
import {getMetricsObjects, isoStringToDate} from "../../components/Analytics/AnalyticsUtils";

const AnalyticsStateContext = React.createContext();
const AnalyticsDispatchContext = React.createContext();

export function useAnalyticsState() {
  const context = React.useContext(AnalyticsStateContext);
  if (context === undefined) {
    throw new Error('useAnalyticsState must be used within a AnalyticsProvider');
  }

  return context;
}

export function useAnalyticsDispatch() {
  const context = React.useContext(AnalyticsDispatchContext);
  if (context === undefined) {
    throw new Error('useAnalyticsDispatch must be used within a AnalyticsProvider');
  }

  return context;
}

export const AnalyticsProvider = ({children}) => {
  const [searchParams] = useSearchParams()
  let reducerState = initialState
  if (searchParams.size > 0) {
    const startDateComp =  searchParams.get('start_date_comp')
    const endDateComp = searchParams.get('end_date_comp')
    const metrics = searchParams.getAll('metrics[]')
    reducerState = {
      dates: {
        start_date: isoStringToDate(searchParams.get('start_date')) || reducerState.dates.start_date,
        end_date: isoStringToDate(searchParams.get('end_date')) || reducerState.dates.end_date,
      },
      compare_dates: startDateComp && endDateComp ? {
        start_date: isoStringToDate(searchParams.get('start_date_comp')),
        end_date: isoStringToDate(searchParams.get('end_date_comp')),
      } : null,
      filters: [],
      metrics: metrics ?
        getMetricsObjects(metrics) :
        [{value: 'products', label: 'Products', type: 'number'}, {value: 'products', label: 'Products', type: 'number'}],
      dimensions: searchParams.getAll('dimensions[]') || [],
      selected_rows: [],
    };
  }

  const [AnalyticsFilters, dispatch] = useReducer(AnalyticsReducer, reducerState);

  return (
    <AnalyticsStateContext.Provider value={AnalyticsFilters}>
      <AnalyticsDispatchContext.Provider value={dispatch}>
        {children}
      </AnalyticsDispatchContext.Provider>
    </AnalyticsStateContext.Provider>
  );
};
