import {useProjectState} from "../../../context/project/ProjectContext";
import {useNavigate} from "react-router";
import React, {useEffect, useState} from "react";
import {useApiErrorDispatch} from "../../../context/api_error/ApiErrorContext";
import {apiErrorRaised} from "../../../context/api_error/ApiErrorActions";
import {Link} from "react-router-dom";
import {Button, Card, CardBody, CardHeader, Table} from "reactstrap";
import {DataSources} from "../../../services/api/DataSources";
import {DataSourceStepButtonGroup} from "../DataSourceStepButtonGroup/DataSourceStepButtonGroup";
import {DataSourceMappingItem} from "./DataSourceMappingItem";

export const DataSourceMappingList = ({id}) => {
  const projectState = useProjectState()
  const navigate = useNavigate()
  const [dataSource, setDataSource] = useState({})
  const [isLoading, setLoading] = useState(false)
  const apiErrorDispatch = useApiErrorDispatch()
  const [mapping, setMapping] = useState([])

  const [destinations, setDestinations] = useState([
    {value: 'sku', label: 'Sku', multiple: false},
    {value: 'title', label: 'Title', multiple: false},
    {value: 'description', label: 'Description', multiple: false},
    {value: 'link', label: 'Link', multiple: false},
    {value: 'images', label: 'Images', multiple: false},
    {value: 'availability', label: 'Availability', multiple: false},
    {value: 'price', label: 'Price', multiple: false},
    {value: 'sale_price', label: 'Sale price', multiple: false},
    {value: 'discount', label: 'Discount', multiple: false},
    {value: 'product_category', label: 'Product category', multiple: false},
    {value: 'product_type', label: 'Product type', multiple: false},
    {value: 'brand', label: 'Brand', multiple: false},
    {value: 'gtin', label: 'Gtin', multiple: false},
    {value: 'additional_images', label: 'Additional Images', multiple: false},
    {value: 'condition', label: 'Condition', multiple: false},
    {value: 'shipping', label: 'Shipping', multiple: false},
    {value: 'status', label: 'Status', multiple: false},
    {value: 'weight', label: 'Weight', multiple: false},
    {value: 'gender', label: 'Gender', multiple: false},
    {value: 'color', label: 'Color', multiple: false},
    {value: 'item_group_id', label: 'Item group ID', multiple: false},
    {value: 'availability_date', label: 'Availability Date', multiple: false},
    {value: 'mpn', label: 'MPN', multiple: false},
    {value: 'material', label: 'Material', multiple: false},
    {value: 'size', label: 'Size', multiple: false},
    {value: 'identifier_exists', label: 'Identifier Exists', multiple: false},
    {value: 'energy_efficiency_class', label: 'Energy Efficiency Class', multiple: false},
    {value: 'properties', label: 'Custom Property', multiple: true},
    {value: 'videos', label: 'Videos', multiple: false},
  ])

  useEffect(() => {
    DataSources.get(projectState.companyId, projectState.projectId, id).then((r) => {
      if (r?.status < 400) {
        setDataSource(r.data)
        setMapping([
          ...r.data.mapping.map(property => ({...property, editable: false})),
          ...r.data.dynamicProperties.map(property => ({
            origin: property,
            destination: 'properties',
            editable: false
          }))
        ])
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    });
  }, [id])

  useEffect(() => {
    if (Object.keys(dataSource).length === 0 && dataSource.constructor === Object) {
      return
    }
    setLoading(true)
    DataSources.getFields(projectState.companyId, projectState.projectId, id).then((r) => {
      const newFields = []
      if (r?.status < 400) {
        r.data.forEach(field => {
          let map = mapping.find(map => map.origin === field)
          if (map === undefined) {
            newFields.push({origin: field, destionation: ''})
          }
        })
        setLoading(false);
        setMapping([
          ...mapping,
          ...newFields
        ])
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    });
  }, [dataSource.id])

  const handleChangeMappingItem = (mappingItem, index) => {
    if (index > -1) {
      mapping[index] = mappingItem
      const nextProperties = [
        ...mapping.slice(0, index),
        mappingItem,
        ...mapping.slice(index + 1)
      ];
      setMapping(nextProperties);
    }
  }

  const handleNewField = () => {
    setMapping([...mapping, {
      origin: '',
      destination: '',
      editable: true
    }])
  }

  const handleClick = () => {
    const importProperties = []
    for (let [key, value] of Object.entries(dataSource.importProperties)) {
      importProperties.push({name: key, value: value})
    }
    let data = {
      name: dataSource.name,
      url: dataSource.url,
      mapping: (mapping.filter(map => map.destination !== 'properties' && map.destination !== undefined && map.destination !== '')).map(map => ({
        origin: map.origin,
        destination: map.destination
      })),
      dynamicProperties: (mapping.filter(map => map.destination === 'properties')).map(map => map.origin),
      type: dataSource.type,
      order: dataSource.order,
      locale: dataSource.locale,
      status: dataSource.status ? 1 : 0,
      combineField: dataSource.combineField,
      importProperties: importProperties,
      data: dataSource.data
    }

    setLoading(true);
    DataSources.put(projectState.companyId, projectState.projectId, id, data).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        navigate(`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/data_sources/${id}/details`)
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    });
  }

  return (
    <>
      <div className={'d-flex flex-column'}>
        <div className={'d-flex flex-row justify-content-between'}>
          <div className={'d-flex flex-row'}>
            <Link
              to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/data_sources/`}>
              <Button className={'btn-icon-only text-dark'}>
                <span className={'fa fa-arrow-left'}/>
              </Button>
            </Link>
            <h1 className={'ms-4'}>{dataSource.name}: Mapping</h1>
          </div>
          <DataSourceStepButtonGroup id={id} step={'mapping'}/>
        </div>
        <div className={'d-flex flex-row mt-4'}>
          <Card className={'flex-grow-1'}>
            <CardHeader>
              <h3>Mapping</h3>
            </CardHeader>
            <CardBody className={'d-flex flex-column'}>
              {
                !isLoading ? <>
                    <Table className="align-items-center table-flush" responsive>
                      <thead className="thead-light">
                      <tr>
                        <th scope="col">Data Source Origin</th>
                        <th scope="col">Feedest Property</th>
                      </tr>
                      </thead>
                      <tbody className={'list'}>
                      {
                        mapping?.map((property, i) => {
                          return (
                            <DataSourceMappingItem
                              key={i}
                              property={property}
                              availableProperties={destinations}
                              handleChangeMappingItem={handleChangeMappingItem}
                              index={i}
                            />
                          )
                        })
                      }
                      <tr>
                        <td>
                          <Button color={'primary'} className={'btn-icon-only'} onClick={handleNewField}>
                            <span className={'fa fa-solid fa-plus'}/>
                          </Button>
                        </td>
                      </tr>
                      </tbody>
                    </Table>
                  </> :
                  <span className="spinner-border spinner-border-lg me-1"/>
              }
            </CardBody>
          </Card>
        </div>
        <div className={'d-flex flew-row justify-content-between'}>
          <Link
            to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/data_sources/${id}/edit`}>
            <Button color={'secondary'}>Back</Button>
          </Link>
          <Button color={'primary'} onClick={handleClick}>
            Next
          </Button>
        </div>
      </div>
    </>
  )
}
