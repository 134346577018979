import {useProjectState} from "../../context/project/ProjectContext";
import React, {useState} from "react";
import {Card} from "reactstrap";
import {EventsManagerTable} from "./EventsManagerTable/EventsManagerTable";
export const EventsManagerDashboard = () => {
  const projectState = useProjectState()
  const [isLoading, setLoading] = useState(false)

  return (
    <>
      <div className={'d-flex flex-column'}>
        <div className={'view_header d-flex flex-row mb-3'}>
          <div className={'title'}>
            <h1>Events Manager</h1>
          </div>

        </div>
        {
          projectState.features?.data_history ? <>
            <div>
              <EventsManagerTable isLoading={isLoading} setLoading={setLoading}/>
            </div>
          </> : <>
            <Card className={'p-5'}>
              <h1>This company has no access to this feature</h1>
            </Card>
          </>
        }
      </div>
    </>
  )
}
