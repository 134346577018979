import React from "react";
import {Card, CardBody, CardFooter, CardImg} from "reactstrap";
import {
  useSelectCanvasAssetDispatch,
  useSelectCanvasAssetState
} from "../../../context/canvas/selectCanvasAsset/SelectCanvasAssetContext";
import {selectCanvasAsset} from "../../../context/canvas/selectCanvasAsset/SelectCanvasAssetIndex";
import fallbackImg from "../../../assets/img/asset_placeholder.png";

export const AssetModalSelectorElement = ({asset}) => {

  const selectCanvasAssetDispatch = useSelectCanvasAssetDispatch();
  const selectedCanvasAsset = useSelectCanvasAssetState();

  const handleClick = () => {
    selectCanvasAsset(selectCanvasAssetDispatch, asset.id)
  }

  return (
    <>
      <Card className={selectedCanvasAsset === asset.id ? "asset selected" : ""} onClick={handleClick}>
        <CardBody className='p-0 d-flex'>
          {
            asset.type === "image" ?
              <CardImg className="rounded-0 card-image-fit" src={asset.externalUrl}
                       onError={({ currentTarget }) => {
                         currentTarget.onerror = null;
                         currentTarget.src=fallbackImg;
                         currentTarget.classList.remove('card-image-fit')
                       }}
              /> :
              <span className="ni ni-5x ni-caps-small asset-font" />
          }
        </CardBody>
        <CardFooter className={'d-flex flex-row w-100 justify-content-between'}>
            {asset.name}
        </CardFooter>
        </Card>
    </>
  );
};
