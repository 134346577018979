import {Card, CardBody, CardFooter, CardImg} from "reactstrap";
import {Link} from "react-router-dom";
import React from "react";

export const  PlaceholderAssetsGrid = ({rows = 2, columns = 5}) => {
  const renderItems = () => {
    const gridRows = [];
    for (let i = 0; i < rows; i++) {
      const gridColumns = [];
      for (let j = 0; j < columns; j++) {
        gridColumns.push(
          <Card key={`grid-${i}-${j}`} className={'p-2'}>
            <CardBody className='p-0 d-flex'>
                  <CardImg className="rounded-0" src={require('assets/img/asset_placeholder.png')}/>

            </CardBody>
            <CardFooter className={'d-flex flex-row w-100 justify-content-between'}>
              <Link className={'mw-100'} target={"_blank"}
                    to='Asset Name'>
                Asset Name
              </Link>
            </CardFooter>
          </Card>
        );
      }
      gridRows.push(
        <div className={'d-flex flex-row justify-content-center'} key={`row-${i}`}>{gridColumns}</div>
      );
    }
    return gridRows;
  };
  return (
    <>
      <div className={'position-relative'}>
        {
          renderItems()
        }
        <div className={'position-absolute w-100 h-100 top-0 bg-blur d-flex justify-content-center align-items-center'}>
          <span className="spinner-border spinner-border-lg"/>
        </div>
      </div>
    </>
  )
}
