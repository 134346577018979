import React, {useEffect, useState} from 'react';
import {Rnd} from "react-rnd";
import {addFont, resizeObject, selectCanvasObject} from "../../../context/canvas/CanvasActions";
import {useCanvasDispatch, useCanvasState} from "../../../context/canvas/CanvasIndex";
import classNames from "classnames";
import {ConvertRGBtoHex} from "../CanvasUtils";
import {useProjectState} from "../../../context/project/ProjectIndex";
import {apiErrorRaised, useApiErrorDispatch} from "../../../context/api_error/ApiErrorIndex";
import {Assets} from "../../../services/api/Assets";

export const CanvasText = ({position}) => {
  const projectState = useProjectState()
  const apiErrorDispatch = useApiErrorDispatch()
  const canvasDispatch = useCanvasDispatch();
  const canvasState = useCanvasState()
  const templateObject = canvasState.objects[position];
  const [font, setFont] = useState('')

  useEffect(() => {
    if (templateObject.object?.data?.font === null || templateObject.object?.data?.font === undefined) {
      return;
    }
    Assets.get(projectState.companyId, projectState.projectId, templateObject.object.data.font).then((r) => {
      if (r?.status < 400) {
        setFont(r.data.id)
        addFont(r.data.id, r.data.external_url);
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    });
  }, [templateObject.object.data.font])

  const onDragStop = (e, d) => {
    resizeObject(
      canvasDispatch,
      templateObject.id,
      parseInt(d.x),
      parseInt(d.y),
      parseInt(templateObject.object.data.size.x),
      parseInt(templateObject.object.data.size.y),
    )
  }

  const renderText = () => {
    let text = templateObject.object.data.content?.textContent?.value
    const matches = templateObject.object.data.content?.textContent?.value.match(/{{(.*?)}}/g);
    if (matches) {
      for (const match of matches) {
        let element = match.replace(/[{}]/g, '');
        element = element.replace('properties.', '');
        if (canvasState.product.hasOwnProperty(element)) {
          if (typeof canvasState.product[element] === 'object') {
            text = text.replace(match, templateObject.object.data.content?.textContent?.value)
          }
          text = text.replace(match, canvasState.product[element])
        } else if (canvasState.product?.properties?.hasOwnProperty(element)) {
          text = text.replace(match, canvasState.product.properties[element])
        }
      }
    }
    return text
  }

  const onResizeStop = (e, direction, ref, delta, pos) => {
    resizeObject(
      canvasDispatch,
      templateObject.id,
      parseInt(pos.x),
      parseInt(pos.y),
      parseInt(ref.offsetWidth),
      parseInt(ref.offsetHeight),
    )
  }

  const handleClick = () => {
    selectCanvasObject(canvasDispatch, templateObject.id);
  }

  return (
    <>
      <Rnd className={classNames({
        canvas_object: true,
        hide: !templateObject.visible,
        selected: templateObject.selected,
        'd-flex': true,
        'justify-content-start': templateObject.object.data.align?.horizontal === 'left',
        'justify-content-center': templateObject.object.data.align?.horizontal === 'center',
        'justify-content-end': templateObject.object.data.align?.horizontal === 'right',
        'align-items-start': templateObject.object.data.align?.vertical === 'top',
        'align-items-center': templateObject.object.data.align?.vertical === 'center',
        'align-items-end': templateObject.object.data.align?.vertical === 'bottom',
        canvas_object_hover: !templateObject.selected && templateObject?.errors?.length === 0,
        error: templateObject?.errors?.length > 0
      })}
           bounds="#canvas"
           style={{
             zIndex: templateObject.index,
             backgroundColor: ConvertRGBtoHex(
               templateObject.object.data.backgroundColor?.red ?? 255,
               templateObject.object.data.backgroundColor?.green ?? 255,
               templateObject.object.data.backgroundColor?.blue ?? 255,
               templateObject.object.data.backgroundColor?.alpha ?? 127,
             ),
             paddingTop: templateObject.object.data.margin?.top + 'px',
             paddingBottom: templateObject.object.data.margin?.bottom + 'px',
             paddingLeft: templateObject.object.data.margin?.left + 'px',
             paddingRight: templateObject.object.data.margin?.right + 'px',
             cursor: 'pointer',
           }}
           position={{
             x: templateObject.object.data.origin.x,
             y: templateObject.object.data.origin.y,
           }}
           size={{
             width: templateObject.object.data.size.x,
             height: templateObject.object.data.size.y,
           }}
           onDragStop={onDragStop}
           onResizeStop={onResizeStop}
           onClick={handleClick}
           onResizeStart={handleClick}
           onDragStart={handleClick}
           enableResizing={templateObject.selected}
           resizeHandleClasses={{
             topLeft: templateObject.selected ? 'resize-handle' : '',
             topRight: templateObject.selected ? 'resize-handle' : '',
             bottomLeft: templateObject.selected ? 'resize-handle' : '',
             bottomRight: templateObject.selected ? 'resize-handle' : '',
           }}
           dragGrid={canvasState.stickToGrid === true ? [16, 16] : [1, 1]}
           resizeGrid={canvasState.stickToGrid === true ? [16, 16] : [1, 1]}
      >
        <p
          style={{
            color: ConvertRGBtoHex(
              templateObject.object.data.color?.red ?? 255,
              templateObject.object.data.color?.green ?? 255,
              templateObject.object.data.color?.blue ?? 255,
              templateObject.object.data.color?.alpha ?? 127,
            ),
            fontFamily: "'" + font + "'",
            fontSize: templateObject.object.data.fontSize + "px",
            backgroundColor: ConvertRGBtoHex(
              templateObject.object.data.innerBackgroundColor?.red ?? 255,
              templateObject.object.data.innerBackgroundColor?.green ?? 255,
              templateObject.object.data.innerBackgroundColor?.blue ?? 255,
              templateObject.object.data.innerBackgroundColor?.alpha ?? 127,
            ),
            paddingTop: templateObject.object.data.padding?.top + 'px',
            paddingBottom: templateObject.object.data.padding?.bottom + 'px',
            paddingLeft: templateObject.object.data.padding?.left + 'px',
            paddingRight: templateObject.object.data.padding?.right + 'px',
            textAlign: templateObject.object.data.align?.horizontal,
            marginBottom: '0px',
            lineHeight: 1,
          }}
        >{renderText()}</p>
      </Rnd>
    </>
  )
}
