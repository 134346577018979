export const objectUpdateConditions = (canvas, id, data) => {
  let selectedObject = canvas.objects.find(canvasObject => canvasObject.object.id === id);
  selectedObject = {
    ...selectedObject,
    object: {
      ...selectedObject.object,
      conditions: data
    }
  }

  return {
    ...canvas,
    selectedObject: selectedObject,
    objects: canvas.objects.map((canvasObject) => {
      if (canvasObject.object.id !== id) {
        return canvasObject;
      }

      return selectedObject;
    })
  }
};
