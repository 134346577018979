import {Card, Col, Row} from "reactstrap";
import React from "react";
import {useTranslation} from "react-i18next";

export const LandingCyberclick = () => {
  const {t} = useTranslation('landing')
  return (
    <>
      <div className="pt-6 pb-6">
        <Row className="justify-content-center text-center pt-5 pb-5 px-2">
          <Col>
            <h2 className={'landing_header'}>
              {t('CYBERCLICK_GUARANTOR')}
              <img
                alt="..."
                width="250px"
                className="img-fluid px-3 logo-cyber"
                src={require("assets/img/landing/logo_cyberclick.png")}
              />
            </h2>
          </Col>
        </Row>
        <div className={'d-flex justify-content-center cyberclick_description'}>
          <div className="me-xl-5">
            <div className={'d-flex text-primary'}>
              <Card
                className={'d-flex flex-column align-items-center cyberclick_skill justify-content-evenly m-2 bg-white'}>
                <span className={'fa fa-bullhorn fa-2x'}/>
                <b className={'text-purple-header text-center'}>{t('CYBERCLICK_SKILL_1')}</b>
              </Card>
              <Card className={'d-flex flex-column align-items-center cyberclick_skill justify-content-evenly m-2'}>
                <span className={'fa fa-search fa-2x'}/>
                <b className={'text-purple-header text-center'}>{t('CYBERCLICK_SKILL_2')}</b>
              </Card>
              <Card className={'d-flex flex-column align-items-center cyberclick_skill justify-content-evenly m-2'}>
                <span className={'fa fa-desktop fa-2x'}/>
                <b className={'text-purple-header text-center'}>{t('CYBERCLICK_SKILL_3')}</b>
              </Card>
            </div>
            <div className={'d-flex text-primary'}>
              <Card className={'d-flex flex-column align-items-center cyberclick_skill justify-content-evenly m-2'}>
                <span className={'fa fa-robot fa-2x'}/>
                <b className={'text-purple-header text-center'}>{t('CYBERCLICK_SKILL_4')}</b>
              </Card>
              <Card className={'d-flex flex-column align-items-center cyberclick_skill justify-content-evenly m-2'}>
                <span className={'fa fa-chart-simple fa-2x'}/>
                <b className={'text-purple-header text-center'}>{t('CYBERCLICK_SKILL_5')}</b>
              </Card>
              <Card className={'d-flex flex-column align-items-center cyberclick_skill justify-content-evenly m-2'}>
                <span className={'fa fa-comment-dollar fa-2x'}/>
                <b className={'text-purple-header text-center'}>{t('CYBERCLICK_SKILL_6')}</b>
              </Card>
            </div>
          </div>
          <div className="m-3 cyberclick_description_landing">
            <p style={{textAlign: "justify"}}>{t('CYBERCLICK_GUARANTOR_DESCRIPTION_1')}
              <strong>{t('CYBERCLICK_GUARANTOR_DESCRIPTION_2')}</strong>
              {t('CYBERCLICK_GUARANTOR_DESCRIPTION_3')}
              <strong>{t('CYBERCLICK_GUARANTOR_DESCRIPTION_4')}</strong>.
            </p>
            <p style={{textAlign: "justify"}}>{t('CYBERCLICK_GUARANTOR_DESCRIPTION_5')}</p>
            <div className={'d-flex'}>
              <div className="d-flex justify-content-start p-1">
                <span className="fa-regular fa-circle-check fa-lg text-primary pe-1 pt-xl-1"></span>
                <p className="p-0"><strong>{t('CYBERCLICK_GUARANTOR_ITEM_1')}</strong></p>
              </div>
              <div className="d-flex justify-content-start p-1">
                <span className="fa-regular fa-circle-check fa-lg text-primary pe-1 pt-xl-1"></span>
                <p className="p-0"><strong>{t('CYBERCLICK_GUARANTOR_ITEM_2')}</strong></p>
              </div>
              <div className="d-flex justify-content-start p-1">
                <span className="fa-regular fa-circle-check fa-lg text-primary pe-1 pt-xl-1"></span>
                <p className="p-0"><strong>{t('CYBERCLICK_GUARANTOR_ITEM_3')}</strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
