const awinProperties = [
  {destination: 'image_url', origin: 'image', isRequired: true},
  {destination: 'deep_link', origin: 'link', isRequired: true},
  {destination: 'product_id', origin: 'sku', isRequired: true},
  {destination: 'product_name', origin: 'title', isRequired: true},
  {destination: 'price', origin: 'price', isRequired: true},
  {destination: 'alternate_image', origin: '', isRequired: false},
  {destination: 'alternate_image_four', origin: '', isRequired: false},
  {destination: 'alternate_image_three', origin: '', isRequired: false},
  {destination: 'alternate_image_two', origin: '', isRequired: false},
  {destination: 'average_rating', origin: '', isRequired: false},
  {destination: 'base_price', origin: '', isRequired: false},
  {destination: 'base_price_amount', origin: '', isRequired: false},
  {destination: 'base_price_text', origin: '', isRequired: false},
  {destination: 'basket_link', origin: '', isRequired: false},
  {destination: 'brand_name', origin: 'brand', isRequired: false},
  {destination: 'colour', origin: 'color', isRequired: false},
  {destination: 'commission_group', origin: '', isRequired: false},
  {destination: 'condition', origin: 'condition', isRequired: false},
  {destination: 'currency', origin: '', isRequired: false},
  {destination: 'custom1', origin: '', isRequired: false},
  {destination: 'custom2', origin: '', isRequired: false},
  {destination: 'custom3', origin: '', isRequired: false},
  {destination: 'custom4', origin: '', isRequired: false},
  {destination: 'custom5', origin: '', isRequired: false},
  {destination: 'custom6', origin: '', isRequired: false},
  {destination: 'custom7', origin: '', isRequired: false},
  {destination: 'custom8', origin: '', isRequired: false},
  {destination: 'custom9', origin: '', isRequired: false},
  {destination: 'shipping', origin: 'shipping', isRequired: false},
  {destination: 'delivery_cost', origin: '', isRequired: false},
  {destination: 'delivery_restrictions', origin: '', isRequired: false},
  {destination: 'delivery_time', origin: '', isRequired: false},
  {destination: 'delviery_weight', origin: '', isRequired: false},
  {destination: 'description', origin: 'description', isRequired: false},
  {destination: 'dimensions', origin: '', isRequired: false},
  {destination: 'ean', origin: '', isRequired: false},
  {destination: 'in_stock', origin: '', isRequired: false},
  {destination: 'is_for_sale', origin: '', isRequired: false},
  {destination: 'isbn', origin: '', isRequired: false},
  {destination: 'keywords', origin: '', isRequired: false},
  {destination: 'language', origin: '', isRequired: false},
  {destination: 'large_image', origin: '', isRequired: false},
  {destination: 'last_updated', origin: '', isRequired: false},
  {destination: 'merchant_category', origin: '', isRequired: false},
  {destination: 'merchant_thumb', origin: '', isRequired: false},
  {destination: 'model_number', origin: '', isRequired: false},
  {destination: 'mpn', origin: 'mpn', isRequired: false},
  {destination: 'availability', origin: 'availability', isRequired: false},
  {destination: 'number_available', origin: '', isRequired: false},
  {destination: 'parent_product_id', origin: '', isRequired: false},
  {destination: 'pre_order', origin: '', isRequired: false},
  {destination: 'product_GTIN', origin: 'gtin', isRequired: false},
  {destination: 'product_model', origin: '', isRequired: false},
  {destination: 'product_price_old', origin: '', isRequired: false},
  {destination: 'product_short_description', origin: '', isRequired: false},
  {destination: 'product_type', origin: 'type', isRequired: false},
  {destination: 'promotional_text', origin: '', isRequired: false},
  {destination: 'rating', origin: '', isRequired: false},
  {destination: 'reviews', origin: '', isRequired: false},
  {destination: 'rrp_price', origin: '', isRequired: false},
  {destination: 'saving', origin: '', isRequired: false},
  {destination: 'savings_percent', origin: '', isRequired: false},
  {destination: 'size_stock_amount', origin: '', isRequired: false},
  {destination: 'size_stock_status', origin: '', isRequired: false},
  {destination: 'specifications', origin: '', isRequired: false},
  {destination: 'stock_quantity', origin: '', isRequired: false},
  {destination: 'stock_status', origin: '', isRequired: false},
  {destination: 'store_price', origin: '', isRequired: false},
  {destination: 'terms_of_contract', origin: '', isRequired: false},
  {destination: 'upc', origin: '', isRequired: false},
  {destination: 'valid_from', origin: '', isRequired: false},
  {destination: 'valid_to', origin: '', isRequired: false},
  {destination: 'warranty', origin: '', isRequired: false},
  {destination: 'web_offer', origin: '', isRequired: false},
  {destination: 'energy_label', origin: 'energyEfficiencyClass', isRequired: false},
  {destination: 'energy_label_link', origin: '', isRequired: false},
  {destination: 'energy_label_logo', origin: '', isRequired: false},
  {destination: 'google_taxonomy', origin: '', isRequired: false},
]


export default awinProperties;
