import React from 'react';
import {Card, CardBody, CardHeader} from "reactstrap";
import FeedExportDetail from "./FeedExportDetail";
import {ProjectDetailProductsChart} from "./ProjectDetailProductsChart";
import {AnalyticsProvider} from "../../../context/analytics/AnalyticsContext";
import {TemplatesInfo} from "./TemplatesInfo";
import ProjectDetailInfo from "./ProjectDetailInfo";

const ProjectDetail = ({companyId, projectId}) => {
  return (
    <>
      <div className={'ms-4 mt-4'}>
        <h1 className="mb-3">Dashboard</h1>
        <Card>
          <CardHeader></CardHeader>
          <CardBody className={'d-flex flex-column'}>
            <ProjectDetailInfo
              companyId={companyId}
              projectId={projectId}
            />
            <TemplatesInfo
              showPagination={false}
            />

            <div className={'d-flex flex-row'}>
              <div className={'flex-basis-50'}>
                <FeedExportDetail
                  companyId={companyId}
                  projectId={projectId}
                />
              </div>
              <div className={'flex-basis-50 ms-2'}>
                <AnalyticsProvider>
                  <ProjectDetailProductsChart/>
                </AnalyticsProvider>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default ProjectDetail;
