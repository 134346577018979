import {get, post, put} from './config/base';
import {handleError, handleResponse} from "./config/response";
import {urlParser} from "./config/utils";

const url = 'companies/{companyId}/projects/{projectId}/feed_exports/';

export const FeedExports = {
  index: (companyId, projectId, params = null) =>
    get(urlParser(url, companyId, projectId), {params: params})
      .then(handleResponse)
      .catch(handleError),
  requestExports: (companyId, projectId) =>
    post(`${urlParser(url, companyId, projectId)}request_exports`)
      .then(handleResponse)
      .catch(handleError),
  get: (companyId, projectId, id) =>
    get(`${urlParser(url, companyId, projectId)}${id}`)
      .then(handleResponse)
      .catch(handleError),
  put: (companyId, projectId, id, payload) =>
    put(`${urlParser(url, companyId, projectId)}${id}`, payload)
      .then(handleResponse)
      .catch(handleError),
  update: (companyId, projectId, id, payload) =>
    put(`${urlParser(url, companyId, projectId)}${id}/update`, payload)
      .then(handleResponse)
      .catch(handleError),
  activate: (companyId, projectId, id) =>
    post(`${urlParser(url, companyId, projectId)}${id}/activate`)
      .then(handleResponse)
      .catch(handleError),
  disable: (companyId, projectId, id) =>
    post(`${urlParser(url, companyId, projectId)}${id}/disable`)
      .then(handleResponse)
      .catch(handleError),
  delete: (companyId, projectId, id) =>
    post(`${urlParser(url, companyId, projectId)}${id}/delete`)
      .then(handleResponse)
      .catch(handleError),
  requestExport: (companyId, projectId, id) =>
    post(`${urlParser(url, companyId, projectId)}${id}/request_export`)
      .then(handleResponse)
      .catch(handleError),
  getDiagnosticData: (companyId, projectId, id) =>
    get(`${urlParser(url, companyId, projectId)}${id}/diagnostic`)
      .then(handleResponse)
      .catch(handleError),
  putDiagnosticData: (companyId, projectId, id, payload) =>
    put(`${urlParser(url, companyId, projectId)}${id}/diagnostic`, payload)
      .then(handleResponse)
      .catch(handleError),
};
