import React, {useCallback, useEffect, useState} from "react";
import {Button, Card, CardBody, CardHeader} from "reactstrap";
import {FeedExportName} from "./FeedExportName";
import {v4 as uuid4} from "uuid";
import {useProjectState} from "../../../context/project/ProjectIndex";
import handlePlatformMapping from "../FeedExportPlatform/FeedExportPlatformProperties/FeedExportPropertiesMapping";
import {FeedExports} from "../../../services/api/FeedExports";
import {apiErrorRaised} from "../../../context/api_error/ApiErrorActions";
import {useApiErrorDispatch} from "../../../context/api_error/ApiErrorIndex";
import {FeedExportStepButtonGroup} from "../FeedExportStepButtonGroup/FeedExportStepButtonGroup";
import {FeedExportPlatformBox} from "./FeedExportPlatformBox";
import {Link, useNavigate} from "react-router-dom";
import {SharedRuleGroupsSelect} from "../FeedExportSettings/SharedRuleGroupsSelect";
import {RuleGroups} from "../../../services/api/RuleGroups";

export const FeedExportNew = () => {
    const navigate = useNavigate()
    const projectState = useProjectState()
    const apiErrorDispatch = useApiErrorDispatch()
    const [platform, setPlatform] = useState()
    const [name, setName] = useState()
    const [submitted, setSubmitted] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [firstSharedRule, setFirstSharedRule] = useState(null)
    const [secondSharedRule, setSecondSharedRule] = useState(null)
    const [ruleGroups, setRuleGroups] = useState([])

    const handleClick = () => {
        setSubmitted(true)
        if (name !== undefined && name !== '' && platform !== undefined) {
            let sharedRuleGroups = []
            if (firstSharedRule !== null) {
                sharedRuleGroups.push({rule_group_id: firstSharedRule, order: 1})
                if (secondSharedRule !== null) {
                    sharedRuleGroups.push({rule_group_id: secondSharedRule, order: 2})
                }
            }
            let data = {
                id: uuid4(),
                name: name,
                status: 0,
                filename: uuid4(),
                type: platform === 'googleLocalInventories' ? 'inventories' : 'data',
                format: 'csv',
                mapping: {
                    mapping: handlePlatformMapping(platform).filter(map => map.origin !== ''),
                    platform: platform
                },
                schedule: {
                    hour: new Date().getHours(),
                    minute: new Date().getMinutes(),
                    interval: 'DAILY',
                    interval_count: '1',
                    timezone: projectState.timezone,
                },
                locale: projectState.locale,
                url_parameters: [],
                rule_group_id: uuid4(),
                shared_rule_groups: sharedRuleGroups
            }
            let feedExportId = uuid4()
            FeedExports.put(projectState.companyId, projectState.projectId, feedExportId, data).then((r) => {
                setLoading(false);
                if (r?.status < 400) {
                    navigate(`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/${feedExportId}/mapping`)
                } else {
                    apiErrorRaised(apiErrorDispatch, r?.response)
                }
            });
        }
    }

    useEffect(() => {
        RuleGroups.index(projectState.companyId, projectState.projectId).then((r) => {
            setLoading(false);
            if (r?.status < 400) {
                if (r.data.length === 0) {
                    return
                }
                setRuleGroups([{'label': '-', 'value': null}].concat(r.data.rule_groups.map((ruleGroup) => ({
                    'label': ruleGroup.name,
                    'value': ruleGroup.id
                }))))
            } else {
                apiErrorRaised(apiErrorDispatch, r?.response)
            }
        })
    }, [])


    const renderFirstSharedRule = useCallback(() => {
        if (firstSharedRule === undefined) {
            return <></>
        }
        return <SharedRuleGroupsSelect
            ruleGroups={ruleGroups}
            defaultRuleGroup={firstSharedRule}
            parentCallback={handleChangeSharedRuleGroup}
            label={'first'}
            disabled={false}
        />
    }, [firstSharedRule, ruleGroups])

    const renderSecondSharedRule = useCallback(() => {
        if (secondSharedRule === undefined) {
            return <></>
        }
        return <SharedRuleGroupsSelect
            ruleGroups={ruleGroups.filter((ruleGroup) => ruleGroup.value !== firstSharedRule || ruleGroup.value === null)}
            defaultRuleGroup={secondSharedRule}
            label={'second'}
            parentCallback={handleChangeSharedRuleGroup}
            disabled={firstSharedRule === null}
        />
    }, [firstSharedRule, secondSharedRule, ruleGroups])

    const handleChangeSharedRuleGroup = useCallback((label, sharedRule) => {
        if (label === 'first') {
            setFirstSharedRule(sharedRule);
        }
        if (label === 'second') {
            setSecondSharedRule(sharedRule);
        }
    }, [firstSharedRule, secondSharedRule]);

    return <>
        <div className={'d-flex flex-column'}>
            <div className={'d-flex flex-row justify-content-between'}>
                <div className={'d-flex flex-row'}>
                    <Link
                        to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/`}>
                        <Button className={'btn-icon-only text-dark'}>
                            <span className={'fa fa-arrow-left'}/>
                        </Button>
                    </Link>
                    <h1 className={'ms-4'}>Settings</h1>
                </div>
                <FeedExportStepButtonGroup step={'settings'}/>
            </div>
            <div className={'d-flex flex-row mt-4'}>
                <Card className={'flex-grow-1'}>
                    <CardHeader>
                        <h3>Settings</h3>
                    </CardHeader>
                    <CardBody className={'d-flex flex-column'}>
                        <h4>Platform</h4>
                        <FeedExportPlatformBox setPlatform={setPlatform}/>
                        <div className={'d-flex flex-row mt-2'}>
                            <FeedExportName name={name} setName={setName} submitted={submitted} disabled={false}/>
                        </div>
                        <div className={'d-flex flex-column mt-3'}>
                            <h4>First Shared Rule Group</h4>
                            {renderFirstSharedRule()}
                        </div>
                        <div className={'d-flex flex-column mt-3'}>
                            <h4>Second Shared Rule Group</h4>
                            {renderSecondSharedRule()}
                        </div>
                    </CardBody>
                </Card>
            </div>
            <div className={'d-flex flew-row justify-content-end'}>
                <Button color={'primary'} onClick={handleClick}>
                    Next
                </Button>
            </div>
        </div>
    </>
}
