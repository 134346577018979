import React, {useEffect, useState} from 'react';
import {FeedExportHistorys} from "../../../services/api/FeedExportHistorys";
import {apiErrorRaised} from "../../../context/api_error/ApiErrorActions";
import {useApiErrorDispatch} from "../../../context/api_error/ApiErrorIndex";
import {useProjectState} from "../../../context/project/ProjectIndex";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";

const FeedExportHistoryDetail = ({companyId, projectId, feedExportId, id}) => {
  const projectState = useProjectState()
  const [feedExportHistory, setFeedExportHistory] = useState({});
  const apiErrorDispatch = useApiErrorDispatch()
  const [isLoading, setIsLoading] = useState(false)

  function feedExportHistoryFromData(data) {
    let d = new Date(data.exported_time)
    data.exported_time = d.toLocaleString(projectState.locale.replace('_', '-'), {timeZone: projectState.timezone})
    return data
  }

  useEffect(() => {
    setIsLoading(true)
    FeedExportHistorys.get(companyId, projectId, feedExportId, id).then((r) => {
      setIsLoading(false)
      if (r?.status < 400) {
        setFeedExportHistory(feedExportHistoryFromData(r.data))
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    });
  }, [id, apiErrorDispatch]);

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <h3 className="mb-0">Feed export History Info</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {
            isLoading &&
            <Row>
              <Col>
                <span className="spinner-border spinner-border-lg me-1"/>
              </Col>
            </Row>
          }
          <Row className="py-3 align-items-center">
            <Col>
              <Row>
                <Col>
                  <h1>Exported Items</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h1>{feedExportHistory.exported_products}</h1>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="py-3 align-items-center">
            <Col>
              <Row>
                <Col>
                  <h1>Total Items</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h1>{feedExportHistory.total_products}</h1>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="py-3 align-items-center">
            <Col>
              <Row>
                <Col>
                  <h1>Date</h1>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h1>{feedExportHistory.exported_time}</h1>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default FeedExportHistoryDetail;
