

const googleLocalInventoryProperties = [

  {destination: 'id', origin: 'sku', isRequired: true},
  {destination: 'store_code', origin: 'store_id', isRequired: true},
  {destination: 'price', origin: 'price', isRequired: true},
  {destination: 'quantity', origin: 'quantity', isRequired: true},
  {destination: 'availability', origin: 'availability', isRequired: false},
  {destination: 'pickup_method', origin: '', isRequired: false},
  {destination: 'pickup_sla', origin: '', isRequired: false},
  {destination: 'sale_price', origin: 'salePrice', isRequired: false},
  {destination: 'sale_price_efective_date', origin: '', isRequired: false},
]


export default googleLocalInventoryProperties;
