const metaLocalizedCatalogs = [
  {destination: 'id', origin: 'sku', isRequired: true},
  {destination: 'override', origin: '', isRequired: true},
  {destination: 'title', origin: '', isRequired: false},
  {destination: 'description', origin: '', isRequired: false},
  {destination: 'availability', origin: '', isRequired: false},
  {destination: 'brand', origin: '', isRequired: false},
  {destination: 'link', origin: '', isRequired: false},
  {destination: 'image_link', origin: '', isRequired: false},
  {destination: 'image[0].url', origin: '', isRequired: false},
  {destination: 'additional_image_link', origin: '', isRequired: false},
  {destination: 'color', origin: '', isRequired: false},
  {destination: 'material', origin: '', isRequired: false},
  {destination: 'size', origin: '', isRequired: false},
  {destination: 'price', origin: '', isRequired: false},
  {destination: 'sale_price', origin: '', isRequired: false},
  {destination: 'sale_price_effective_date', origin: '', isRequired: false},
  {destination: 'custom_label_0', origin: '', isRequired: false},
  {destination: 'custom_label_1', origin: '', isRequired: false},
  {destination: 'custom_label_2', origin: '', isRequired: false},
  {destination: 'custom_label_3', origin: '', isRequired: false},
  {destination: 'custom_label_4', origin: '', isRequired: false},
]

export default metaLocalizedCatalogs;
