import React, {useEffect, useState} from "react";
import {apiErrorRaised} from "../../../../context/api_error/ApiErrorActions";
import {useApiErrorDispatch} from "../../../../context/api_error/ApiErrorContext";
import {
  loadFeedExports,
  selectFeedExport,
  useRuleImportDispatch,
  useRuleImportState
} from "./Context/FeedExportImportRuleIndex";
import {FeedExports} from "../../../../services/api/FeedExports";
import classNames from "classnames";
import {Badge} from "reactstrap";

export const FeedExportImportRulesFeedExportsList = () => {
  const [isLoading, setLoading] = useState(false)
  const dispatch = useApiErrorDispatch();
  const ruleImportState = useRuleImportState()
  const ruleGroupImportDispatch = useRuleImportDispatch()

  useEffect(() => {
    if (ruleImportState.selected_project === '') {
      return
    }
    setLoading(true)
    FeedExports.index(ruleImportState.selected_company, ruleImportState.selected_project).then((r) => {
      if (r?.status < 400) {
        loadFeedExports(ruleGroupImportDispatch, r.data.sort(function (a, b) {
          if (a.status === 1) {
            return -1;
          } else {
            return 1
          }
        }))
      } else {
        apiErrorRaised(dispatch, r?.response)
      }
      setLoading(false);
    })
  }, [ruleImportState.selected_project])


  const handleSelectFeedExport = (ruleGroupId) => {
    selectFeedExport(ruleGroupImportDispatch, ruleGroupId)
  }

  return (
    <>
      <h5>Feed Exports</h5>
      <div className={'d-flex flex-column'}>
        {
          isLoading ? <span className="spinner-border spinner-border-sm me-1"/> :
            ruleImportState.feed_exports && ruleImportState.feed_exports.map((feed_export, i) => {
              return (
                <div role={"button"} className={classNames({
                  'p-2 me-4 text-primary': true,
                  'bg-primary': ruleImportState.selected_rule_group === feed_export.rule_group_id,
                  'text-white': ruleImportState.selected_rule_group === feed_export.rule_group_id
                })}
                     key={i}
                     onClick={() => handleSelectFeedExport(feed_export.rule_group_id)}>
                  <Badge color="" className="badge-dot badge-lg pt-0 pb-0 product-card_header_badge">
                    <i className={classNames({
                      'bg-gray': feed_export.status === 'DISABLED',
                      'bg-success': feed_export.status === 'ACTIVE',
                    })}/>
                  </Badge>
                  {feed_export.name} {feed_export.status === 'DISABLED' ? <small>inactive</small> : <></>}
                </div>
              )
            })
        }
      </div>
    </>
  )
}
