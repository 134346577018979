import {get, put} from './config/base';
import {handleError, handleResponse} from "./config/response";

const url = 'companies/';

export const Companies = {
  index: (limit, offset, filters) =>
    get(`${url}`, {params: {limit: limit, offset: offset, filters: filters}})
      .then(handleResponse)
      .catch(handleError),
  get: (id) =>
    get(`${url}${id}`)
      .then(handleResponse)
      .catch(handleError),
  put: (id, payload) =>
    put(`${url}${id}`, payload)
      .then(handleResponse)
      .catch(handleError),
}
