import React, {useEffect, useState} from "react";
import {useCanvasState} from "../../../context/canvas/CanvasIndex";
import {SelectCanvasAssetProvider} from "../../../context/canvas/selectCanvasAsset/SelectCanvasAssetContext";
import {CanvasObjectInfoSidebarDetailTwoPrice} from "./Detail/Price/CanvasObjectInfoSidebarDetailTwoPrice";
import {CanvasObjectInfoSidebarDetailText} from "./Detail/Text/CanvasObjectInfoSidebarDetailText";
import {CanvasObjectInfoSidebarConditions} from "./CanvasObjectInfoSidebarConditions";
import {CanvasObjectInfoSidebarDetailImage} from "./Detail/Image/CanvasObjectInfoSidebarDetailImage";

export const CanvasObjectInfoSidebar = () => {
  const canvasState = useCanvasState();
  const [component, setComponent] = useState(null);

  useEffect(() => {
    switch (canvasState.selectedObject?.object?.type.split(/[\\ ]+/).pop()) {
      case "TemplateImageObject":
        setComponent(<CanvasObjectInfoSidebarDetailImage/>)
        break;
      case "TemplateTwoPriceObject":
        setComponent(<CanvasObjectInfoSidebarDetailTwoPrice/>)
        break;
      case "TemplateTextObject":
        setComponent(<CanvasObjectInfoSidebarDetailText/>)
        break;
      default:
        setComponent(null);
    }
  }, [canvasState.selectedObject]);

  return (
    <div
      className={
        "bg-white canvas_object_info_sidebar w-100 justify-content-between"
      }
    >
      <SelectCanvasAssetProvider>
        <div className={'canvas_object_info_sidebar_info'}>
          {component === null ? <></> : component}
        </div>
        {component === null ? <></> :
          <CanvasObjectInfoSidebarConditions/>
        }
      </SelectCanvasAssetProvider>
    </div>
  );
}
