export function publishTemplate(dispatch) {
  dispatch({type: 'PUBLISH', payload: 'PUBLISHED'})
}

export function templateUpdated(dispatch, name, value) {
  dispatch(
    {
      type: 'TEMPLATE_UPDATED',
      payload: {
        name: name,
        value: value
      },
    }
  )
}

export function templateActivated(dispatch) {
  dispatch(
    {
      type: 'TEMPLATE_ACTIVATED',
      payload: 1,
    }
  )
}

export function templateDisabled(dispatch) {
  dispatch(
    {
      type: 'TEMPLATE_DISABLED',
      payload: 0,
    }
  )
}

export function templateDeleted(dispatch) {
  dispatch(
    {
      type: 'TEMPLATE_DELETED',
    }
  )
}


export function templateConditionsUpdated(dispatch, conditions) {
  dispatch(
    {
      type: 'TEMPLATE_CONDITIONS_UPDATED',
      payload: conditions,
    }
  )
}

export function templateLoaded(dispatch, template) {
  dispatch(
    {
      type: 'TEMPLATE_LOADED',
      payload: template,
    }
  )
}
