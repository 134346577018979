import React, {useEffect, useState} from 'react'
import googleImage from "../../../assets/img/icons/common/google.png";
import bingImage from "../../../assets/img/icons/common/bing.png";
import facebookImage from "../../../assets/img/icons/common/facebook.png";
import googleLocalInventoryImage from "../../../assets/img/icons/common/googleLocalInventory.png";
import pinterestImage from "../../../assets/img/icons/common/pinterest.png";
import tradeDoublerImage from "../../../assets/img/icons/common/doubler.png";
import awinImage from "../../../assets/img/icons/common/awin.png";
import criteoImage from "../../../assets/img/icons/common/criteo.png";
import googleStudioImage from "../../../assets/img/icons/common/google_studio.png";
import tiktokImage from "../../../assets/img/icons/common/tiktok.png";
import customFeedImage from "../../../assets/img/icons/common/custom.png";

import metaLocalizedCatalogs from "../../../assets/img/icons/common/metaLocalizedCatalogs.png";
import classNames from "classnames";

export const FeedExportPlatformImage = ({platform, status}) => {
  const [image, setImage] = useState()
  useEffect(() => {
    switch (platform) {
      case 'google':
        setImage(googleImage)
        break;
      case 'bing':
        setImage(bingImage)
        break;
      case 'facebook':
        setImage(facebookImage)
        break;
      case 'googleLocalInventory':
        setImage(googleLocalInventoryImage)
        break;
      case 'pinterest':
        setImage(pinterestImage)
        break;
      case 'tradedoubler':
        setImage(tradeDoublerImage)
        break;
      case 'criteo':
        setImage(criteoImage)
        break;
      case 'awin':
        setImage(awinImage)
        break;
      case 'googleStudio':
        setImage(googleStudioImage)
        break;
      case 'tiktok':
        setImage(tiktokImage)
        break;
      case 'metaLocalizedCatalogs':
        setImage(metaLocalizedCatalogs)
        break;
      case 'custom':
        setImage(customFeedImage)
        break;

    }
  }, [platform])
  return (
    <>
      <div className='text-center'>
        <img
          className={classNames({
            "platform-image img-thumbnail img-fluid": true,
            "img-blackwhite": !status
          })}
          alt="..."
          width="130px"
          src={image}/>
      </div>
    </>
  )
}
