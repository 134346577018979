import React, {useEffect} from "react";
import {Container,} from "reactstrap";
import {useProjectDispatch} from "../../../context/project/ProjectContext";
import {useParams} from "react-router-dom";
import {updateCompanyAndProjectBasedOnUrl} from "../../../context/project/ProjectActions";
import {AnalyticsDashboard} from "../../../components/Analytics/AnalyticsDashboard";

export const AnalyticsView = () => {
  const projectDispatch = useProjectDispatch()
  const routerParams = useParams();
  useEffect(() => {
    updateCompanyAndProjectBasedOnUrl(projectDispatch, routerParams.companyId, routerParams.projectId)
  }, [])
  return (
    <>
      <Container className="mt-5" fluid>
        <AnalyticsDashboard/>
      </Container>
    </>
  );
};
