import {useCanvasDispatch, useCanvasState} from "../../../../../context/canvas/CanvasIndex";
import {updateData} from "../../../../../context/canvas/CanvasActions";
import {Input} from "reactstrap";
import React from "react";

export const CanvasObjectInfoSidebarDetailMargin = ({property, title}) => {
  const canvasState = useCanvasState()
  const canvasDispatch = useCanvasDispatch()
  const templateObject = canvasState.selectedObject

  const handleMargin = (e) => {
    const {name, value} = e.target;
    let marginTop = templateObject?.object.data[property].top
    let marginLeft = templateObject?.object.data[property].left
    let marginRight = templateObject?.object.data[property].right
    let marginBottom = templateObject?.object.data[property].bottom

    switch (name) {
      case 'margin.top':
        marginTop = value;
        break;
      case 'margin.left':
        marginLeft = value;
        break;
      case 'margin.right':
        marginRight = value;
        break;
      case 'margin.bottom':
        marginBottom = value;
        break;
      default:
    }
    updateData(
      canvasDispatch,
      templateObject?.id,
      property,
      {
        top: parseInt(marginTop),
        left: parseInt(marginLeft),
        right: parseInt(marginRight),
        bottom: parseInt(marginBottom),
      }
    )
  }

  return (
    <>
      <div className={'d-flex flex-column'}>
        <strong>Margin</strong>
        <div className={'d-flex flex-row justify-content-center'}>
          <div className={'w-25'}>
            <Input
              placeholder="X"
              type="number"
              value={templateObject?.object.data[property]?.top ?? 0}
              name="margin.top"
              onChange={handleMargin}
            />
          </div>
        </div>
        <div className={'d-flex flex-row justify-content-evenly mt-1'}>
          <div className={'w-25'}>
            <Input
              placeholder="X"
              type="number"
              value={templateObject?.object.data[property]?.left ?? 0}
              name="margin.left"
              onChange={handleMargin}
            />
          </div>
          <div className={'w-25 border border-primary border-2'}></div>
          <div className={'w-25'}>
            <Input
              placeholder="X"
              type="number"
              value={templateObject?.object.data[property]?.right ?? 0}
              name="margin.right"
              onChange={handleMargin}
            />
          </div>
        </div>
        <div className={'d-flex flex-row justify-content-center mt-1'}>
          <div className={'w-25'}>
            <Input
              placeholder="X"
              type="number"
              value={templateObject?.object.data[property]?.bottom ?? 0}
              name="margin.bottom"
              onChange={handleMargin}
            />
          </div>
        </div>
      </div>
    </>
  )
}
