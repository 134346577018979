import React, {useState} from "react";
import {CanvasObjectInfoSidebarConditionsModal} from "./CanvasObjectInfoSidebarConditionsModal";

export const CanvasObjectInfoSidebarConditions = () => {
  const [showModal, setShowModal] = useState(false)

  const handleClick = () => {
    setShowModal(true);
  }

  return (
    <>
      <div onClick={handleClick}
           className={'canvas_sidebar_header d-flex flex-row justify-content-between align-items-center p-3 canvas_sidebar_conditions'}>
        <h3 className="mb-0">Conditions</h3>
        <span className={'fa fa-square-arrow-up-right'}/>
      </div>
      <CanvasObjectInfoSidebarConditionsModal
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
}
