import IndexFooter from "../../components/Footers/IndexFooter";
import IndexNavbar from "../../components/Navbars/IndexNavbar";
import {Container} from "reactstrap";
import React from "react";

export const NotFoundError = () => {
  return (
    <>
      <IndexNavbar logo={{
        innerLink: `/`, imgSrc: "assets/img/brand/feedest_purple.svg", imgAlt: "logo",
      }}/>
      <Container className={'mt-8 pt-8 mb-8 pb-8'}>
        <div className={'justify-content-center'}>
          <h1 className={'display-1'}>404 - Not Found</h1>
        </div>
      </Container>
      <IndexFooter/>
    </>
  )
}

export default NotFoundError
