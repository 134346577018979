import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useProjectState} from "../../../context/project/ProjectIndex";
import classNames from "classnames";
import {FeedExportPlatformImage} from "../../FeedExports/FeedExportSettings/FeedExportPlatformImage";

const ProjectDetailFeedExportItem = ({data}) => {
  const projectState = useProjectState()
  const [feedExport, setFeedExport] = useState(data);

  useEffect(() => {
    data = {
      ...data,
      url: process.env.REACT_APP_CDN_URL + 'feeds/' + projectState.projectId + '/' + data.filename + '.' + data.format
    }
    if (data.last_time === null || data.last_time === undefined) {
      data = {
        ...data,
        lastDay: '',
        lastHour: '',
      }
    } else {
      let d = new Date(data.last_time)
      data = {
        ...data,
        lastDay: d.toLocaleDateString(projectState.locale.replace('_', '-'), {timeZone: projectState.timezone}),
        lastHour: d.toLocaleTimeString(projectState.locale.replace('_', '-'), {timeZone: projectState.timezone}),
      }
    }
    setFeedExport(data)
  }, [data]);

  return (
    <>
      <tr className={'text-dusty-violet'}>
        <td className={'p-1 feed_export_item text-center border-0 border-end'}>
          <FeedExportPlatformImage platform={feedExport?.mapping?.platform} status={feedExport.status}/>
        </td>
        <td className={'p-2 text-center border-0 border-end'}>
          <Link
            to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/${feedExport.id}/edit`}>
            <span className={classNames({
              'text-center': true,
              'text-gray': !feedExport.status
            })}>{feedExport.name}</span>
          </Link>
        </td>
        <td className={'p-2 border-0 border-end text-center'} style={{overflow: "visible"}}>
          <div className="progress w-100" style={{height: 10}}>
            <div className="progress-bar tooltip-hover" role="progressbar"
                 style={{
                   width: feedExport.last_exported + '%',
                   overflow: "visible",
                   backgroundColor: '#8FDC24',
                   borderTopLeftRadius: '20px',
                   borderBottomLeftRadius: '20px'
                 }}
                 aria-valuenow={feedExport.last_exported}
                 aria-valuemin="0" aria-valuemax={feedExport.last_total}>
              <span
                className="tooltiptext p-1 pe-2 ps-1">
                <span className={'fa fa-circle text-green pe-2'}/>
                {feedExport.last_exported} Exported products</span>
            </div>
            <div className="progress-bar tooltip-hover" role="progressbar"
                 style={{
                   width: feedExport.last_total - feedExport.last_exported + '%',
                   overflow: "visible",
                   backgroundColor: '#FF7169',
                   borderTopRightRadius: '20px',
                   borderBottomRightRadius: '20px'
                 }}
                 aria-valuenow={feedExport.last_total - feedExport.last_exported}
                 aria-valuemin="0" aria-valuemax={feedExport.last_total}>
              <span
                className="tooltiptext p-1 pe-2 ps-1">
                <span className={'fa fa-circle text-red pe-2'}/>
                {feedExport.last_total - feedExport.last_exported} Not exported products</span>
            </div>
          </div>
        </td>
        <td className={'text-center  border-0 border-end'}>
          {Intl.NumberFormat(projectState.locale.replace('_', '-')).format(feedExport.last_exported) + ' '}
          <small>({Intl.NumberFormat(projectState.locale.replace('_', '-')).format(feedExport.last_total)})</small>
        </td>
        <td className={'text-center  border-0'}>
          <div className={'d-flex flex-column justify-content-between'}>
            <div className={'d-flex flex-row justify-content-center'}>
              <span className={'fa fa-calendar fa-regular pe-2'}/>
              <span>{feedExport.lastDay}</span>
            </div>
            <div className={'d-flex flex-row justify-content-center'}>
              <span className={'fa fa-clock fa-regular pe-2'}/>
              <span>{feedExport.lastHour}</span>
            </div>
          </div>
        </td>
      </tr>
    </>
  )
}

export default ProjectDetailFeedExportItem;
