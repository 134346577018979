export const initialState = {
  status: 500,
  statusText: '',
  code: '',
  message: '',
  visible: false
};

export const ApiErrorReducer = (initialState, action) => {
  switch (action.type) {
    case "API_ERROR_OCCURRED":
      return {
        ...initialState,
        message: action.payload?.data?.message,
        status: action.payload?.status,
        statusText: action.payload?.statusText,
        code: action.payload?.data?.code,
        visible: true
      };
    case "API_ERROR_CLOSED":
      return {
        ...initialState,
        message: '',
        status: 500,
        code: '',
        statusText: '',
        visible: false
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
