import React from 'react';
import {Button} from "reactstrap";
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {closeModal, openModal, useModalDispatch} from "../../context/modal/ModalIndex";
import {useProjectState} from "../../context/project/ProjectIndex";
import {DataSources} from "../../services/api/DataSources";
import {useNavigate} from "react-router";

const DataSourceDeleteButton = ({id}) => {
  const projectState = useProjectState()
  const dispatchApiError = useApiErrorDispatch();
  const modalDispatcher = useModalDispatch();
  const navigate = useNavigate()

  const handleDeleteClick = () => {
    openModal(modalDispatcher, {
      title: 'Delete data source',
      message: 'Do you want to delete this data source? This action cannot be undone',
      onConfirm: () => {
        DataSources.delete(projectState.companyId, projectState.projectId, id).then((r) => {
          if (r?.status < 400) {
            navigate(`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/data_sources`);
          } else {
            apiErrorRaised(dispatchApiError, r?.response)
          }
          closeModal(modalDispatcher)
        });
      },
      onCancel: () => closeModal(modalDispatcher),
      onBackdropClick: () => closeModal(modalDispatcher),
      buttonColor: 'danger',
      buttonText: 'Yes'
    })
  }

  return (
    <>
      {
        <Button className={'btn-icon'} color={'danger'} onClick={handleDeleteClick}>
          <span className="fa fa-trash"/> Delete
        </Button>
      }
    </>
  );
}

export default DataSourceDeleteButton;
