import React, {useState} from 'react';
import {Button} from "reactstrap";
import {v4 as uuid4} from "uuid";
import {useProjectState} from "../../../../context/project/ProjectIndex";
import {apiErrorRaised, useApiErrorDispatch} from "../../../../context/api_error/ApiErrorIndex";
import {closeModal, openModal, useModalDispatch} from "../../../../context/modal/ModalIndex";
import {Templates} from "../../../../services/api/Templates";
import {useTemplateState} from "../../../../context/template/TemplateIndex";
import {useNavigate} from "react-router-dom";

const TemplateDuplicateButton = () => {
  const projectState = useProjectState()
  const templateState = useTemplateState()
  const dispatchApiError = useApiErrorDispatch();
  const [isLoading, setLoading] = useState(false)
  const navigate = useNavigate();
  const modalDispatcher = useModalDispatch();

  const handleClick = () => {
    openModal(modalDispatcher, {
      title: 'Request duplicate template',
      message: 'Do you want to duplicate this template and its template objects?',
      onConfirm: () => {
        setLoading(true);
        let newId = uuid4();
        Templates.duplicate(projectState.companyId, projectState.projectId, templateState.id, newId).then((r) => {
          if (r?.status < 400) {
            setLoading(false)
            navigate(`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/templates/${newId}`);
          } else {
            apiErrorRaised(dispatchApiError, r?.response)
          }
          closeModal(modalDispatcher)
        });
      },
      onCancel: () => closeModal(modalDispatcher),
      onBackdropClick: () => closeModal(modalDispatcher),
      buttonColor: 'primary',
      buttonText: 'Duplicate',
      buttonIcon: 'fa-copy'
    })
  }

  return (
    <>
      {
        isLoading ?
          <span className="spinner-border spinner-border-sm me-1"/>
          :
          <Button color={'primary'} outline onClick={handleClick} className={'m-0 mt-1 mb-2'}>
            <span className="fa-solid fa-copy"/> Duplicate
          </Button>
      }
    </>
  );
}

export default TemplateDuplicateButton;
