import React from 'react';
import {Col, Row} from "reactstrap";
import {ProductIssueItemRow} from "./ProductIssueItemRow";

const ProductIssuesTable = ({productIssues}) => {
  return (<>
    <section className={'ms-1 mt-3 me-1 mb-3'}>
      <Row className={'bg-secondary p-2'}>
        <Col md={1}><small>Sku</small></Col>
        <Col md={1}><small>Severity</small></Col>
        <Col md={5}><small>Title</small></Col>
        <Col md={5}><small>Description</small></Col>
      </Row>
      {
        productIssues && productIssues.map((productIssue, i) => <ProductIssueItemRow key={i} data={productIssue}/>)
      }
    </section>
  </>)
}


export default ProductIssuesTable;
