import React, {useEffect, useState} from 'react';
import {Input} from "reactstrap";
import FeedExportInternalPropertiesSelector from "../FeedExportPlatform/FeedExportInternalPropertiesSelector";

const FeedExportMappingItem = ({platformPropertyDetail, property, handleChangeMappingItem, custom}) => {
  const [mappingItem, setMappingItem] = useState({
    'origin': property?.origin,
    'destination': platformPropertyDetail.destination,
    'format': property?.format === undefined ? '' : property.format,
    'isRequired': platformPropertyDetail?.isRequired
  })

  const handleChange = (e) => {
    setMappingItem({...mappingItem, [e.target.name]: e.target.value,})
  }

  useEffect(() => {
    handleChangeMappingItem(mappingItem)
  }, [mappingItem])

  return (
    <>
      <tr>
        <td>
          <FeedExportInternalPropertiesSelector
            name={'origin'}
            item={mappingItem.origin}
            handleChange={handleChange}
            isRequired={mappingItem.isRequired}
          />
        </td>
        {
          custom ? (
            <td>
              <Input
                type="text"
                name="destination"
                value={mappingItem.destination}
                onChange={handleChange}
              />
            </td>
          ) :
          <td>
            {mappingItem.destination}
          </td>
        }
        <td>
          <Input
            type="text"
            name="format"
            value={mappingItem.format}
            onChange={handleChange}
          />
        </td>
        <td>
          {mappingItem.isRequired ? (<b>Required</b>) : ('Optional')}
        </td>
      </tr>
    </>
  )
}

export default FeedExportMappingItem;
