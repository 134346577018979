import React from 'react'

export const textTransformOptions = [
  {label: 'capitalize only first character', value: 'capitalize'},
  {label: 'capitalize first character per word', value: 'camelcase'},
  {label: 'capitalize first character per sentence', value: 'sentence'},
  {label: 'lowercase all words', value: 'lowercase'},
  {label: 'uppercase all words', value: 'uppercase'},
  {label: 'remove all non-numeric characters', value: 'only_digits'},
  {label: 'remove digits', value: 'remove_digits'},
  {label: 'remove linebreaks', value: 'remove_linebreaks'},
  {label: 'strip_remove unnecessary whitespaces', value: 'strip_whitespace'},
  {label: 'remove HTML from text', value: 'strip_html'},
]

