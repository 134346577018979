import {Projects} from "../../services/api/Projects";
import {UserAccess} from "../../services/api/UserAccess";
import {Companies} from "../../services/api/Companies";

export function selectCompany(dispatch, companyId, companyName, companyFeatures, userRole, userId) {
  UserAccess.byUserId(companyId, userId).then((r) => {
    if (r?.status < 400) {
      dispatch({
        type: 'COMPANY_SELECTED', payload: {
          companyId: companyId,
          companyName: companyName,
          companyFeatures: companyFeatures,
        }
      })
      dispatch({
        type: 'ROLE_UPDATED', payload: {
          userRole: r.data.role
        }
      })
    } else {
      dispatch({
        type: 'COMPANY_SELECTED', payload: {
          companyId: companyId,
          companyName: companyName,
          companyFeatures: companyFeatures,
        }
      })
      dispatch({
        type: 'ROLE_UPDATED', payload: {
          userRole: null,
        }
      })
    }
  })
}

export function selectProject(dispatch, projectId, name, locale, timezone, properties, status, currency, productsDetected) {
  dispatch({
    type: 'PROJECT_SELECTED',
    payload: {
      id: projectId,
      locale: locale,
      timezone: timezone,
      properties: properties,
      name: name,
      currency: currency,
      status: status,
      productsDetected: productsDetected,
    }
  })
}

export function updateCompanyAndProjectBasedOnUrl(dispatch, companyId, projectId) {
  Companies.get(companyId).then((r) => {
    if (r?.status < 400) {
      dispatch({
        type: 'COMPANY_SELECTED', payload: {
          companyId: companyId,
          companyName: r.data.name,
          companyFeatures: r.data.features.reduce((features, feature) => {
            features[feature.name] = true
            return features
          }, {})
        }
      })
    }
  });
  if (projectId === undefined || projectId === null) {
    return
  }
  Projects.get(companyId, projectId).then((r) => {
    if (r?.status < 400) {
      dispatch(
        {
          type: 'UPDATE_COMPANY_AND_PROJECT',
          payload: {
            companyId: companyId,
            projectId: projectId,
            projectName: r.data.name,
            projectStatus: r.data.status,
            projectCurrency: r.data.currency,
            locale: r.data.locale,
            timezone: r.data.timezone,
            properties: r.data.properties,
            productsDetected: r.data.products_detected,
          }
        })
    }
  })
}

export function updateCompanyBasedOnUrl(dispatch, companyId) {
  dispatch(
    {
      type: 'UPDATE_COMPANY',
      payload: {
        companyId: companyId
      }
    })
}

export function clearProjectState(dispatch) {
  dispatch(
    {
      type: 'CLEAR_PROJECT_STATE'
    }
  )
}
