import {useCanvasDispatch, useCanvasState} from "../../../../../context/canvas/CanvasIndex";
import {updateData} from "../../../../../context/canvas/CanvasActions";
import {Input} from "reactstrap";
import React from "react";

export const CanvasObjectInfoSidebarDetailOrigin = () => {
  const canvasState = useCanvasState()
  const canvasDispatch = useCanvasDispatch()
  const templateObject = canvasState.selectedObject

  const handleOrigin = (e) => {
    const {name, value} = e.target;
    let originX = templateObject?.object.data.origin.x
    let originY = templateObject?.object.data.origin.y

    switch (name) {
      case 'x':
        originX = value;
        break;
      case 'y':
        originY = value;
        break;
      default:
    }
    updateData(
      canvasDispatch,
      templateObject?.id,
      'origin',
      {
        x: parseInt(originX),
        y: parseInt(originY),
      }
    )
  }

  return (
    <>
      <div className={'d-flex flex-column'}>
        <strong className={'mt-1'}>Position</strong>
        <div className={'d-flex flex-row mt-1'}>
          <Input
            className={'m-2'}
            placeholder="X"
            type="number"
            value={templateObject?.object.data.origin.x ?? 0}
            name="x"
            onChange={handleOrigin}
          />
          <Input
            className={'m-2'}
            placeholder="X"
            type="number"
            value={templateObject?.object.data.origin.y ?? 0}
            name="y"
            onChange={handleOrigin}
          />
        </div>
      </div>
    </>
  )
}
