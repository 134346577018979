import React from 'react'
import {Col, FormGroup, Input, Row} from "reactstrap";
import {ruleOperators} from "../FeedExportRuleFields/FeedExportRuleOperators";
import {useRuleDispatch, useRuleState} from "../../../../context/rule/RuleIndex";
import Select from "react-select";
import {updateOrQuery} from "../../../../context/rule/RuleActions";
import {FeedExportRuleActionPropertiesSelect} from "../FeedExportRuleFields/FeedExportRuleActionPropertiesSelect";


export const FeedExportRuleOrQueryCondition = ({position, orPosition, type}) => {
  const ruleState = useRuleState()
  const ruleDispatch = useRuleDispatch()

  const handleFieldChange = (e) => {
    const {value} = e;
    updateOrQuery(ruleDispatch,
      {
        or_query: {...ruleState.queries[position].or_queries[orPosition], 'field': value},
        position: position,
        or_position: orPosition,
      })
  }

  const handleOperatorChange = (e) => {
    const {value} = e;
    updateOrQuery(ruleDispatch,
      {
        or_query: {...ruleState.queries[position].or_queries[orPosition], 'operator': value},
        position: position,
        or_position: orPosition,
      })
  }

  const handleValueChange = (e) => {
    const {value} = e.target;
    updateOrQuery(ruleDispatch,
      {
        or_query: {...ruleState.queries[position].or_queries[orPosition], 'value': value},
        position: position,
        or_position: orPosition,
      })
  }

  return (
    <>
      <div className='d-flex align-items-center gap-4 justify-content-start mb-3 w-100 h-100'>
        <div md={1}/>
        <div md={1} className='fe-height-statement d-flex align-items-center justify-content-evenly div-md-1 bg-gray-light rounded text-sm'>
          or
        </div>
        <div className='w-100'>
          <div className='row d-flex align-items-center p-2'>
            <div md={3} className='col-sm'>
              <>
                <div>
                  <FeedExportRuleActionPropertiesSelect
                    value={ruleState.queries[position].or_queries[orPosition]?.field}
                    onChange={handleFieldChange}
                  />
                </div>
              </>
            </div>
            <div md={3} className='col-sm'>
              {
                ruleState.queries[position].or_queries[orPosition]?.field !== 'all' ?
                  <div>
                    <Select
                      value={ruleOperators.find(listOperator => listOperator.value === ruleState.queries[position].or_queries[orPosition]?.operator)}
                      onChange={handleOperatorChange}
                      options={ruleOperators}
                    />
                  </div>
                  : ''
              }
            </div>
            <div md={3} className='col-sm'>
              {
                ruleState.queries[position].or_queries[orPosition]?.field !== 'all'
                && ruleState.queries[position].or_queries[orPosition]?.operator !== 'IS NULL'
                && ruleState.queries[position].or_queries[orPosition]?.operator !== 'IS NOT NULL' ?
                  <div>
                    <Input
                      value={ruleState.queries[position].or_queries[orPosition]?.value}
                      name={"value"}
                      type="text"
                      onChange={handleValueChange}
                    />
                  </div> : ''
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
