import React from "react";
import {Outlet, useLocation} from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import {useProjectState} from "../context/project/ProjectIndex";

export default function AdminLayout() {
  const projectState = useProjectState()

  const location = useLocation();
  const mainContentRef = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  const getNavbarTheme = () => {
    return 'light'
  };

  return (
    <>
      <AdminNavbar
        theme={getNavbarTheme()}
        logo={{
          innerLink: `/admin/companies/${projectState?.companyId}`,
          imgSrc: "assets/img/brand/feedest_purple.svg",
          imgAlt: "logo",
        }}
      />
      <div className={'feedest-global-container'}>
        <Sidebar
          routes={routes}
          logo={{
            innerLink: `/admin/companies/${projectState.companyId}`,
            imgSrc: "assets/img/brand/feedest_purple.svg",
            imgAlt: "logo",
          }}
        />
        <div className="main-content bg-white" ref={mainContentRef}>
          <Outlet/>
          <AdminFooter/>
        </div>
      </div>
    </>
  );
}
