import React, {useEffect, useRef, useState} from 'react';
import {Card, CardBody, CardHeader} from "reactstrap";
import {Analytics} from "../../../services/api/Analytics";
import {useProjectState} from "../../../context/project/ProjectIndex";
import {Chart} from "react-chartjs-2";
import {apiErrorRaised} from "../../../context/api_error/ApiErrorActions";
import {useApiErrorDispatch} from "../../../context/api_error/ApiErrorIndex";
import {Projects} from "../../../services/api/Projects";
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  DoughnutController,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip
} from "chart.js";
import {startOfDay, subDays} from "date-fns";
import {chartColors} from "../../../utils/Chart";

const ProjectDetailInfo = () => {
  const projectState = useProjectState();
  const dispatch = useApiErrorDispatch();
  const [lastReadDay, setLastReadDay] = useState('-')
  const [lastReadTime, setLastReadTime] = useState('-')
  const [productsDetected, setProductsDetected] = useState(0)
  const [isLoading, setLoading] = useState(false);
  const dispatchApiError = useApiErrorDispatch();
  const chartRef = useRef(null)
  const [healthData, setHealthData] = useState({
    datasets: [],
  })
  const [topLevelData, setTopLevelData] = useState({
    datasets: [],
  })

  const params = {};
  params.group_by = [];

  useEffect(() => {
    if (!projectState.features?.data_history) {
      return
    }
    setLoading(true);
    let timezone_date = startOfDay(subDays((new Date()).setHours(0, 0, 0, 0), 1))

    Analytics.insights(projectState.companyId, projectState.projectId, {
      'start_date': timezone_date.toLocaleString('en-US'),
      'end_date': timezone_date.toLocaleString('en-US'),
      'dimensions': ['top_level_type', 'date']
    }).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        let data = {
          labels: r.data.sort((a, b) => a.products > b.products ? -1 : 1)
            .slice(0, 5)
            .map((insight) => (insight.top_level_type)),
          datasets: [{
            data: r.data.sort((a, b) => a.products > b.products ? -1 : 1)
              .slice(0, 5)
              .map((insight) => (insight.products)),
            backgroundColor: [
              chartColors.topLevels[0],
              chartColors.topLevels[1],
              chartColors.topLevels[2],
              chartColors.topLevels[3],
              chartColors.topLevels[4],
            ],
            barThickness: 80
          }]
        };

        setTopLevelData(
          data
        )

      } else {
        if (r?.response !== undefined) {
          apiErrorRaised(dispatch, r?.response)
        } else {
          apiErrorRaised(dispatch, {
            status: r.status,
            statusText: r.name,
            data: {
              message: r.message,
            }
          })
        }
      }
    })

    Analytics.insights(projectState.companyId, projectState.projectId, {
      'start_date': timezone_date.toLocaleString('en-US'),
      'end_date': timezone_date.toLocaleString('en-US'),
      'dimensions': ['availability', 'date']
    }).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        setHealthData({
          labels: r.data.map(insight => `${(new Intl.NumberFormat(projectState.locale.replace('_', '-'))).format(insight.products)} ${insight.availability.charAt(0).toUpperCase()}${insight.availability.slice(1)}`),
          datasets: [{
            label: '',
            data: r.data.map(insight => insight.products),
            backgroundColor: [
              r.data[0]?.availability === 'in stock' ? '#8FDC24' : '#FFA113',
              r.data[1]?.availability === 'in stock' ? '#8FDC24' : '#FFA113',
            ],
            borderWidth: 0,
            weight: 10
          }
          ]
        });

      } else {
        if (r?.response !== undefined) {
          apiErrorRaised(dispatch, r?.response)
        } else {
          apiErrorRaised(dispatch, {
            status: r.status,
            statusText: r.name,
            data: {
              message: r.message,
            }
          })
        }
      }
    })
  }, [projectState.companyId, projectState.projectId, projectState.features])

  ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    BarElement,
    LineController,
    DoughnutController,
    BarController,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    TimeScale,
  );

  useEffect(() => {
    setLoading(true);
    Projects.get(projectState.companyId, projectState.projectId).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        let date = new Date(r.data.last_read);
        if (r.data.last_read !== '') {
          setLastReadDay(date.toLocaleDateString(projectState.locale.replace('_', '-'), {timeZone: projectState.timezone}));
          setLastReadTime(date.toLocaleTimeString(projectState.locale.replace('_', '-'), {timeZone: projectState.timezone}));
        }

        setProductsDetected(r.data.products_detected)
      } else {
        apiErrorRaised(dispatchApiError, r?.response)
      }
    });
  }, [projectState.companyId, projectState.projectId]);

  const getOrCreateLegendList = (chart, id) => {
    const legendContainer = document.getElementById(id);
    legendContainer.classList = 'd-flex justify-content-center'
    let container = legendContainer.querySelector('div');

    if (!container) {
      container = document.createElement('div');
      container.style.display = 'flex';
      container.style.flexDirection = 'column';
      container.style.margin = 0;
      container.style.padding = 0;

      legendContainer.appendChild(container);
    }

    return container;
  };


  const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart, args, options) {
      const div = getOrCreateLegendList(chart, options.containerID);
      // Remove old legend items
      while (div.firstChild) {
        div.firstChild.remove();
      }

      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      items.forEach(item => {
        const divItem = document.createElement('div');
        divItem.style.alignItems = 'center';
        divItem.style.cursor = 'pointer';
        divItem.style.display = 'flex';
        divItem.style.flexDirection = 'row';
        divItem.style.flexGrow = '1'
        divItem.style.marginLeft = '10px';

        divItem.onclick = () => {
          const {type} = chart.config;
          if (type === 'pie' || type === 'doughnut') {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            chart.toggleDataVisibility(item.index);
          } else {
            chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
          }
          chart.update();
        };

        // Color box
        const boxSpan = document.createElement('span');
        boxSpan.style.background = item.fillStyle;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = item.lineWidth + 'px';
        boxSpan.style.borderRadius = '50px';
        boxSpan.style.display = 'inline-block';
        boxSpan.style.flexShrink = 0;
        boxSpan.style.height = '20px';
        boxSpan.style.marginRight = '10px';
        boxSpan.style.width = '20px';

        // Text
        const textContainer = document.createElement('p');
        textContainer.style.color = item.fontColor;
        textContainer.style.margin = 0;
        textContainer.style.padding = 0;
        textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);

        divItem.appendChild(boxSpan);
        divItem.appendChild(textContainer);
        div.appendChild(divItem);
      });
    }
  };

  return (
    <>
      <Card>
        <CardHeader className={'text-dusty-violet'}>
          FEED IMPORT
        </CardHeader>
        <CardBody className={'d-flex flex-row p-0'}>
          <div className={'d-flex flex-column flex-basis-25 border-end p-4'}>
            <h4 className={'text-center text-dusty-violet'}>Data</h4>
            <div className={'d-flex flex-grow-1 flex-column justify-content-evenly'}>
              <div>
                <h2 className={'text-center text-dusty-violet'}>Last Products Detected</h2>
                <h3 className={'text-center justify-content-center text-dusty-violet'}>
                  {productsDetected} Products
                </h3>
              </div>
              <div className={'d-flex flex-column'}>
                <h2 className={'text-center text-dusty-violet'}>Last imported date</h2>
                <div className={'d-flex justify-content-center'}>
                  <div
                    className={'border d-flex flex-column w-100 ps-2 pe-2 pt-1 pb-1 border-2 rounded-2'}>
                    <div className={'d-flex flex-row align-items-center justify-content-center'}>
                      <span className={'fa fa-calendar fa-regular pe-2'}/>
                      <span className={'text-dusty-violet'}>{lastReadDay}</span>
                    </div>
                    <div className={'d-flex flex-row align-items-center justify-content-center'}>
                      <span className={'fa fa-clock fa-regular pe-2'}/>
                      <span className={'text-dusty-violet'}>{lastReadTime}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={'d-flex flex-column flex-basis-25 border-end p-3'}>
            <h4 className={'text-center text-dusty-violet'}>Availability</h4>
            {
              projectState.features?.data_history ? <div className={'flex-grow-1'}>
                {
                  isLoading === true ?
                    <span className="spinner-border spinner-border-xl"/>
                    : <Chart
                      ref={chartRef}
                      type={'doughnut'}
                      data={healthData}
                      id="chart-current_state"
                      className="chart-canvas"
                      options={{
                        cutout: 115,
                        radius: 120,
                        plugins: {
                          htmlLegend: {
                            containerID: 'legend-container',
                          },
                          legend: {
                            display: false,
                          },
                          tooltip: {
                            enabled: true,
                            intersect: false,
                            titleAlign: 'center',
                            padding: 10,
                            backgroundColor: '#3F3949',
                            usePointStyle: true,
                            cornerRadius: 10,
                          },
                        }
                      }}
                      plugins={[htmlLegendPlugin]}
                    />
                }
              </div> : <>
                <div className={'d-flex p-5 flex-grow-1 justify-content-center align-items-center'}>
                  <h2>This company has no access to this feature</h2>
                </div>
              </>
            }
            <div id={'legend-container'}/>
          </div>
          <div className={'d-flex flex-column flex-basis-50 p-3'}>
            <h4 className={'text-center text-dusty-violet'}>Top Level</h4>
            {projectState.features?.data_history ?
              <div className={'barChart'}>
                {
                  isLoading === true ?
                    <div className={'d-flex align-items-center justify-content-center h-100'}>
                      <span className="spinner-border spinner-border-xl"/>
                    </div>
                    :
                    <Chart
                      ref={chartRef}
                      type={'bar'}
                      data={topLevelData}
                      id="chart-current_state"
                      className="chart-canvas"
                      height="400px"
                      width="500px"
                      options={{
                        indexAxis: 'x',
                        responsive: true,
                        maintainAspectRatio: false,
                        defaultColor: chartColors.gray[600],
                        defaultFontColor: chartColors.gray[600],
                        defaultFontFamily: "Nunito Regular",
                        defaultFontSize: 13,
                        layout: {
                          borderWidth: 0,
                          padding: 0,
                        },
                        plugins: {
                          legend: {
                            display: false
                          },
                          tooltip: {
                            enabled: true,
                            intersect: false,
                            titleAlign: 'center',
                            padding: 10,
                            backgroundColor: '#3F3949',
                            usePointStyle: true,
                            cornerRadius: 10,
                            callbacks: {
                              label: function (context) {
                                let label = context.dataset.label || '';
                                label = '  ' + label

                                label += new Intl.NumberFormat(projectState.locale.replace('_', '-')).format(context.parsed.y) + ' products';
                                return label;
                              },
                            },
                          },
                        },
                        scales: {
                          x: {
                            grid: {
                              display: false
                            },
                            border: {
                              display: false
                            },
                            ticks: {
                              color: '#8F88AA'
                            }
                          },
                          y: {
                            grid: {
                              display: true
                            },
                            border: {
                              dash: [4, 8],
                              display: false,
                            },

                            ticks: {
                              color: '#8F88AA'
                            }
                          }
                        }
                      }}
                    />
                }
              </div> : <>
                <div className={'d-flex p-5 flex-grow-1 justify-content-center align-items-center'}>
                  <h2>This company has no access to this feature</h2>
                </div>
              </>
            }
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default ProjectDetailInfo;
