import {useCanvasDispatch, useCanvasState} from "../../../../../context/canvas/CanvasIndex";
import {updateData} from "../../../../../context/canvas/CanvasActions";
import {Input} from "reactstrap";
import React from "react";
import {CanvasColorPicker} from "../../../CanvasColorPicker";

export const CanvasObjectInfoSidebarDetailPriceLine = () => {
  const canvasState = useCanvasState()
  const canvasDispatch = useCanvasDispatch()
  const templateObject = canvasState.selectedObject

  const handleChange = (e) => {
    const {name, value} = e.target;
    let origin = templateObject?.object.data.strickedPriceLine.origin
    let thickness = templateObject?.object.data.strickedPriceLine.thickness

    switch (name) {
      case 'origin':
        origin = value;
        break;
      case 'thickness':
        thickness = value;
        break;
      default:
    }
    updateData(
      canvasDispatch,
      templateObject?.id,
      'strickedPriceLine',
      {
        ...templateObject?.object.data.strickedPriceLine,
        origin: origin,
        thickness: parseInt(thickness),
      }
    )
  }

  const handleChangeColor = (color) => {
    updateData(
      canvasDispatch,
      templateObject?.id,
      'strickedPriceLine',
      {
        ...templateObject?.object.data.strickedPriceLine,
        lineColor: {
          red: parseInt(color.red),
          blue: parseInt(color.blue),
          green: parseInt(color.green),
          alpha: parseInt(color.alpha),
        },
      }
    )
  }

  return (
    <>
      <div className={'d-flex flex-column'}>
        <strong className={'mt-1'}>Line</strong>
        <div className={'d-flex flex-row'}>
          <div className={'d-flex flex-column flex-grow-1 m-2'}>
            <strong className={'mt-1'}>Origin</strong>
            <Input
              type="select"
              name="origin"
              value={templateObject?.object.data?.strickedPriceLine?.origin ?? 0}
              className={'form-control'}
              onChange={handleChange}
            >
              <option value="top">Top</option>
              <option value="center">Center</option>
              <option value="bottom">Bottom</option>
            </Input>
          </div>
          <div className={'d-flex flex-column flex-grow-1 m-2'}>
            <strong className={'mt-1'}>Thickness</strong>
            <Input
              placeholder="0"
              type="number"
              value={templateObject?.object.data?.strickedPriceLine?.thickness ?? 0}
              name="thickness"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className={'d-flex flex-column m-2'}>
          <strong className={'mt-1'}>Line Color</strong>
          <CanvasColorPicker
            color={templateObject?.object.data?.strickedPriceLine?.lineColor ?? 0}
            onColorChange={handleChangeColor}
          />
        </div>
      </div>
    </>
  )
}
