import {useCanvasDispatch, useCanvasState} from "../../../../../context/canvas/CanvasIndex";
import {updateData} from "../../../../../context/canvas/CanvasActions";
import {Input} from "reactstrap";
import React from "react";

export const CanvasObjectInfoSidebarDetailImageDisplayTransformation = () => {
  const canvasState = useCanvasState()
  const canvasDispatch = useCanvasDispatch()
  const templateObject = canvasState.selectedObject

  const handleChange = (e) => {
    const {name, value} = e.target;
    updateData(
      canvasDispatch,
      templateObject?.id,
      name,
      value,
    )
  }

  return (
    <>
      <div className={'d-flex flex-column'}>
        <strong className={'mt-1'}>Display Transformation</strong>
        <Input type="select" name="displayTransformation"
               value={templateObject?.object.data.displayTransformation ?? ''}
               className={'form-control'}
               onChange={handleChange}
        >
          <option value="fit">Fit</option>
          <option value="fill">Fill</option>
        </Input>
      </div>
    </>
  )
}
