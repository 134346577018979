export const setDatesWithComparation = (analyticsState, data) => {
  analyticsState = {
    ...analyticsState,
    dates: {
      start_date: data.start_date,
      end_date: data.end_date,
    },
    compare_dates: {
      start_date: data.start_date_comparation,
      end_date: data.end_date_comparation,
    },
  }
  return analyticsState
};
