const criteoProperties = [
  {destination: 'description', origin: 'description', isRequired: true},
  {destination: 'availability', origin: 'availability', isRequired: true},
  {destination: 'brand', origin: 'brand', isRequired: true},
  {destination: 'condition', origin: 'condition', isRequired: true},
  {destination: 'google_product_category', origin: 'category', isRequired: true},
  {destination: 'id', origin: 'sku', isRequired: true},
  {destination: 'image_link', origin: 'image', isRequired: true},
  {destination: 'price', origin: 'price', isRequired: true},
  {destination: 'shipping', origin: 'shipping', isRequired: false},
  {destination: 'link', origin: 'link', isRequired: true},
  {destination: 'title', origin: 'title', isRequired: true},
  {destination: 'additional_image_link', origin: 'additionalImages', isRequired: false},
  {destination: 'energy_efficiency_class', origin: 'energyEfficiencyClass', isRequired: false},
  {destination: 'gtin', origin: 'gtin', isRequired: false},
  {destination: 'item_group_id', origin: 'itemGroupId', isRequired: false},
  {destination: 'max_energy_efficiency_class', origin: '', isRequired: false},
  {destination: 'min_energy_efficiency_class', origin: '', isRequired: false},
  {destination: 'mpn', origin: 'mpn', isRequired: false},
  {destination: 'product_type', origin: 'type', isRequired: false},
  {destination: 'sale_price', origin: 'salePrice', isRequired: false},
  {destination: 'unit_pricing_measure', origin: '', isRequired: false},
  {destination: 'ads_grouping', origin: '', isRequired: false},
  {destination: 'ads_labels', origin: '', isRequired: false},
  {destination: 'ads_redirect', origin: '', isRequired: false},
  {destination: 'availability_date', origin: '', isRequired: false},
  {destination: 'cost_of_goods_sold', origin: '', isRequired: false},
  {destination: 'custom_label_0', origin: '', isRequired: false},
  {destination: 'custom_label_1', origin: '', isRequired: false},
  {destination: 'custom_label_2', origin: '', isRequired: false},
  {destination: 'custom_label_3', origin: '', isRequired: false},
  {destination: 'custom_label_4', origin: '', isRequired: false},
  {destination: 'expiration_date', origin: '', isRequired: false},
  {destination: 'identifier_exists', origin: 'identifierExists', isRequired: false},
  {destination: 'installment_amount', origin: '', isRequired: false},
  {destination: 'installment_months', origin: '', isRequired: false},
  {destination: 'is_bundle', origin: '', isRequired: false},
  {destination: 'max_handling_time', origin: '', isRequired: false},
  {destination: 'min_handling_time', origin: '', isRequired: false},
  {destination: 'multipack', origin: '', isRequired: false},
  {destination: 'product_detail_attribute_name', origin: '', isRequired: false},
  {destination: 'product_detail_attribute_value', origin: '', isRequired: false},
  {destination: 'product_detail_section_name', origin: '', isRequired: false},
  {destination: 'product_highlight', origin: '', isRequired: false},
  {destination: 'promotion_id', origin: '', isRequired: false},
  {destination: 'sale_price_effective_date', origin: '', isRequired: false},
  {destination: 'shipping_height', origin: '', isRequired: false},
  {destination: 'shipping_label', origin: '', isRequired: false},
  {destination: 'shipping_length', origin: '', isRequired: false},
  {destination: 'shipping_weight', origin: '', isRequired: false},
  {destination: 'shipping_width', origin: '', isRequired: false},
  {destination: 'shopping_ads_excluded_country', origin: '', isRequired: false},
  {destination: 'short_title', origin: '', isRequired: false},
  {destination: 'subscription_amount', origin: '', isRequired: false},
  {destination: 'subscription_period', origin: '', isRequired: false},
  {destination: 'subscription_period_length', origin: '', isRequired: false},
  {destination: 'tax_category', origin: '', isRequired: false},
  {destination: 'transit_time_label', origin: '', isRequired: false},
  {destination: 'unit_pricing_base_measure', origin: '', isRequired: false},
  {destination: 'mobile_link', origin: '', isRequired: false},
  {destination: 'adwords_grouping', origin: '', isRequired: false},
  {destination: 'adwords_labels', origin: '', isRequired: false},
  {destination: 'adwords_redirect', origin: '', isRequired: false},
  {destination: 'category', origin: '', isRequired: false}

]


export default criteoProperties;
