import React, {useReducer} from 'react';
import {initialState, ProjectReducer} from './ProjectReducer';

const ProjectStateContext = React.createContext();
const ProjectDispatchContext = React.createContext();

export function useProjectState() {
  const context = React.useContext(ProjectStateContext);
  if (context === undefined) {
    throw new Error('useProjectState must be used within a ProjectProvider');
  }

  return context;
}

export function useProjectDispatch() {
  const context = React.useContext(ProjectDispatchContext);
  if (context === undefined) {
    throw new Error('useProjectDispatch must be used within a ProjectProvider');
  }

  return context;
}

export const ProjectProvider = ({children}) => {
  const [project, dispatch] = useReducer(ProjectReducer, initialState);

  return (
    <ProjectStateContext.Provider value={project}>
      <ProjectDispatchContext.Provider value={dispatch}>
        {children}
      </ProjectDispatchContext.Provider>
    </ProjectStateContext.Provider>
  );
};
