import {useImportState} from "../Import/Context/ImportContext";
import React from "react";
import {ImportCompaniesList} from "../Import/ImportCompaniesList";
import {ImportProjectsList} from "../Import/ImportProjectsList";
import {Button, Card, CardFooter, Row} from "reactstrap";
import {useNavigate} from "react-router-dom";

export const ShopifyConnectionList = () => {
  const navigate = useNavigate();
  const importState = useImportState();

  function handleCreate() {
    navigate(`/admin/companies/${importState.selected_company}/projects/${importState.selected_project}/data_sources/new/api_connection`)
  }

  return (
    <>
      <div className={'d-flex flex-column'}>
        <div className={'d-flex flex-row justify-content-between'}>
            <h1 className={'ms-4'}>Create Shopify Connection</h1>
          </div>
      </div>
      <div className={'mt-4'}>
        <Card className={'flex-grow-1 d-flex flex-column'}>
          <div className={'m-4'}>
            <h4>Select the company and project where you want to create the connection:</h4>
          </div>
          <Row className={'ms-5 me-5'}>
              <div className={'flex-basis-50 d-flex flex-column'}>
                <ImportCompaniesList/>
              </div>
              <div className={'flex-basis-50 d-flex flex-column'}>
                <ImportProjectsList/>
              </div>
          </Row>
          <CardFooter>
            <div className={'d-flex flex-row justify-content-end'}>
              <Button color="primary" type="button"
                      onClick={handleCreate}>
                Create
              </Button>
            </div>
          </CardFooter>
        </Card>
      </div>
    </>
  )}
