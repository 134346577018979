import React from "react";
import {CanvasObjectInfoSidebarDetailTwoPriceCommon} from "./CanvasObjectInfoSidebarDetailTwoPriceCommon";
import {CanvasObjectInfoSidebarDetailTwoPriceFinalPrice} from "./CanvasObjectInfoSidebarDetailTwoPriceFinalPrice";
import {CanvasObjectInfoSidebarDetailTwoPriceStrickedPrice} from "./CanvasObjectInfoSidebarDetailTwoPriceStrickedPrice";

export const CanvasObjectInfoSidebarDetailTwoPrice = () => {
  return (
    <>
      <CanvasObjectInfoSidebarDetailTwoPriceCommon/>
      <CanvasObjectInfoSidebarDetailTwoPriceFinalPrice/>
      <CanvasObjectInfoSidebarDetailTwoPriceStrickedPrice/>
    </>
  );
};
