import React from 'react';
import {Card, CardBody, CardFooter, CardImg} from "reactstrap";
import classNames from "classnames";

const FeedExportPlatformItem = ({platform, selectedPlatform, setSelectedPlatform}) => {

  const handleClick = () => {
    setSelectedPlatform(platform.code)
  }

  return (
    <>
      <Card className={classNames({
        'platform-card': true,
        'asset selected': selectedPlatform === platform.code
      })} onClick={handleClick}>
        <CardBody className={'d-flex flex-column justify-content-center'}>
          <CardImg width={150} src={platform.image}/>
        </CardBody>
        <CardFooter className={'d-flex flex-row w-100 justify-content-between'}>
          <p>{platform.name}</p>
        </CardFooter>
      </Card>
    </>
  )
}

export default FeedExportPlatformItem;
