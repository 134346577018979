import React, {useReducer} from 'react';
import {initialState, UrlParametersReducer} from './UrlParametersReducer';

const UrlParametersStateContext = React.createContext();
const UrlParametersDispatchContext = React.createContext();

export function useUrlParametersState() {
  const context = React.useContext(UrlParametersStateContext);
  if (context === undefined) {
    throw new Error('useUrlParametersState must be used within a UrlParametersStateProvider');
  }

  return context;
}

export function useUrlParametersDispatch() {
  const context = React.useContext(UrlParametersDispatchContext);
  if (context === undefined) {
    throw new Error('useUrlParametersDispatch must be used within a UrlParametersProvider');
  }

  return context;
}

export const UrlParametersProvider = ({children}) => {
  const [urlParameters, dispatch] = useReducer(UrlParametersReducer, initialState);

  return (
    <UrlParametersStateContext.Provider value={urlParameters}>
      <UrlParametersDispatchContext.Provider value={dispatch}>
        {children}
      </UrlParametersDispatchContext.Provider>
    </UrlParametersStateContext.Provider>
  );
};
