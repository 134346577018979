import React, {useReducer} from 'react';
import {initialState, RuleReducer} from './RuleReducer';

const RuleStateContext = React.createContext();
const RuleDispatchContext = React.createContext();

export function useRuleState() {
  const context = React.useContext(RuleStateContext);

  return context;
}

export function useRuleDispatch() {
  const context = React.useContext(RuleDispatchContext);
  if (context === undefined) {
    throw new Error('useRuleDispatch must be used within a RuleProvider');
  }

  return context;
}

export const RuleProvider = ({children}) => {
  const [Rule, dispatch] = useReducer(RuleReducer, initialState);

  return (
    <RuleStateContext.Provider value={Rule}>
      <RuleDispatchContext.Provider value={dispatch}>
        {children}
      </RuleDispatchContext.Provider>
    </RuleStateContext.Provider>
  );
};
