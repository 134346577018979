import React from "react";
import ResetPassword from "../../../components/ResetPassword/ResetPassword";
import {useParams} from "react-router-dom";

const ResetPasswordView = () => {
  const routerParams = useParams()

  return (
    <>
      <ResetPassword
        userId={routerParams.userId}
        confirmation={routerParams.confirmation}
      />
    </>
  );
};

export default ResetPasswordView;
