const internalProperties = [
  'availability',
  'brand',
  'category',
  'color',
  'condition',
  'description',
  'discount',
  'gender',
  'gtin',
  'inventories',
  'image',
  'additionalImages',
  'link',
  'material',
  'mpn',
  'price',
  'quantity',
  'renderedImage',
  'salePrice',
  'shipping',
  'size',
  'sku',
  'store_id',
  'title',
  'type',
  'weight',
  'energyEfficiencyClass',
  'itemGroupId',
  'identifierExists',
  'videos',
]


export {internalProperties};
