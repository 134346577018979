import React from 'react'
import classNames from "classnames";
import {select, useRulesDispatch, useRulesState} from "../../../context/rules/RulesIndex";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from '@dnd-kit/utilities';

export const FeedExportRuleItem = ({rule, id}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: id});
  const rulesDispatch = useRulesDispatch()
  const rulesState = useRulesState()
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const handleClick = () => {
    select(rulesDispatch, rule.id)
  }

  return (
    <>
      <div ref={setNodeRef} style={style}>
        <div className={classNames({
          'd-flex flex-row justify-content-between align-items-center': true,
          'p-3': true,
          feed_export_rules_item: true,
          disabled: rule.status !== 'ACTIVE',
          selected: rulesState.selected.id === rule.id
        })} onClick={handleClick}>
          <p className="mb-0">
            {rule.name}
          </p>
          <span
            className={classNames({
              'feed_export_drag_button fa-solid fa-bars align-content-center': true,
              selected: rulesState.selected.id === rule.id
            })}
            {...attributes} {...listeners}/>
        </div>
      </div>
    </>
  )
}
