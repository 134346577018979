import React, {useState} from "react";
import {Button, Card, CardBody, Input} from "reactstrap";
import Select from "react-select";

export const CanvasHeaderOptionsModal = ({discount, price, handleSubmitDialog, handleOpenCloseDialog}) => {
  const [state, setState] = useState({
    sku: '',
    discount: discount,
    price: price,
  })

  const handleChange = (e) => {
    const {value} = e.target
    setState({...state, sku: value})
  }

  const handleChangePrice = (e) => {
    const {value} = e
    setState({...state, price: value})
  }

  const handleChangeDiscount = (e) => {
    const {value} = e
    setState({...state, discount: value})
  }

  const handleSubmit = () => {
    handleSubmitDialog(state.sku, state.price, state.discount)
    setState({...state, sku: ''})
  }

  const priceOptions = [
    {
      "value": "ANY",
      "label": "Any",
    },
    {
      "value": "SMALL",
      "label": "Small (0-99)",
    },
    {
      "value": "MEDIUM",
      "label": "Medium (100-999)",
    },
    {
      "value": "LARGE",
      "label": "Large (1000 - ...)",
    },
  ]
  const discountOptions = [
    {
      "value": "ANY",
      "label": "Any",
    },
    {
      "value": "WITH_DISCOUNT",
      "label": "With Discount",
    },
    {
      "value": "WITHOUT_DISCOUNT",
      "label": "Without Discount",
    },
  ]

  return (
    <>
      <Card>
        <CardBody className={'d-flex flex-column'}>
          <div className={'align-self-end'}>
            <span className={'fa fa-solid fa-times'} onClick={handleOpenCloseDialog}/>
          </div>
          <div className={'d-flex flex-column'}>
            <span className={'align-self-start'}>SKU</span>
            <Input
              value={state.sku}
              name={'sku'}
              onChange={handleChange}
            />
            <span className={'align-self-start mt-3'}>Price</span>
            <Select
              name={'price'}
              value={priceOptions.find(priceOption => priceOption.value === state.price)}
              options={priceOptions}
              onChange={handleChangePrice}
            />
            <span className={'align-self-start mt-3'}>Discount</span>
            <Select
              name={'discount'}
              value={discountOptions.find(discountOption => discountOption.value === state.discount)}
              options={discountOptions}
              onChange={handleChangeDiscount}
            />
            <div className={'mt-3 align-self-end'}>
              <Button onClick={handleSubmit}
                      color={'primary'}>Apply</Button>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  )
}
