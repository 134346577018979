import React, {useEffect, useState} from 'react';
import {Pagination, PaginationItem, PaginationLink} from "reactstrap";


const Paginator = ({
                     pagination,
                     offset,
                     limit,
                     handleClick,
                     showRowOptions = true,
                     numRows = [10, 30, 50, 100, 200, 500]
                   }) => {
  let maxItems = 5
  const [paginator, setPaginator] = useState({})

  const handleChange = (e) => {
    handleClick(e, 0, e.target.value)
  }
  
  useEffect(() => {
    setPaginator(getPagination(pagination.current, pagination.total_pages))
  }, [pagination, offset, limit])

  const getPagination = (currentPage, totalPages) => {
    let i, j
    let pagination = {
      current: currentPage,
      first: {value: 1, status: totalPages !== 1 && currentPage !== 1},
      before: {value: currentPage - 1, status: totalPages !== 1 && currentPage !== 1},
      next: {value: currentPage + 1, status: totalPages !== 1 && currentPage !== totalPages},
      last: {value: totalPages, status: totalPages !== 1 && currentPage !== totalPages},
      items: []
    }
    if (totalPages < maxItems) {
      i = 1;
      j = 1;
      for (i; i <= totalPages; i++) {
        pagination.items.push({status: i !== currentPage, value: i});
      }
      return pagination;
    }
    if (currentPage < totalPages - Math.floor(maxItems / 2)) {
      i = Math.max(1, currentPage - Math.floor(maxItems / 2));
      j = i;
      for (i; i < Math.min(maxItems + j, totalPages); i++) {
        pagination.items.push({status: i !== currentPage, value: i});
      }
      return pagination;
    }

    i = totalPages - maxItems + 1;
    j = i;
    for (i; i < maxItems + j; i++) {
      pagination.items.push({status: i !== currentPage, value: i});
    }
    return pagination;
  }

  const paginationStatus = () => {
    return `${(pagination.current - 1) * limit + 1} - ${Math.min(pagination.current * limit, pagination.total_items)} of ${pagination.total_items}`
  }

  return (<>
    <div className={'d-flex justify-content-between'}>
      <Pagination className={"pagination ms-auto me-auto align-items-center"}>
        <PaginationItem disabled={!paginator?.first?.status}>
          <PaginationLink
            onClick={e => handleClick(e, 0, limit)}
            first
            href="#"
          />
        </PaginationItem>
        <PaginationItem disabled={!paginator?.before?.status}>
          <PaginationLink
            onClick={e => handleClick(e, (paginator?.before.value - 1) * limit, limit)}
            previous
            href="#"
          />
        </PaginationItem>
        {paginator.items?.map((page, i) => <PaginationItem active={page.value === paginator?.current} key={i}>
          <PaginationLink onClick={e => handleClick(e, (page.value - 1) * limit, limit)} href="#">
            {page.value}
          </PaginationLink>
        </PaginationItem>)}
        <PaginationItem disabled={!paginator?.next?.status}>
          <PaginationLink
            onClick={e => handleClick(e, (paginator.next.value - 1) * limit, limit)}
            next
            href="#"
          />
        </PaginationItem>
        <PaginationItem disabled={!paginator?.last?.status}>
          <PaginationLink
            onClick={e => handleClick(e, (paginator.last.value - 1) * limit, limit)}
            last
            href="#"
          />
        </PaginationItem>
      </Pagination>
      {
        showRowOptions ?
          <div className={'d-flex align-items-center'}>
            <span className={'me-2 align-self-center'}>Show rows:</span>
            <div className={'d-flex me-2'}>
              <select name="limit"
                      className="form-control"
                      value={limit}
                      onChange={handleChange}>
                {
                  numRows.map((rowValue, i) => {
                    return <option key={i} value={rowValue}>{rowValue}</option>
                  })

                }

              </select>
            </div>
            <span>{paginationStatus()}</span>
          </div> :
          <></>
      }
    </div>
  </>)
}

export default Paginator;
