import React, {useState} from 'react';
import {Card, CardBody, CardHeader, CardTitle} from "reactstrap";
import Select from "react-select";
import 'chartjs-adapter-date-fns'
import {DateSelector} from "../Analytics/DateSelector";
import {ProductDiagnosticIssueTypologiesTable} from "./ProductDiagnosticIssueTypologiesTable/ProductDiagnosticIssueTypologiesTable";
import {ProductDiagnosticHealthGauge} from "./ProductDiagnosticHealthGauge";
import {ProductDiagnosticHistory} from "./ProductDiagnosticHistory";

export const ProductDiagnosticViewer = () => {
  const eligiblePlatforms = [
    {value: 'internal', label: 'Feedest'},
    {value: 'google.Shopping', label: 'Google Shopping'},
    {value: 'google.LocalInventoryAds', label: 'Google Local Inventory'},
  ]

  const [state, setState] = useState({
    platform: 'internal',
    destination: '',
  })

  const platformChange = (e) => {
    let splitted = e.value.split('.')
    setState({
      platform: splitted[0],
      destination: splitted[1] ?? ''
    })
  }

  return (
    <>
      <Card>
        <CardHeader className={'d-flex flex-row justify-content-between align-items-center'}>
          <CardTitle className={'flex-grow-1 m-0'}>
            <h1>
              Health
            </h1>
          </CardTitle>
          <div className={'d-flex justify-content-evenly align-items-center'}>
            <Select
              name={"platform"}
              onChange={platformChange}
              value={eligiblePlatforms.find(p => state.platform === p.value)}
              options={eligiblePlatforms.map(platform => ({
                value: platform.value ?? 'null',
                label: platform.label ?? 'Empty'
              }))}
              placeholder={'Select Platform'}
            />
            <DateSelector className={'ms-1'}/>
          </div>
        </CardHeader>
        <CardBody>
          <div className={'d-flex flex-row mt-3 justify-content-between'}>
            <ProductDiagnosticHealthGauge
              className={'flex-basis-20'}
              platform={state.platform}
              destination={state.destination}
            />
            <ProductDiagnosticHistory
              className={'flex-basis-75'}
              platform={state.platform}
              destination={state.destination}
            />
          </div>
        </CardBody>
      </Card>
      <ProductDiagnosticIssueTypologiesTable
        platform={state.platform}
        destination={state.destination}
      />
    </>);
}
