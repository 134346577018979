import React from "react";
import {CanvasObjectInfoSidebar} from "./CanvasObjectInfoSidebar/CanvasObjectInfoSidebar";
import {CanvasObjectSidebar} from "./CanvasObjectSidebar/CanvasObjectSidebar";

export const CanvasSidebar = () => {
  return (
    <>
      <section className={'canvas_sidebar h-100 bg-white'}>
        <CanvasObjectInfoSidebar/>
        <CanvasObjectSidebar/>
      </section>
    </>
  );
}
