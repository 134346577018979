import Select from "react-select";
import {useEffect, useState} from "react";
import {useAnalyticsDispatch, useAnalyticsState} from "../../context/analytics/AnalyticsIndex";
import {updateDimensions} from "../../context/analytics/AnalyticsActions";
import {setQueryParams} from "./AnalyticsUtils";
import {useNavigate} from "react-router-dom";

export const AnalyticsDimensionsSelect = ({customGroups}) => {
  const analyticsDispatch = useAnalyticsDispatch();
  const analyticsState = useAnalyticsState();
  const [values, setValues] = useState([]);
  const baseOptions = [
    {value: 'date', label: 'Date'},
    {value: 'type', label: 'Product Type'},
    {value: 'top_level_type', label: 'Top Level Type'},
    {value: 'brand', label: 'Brand'},
    {value: 'availability', label: 'Availability'},
  ];
  const [options, setOptions] = useState(baseOptions);
  const navigate = useNavigate()

  useEffect(() => {
    let newOptions = baseOptions;
    for (const [key, value] of Object.entries(customGroups)) {
      newOptions.push(
        {
          value: key,
          label: value
        }
      )
    }
    setOptions(newOptions);
  }, [customGroups])

  useEffect(() => {
    if (analyticsState.dimensions !== undefined) {
      setValues(analyticsState.dimensions.map(item => ({
        value: item,
        label: options.find(option => option.value === item)?.label
      })));
    }
  }, [analyticsState.dates, analyticsState.metrics, analyticsState.dimensions, options]);

  const handleChange = (e) => {
    updateDimensions(analyticsDispatch, (e.map(el => el.value)));
  }

  useEffect(() => {
    setQueryParams(navigate, {
      'dimensions[]': analyticsState.dimensions
    })
  }, [analyticsState.dimensions])

  return (
    <>
      <Select
        placeholder="Dimensions"
        value={values}
        options={options}
        isMulti="true"
        onChange={handleChange}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: '#553AC0',
            ':hover': {
              borderColor: '#553AC0',
            },
          }),
          multiValueLabel: (styles, {data}) => ({
            ...styles,
            color: '#FFFFFF',
            backgroundColor: '#553AC0',
            fontWeight: 'bold',
            paddingLeft: '10px',
            paddingTop: '10px',
            paddingBottom: '10px',
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px',
            fontSize: '16px',
          }),
          multiValueRemove: (styles, {data}) => ({
            ...styles,
            color: '#FFFFFF',
            backgroundColor: '#553AC0',
            fontWeight: 'bold',
            padding: '10px',
            borderTopLeftRadius: '0px',
            borderBottomLeftRadius: '0px',
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
            ':hover': {
              backgroundColor: data.color,
              color: 'grey',
            },
          }),
        }}
      />
    </>
  )
}
