import React from 'react'
import {Col, FormGroup, Input, Row} from "reactstrap";

export const FeedExportRuleActionFindValue = ({value, onChange}) => {
  const handleChange = (e) => {
    let {name, value} = e.target
    onChange(name, value.split('\n'))
  }

  return (
    <>
      <Row>
        <Col md={5}>
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="exampleFormControlTextarea3"
            >
              Search Value
            </label>
            <Input
              className={'find_text_area'}
              resize="none"
              rows="8"
              height="500"
              defaultValue={value?.values?.join('\n')}
              type="textarea"
              name={"values"}
              placeholder="Enter one value per line"
              onChange={handleChange}
            />
          </FormGroup>
        </Col>
        <Col md={5}>
          <FormGroup>
            <label
              className="form-control-label"
              htmlFor="exampleFormControlTextarea3"
            >
              Replace Value
            </label>
            <Input
              className={'find_text_area'}
              name="replaces"
              resize="none"
              rows="8"
              defaultValue={value?.replaces?.join('\n')}
              type="textarea"
              onChange={handleChange}
              placeholder="Enter one replacement per line"
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  )
}
