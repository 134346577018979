import React, {useEffect} from 'react';
import {TemplateObjects} from "../../services/api/TemplateObjects";
import {apiErrorRaised} from "../../context/api_error/ApiErrorActions";
import {useApiErrorDispatch} from "../../context/api_error/ApiErrorContext";
import CanvasFactory from "./CanvasFactory";
import {loadCanvasObjects, useCanvasDispatch, useCanvasState} from "../../context/canvas/CanvasIndex";
import {Templates} from "../../services/api/Templates";
import {loadCanvasTemplate} from "../../context/canvas/CanvasActions";
import classNames from "classnames";
import {CanvasHeader} from "./CanvasHeader";

const CanvasDesign = ({companyId, projectId, id}) => {
  const apiErrorDispatch = useApiErrorDispatch();
  const dispatch = useApiErrorDispatch();
  const canvasDispatch = useCanvasDispatch();
  const canvasState = useCanvasState();
  const params = {
    order: [
      {
        order_by: 'priority',
        order_type: 'asc'
      }
    ],
  }

  useEffect(() => {
    TemplateObjects.index(companyId, projectId, id, params).then((r) => {
      if (r?.status < 400) {
        loadCanvasObjects(canvasDispatch, r.data);
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    });
    Templates.get(companyId, projectId, id).then((r) => {
      if (r?.status < 400) {
        loadCanvasTemplate(canvasDispatch, r.data);
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    });
  }, [id, dispatch, apiErrorDispatch, canvasDispatch]);

  return (
    <>
      <div className={'order-2 flex-grow-1 d-flex flex-column'}>
        <CanvasHeader/>
        <div className={'d-flex flex-column justify-content-center flex-grow-1'}>
          <div className={'canvas_layer'}>
            <div>
              <h1>{canvasState.template.name ?? 'Template'} </h1>
            </div>
            <div className={'position-relative'}>
              <div id="canvas" className={classNames({
                canvas: true,
                "canvas-grid": canvasState.grid,
                "center-lines": canvasState.grid,
              })}>
                {canvasState.objects && canvasState.objects.map(
                  (canvasObject, id) =>
                    <CanvasFactory
                      key={id}
                      id={id}
                      canvasObject={canvasObject}
                      position={id}
                    />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CanvasDesign;
