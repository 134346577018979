import React, {useCallback, useEffect, useState} from "react";
import {Button, Card, CardBody, CardFooter, Input} from "reactstrap";
import {apiErrorRaised, useApiErrorDispatch} from "../../../context/api_error/ApiErrorIndex";
import {Projects} from "../../../services/api/Projects";
import Schedule from "../../Schedule/Schedule";
import {Link, useNavigate} from "react-router-dom";
import {v4 as uuid4} from "uuid";
import KeyValues from "../../KeyValueSelector/KeyValues";
import Select from "react-select";
import {currencies} from "../../../utils/Currencies";
import ReactFlagsSelect from "react-flags-select";

export const ProjectSettings = ({companyId, projectId, from}) => {
  const navigate = useNavigate()
  const dispatchApiError = useApiErrorDispatch()
  const [project, setProject] = useState({
    name: '',
    status: 'DISABLED',
    locale: '',
    schedule: [],
    timezone: '',
    conditions: [],
    default_properties: [],
    currency: '',
  })
  const [scheduleComponent, setScheduleComponent] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const isNewProject = projectId === null || projectId === undefined

  const customLocaleLabels = {
    "GB": {
      "primary": "United Kingdom",
      "secondary": "en_GB"
    },
    "US": {
      "primary": "USA",
      "secondary": "en_US"
    },
    "ES": {
      "primary": "Spain",
      "secondary": "es_ES"
    },
    "DE": {
      "primary": "Germany",
      "secondary": "de_DE"
    },
    "FR": {
      "primary": "France",
      "secondary": "fr_FR"
    },
    "PT": {
      "primary": "Portugal",
      "secondary": "pt_PT"
    },
    "IT": {
      "primary": "Italy",
      "secondary": "it_IT"
    },
    "SE": {
      "primary": "Sweden",
      "secondary": "sv_SE"
    },
    "AT": {
      "primary": "Austria",
      "secondary": "de_AT"
    },
    "BE": {
      "primary": "Belgium",
      "secondary": "fr_BE"
    },
    "NL": {
      "primary": "Netherlands",
      "secondary": "nl_NL"
    },
    "IE": {
      "primary": "Ireland",
      "secondary": "en_IE"
    },
    "DK": {
      "primary": "Denmark",
      "secondary": "da_DK"
    },
    "PE": {
      "primary": "Perú",
      "secondary": "es_PE"
    },
    "CH": {
      "primary": "Switzerland",
      "secondary": "de_CH"
    },
    "NO": {
      "primary": "Norway",
      "secondary": "no_NO"
    },
    "FI": {
      "primary": "Finland",
      "secondary": "fi_FI"
    },
    "PL": {
      "primary": "Poland",
      "secondary": "pl_PL"
    },
    "CZ": {
      "primary": "Czech Republic",
      "secondary": "cs_CZ"
    },
    "HU": {
      "primary": "Hungary",
      "secondary": "hu_HU"
    },
    "RO": {
      "primary": "Romania",
      "secondary": "ro_RO"
    },
    "GR": {
      "primary": "Greece",
      "secondary": "el_GR"
    },
    "LU": {
      "primary": "Luxembourg",
      "secondary": "fr_LU"
    },
    "AD": {
      "primary": "Andorra",
      "secondary": "ca_AD"
    },
    "TR": {
      "primary": "Turkey",
      "secondary": "tr_TR"
    },
    "BR": {
      "primary": "Brazil",
      "secondary": "pt_BR"
    },
    "AR": {
      "primary": "Argentina",
      "secondary": "es_AR"
    },
    "MX": {
      "primary": "Mexico",
      "secondary": "es_MX"
    },
    "CL": {
      "primary": "Chile",
      "secondary": "es_CL"
    },
    "CO": {
      "primary": "Colombia",
      "secondary": "es_CO"
    },
    "AU": {
      "primary": "Australia",
      "secondary": "en_AU"
    }
  }

  useEffect(() => {
    if (isNewProject) {
      setProject({...project, id: uuid4()})
      setScheduleComponent(
        <Schedule
          parentCallback={handleScheduleChange}
        />
      )
      return;
    }
    setLoading(true)
    Projects.get(companyId, projectId).then((r) => {
      setLoading(false)
      if (r?.status < 400) {
        setProject(r?.data)
        setCountryBasedOnLocale(r?.data.locale)
        setScheduleComponent(
          <Schedule
            interval={r.data.schedule.interval}
            hour={r.data.schedule.hour}
            minute={r.data.schedule.minute}
            interval_count={r.data.schedule.interval_count}
            timezone={r.data.schedule.timezone}
            locale={r.data.locale}
            parentCallback={handleScheduleChange}
          />
        )
      } else {
        apiErrorRaised(dispatchApiError, r?.response)
      }
    })
  }, [projectId]);

  const setCountryBasedOnLocale = (locale) => {
    for (const [key, value] of Object.entries(customLocaleLabels)) {
      if (value.secondary === locale) {
        setCountry(key)
      }
    }
  }

  const handleChange = (e) => {
    const {name, value} = e.target;
    setProject(project => ({...project, [name]: value}));
  }

  const handleDefaultPropertiesChange = useCallback((defaultProperties) => {
    let formDefaultProperties = defaultProperties.map(function (item) {
      return {
        'name': item.name,
        'value': item.value
      }
    }, {});
    setProject(project => ({...project, 'defaultProperties': formDefaultProperties}));
  }, []);

  const handleScheduleChange = useCallback((schedule) => {
    let copy = schedule
    copy.hour = parseInt(schedule.hour)
    copy.minute = parseInt(schedule.minute)
    setProject(project => (
        {
          ...project,
          'schedule': copy,
          'timezone': copy.timezone,
        }
      )
    );
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitted(true)

    if (project.name) {
      setLoading(true)
      let {id, last_read, properties, products_detected, ...projectData} = project;
      Projects.put(companyId, id, projectData).then((r) => {
        setLoading(false)
        if (r?.status < 400) {
          if (from !== null && from !== undefined) {
            navigate(from)
          }
          navigate(`/admin/companies/${companyId}`);
        } else {
          apiErrorRaised(dispatchApiError, r?.response)
        }
      })
    }
  }

  const onChange = (e) => {
    const {value} = e;
    setProject({...project, currency: value})
  }
  const [country, setCountry] = useState("");

  const handleSelectLocale = (code) => {
    setCountry(code)
    setProject({
      ...project,
      locale: customLocaleLabels[code].secondary
    })
  }

  const handleToggle = (e) => {
    const {name, checked} = e.target;
    if (checked) {
      setProject({
        ...project,
        status: 'ACTIVE',
      })
      return
    }
    setProject({
      ...project,
      status: 'DISABLED',
    })
  }
  return (
    <>
      <div className={'view_header'}>
        <div className={'back-button'}>{
          <Link
            to={from !== undefined && from !== null ? from : `/admin/companies/`}>
            <Button className={'btn-icon-only text-dark'}>
              <span className={'fa fa-arrow-left'}/>
            </Button>
          </Link>
        }</div>
        <div className={'title'}>
          <h1>Settings</h1>
        </div>
        <div className={'button_actions'}>
        </div>
      </div>
      <Card className="shadow mt-3">
        <CardBody>
          <div className={'d-flex flex-column'}>
            <div className={'d-flex'}>
              <div className={'d-flex flex-column mb-3 flex-grow-1 me-5'}>
                <h4>Project Name</h4>
                <div className={'d-flex'}>
                  <Input
                    placeholder="Project Name"
                    type="text"
                    valid={submitted && project.name.length > 0}
                    invalid={submitted && !project.name}
                    name="name"
                    value={project.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="invalid-feedback">
                  Please provide a name
                </div>
              </div>
              <div className={'d-flex flex-column justify-content-evenly mb-3'}>
                <h4>Status</h4>
                <label className="custom-toggle me-1">
                  <input type="checkbox" name="status" checked={project.status === 'ACTIVE'}
                         onChange={handleToggle}/>
                  <span className="custom-toggle-slider rounded-circle"/>
                </label>
              </div>
            </div>

            <div className={'d-flex flex-column mb-3'}>
              <h4>Schedule</h4>
              {scheduleComponent}
            </div>
            <div className={'d-flex flex-column mt-5'}>
              <h4>Default Properties</h4>
              <KeyValues parentCallback={handleDefaultPropertiesChange}
                         values={project.default_properties}/>
            </div>
            <div className={'d-flex justify-content-between'}>
              <div className={'d-flex flex-column mt-5 flex-grow-1'}>
                <h3>Currency</h3>
                <div className={'d-flex flex-row'}>
                  <Select
                    isDisabled={!isNewProject}
                    className={'flex-grow-1 pe-5'}
                    value={currencies.find(currency => currency.value === project.currency)}
                    onChange={onChange}
                    options={currencies}
                  />
                </div>
                <div className="invalid-feedback">
                  Please provide a valid locale
                </div>
              </div>
              <div className={'d-flex flex-column mt-5 flex-grow-1'}>
                <h3>Locale</h3>
                <ReactFlagsSelect
                  selected={country}
                  className={'pb-0'}
                  searchable={true}
                  placeholder={'Select region'}
                  countries={['AD', 'AR', 'AT', 'AU', 'BE', 'BR', 'CL', 'CO', 'CZ', 'DK', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LU', 'MX', 'NL', 'NO', 'PE', 'PL', 'PT', 'RO', 'SE', 'ES', 'CH', 'TR', 'GB', 'US']}
                  customLabels={customLocaleLabels}
                  disabled={!isNewProject}
                  onSelect={(code) => handleSelectLocale(code)}
                />
              </div>
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <div className={'d-flex flex-row justify-content-end'}>
            <Button
              className={'float-right'}
              color={'primary'}
              onClick={handleSubmit}
            >
              {isLoading &&
                <span className="spinner-border spinner-border-sm me-1"/>}
              {isNewProject ? 'Create project' : 'Save'}
            </Button>
          </div>
        </CardFooter>
      </Card>
    </>
  )
}
