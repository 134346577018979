import {Button, Col, FormGroup, Input, Row} from "reactstrap";
import React, {useState} from "react";
import {v4 as uuid4} from "uuid";

export const TableFilterEditString = ({filter, applyFilter}) => {
  const [state, setState] = useState({
    id: filter?.id !== undefined ? filter.id : uuid4(),
    field: filter.field,
    operator: filter.operator !== undefined ? filter.operator : '=',
    value: filter?.value.replaceAll('%', ''),
    label: filter?.label,
    type: filter?.type,
  })

  return (<>
    <FormGroup>
      <Input
        type="select"
        className={'form-control'}
        value={state.operator}
        onChange={e => setState({...state, operator: e.target.value})}
      >
        <option value="=">Equals</option>
        <option value="!=">Not Equals</option>
        <option value="CONTAINS">Contains</option>
        <option value="STARTS_WITH">Starts With</option>
        <option value="ENDS_WITH">Ends With</option>
        <option value="IS NULL">Is NULL</option>
        <option value="IS NOT NULL">Is not NULL</option>
      </Input>
    </FormGroup>
    {
      state.operator !== 'IS NULL' && state.operator !== 'IS NOT NULL' &&
      <FormGroup
        className="mb-3">
        <Input
          placeholder="Value"
          type="text"
          name="value"
          value={state.value}
          onChange={e => setState({...state, value: e.target.value})}
        />
      </FormGroup>
    }
    <Row>
      <Col>
        <Button className="btn-info float-right" outline onClick={() => applyFilter(state)}>
          Apply
        </Button>
      </Col>
    </Row>
  </>)
}
