
export const initialState = {
  conditions: [],
  fields: [],
};

export const FilterReducer = (initialState, action) => {
  switch (action.type) {
    case "FILTERS_FETCHED":
      return {
        conditions: action.payload.conditions,
        fields: action.payload.fields
      };

    case "FILTERS_UPDATED":
      return {
        ...initialState,
        conditions: action.payload.conditions,
        fields: action.payload.fields,
      }

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
