import {get, post, put} from './config/base';
import {handleError, handleResponse} from "./config/response";
import {urlParser} from "./config/utils";

const url = 'companies/{companyId}/projects/{projectId}/assets/';

export const Assets = {
  index: (companyId, projectId, params = null) =>
    get(`${urlParser(url, companyId, projectId)}`, {params: params})
      .then(handleResponse)
      .catch(handleError),
  get: (companyId, projectId, id) =>
    get(`${urlParser(url, companyId, projectId)}${id}`)
      .then(handleResponse)
      .catch(handleError),
  put: (companyId, projectId, id, payload) =>
    put(`${urlParser(url, companyId, projectId)}${id}`, payload)
      .then(handleResponse)
      .catch(handleError),
  upload: (companyId, projectId, payload) => {
    let formData = new FormData();
    formData.append('file', payload);
    return post(`${urlParser(url, companyId, projectId)}upload`, formData)
      .then(handleResponse)
      .catch(handleError)
  },
}
