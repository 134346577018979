import React from "react";

// reactstrap components
import {Col, Container, Row} from "reactstrap";

function AdminFooter() {
  return (
    <>
      <Container fluid className={'mt-5'}>
        <footer className="footer pt-0">
          <Row className="align-items-center justify-content-lg-between">
            <Col lg="6">
              <div className="copyright text-left text-lg-left text-muted">
                © {new Date().getFullYear()}{" "}
                <a
                  className="font-weight-bold ms-1"
                  href="https://feedest.io"
                  target="_blank"
                >
                  Feedest
                </a>
              </div>
            </Col>
            <Col lg="6">

            </Col>
          </Row>
        </footer>
      </Container>
    </>
  );
}

export default AdminFooter;
