import React, {useEffect, useState} from 'react';
import {Rules} from "../../services/api/Rules";
import {load} from "../../context/rules/RulesActions";
import {apiErrorRaised} from "../../context/api_error/ApiErrorActions";
import {useRulesDispatch, useRulesState} from "../../context/rules/RulesIndex";
import {useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {Button} from "reactstrap";
import {Link} from "react-router-dom";
import {FeedExportRulesList} from "../FeedExports/FeedExportRules/FeedExportRulesList";
import {RuleProvider} from "../../context/rule/RuleContext";
import {FeedExportRuleDetail} from "../FeedExports/FeedExportRules/FeedExportRuleDetail";
import {RuleGroups} from "../../services/api/RuleGroups";
import {RuleGroupNameEditableModal} from "./RuleGroupEditableModal";

export const RuleGroupDetail = ({companyId, projectId, id}) => {
  const rulesState = useRulesState()
  const rulesDispatch = useRulesDispatch()
  const apiErrorDispatch = useApiErrorDispatch()
  const [ruleGroup, setRuleGroup] = useState()
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    RuleGroups.get(companyId, projectId, id).then((r) => {
      if (r?.status < 400) {
        setRuleGroup(r.data)
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    })

    Rules.index(companyId, projectId, id, {
        order: [{
          order_by: 'order',
          order_type: 'asc'
        }]
      }
    ).then((r) => {
      if (r?.status < 400) {
        load(rulesDispatch, r.data.rules)
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    })
  }, [id])

  const setName = (name) => {
    setRuleGroup({...ruleGroup, name: name})
  }

  const handleNewClick = () => {
    // change name
    setLoading(true)
    RuleGroups.put(companyId, projectId, ruleGroup.id, {
      name: ruleGroup.name,
      type: ruleGroup.type
    }).then((r) => {
      if (r?.status < 400) {
        let data = rulesState?.rules.map((rule, i) => {
            return {...rule, order: i + 1}
          }
        )
        Rules.put_batch(companyId, projectId, id, data).then((r) => {
          setLoading(false)
          if (r?.status < 400) {

          } else {
            apiErrorRaised(apiErrorDispatch, r?.response)
          }
        });
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    })
  }

  return (
    <>
      <div className={'view_header'}>
        <div className={'back-button'}>{
          <Link
            to={`/admin/companies/${companyId}/projects/${projectId}/rule_groups/`}>
            <Button className={'btn-icon-only text-dark'}>
              <span className={'fa fa-arrow-left'}/>
            </Button>
          </Link>
        }</div>
        <div className={'title d-flex flex-row align-items-center'}>
          <h1 className={'m-0'}>{ruleGroup?.name}</h1>
          <span className={'fa-solid fa-pen-to-square ms-4 pencil-button'} onClick={() => setShowModal(true)}/>
        </div>
      </div>
      <div className={'d-flex flex-row mt-4'}>
        <div className={'rule_list me-3'}>
          <FeedExportRulesList ruleGroupId={id}/>
        </div>
        <div className={'flex-grow-1'}>
          <RuleProvider>
            <FeedExportRuleDetail/>
          </RuleProvider>
        </div>
      </div>
      <div className={'d-flex flex-row justify-content-end'}>
        <Button className={'float-right'} color={'primary'} onClick={handleNewClick}>
          {
            isLoading ?
              <span className="spinner-border spinner-border-sm me-1"/>
              : <>
                Save
              </>
          }
        </Button>
      </div>
      <RuleGroupNameEditableModal
        name={ruleGroup?.name}
        setName={setName}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  )
}
