export const FilterOperators = {
  EQUAL: '=',
  NOT_EQUAL: '!=',
  GT: '>',
  LT: '<',
  CONTAINS: 'CONTAINS',
  NOT_CONTAINS: 'NOT_CONTAINS',
  IN: 'IN',
  IS_NULL: 'IS NULL',
  IS_NOT_NULL: 'IS NOT NULL',
  ARRAY_CONTAINS: 'ARRAY_CONTAINS',
  ARRAY_SIZE_EQUALS: 'ARRAY_SIZE_EQUALS',
  ARRAY_SIZE_NOT_EQUALS: 'ARRAY_SIZE_NOT_EQUALS',
  ARRAY_SIZE_GT: 'ARRAY_SIZE >',
  ARRAY_SIZE_LT: 'ARRAY_SIZE <',
}
