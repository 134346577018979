import React, {useEffect, useState} from 'react';
import {Rnd} from "react-rnd";
import {addFont, resizeObject, selectCanvasObject} from "../../../context/canvas/CanvasActions";
import {useCanvasDispatch, useCanvasState} from "../../../context/canvas/CanvasIndex";
import classNames from "classnames";
import {
  ConvertHorizontalAlignToJustifyContent,
  ConvertRGBtoHex,
  ConvertVerticalAlignToJustifyContent
} from "../CanvasUtils";
import {Assets} from "../../../services/api/Assets";
import {apiErrorRaised} from "../../../context/api_error/ApiErrorActions";
import {useApiErrorDispatch} from "../../../context/api_error/ApiErrorIndex";
import {useProjectState} from "../../../context/project/ProjectIndex";

export const CanvasTwoPrice = ({position}) => {
  const projectState = useProjectState()
  const apiErrorDispatch = useApiErrorDispatch()
  const canvasDispatch = useCanvasDispatch();
  const canvasState = useCanvasState()
  const templateObject = canvasState.objects[position];
  const [finalPriceFont, setFinalPriceFont] = useState('')
  const [strickedPriceFont, setStrickedPriceFont] = useState('')
  const [finalPrice, setFinalPrice] = useState({})
  const [strickedPrice, setStrickedPrice] = useState({})

  useEffect(() => {
    if (templateObject.object.data?.finalPriceFont === null || templateObject.object.data?.finalPriceFont === undefined) {
      return;
    }
    Assets.get(projectState.companyId, projectState.projectId, templateObject.object.data.finalPriceFont).then((r) => {
      if (r?.status < 400) {
        setFinalPriceFont(r.data.id)
        addFont(r.data.id, r.data.external_url);
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    });
  }, [templateObject.object?.data?.finalPriceFont])

  useEffect(() => {
    if (templateObject.object.data?.strickedPriceFont === null || templateObject.object.data?.strickedPriceFont === undefined) {
      return;
    }
    Assets.get(projectState.companyId, projectState.projectId, templateObject.object.data.strickedPriceFont).then((r) => {
      if (r?.status < 400) {
        addFont(r.data.id, r.data.external_url);
        setStrickedPriceFont(r.data.id)
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    });
  }, [templateObject.object.data?.strickedPriceFont])

  useEffect(() => {
    let cleanFinalPriceContent = templateObject.object.data.content?.offerContent?.value.replace('{{', '').replace('}}', '')
    let cleanStrickedPriceContent = templateObject.object.data.content?.priceContent?.value.replace('{{', '').replace('}}', '')

    assignPrices(cleanFinalPriceContent, cleanStrickedPriceContent)

  }, [templateObject.object.data.content, canvasState.product])

  const assignPrices = (cleanOfferPriceContent, cleanStrickedPriceContent) => {
    let offerPriceValue = canvasState.product[cleanOfferPriceContent]
    let strickedPriceValue = canvasState.product[cleanStrickedPriceContent]

    if (offerPriceValue === null || offerPriceValue === undefined) {
      setStrickedPrice(null)
      if (strickedPriceValue !== null && offerPriceValue !== undefined) {
        setFinalPrice(extractPrice(strickedPriceValue))
      }
    } else {
      if (strickedPriceValue !== null && offerPriceValue !== undefined) {
        setStrickedPrice(extractPrice(strickedPriceValue))
      }
      setFinalPrice(extractPrice(offerPriceValue))
    }
  }

  const extractPrice = (price) => {
    const formatter = new Intl.NumberFormat(projectState.locale.replace('_', '-'), {
      style: 'currency',
      currency: price.currency,
    });

    return formatter.formatToParts(price.amount / 1000).reduce((object, item) => {
      object[item.type] = item.value;
      return object;
    }, {})
  }

  const onDragStop = (e, d) => {
    resizeObject(
      canvasDispatch,
      templateObject.id,
      parseInt(d.x),
      parseInt(d.y),
      parseInt(templateObject.object.data.size.x),
      parseInt(templateObject.object.data.size.y),
    )
  }

  const onResizeStop = (e, direction, ref, delta, pos) => {
    resizeObject(
      canvasDispatch,
      templateObject.id,
      parseInt(pos.x),
      parseInt(pos.y),
      parseInt(ref.offsetWidth),
      parseInt(ref.offsetHeight),
    )
  }

  const handleClick = () => {
    selectCanvasObject(canvasDispatch, templateObject.id);
  }

  const calculateLineHeight = (isFinalOrStricked) => {
    const span = document.createElement('span');
    if (isFinalOrStricked === 'final') {
      span.style.fontFamily = finalPriceFont;
      span.style.fontSize = templateObject.object.data.finalPriceData?.whole.size + "px";
    } else {
      span.style.fontFamily = strickedPriceFont;
      span.style.fontSize = templateObject.object.data.strickedPriceData?.whole.size + "px";
    }

    span.textContent = '1234567890';

    document.body.appendChild(span);
    const rect = span.getBoundingClientRect();
    document.body.removeChild(span);

    return rect.height;
  }

  return (
    <>
      <Rnd className={classNames({
        canvas_object: true,
        hide: !templateObject.visible,
        selected: templateObject.selected,
        canvas_object_hover: !templateObject.selected && templateObject?.errors?.length === 0,
        error: templateObject?.errors?.length > 0
      })}
           bounds="#canvas"
           style={{
             zIndex: templateObject.index,
             cursor: 'pointer',
           }}
           position={{
             x: templateObject.object.data.origin.x,
             y: templateObject.object.data.origin.y,
           }}
           size={{
             width: templateObject.object.data.size.x,
             height: templateObject.object.data.size.y,
           }}
           onDragStop={onDragStop}
           onResizeStop={onResizeStop}
           onClick={handleClick}
           onResizeStart={handleClick}
           onDragStart={handleClick}
           enableResizing={templateObject.selected}
           resizeHandleClasses={{
             topLeft: templateObject.selected ? 'resize-handle' : '',
             topRight: templateObject.selected ? 'resize-handle' : '',
             bottomLeft: templateObject.selected ? 'resize-handle' : '',
             bottomRight: templateObject.selected ? 'resize-handle' : '',
           }}
           dragGrid={canvasState.stickToGrid === true ? [16, 16] : [1, 1]}
           resizeGrid={canvasState.stickToGrid === true ? [16, 16] : [1, 1]}
      >
        <div
          className={classNames({
            'd-flex': true,
            'flex-column': templateObject.object.data.oneAboveOther === 1
          })}
          style={{
            width: '100%',
            backgroundColor: ConvertRGBtoHex(
              templateObject.object.data.backgroundColor?.red ?? 255,
              templateObject.object.data.backgroundColor?.green ?? 255,
              templateObject.object.data.backgroundColor?.blue ?? 255,
              templateObject.object.data.backgroundColor?.alpha ?? 127,
            ),
            paddingTop: templateObject.object.data.padding?.top + 'px',
            paddingBottom: templateObject.object.data.padding?.bottom + 'px',
            paddingLeft: templateObject.object.data.padding?.left + 'px',
            paddingRight: templateObject.object.data.padding?.right + 'px',
            marginTop: templateObject.object.data.margin?.top + 'px',
            marginBottom: templateObject.object.data.margin?.bottom + 'px',
            marginLeft: templateObject.object.data.margin?.left + 'px',
            marginRight: templateObject.object.data.margin?.right + 'px',
          }}
        >
          <div
            className={'d-flex flex-column'}
            style={{
              order: templateObject.object.data.offerIsFirst === 1 ? 1 : 2,
              outline: templateObject.object.data.oneAboveOther === 1 && templateObject.selected ? 'red 1px dashed' : '',
              width: templateObject.object.data.oneAboveOther === 1 || templateObject.object.data.onlyFinalPrice === 1 || strickedPrice === null ? '100%' : templateObject.object.data.finalPricePercentageSize + '%',
              height: templateObject.object.data.oneAboveOther === 0 || templateObject.object.data.onlyFinalPrice === 1 || strickedPrice === null ? '100%' : templateObject.object.data.finalPricePercentageSize + '%',
              justifyContent: ConvertVerticalAlignToJustifyContent(templateObject.object.data.finalPriceAlign?.vertical),
            }}
          >
            <p
              style={{
                color: ConvertRGBtoHex(
                  templateObject.object.data.finalPriceColor?.red ?? 255,
                  templateObject.object.data.finalPriceColor?.green ?? 255,
                  templateObject.object.data.finalPriceColor?.blue ?? 255,
                  templateObject.object.data.finalPriceColor?.alpha ?? 127,
                ),
                backgroundColor: ConvertRGBtoHex(
                  templateObject.object.data.finalPriceInnerBackgroundColor?.red ?? 255,
                  templateObject.object.data.finalPriceInnerBackgroundColor?.green ?? 255,
                  templateObject.object.data.finalPriceInnerBackgroundColor?.blue ?? 255,
                  templateObject.object.data.finalPriceInnerBackgroundColor?.alpha ?? 127,
                ),
                textAlign: templateObject.object.data.finalPriceAlign?.horizontal,
                fontFamily: "'" + finalPriceFont + "'",
                paddingTop: templateObject.object.data.finalPricePadding?.top + 'px',
                paddingBottom: templateObject.object.data.finalPricePadding?.bottom + 'px',
                paddingLeft: templateObject.object.data.finalPricePadding?.left + 'px',
                paddingRight: templateObject.object.data.finalPricePadding?.right + 'px',
                marginTop: templateObject.object.data.finalPriceMargin?.top + 'px',
                marginBottom: templateObject.object.data.finalPriceMargin?.bottom + 'px',
                marginLeft: templateObject.object.data.finalPriceMargin?.left + 'px',
                marginRight: templateObject.object.data.finalPriceMargin?.right + 'px',
                lineHeight: 100 / calculateLineHeight('final'),
              }}
              className={classNames({})}
            >
          <span style={{
            fontSize: templateObject.object.data.finalPriceData?.whole.size + "px",
            position: "relative",
            bottom: templateObject.object.data.finalPriceData?.whole.elevation + "px",
          }}>{finalPrice.integer}</span>
              {
                finalPrice.fraction !== '00' ?
                  <span style={{
                    fontSize: templateObject.object.data.finalPriceData?.decimal.size + "px",
                    position: "relative",
                    bottom: templateObject.object.data.finalPriceData?.decimal.elevation + "px",
                  }}>{finalPrice.decimal}{finalPrice.fraction}</span>
                  : <></>
              }
              <span style={{
                fontSize: templateObject.object.data.finalPriceData?.currency.size + "px",
                position: "relative",
                bottom: templateObject.object.data.finalPriceData?.currency.elevation + "px",
              }}>{finalPrice.currency}</span></p>
          </div>
          <div
            className={'d-flex'}
            style={{
              order: templateObject.object.data.offerIsFirst === 1 ? 2 : 1,
              outline: templateObject.selected ? 'red 1px dashed' : '',
              width: templateObject.object.data.oneAboveOther === 1 ? '100%' : templateObject.object.data.onlyFinalPrice === 1 || strickedPrice === null ? '0%' : templateObject.object.data.strickedPricePercentageSize + '%',
              height: templateObject.object.data.oneAboveOther === 0 ? '100%' : templateObject.object.data.onlyFinalPrice === 1 || strickedPrice === null ? '0%' : templateObject.object.data.strickedPricePercentageSize + '%',
              justifyContent: ConvertHorizontalAlignToJustifyContent(templateObject.object.data.strickedPriceAlign?.horizontal),
              alignItems: ConvertVerticalAlignToJustifyContent(templateObject.object.data.strickedPriceAlign?.vertical)
            }}
          >
            <p
              style={{
                color: ConvertRGBtoHex(
                  templateObject.object.data.strickedPriceColor?.red ?? 255,
                  templateObject.object.data.strickedPriceColor?.green ?? 255,
                  templateObject.object.data.strickedPriceColor?.blue ?? 255,
                  templateObject.object.data.strickedPriceColor?.alpha ?? 127,
                ),
                backgroundColor: ConvertRGBtoHex(
                  templateObject.object.data.strickedPriceInnerBackgroundColor?.red ?? 255,
                  templateObject.object.data.strickedPriceInnerBackgroundColor?.green ?? 255,
                  templateObject.object.data.strickedPriceInnerBackgroundColor?.blue ?? 255,
                  templateObject.object.data.strickedPriceInnerBackgroundColor?.alpha ?? 127,
                ),
                display: templateObject.object.data.onlyFinalPrice === 1 ? 'none' : 'flex',
                textAlign: templateObject.object.data.strickedPriceAlign?.horizontal,
                fontFamily: "'" + strickedPriceFont + "'",
                paddingTop: templateObject.object.data.strickedPricePadding?.top + 'px',
                paddingBottom: templateObject.object.data.strickedPricePadding?.bottom + 'px',
                paddingLeft: templateObject.object.data.strickedPricePadding?.left + 'px',
                paddingRight: templateObject.object.data.strickedPricePadding?.right + 'px',
                marginTop: templateObject.object.data.strickedPriceMargin?.top + 'px',
                marginBottom: templateObject.object.data.strickedPriceMargin?.bottom + 'px',
                marginLeft: templateObject.object.data.strickedPriceMargin?.left + 'px',
                marginRight: templateObject.object.data.strickedPriceMargin?.right + 'px',
                lineHeight: Math.max(templateObject.object.data.finalPriceData?.whole.size, templateObject.object.data.finalPriceData?.decimal.size, templateObject.object.data.finalPriceData?.currency.size) / calculateLineHeight('final'),
                justifyContent: ConvertVerticalAlignToJustifyContent(templateObject.object.data.strickedPriceAlign?.horizontal),
              }}
            >
              <div
                className={classNames({
                  strickedPrice: true,
                })}
                style={{
                  "--lineWidth": templateObject.object.data?.strickedPriceLine?.thickness + 'px',
                  "--lineColor": ConvertRGBtoHex(
                    templateObject.object.data.strickedPriceLine?.lineColor?.red ?? 255,
                    templateObject.object.data.strickedPriceLine?.lineColor?.green ?? 255,
                    templateObject.object.data.strickedPriceLine?.lineColor?.blue ?? 255,
                    templateObject.object.data.strickedPriceLine?.lineColor?.alpha ?? 127,
                  ),
                  "--lineRotate": templateObject.object.data?.strickedPriceLine?.origin === 'center' ? '0deg' :
                    templateObject.object.data?.strickedPriceLine?.origin === 'top' ? '16deg' : '158deg'
                }}
              >
          <span style={{
            fontSize: templateObject.object.data.strickedPriceData?.whole.size + "px",
            position: "relative",
            bottom: templateObject.object.data.strickedPriceData?.whole.elevation + "px",
          }}>{strickedPrice?.integer}</span>
                {
                  strickedPrice?.fraction !== '00' ?
                    <span style={{
                      fontSize: templateObject.object.data.strickedPriceData?.decimal.size + "px",
                      position: "relative",
                      bottom: templateObject.object.data.strickedPriceData?.decimal.elevation + "px",
                    }}>{strickedPrice?.decimal}{strickedPrice?.fraction}</span>
                    : <></>
                }

                <span style={{
                  fontSize: templateObject.object.data.strickedPriceData?.currency.size + "px",
                  position: "relative",
                  bottom: templateObject.object.data.strickedPriceData?.currency.elevation + "px",
                }}>
                {strickedPrice?.currency}
              </span>
              </div>
            </p>
          </div>
        </div>
      </Rnd>
    </>
  )
}
