import React, {useState} from 'react';
import {Button, Col, Form, Input, Row} from "reactstrap";
import {useUrlParametersDispatch} from "../../../../context/url_parameters/UrlParametersIndex";
import {add} from "../../../../context/url_parameters/UrlParametersActions";

export const FeedExportUrlParameterForm = () => {
  const urlParametersDispatch = useUrlParametersDispatch()
  const [submitted, setSubmitted] = useState(false)
  const cleanUserInput = {
    name: '',
    value: '',
    override: true
  };

  const [userInput, setUserInput] = useState(cleanUserInput)

  const handleChange = (e) => {
    const {name, value} = e.target;
    setUserInput(userInput => ({...userInput, [name]: value}));
  }

  const handleToggle = (e) => {
    const {name, checked} = e.target;
    setUserInput(userInput => ({...userInput, [name]: checked}))
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (userInput.name && userInput.value) {
      add(urlParametersDispatch, userInput)
      setUserInput(cleanUserInput);
      setSubmitted(false)
    }
  }
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className='d-flex align-items-center gap-3'>
          <div className='col-md-5'>
            <Input
              placeholder="name"
              name="name"
              valid={submitted && userInput.name.length > 0}
              invalid={submitted && !userInput.name}
              value={userInput.name}
              onChange={handleChange}
            />
          </div>
          <div className='col-md-3'>
            <Input
              placeholder="Value"
              name="value"
              valid={submitted && userInput.value.length > 0}
              invalid={submitted && !userInput.value}
              value={userInput.value}
              onChange={handleChange}
            />
          </div>
          <div className='col-md-2'>
            <label className="custom-toggle me-1">
              <input type="checkbox" name="override" checked={userInput.override} onChange={handleToggle}/>
              <span className="custom-toggle-slider rounded-circle"/>
            </label>
          </div>
          <div className='col-md-2'>
            <Button color={'white'} className={'btn-icon-only btn btn-terciary'}>
              <span className={'fa-solid fa-plus fs-3'}/>
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};
