import {Button, FormGroup, Input, Modal} from "reactstrap";
import React, {useState} from "react";
import {v4 as uuid4} from "uuid";
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {Templates} from "../../services/api/Templates";
import {useProjectState} from "../../context/project/ProjectIndex";
import {useNavigate} from "react-router-dom";

const TemplateNewModal = ({showModal, setShowModal, setCreatedTemplate}) => {
  const navigate = useNavigate();
  const projectState = useProjectState()
  const dispatch = useApiErrorDispatch();
  const [isLoading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const [template, setTemplate] = useState(
    {
      name: '',
      priority: 1,
      locale: projectState?.locale,
      status: 0,
      render_status: 'DRAFT',
      conditions: [],
      start_date: null,
      end_date: null
    }
  )

  const parseDateTime = (value) => {
    if (value === null || value === '') {
      return null;
    }
    let d = new Date(value)
    return d.toUTCString()
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true)

    if (template.name) {
      setLoading(true);
      let templateId = uuid4();
      Templates.put(projectState.companyId, projectState.projectId, templateId, {
        ...template,
        start_date: parseDateTime(template.start_date),
        end_date: parseDateTime(template.end_date),
      }).then((r) => {
        setLoading(false);
        if (r?.status < 400) {
          setCreatedTemplate(true)
          setShowModal(false)
          navigate(`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/templates/${templateId}/canvas`);
        } else {
          apiErrorRaised(dispatch, r?.response)
        }
      });
    }
  }

  const handleChange = (e) => {
    const {name, value} = e.target;
    setTemplate(template => ({...template, [name]: value}));
  }

  return (
    <>
      <Modal
        size="lg"
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={() => setShowModal(false)}
      >
        <div className="modal-header">
          <button
            aria-label="Close"
            className="btn-close"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
          </button>
        </div>
        <div className="modal-body d-flex flex-column pt-0 pb-0">
          <h6 className="modal-title end text-center mb-5">
            New Template
          </h6>
          <div className={'d-flex'}>
            <FormGroup
              className="mb-3 flex-grow-1">
              <label
                className="form-control-label"
              >
                Name
              </label>
              <Input
                placeholder="Name"
                type="text"
                valid={submitted && template.name.length > 0}
                invalid={submitted && !template.name}
                name="name"
                value={template.name}
                onChange={handleChange}
              />
              <div className="invalid-feedback">
                Please provide a name
              </div>
            </FormGroup>
            <FormGroup
              className="ms-3 mb-3 flex-grow-1">
              <label
                className="form-control-label"
              >
                Priority
              </label>
              <Input
                placeholder="100"
                type="number"
                valid={submitted && template.priority.length > 0}
                invalid={submitted && !template.priority}
                name="priority"
                value={template.priority}
                onChange={handleChange}
              />
              <div className="invalid-feedback">
                Please provide a valid Priority
              </div>
            </FormGroup>
          </div>
          <div className={'d-flex flex-row'}>
            <FormGroup
              className="mb-3 flex-grow-1">
              <label
                className="form-control-label"
              >
                Start Date
              </label>
              <Input
                type="datetime-local"
                name="start_date"
                value={template.start_date}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup
              className="ms-3 mb-3 flex-grow-1">
              <label
                className="form-control-label"
              >
                End Date
              </label>
              <Input
                type="datetime-local"
                name="end_date"
                value={template.end_date}
                onChange={handleChange}
              />
            </FormGroup>
          </div>
        </div>
        <div className="modal-footer d-flex flex-row justify-content-between">
          <Button
            className=""
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
          <Button color="primary" type="button" className="ms-auto"
                  onClick={handleSubmit}>
            {isLoading &&
              <span className="spinner-border spinner-border-sm me-1"/>}
            New
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default TemplateNewModal;
