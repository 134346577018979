import React, {useEffect, useState} from 'react';
import {Button, ButtonGroup} from "reactstrap";
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {Products} from "../../services/api/Products";
import {TableFilters} from "../TableFilters/TableFilters";
import {useTableFiltersState} from "../../context/table_filters/TableFiltersIndex";
import Paginator from "../Pagination/Paginator";
import ProductsTable from "./ProductsTable";
import {ProductsGrid} from "./ProductsGrid";


const ProductsList = ({companyId, projectId}) => {
  const dispatch = useApiErrorDispatch()
  const [isLoading, setLoading] = useState(false)
  const [products, setProducts] = useState([])
  const [pagination, setPagination] = useState({})
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(30)
  const [isFiltersCollapsed, setFiltersCollapsed] = useState(true)
  const tableFilters = useTableFiltersState()
  const [layout, setLayout] = useState('table')
  const [renderedImage, setRenderedImage] = useState(false)

  const handleClick = (e, offset, limit) => {
    e.preventDefault();
    setLimit(limit)
    setOffset(offset)
  }

  const toggleCollapsed = () => {
    setFiltersCollapsed(!isFiltersCollapsed)
  }

  useEffect(() => {
    setLoading(true);
    Products.index(companyId, projectId, limit, offset, tableFilters).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        setProducts(r.data?.products);
        setPagination(r.data?.pagination);
      } else {
        apiErrorRaised(dispatch, r?.response)
      }
      setLoading(false);
    });
  }, [dispatch, limit, offset, tableFilters, companyId, projectId]);


  const handleToggle = () => {
    setRenderedImage(!renderedImage)
  };
  return (
    <>
      <div className={'view_header'}>
        <div className={'title'}>
          <h1>Products</h1>
        </div>
        <div className={'button_actions d-flex flex-column gap-3'}>
          <div>
            <Button outline color="primary"
                    onClick={toggleCollapsed}>
              <span className="fa-filter fa me-1"/>
              Filter
            </Button>
            <ButtonGroup aria-label="Settings" role="group">
              <Button className={'btn-icon-only'} outline color={'primary'} active={layout === 'table'}
                      onClick={() => setLayout('table')}>
                <span className={'fa-solid fa-table'}/>
              </Button>
              <Button className={'btn-icon-only'} outline color={'primary'} active={layout === 'grid'}
                      onClick={() => setLayout('grid')}>
                <span className={'fa-solid fa-grip'}/>
              </Button>
            </ButtonGroup>
          </div>
          <div className={'d-flex flex-row ms-1 align-items-center gap-2'}>
            <p className='small m-0'>
              Rendered Image
            </p>
            <div>
              <label className="custom-toggle mr-1">
                <input defaultChecked type="checkbox" checked={renderedImage} onChange={handleToggle}/>
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="No"
                  data-label-on="Yes"
                />
              </label>
            </div>
          </div>

        </div>
      </div>
      <div className={'mt-3'}>
        <TableFilters entity={'product'} isCollapsed={isFiltersCollapsed}/>
      </div>
      {
        layout === 'table' ?
          <ProductsTable products={products} isLoading={isLoading}/>
          :
          <ProductsGrid products={products} renderedImage={renderedImage}/>
      }
      <Paginator
        pagination={pagination}
        handleClick={handleClick}
        offset={offset}
        limit={limit}
      />
    </>
  )
}


export default ProductsList;
