import React, {useEffect, useState} from 'react';
import TemplateItem from "./TemplateItem.js";
import {Button, Card, CardBody, Table} from "reactstrap";
import {Templates} from "../../services/api/Templates";
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {TableFilters} from "../TableFilters/TableFilters";
import {useTableFiltersState} from "../../context/table_filters/TableFiltersIndex";
import Paginator from "../Pagination/Paginator";
import TemplateNewModal from "./TemplateNewModal";
import {TemplateImportModal} from "./TemplateImportModal";
import {PlaceholderTable} from "../PlaceholderTable/PlaceholderTable";
import {ImportProvider} from "../Import/Context/ImportIndex";
import {useProjectState} from "../../context/project/ProjectIndex";
import {Link} from "react-router-dom";

const TemplateList = ({companyId, projectId}) => {
  const apiErrorDispatch = useApiErrorDispatch()
  const projectState = useProjectState()
  const [isFiltersCollapsed, setFiltersCollapsed] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const [templates, setTemplates] = useState([])
  const [pagination, setPagination] = useState({})
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(15)
  const tableFilters = useTableFiltersState()
  const [showModalImport, setShowModalImport] = useState(false)

  const [showModal, setShowModal] = useState(false);
  const [createdTemplate, setCreatedTemplate] = useState(false);

  const handleClickCreate = () => {
    setShowModal(true);
  }

  const handleClickImport = () => {
    setShowModalImport(true);
  }

  const handleClick = (e, offset, limit) => {
    e.preventDefault();
    setLimit(limit)
    setOffset(offset)
  }

  const toggleCollapsed = () => {
    setFiltersCollapsed(!isFiltersCollapsed)
  }

  useEffect(() => {
    setLoading(true);
    Templates.index(companyId, projectId, {filters: tableFilters, limit: limit, offset: offset}).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        setTemplates(r.data?.templates);
        setPagination(r.data?.pagination);
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    });
  }, [createdTemplate, setCreatedTemplate, apiErrorDispatch, tableFilters, limit, offset, companyId, projectId]);

  return (
    <>
      <div className={'view_header'}>
        <div className={'title'}>
          <h1>Templates</h1>
        </div>
        <div className={'button_actions'}>
          <Button outline color="primary"
                  onClick={toggleCollapsed}>
            <span className="fa-filter fa me-1"/>
            Filter
          </Button>
          <Button color={'primary'} onClick={handleClickImport}
                  disabled={projectState.projectProductsDetected === 0}>Import </Button>
          <Button color={'primary'} onClick={handleClickCreate} disabled={projectState.projectProductsDetected === 0}>Create
            Template</Button>
        </div>
      </div>

      {
        projectState.projectProductsDetected === 0 ?
          <Card className={'mt-3'}>
            <CardBody>
              <h2>In order to create a template, please import some products from <strong>Feed Manager -> Data Sources
              </strong>
                <Link
                  to={`/admin/companies/${projectState?.companyId}/projects/${projectState?.projectId}/data_sources/`}>
                  <span className={'ms-3 fa-solid fa-arrow-up-right-from-square'}></span>
                </Link>
              </h2>
            </CardBody>
          </Card>
          : <>
            <div className={'mt-3'}>
              <TableFilters entity={'template'} isCollapsed={isFiltersCollapsed}/>
              {
                isLoading === true ? <PlaceholderTable rows={20}/> :
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                      <th scope="col" className={'text-center'}></th>
                      <th scope="col" className={'text-center'}>Name</th>
                      <th scope="col" className={'text-center'}>Priority</th>
                      <th scope="col" className={'text-center'}>Active Products</th>
                      <th scope="col" className={'text-center'}>Start</th>
                      <th scope="col" className={'text-center'}>End</th>
                      <th scope="col"/>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      isLoading &&
                      <tr>
                        <td>
                          <span className="spinner-border spinner-border-lg me-1"/>
                        </td>
                      </tr>
                    }
                    {
                      templates && templates.map((template, i) => {
                        return (<TemplateItem key={i} data={template}/>);
                      })
                    }
                    </tbody>
                  </Table>
              }
            </div>
            <Paginator
              pagination={pagination}
              handleClick={handleClick}
              offset={offset}
              limit={limit}
            />
          </>
      }

      <TemplateNewModal
        showModal={showModal}
        setShowModal={setShowModal}
        setCreatedTemplate={setCreatedTemplate}
      />

      <ImportProvider>
        <TemplateImportModal
          showModal={showModalImport}
          setShowModal={setShowModalImport}
          setCreatedTemplate={setCreatedTemplate}
        />
      </ImportProvider>
    </>
  );
}

export default TemplateList;
