import React, {useEffect, useRef, useState} from 'react';
import {apiErrorRaised, useApiErrorDispatch} from "../../../context/api_error/ApiErrorIndex";
import {FeedExportHistorys} from "../../../services/api/FeedExportHistorys";
import {Card, CardBody, CardHeader} from "reactstrap";
import {endOfDay, format, startOfDay, subDays, subMonths} from "date-fns";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  DoughnutController,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip
} from "chart.js";
import {chartColors, chartOptions} from "../../../utils/Chart";
import {getAxis, getDates} from "../../Analytics/AnalyticsUtils";
import {useProjectState} from "../../../context/project/ProjectIndex";
import {Chart} from "react-chartjs-2";

const FeedExportHistoryChart = ({companyId, projectId, id, props}) => {
  const projectState = useProjectState()
  const apiErrorDispatch = useApiErrorDispatch();
  const [isLoading, setIsLoading] = useState(false)
  const [feedExportHistorys, setFeedExportHistorys] = useState([])
  const startDate = startOfDay(subDays(subMonths((new Date()).setHours(0, 0, 0, 0), 1), 2))
  const endDate = endOfDay(new Date())

  const [data, setData] = useState({
    labels: [],
    datasets: []
  })
  ChartJS.register(
    DoughnutController,
    Tooltip,
    CategoryScale,
    LinearScale,
    BarElement,
    LineController,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    TimeScale,
    ArcElement,
    Filler,
  );
  const chartRef = useRef(null)
  useEffect(() => {
    const chart = chartRef.current;
    if (chart) {
      chartOptions()
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [id, apiErrorDispatch]);

  const fetchData = () => {
    setIsLoading(true)
    FeedExportHistorys.index(companyId, projectId, id, {
      'start_date': format(startDate, 'yyyy-MM-dd H:m:s'),
      'end_date': format(endDate, 'yyyy-MM-dd H:m:s'),
    }).then((r) => {
      setIsLoading(false)
      if (r?.status < 400) {
        setFeedExportHistorys(r.data)
      } else {
        if (r?.response !== undefined) {
          apiErrorRaised(apiErrorDispatch, r?.response)
        } else {
          apiErrorRaised(apiErrorDispatch, {
            status: r.status,
            statusText: r.name,
            data: {
              messge: r.message,
            }
          })
        }
      }
    });
  }

  useEffect(() => {
    if (feedExportHistorys.length > 0) {
      parseFeedExportHistorys()
    }
  }, [feedExportHistorys]);

  const parseFeedExportHistorys = () => {
    let dates = getDates(startDate, endDate)
    let emptyValues = Object.fromEntries(dates.map((date) => [date,
      {
        products: 0,
      }
    ]))
    let fillData = feedExportHistorys.reduce((result, feedExportHistory) => {
      const {date} = feedExportHistory
      if (result[date] === undefined) {
        result[date] = {}
      }
      result[date]['products'] = feedExportHistory.products
      return result
    }, emptyValues)

    let parsedFeedExportHistorys = dates.map((date) => {
      let data = {
        data: date
      }
      data['products'] = fillData[date]['products']
      return data;
    })
    setData({
      labels: dates,
      datasets: [
        {
          yAxisID: `y0`,
          data: parsedFeedExportHistorys?.map(feedExportHistory => feedExportHistory['products']),
          label: 'Products',
          borderColor: chartColors.validProducts,
          backgroundColor: chartColors.validProducts,
        },
      ]
    });
  }

  const options = {
    tension: 0.3,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        position: 'nearest',
        enabled: true,
        titleColor: chartColors.white,
        backgroundColor: '#3F3949',
        titleFont: {
          family: 'NunitoRegular',
          size: 16
        },
        bodyFont: {
          family: 'NunitoRegular',
          size: 16
        },
        titleSpacing: 4,
        padding: 10,
        usePointStyle: true,
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            label = '  ' + label

            if (label) {
              label += ': ';
            }
            label += new Intl.NumberFormat(projectState.locale.replace('_', '-')).format(context.parsed.y);
            return label;
          },
        },
      }
    },
    scales: getAxis(startDate, endDate, ['1'], projectState.locale, projectState.projectCurrency, true),
  }


  return (
    <>
      <Card {...props}>
        <CardHeader className="d-flex justify-content-between">
          <h4 className={'text-center text-dusty-violet'}>History</h4>
        </CardHeader>
        <CardBody className={'d-flex flex-column'}>
          <div className={'d-flex flex-column flex-basis-25 p-3'}>

            {
              isLoading === true ?
                <div className={'d-flex align-items-center justify-content-center h-100'}>
                  <span className="spinner-border spinner-border-xl"/>
                </div>
                :
                <div style={{minHeight: '20rem'}}>
                  <Chart
                    type={'line'}
                    ref={chartRef}
                    data={data}
                    options={options}
                    id="export_history"
                    className="export_history_canvas"
                  /></div>
            }
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default FeedExportHistoryChart;
