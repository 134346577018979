import {Card, CardBody, CardHeader} from "reactstrap";
import {Chart} from "react-chartjs-2";
import React, {useEffect, useRef, useState} from "react";
import {EventInfoItem} from "./EventInfoItem";

export const EventsManagerSummary = ({eventsData, isLoading}) => {
  const chartRef = useRef(null)
  const [eventsSummary, setEventsSummary] = useState([]);
  const [healthData, setHealthData] = useState({
    labels: [],
    datasets: [],
  })

  useEffect(() => {
    let numberOfErrors = eventsData.filter(function(item){
      return item.error != null;
    }).length;

    setHealthData({
      labels: ['Success', 'Error'],
      datasets: [{
        label: '',
        data: [eventsData.length - numberOfErrors, numberOfErrors],
        backgroundColor: [
          '#8FDC24', '#F54C43',
        ],
        borderWidth: 0,
        weight: 10
      }
      ]
    })
  }, [eventsData]);

  useEffect(() => {
    let eventsSummary = [];
    let keysMap = [];
    eventsData.forEach((event) => {
      let eventName = event.event_name?.replaceAll('_', ' ').toLowerCase()
      eventName = eventName[0].toUpperCase() + eventName.slice(1)

      let errorString = 'count' + (event.error === null ? '_success' : '_error');

      if (keysMap[eventName] === undefined) {
        keysMap[eventName] = eventsSummary.length;
        eventsSummary.push({action: eventName, count_success: 0, count_error: 0})
      }
      let key = keysMap[eventName];
      eventsSummary[key] = { ...eventsSummary[key],
        [errorString]: eventsSummary[key][errorString] += 1
      };
    })

    setEventsSummary(eventsSummary)
  }, [eventsData]);

  return (
    <Card>
      <CardHeader className={'text-dusty-violet'}>
        OVERVIEW
      </CardHeader>
      <CardBody className={'d-flex flex-row p-0'}>
        <div className={'d-flex flex-column flex-basis-25 border-end p-3'}>
          <h4 className={'text-center text-dusty-violet'}>Events health</h4>

          <div className={'flex-grow-1'}>
            <Chart
              ref={chartRef}
              type={'doughnut'}
              data={healthData}
              id="chart-current_state"
              className="chart-canvas"
              options={{
                cutout: 115,
                radius: 120,
                plugins: {
                  htmlLegend: {
                    containerID: 'legend-container',
                  },
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    enabled: true,
                    intersect: false,
                    titleAlign: 'center',
                    padding: 10,
                    backgroundColor: '#3F3949',
                    usePointStyle: true,
                    cornerRadius: 10,
                  }
                }
              }}
            />
          </div>
          <div id={'legend-container'}/>

        </div>
        <div className={'d-flex flex-column flex-basis-100 p-3'}>
          <h4 className={'text-center text-dusty-violet'}>Events received</h4>
          <div className={'d-flex flex-row mt-4'}>
            {
              isLoading ?
                <span className="spinner-border spinner-border-sm me-1"/>
                :
                eventsSummary && eventsSummary.length > 0 ? eventsSummary.map((data, i) =>
                  <EventInfoItem event={data} key={i}/>
                ) : <>
                  <div>
                    <h2>No events received</h2>
                  </div>
                </>
            }
          </div>

        </div>
      </CardBody>
    </Card>
  )

}
