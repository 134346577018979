import {useCanvasDispatch, useCanvasState} from "../../../context/canvas/CanvasContext";
import React, {useState} from "react";
import {useProjectState} from "../../../context/project/ProjectIndex";
import {CanvasActions} from "./CanvasActions";
import {addCanvasObject} from "../../../context/canvas/CanvasActions";
import {useApiErrorDispatch} from "../../../context/api_error/ApiErrorIndex";

export const CanvasTemplateObjectAddActions = () => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const canvasDispatch = useCanvasDispatch()
  const apiErrorDispatch = useApiErrorDispatch()

  const canvasState = useCanvasState();
  const projectState = useProjectState()

  const handleAddClick = (type) => {
    addCanvasObject(canvasDispatch, type);
  }

  return (<>
    <div className={'bg-white d-flex flex-column order-1 align-items-center'}>
      <CanvasActions
        companyId={projectState.companyId}
        projectId={projectState.projectId}
        id={canvasState.template.id}
      />
      <div className={'canvas_actions pt-4 pb-2 cursor-pointer'} onClick={() => handleAddClick('image')}>
        <span className="fa fa-2x fa-image text-primary"/>
      </div>
      <div className={'canvas_actions py-2 cursor-pointer'} onClick={() => handleAddClick('text_object')}>
        <span className="fa fa-2x fa-font text-primary"/>
      </div>
      <div className={'canvas_actions py-2 cursor-pointer'} onClick={() => handleAddClick('two_prices_object')}>
        <span className="fa fa-2x fa-tags text-primary"/>
      </div>
    </div>
  </>)
}
