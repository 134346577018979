import React from "react";
import ConfirmationDetail from "../../../components/Confirmation/ConfirmationDetail";
import {useParams} from "react-router-dom";

const ConfirmationView = () => {
  const routerParams = useParams()

  return (
    <>
      <ConfirmationDetail
        userId={routerParams.userId}
        confirmation={routerParams.confirmation}
      />
    </>
  );
};

export default ConfirmationView;
