import {useState} from "react";
import {ColorToHex, HexToColor} from "../../utils/Color";

export const CanvasColorPicker = ({color, onColorChange}) => {
  const [state, setState] = useState({
    displayColorPicker: false,
    color: (color !== undefined && color !== 0) ? ColorToHex(color?.red, color?.green, color?.blue) : '',
    alpha: color.alpha
  });

  const handleChangeColor = (e) => {
    let {value} = e.target
    setState({...state, color: value})
    let colorArray = HexToColor(value)
    onColorChange({
      red: colorArray.red,
      green: colorArray.green,
      blue: colorArray.blue,
      alpha: state.alpha,
    })
  }

  const handleChangeAlpha = (e) => {
    let {value} = e.target
    setState({...state, alpha: value})
    let colorArray = HexToColor(state.color)
    onColorChange({
      red: colorArray.red,
      green: colorArray.green,
      blue: colorArray.blue,
      alpha: value,
    })
  }

  return (
    <>
      <input type="color" className={'w-100'} title="Color" name="color" value={state.color}
             onChange={handleChangeColor}/>
      <input type="range" className={'w-100'} title="Opacity" name="opacity" value={state.alpha} min="0" max="127"
             step="1"
             onChange={handleChangeAlpha}
      />
    </>
  )
}
