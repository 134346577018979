import React from 'react';
import {Button} from "reactstrap";

const KeyValue = ({keyValue, handleDelete}) => {
    const handleClick = (e) => {
        e.preventDefault()
        let data = e.currentTarget.id.split('_');
        handleDelete(data[0])
    }

    return (
        <>
            <tr>
                <td>
                    {keyValue.name}
                </td>
                <td>
                    {keyValue.value}
                </td>
                <td>
                    <Button
                        className={'btn-icon-only'} color={'primary'}
                        id={keyValue.name + '_' + keyValue.value}
                        onClick={handleClick}
                    >
                        <span className="fa-solid fa-trash"/>
                    </Button>
                </td>
            </tr>
        </>
    );
};

export default KeyValue;
