import classNames from "classnames";
import React, {useEffect, useState} from "react";
import {
  canvasSelectProduct,
  canvasToggleGrid,
  canvasToggleStickToGrid,
  useCanvasDispatch,
  useCanvasState
} from "../../context/canvas/CanvasIndex";
import {Products} from "../../services/api/Products";
import {apiErrorRaised} from "../../context/api_error/ApiErrorActions";
import {useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {useProjectState} from "../../context/project/ProjectIndex";
import {ButtonToggle} from "reactstrap";
import {CanvasHeaderOptionsModal} from "./CanvasHeaderOptionsModal";

export const CanvasHeader = () => {
  const canvasState = useCanvasState()
  const apiErrorDispatch = useApiErrorDispatch()
  const canvasDispatch = useCanvasDispatch()
  const projectState = useProjectState()
  const [offset, setOffset] = useState(1);
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [filters, setFilters] = useState([
    {
      'field': 'status',
      'value': 1,
      'operator': '='
    }
  ])
  const [state, setState] = useState({
    sku: 'XXXXXXXX',
    discount: 'ANY',
    price: 'ANY',
    offset: 0,
  })

  const loadProduct = () => {
    Products.index(projectState.companyId, projectState.projectId, 1, offset, filters).then((r) => {
      if (r?.status < 400) {
        if (r.data.products.length > 0) {
          canvasSelectProduct(canvasDispatch, r.data.products[0])
          setState({...state, sku: r.data.products[0].sku})
        } else {
          apiErrorRaised(apiErrorDispatch, {
            status: '500',
            statusText: 'No products found',
          })
        }
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    })
  }

  const handleToggleGrid = () => {
    canvasToggleGrid(canvasDispatch)
  }

  const handleToggleStickToGrid = () => {
    canvasToggleStickToGrid(canvasDispatch)
  }

  useEffect(() => {
    loadProduct()
  }, [offset, filters]);

  useEffect(() => {
    loadProduct()
  }, [apiErrorDispatch, canvasDispatch]);

  const handleOpenCloseDialog = () => {
    setDialogOpen(!isDialogOpen)
  }

  const handleApply = (sku, price, discount) => {
    let newFilters = [
      {
        'field': 'status',
        'value': 1,
        'operator': '='
      }
    ]
    setOffset(0)
    if (sku !== undefined && sku !== null && sku !== '') {
      newFilters.push(
        {field: 'sku', operator: '=', value: sku}
      )
    }


    switch (price) {
      case 'SMALL':
        newFilters.push({field: 'price_amount', operator: '<', value: 99000})
        break;
      case 'MEDIUM':
        newFilters.push({field: 'price_amount', operator: '>', value: 99000})
        newFilters.push({field: 'price_amount', operator: '<', value: 999000})
        break;
      case 'LARGE':
        newFilters.push({field: 'price_amount', operator: '>', value: 999000})
        break;
      default:
        break;
    }

    switch (discount) {
      case 'WITH_DISCOUNT':
        newFilters.push({field: 'salePrice', operator: 'IS NOT NULL', value: ''})
        break;
      case 'WITHOUT_DISCOUNT':
        newFilters.push({field: 'salePrice', operator: 'IS NULL', value: ''})
        break;
      default:
        break;
    }
    setFilters(newFilters)

    setDialogOpen(false)
  }

  const nextProduct = () => {
    setFilters(filters.filter((filter) => filter.field !== 'sku'))
    setOffset(offset + 1)
  }

  const previousProduct = () => {
    setFilters(filters.filter((filter) => filter.field !== 'sku'))
    setOffset(Math.max(0, offset - 1))
  }

  return (
    <>
      <div className={'bg-white d-flex flex-row justify-content-start position-relative canvas_header'}>
        <div className={'d-flex flex-row justify-content-center mt-auto flex-grow-1'}>
          <div className={'d-flex flex-row justify-content-center'}
               style={{
                 minWidth: '100rem',
               }}>
            <span className={classNames({
              'fa fa-arrow-left fa-solid me-2 align-self-center cursor-pointer': true,
              'text-grey': offset === 1,
              'text-black': offset !== 1
            })} onClick={previousProduct}/>
            <div className={'bg-lighter m-2 ps-2 pe-2 pt-1 pb-1 rounded-2 w-25 cursor-pointer'}>
              <h3 className={'mb-0 text-center d-flex justify-content-center position-relative'}
                  onClick={handleOpenCloseDialog}>
                <div className={'d-flex flex-column position-absolute left-0'}>
                  <span className={'fa fa-solid fa-caret-up mb--2'}/>
                  <span className={'fa fa-solid fa-caret-down'}/>
                </div>
                <span>{canvasState.product.sku ?? 'XXXXXXXX'} {canvasState.product.availability === 'out of stock' ? '(out of stock)' : ''}</span>
              </h3>
              {
                state.sku !== 'XXXXXXXX' && <div className={classNames(
                  {
                    'position-absolute flex-basis-25 top-100 mt-3 canvas_header_modal': true,
                    'd-none': !isDialogOpen
                  }
                )}>
                  <CanvasHeaderOptionsModal
                    discount={state.discount}
                    price={state.price}
                    handleOpenCloseDialog={handleOpenCloseDialog}
                    handleSubmitDialog={handleApply}
                  />
                </div>
              }
            </div>
            <span className={classNames({
              'fa fa-arrow-right fa-solid ms-2 align-self-center text-black cursor-pointer': true,
            })}
                  onClick={nextProduct}
            />
          </div>
        </div>
        <div
          className={'d-flex flex-row align-items-center position-absolute end-0 align-items-center h-100'}>
          <div className={'d-flex flex-column m-2 align-items-center'}>
            <small>Stick To Grid</small>
            <label className="custom-toggle mr-1">
              <input checked={canvasState.stickToGrid} type="checkbox" onClick={handleToggleStickToGrid}/>
              <span
                className="custom-toggle-slider rounded-circle"
                data-label-off="No"
                data-label-on="Yes"
              />
            </label>
          </div>
          <ButtonToggle className={'btn-icon-only'} onClick={handleToggleGrid}
                        color={canvasState.grid ? 'primary' : 'seconday'}
                        defaultChecked={canvasState.grid}>
            <span className={'fa fa-2x fa-grip'}/>
          </ButtonToggle>
        </div>
      </div>
    </>
  )
}
