import {useCanvasDispatch, useCanvasState} from "../../../../../context/canvas/CanvasIndex";
import {updateData} from "../../../../../context/canvas/CanvasActions";
import {Input} from "reactstrap";
import React from "react";

export const CanvasObjectInfoSidebarDetailAngle = ({title, property}) => {
  const canvasState = useCanvasState()
  const canvasDispatch = useCanvasDispatch()
  const templateObject = canvasState.selectedObject

  const handleChange = (e) => {
    const {value} = e.target;
    updateData(
      canvasDispatch,
      templateObject?.id,
      property,
      parseInt(value),
    )
  }

  return (
    <>
      <div className={'d-flex flex-column'}>
        <strong className={'mt-1'}>{title}</strong>
        <div className={'d-flex flex-row mt-1'}>
          <Input
            className={'m-2'}
            placeholder="angle"
            type="number"
            value={templateObject?.object.data[property] ?? 0}
            name="angle"
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  )
}
