import React, {useEffect} from "react";
import {Container,} from "reactstrap";
import {useProjectDispatch} from "../../../context/project/ProjectContext";
import {useParams} from "react-router-dom";
import {updateCompanyAndProjectBasedOnUrl} from "../../../context/project/ProjectActions";
import ProductIssuesList from "../../../components/ProductIssues/ProductIssuesList";

export const ProductIssuesView = () => {
  const projectDispatch = useProjectDispatch()
  const routerParams = useParams();
  useEffect(() => {
    updateCompanyAndProjectBasedOnUrl(projectDispatch, routerParams.companyId, routerParams.projectId)
  }, [])
  return (
    <>
      <Container className="mt-5" fluid>
        <ProductIssuesList
          companyId={routerParams.companyId}
          projectId={routerParams.projectId}
        />
      </Container>
    </>
  );
};
