import React from "react";
import {Badge} from "reactstrap";

export const ProductDiagnosticIssueTypologiesTableHeaders = (headers, projectState, locale) => {
  const finalHeaders = [];

  const badgeColor = (severity) => {
    switch (severity) {
      case 'WARNING':
        return 'warning'
      case 'ERROR':
        return 'danger'
      case 'CRITICAL':
        return 'black'
      case 'UNKNOWN':
        return 'gray'
      case 'INFO':
        return 'info'
    }
  }
  const options = {
    "occurrences": {
      name: "Occurrences",
      selector: row => row.occurrences,
      format: row => new Intl.NumberFormat(locale.replace('_', '-')).format(row.occurrences),
      sortable: true,
      omit: headers.includes('sku'),
      grow: 0,
      minWidth: "7rem",
    },
    "severity": {
      name: "Severity",
      selector: row => row.severity,
      sortable: true,
      grow: 0,
      maxWidth: '2rem',
      center: true,
      cell: (row) => (
        <Badge className={'badge-lg'} color={badgeColor(row.severity)}>
          {row.severity}
        </Badge>
      ),
    },
    "title": {
      name: "Title",
      selector: row => row.title,
      sortable: true,
      format: row => <a
        href={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/product_diagnostic/${row.diagnosticId}/product_issues?title=${row.title}`}>{row.title}</a>,
      grow: 1,
      maxWidth: "25rem"
    },
    "description": {
      name: "Description",
      selector: row => row.description,
      sortable: true,
      reorder: true,
      grow: 1,
      maxWidth: "80rem"
    },
  }
  headers.forEach(function (item) {
    if (options.hasOwnProperty(item)) {
      finalHeaders.push(options[item]);
    } else {
      if (!item.includes('properties_')) {
        return
      }
      finalHeaders.push({
        name: item.replace('properties_', ''),
        selector: row => row.properties[item],
        sortable: true,
        format: row => (row.properties[item] !== null && row.properties[item] !== undefined) ?
          row.properties[item].map(
            element => element
          ).join(' - ')
          : null,
        grow: 2
      });
    }
  });

  return finalHeaders
}
