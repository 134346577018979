export const addFilter = (analyticsState, data) => {
  if (data.type === 'string' && (data.operator === 'CONTAINS' || data.operator === 'NOT_CONTAINS')) {
    data = {...data, value: `%${data.value}%`};
  }
  if (data.type === 'money') {
    data = {...data, value: (data.value * 1000).toString()}
  }
  if (data.type === 'properties') {
    data = {...data, field: `properties.${data.property}`, value: `%${data.value}%`}
  }
  let filters = analyticsState.filters;
  let found = filters.findIndex(filter => filter.id === data.id);

  if (found === -1) {
    filters.push(data);
  } else {
    filters[found] = data;
  }

  return {
    ...analyticsState,
    filters: filters,
  }
};
