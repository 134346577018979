export const initialState = {
  companies: [],
  selected_company: '',
  projects: [],
  selected_project: '',
  parent_objects: [],
  selected_parent_object: '',
  objects: [],
  selected_object: '',
};

export const ImportReducer = (initialState, action) => {
  switch (action.type) {
    case "LOAD_COMPANIES":
      return {
        ...initialState,
        companies: action.payload,
        selected_company: '',
        projects: [],
        selected_project: '',
        objects: [],
        selected_object: '',
      }
    case "SELECT_COMPANY":
      return {
        ...initialState,
        selected_company: action.payload,
        projects: [],
        selected_project: '',
        objects: [],
        selected_object: '',
      }
    case "LOAD_PROJECTS":
      return {
        ...initialState,
        projects: action.payload,
        selected_project: '',
        objects: [],
        selected_object: '',
      }
    case "SELECT_PROJECT":
      return {
        ...initialState,
        selected_project: action.payload,
      }
    case "LOAD_OBJECTS":
      return {
        ...initialState,
        objects: action.payload,
        selected_object: '',
      }
    case "SELECT_OBJECT":
      return {
        ...initialState,
        selected_object: action.payload,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
