import {useApiErrorDispatch} from "../../../context/api_error/ApiErrorContext";
import React, {useEffect, useState} from "react";
import {Card, CardBody, CardHeader, Table} from "reactstrap";
import {FeedExports} from "../../../services/api/FeedExports";
import {apiErrorRaised} from "../../../context/api_error/ApiErrorActions";
import ProjectDetailFeedExportItem from "./ProjectDetailFeedExportItem";

const FeedExportDetail = ({companyId, projectId}) => {
  const dispatch = useApiErrorDispatch()
  const [isLoading, setLoading] = useState(false)
  const [feedExports, setFeedExports] = useState([])

  useEffect(() => {
    setLoading(true);
    FeedExports.index(companyId, projectId, {}).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        setFeedExports(r.data);
      } else {
        apiErrorRaised(dispatch, r?.response)
      }
    });
  }, [dispatch]);

  return (
    <>
      <Card>
        <CardHeader className={'text-dusty-violet'}>
          FEED EXPORTS
        </CardHeader>
        <CardBody className={'pt-0'}>
          <Table className="align-items-center table-flush" responsive>
            <thead className="">
            <tr className={'text-dusty-violet'}>
              <th scope="col" className={'p-2 text-center  border-0 border-end'}>Channel</th>
              <th scope="col" className={'p-2 text-center border-0 border-end'}>Name</th>
              <th scope="col" className={'p-2 text-center border-0 border-end'}>Product<br></br> Status</th>
              <th scope="col" className={'p-2 text-center border-0 border-end'}>Products <small>(total)</small></th>
              <th scope="col" className={'p-2 text-center border-0'}>Last Update</th>
            </tr>
            </thead>
            <tbody>
            {
              isLoading ?
                <tr>
                  <td><span className="spinner-border spinner-border-lg me-1"/></td>
                </tr> : feedExports && feedExports.map((feedExport, i) => {
                return (<ProjectDetailFeedExportItem key={i} data={feedExport}/>);
              })
            }
            </tbody>
          </Table>
          {
            !isLoading && feedExports && feedExports.length === 0 ? <div className={'pt-3 ps-3'}>
              <h2>There are not feed exports on this project</h2>
            </div> : <></>
          }
        </CardBody>
      </Card>
    </>
  );
}

export default FeedExportDetail;
