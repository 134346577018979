import React, {useEffect, useState} from 'react';
import {Button} from "reactstrap";
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {ProductIssues} from "../../services/api/ProductIssues";
import Paginator from "../Pagination/Paginator";
import ProductIssuesTable from "./ProductIssuesTable";
import {Link, useParams, useSearchParams} from "react-router-dom";

const ProductIssuesList = ({companyId, projectId}) => {
  const dispatch = useApiErrorDispatch()
  const [isLoading, setLoading] = useState(false)
  const [productIssues, setProductIssues] = useState([])
  const [pagination, setPagination] = useState({})
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(30)
  const routerParams = useParams();
  const [searchParams] = useSearchParams();

  const handleClick = (e, offset, limit) => {
    e.preventDefault();
    setLimit(limit)
    setOffset(offset)
  }

  const generateFileName = () => {
    return `diagnostic_product_issues_${searchParams.get('title')}.csv`
  }

  const downloadCSV = () => {

    setLoading(true);
    let filter = [
      {
        'field': 'title',
        'value': searchParams.get('title'),
        'operator': '='
      }
    ];
    ProductIssues.index(companyId, projectId, routerParams.productDiagnosticId, 10000000, offset, filter).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        let link = document.createElement('a');
        let fieldSeparator = ',';
        let lineSeparator = '\n';
        let csv = '';
        let columns = [
          'sku',
          'link',
          'title',
          'description',
          'severity',
          'platform',
          'destination'
        ];
        csv += columns.map(column => column).join(fieldSeparator);
        csv += lineSeparator;

        const filename = generateFileName();

        if (!csv.match(/^data:text\/csv/i)) {
          csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        r.data?.productIssues.forEach(datum => {
          let ctr = 0;
          columns.forEach(column => {
            if (ctr > 0) csv += fieldSeparator;
            csv += datum[column];
            ctr++;
          })
          csv += lineSeparator;
        })

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
      } else {
        apiErrorRaised(dispatch, r?.response)
      }
      setLoading(false);
    });


  }

  useEffect(() => {
    setLoading(true);
    let filter = [
      {
        'field': 'title',
        'value': searchParams.get('title'),
        'operator': '='
      }
    ];
    ProductIssues.index(companyId, projectId, routerParams.productDiagnosticId, limit, offset, filter).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        setProductIssues(r.data?.productIssues);
        setPagination(r.data?.pagination);
      } else {
        apiErrorRaised(dispatch, r?.response)
      }
      setLoading(false);
    });
  }, [dispatch, limit, offset, companyId, projectId]);


  return (
    <>
      <div className={'d-flex'}>
        <div className={''}>{
          <Link
            to={`/admin/companies/${companyId}/projects/${projectId}/product_diagnostic/`}>
            <Button className={'btn-icon-only text-dark'}>
              <span className={'fa fa-arrow-left'}/>
            </Button>
          </Link>
        }</div>
        <div className={'d-flex flex-row justify-content-between flex-grow-1 ms-4'}>
          <h1>Product Issues</h1>
        </div>
        <div>
          <Button color={'primary'} onClick={downloadCSV}>
            <span className={'fa fa-download'}/>
          </Button>
        </div>
      </div>
      <ProductIssuesTable productIssues={productIssues}/>
      <Paginator
        pagination={pagination}
        handleClick={handleClick}
        offset={offset}
        limit={limit}
      />
    </>
  )
}


export default ProductIssuesList;
