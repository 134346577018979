import React from "react";

export const ProductDatumView = ({productDatum}) => {

  return (
    <>
      {
        productDatum && productDatum.dataFields.map((field) =>
          !Array.isArray(field.value) &&
          <div key={field.name} className={'d-flex flex-row justify-content-center mb-1 pt-1 pb-1 border-bottom'}>
            <div key={field.name + '-name'} className={'products-table-values flex-grow-1 w-25 products-table-values'}>{field.name}</div>
            <div key={field.name + '-value'}
              className={'products-table-values d-flex flex-grow-1 w-75 text-left justify-content-left'}>{field.value}</div>
          </div>
        )
      }
      {
        productDatum && productDatum.dataFields.map((field) =>
          Array.isArray(field.value) &&
          <div key={field.name} className={'d-flex flex-row  justify-content-center mb-1 pt-1 pb-1 border-bottom'}>
            <div key={field.name + '-name'} className={'products-table-values flex-grow-1 w-25 products-table-values'}>{field.name}</div>
            <div key={field.name + '-value'}
              className={'products-table-values d-flex flex-grow-1 w-75 text-left justify-content-left'}>
              {Object.entries(field.value).map(([key, value]) =>
                !Array.isArray(value.value) &&
                <React.Fragment key={field.name + '-value-' + key}>
                  {value.name}:{value.value}<br/>
                </React.Fragment>
              )}
              {Object.entries(field.value).map(([key, value]) =>
                Array.isArray(value.value) &&
                <React.Fragment key={field.name + '-value-' + key}>
                  {value.name}:{JSON.stringify(field.value)}<br/>
                </React.Fragment>
              )}
            </div>
          </div>
        )
      }
    </>
  )
}
