let template = {
  status: 1
};

export const initialState = {
  id: template?.id,
  name: template?.name,
  priority: template?.priority,
  status: template?.status,
  conditions: template?.conditions,
  locale: template?.locale,
  renderStatus: template?.render_status,
  dynamic_fields: template?.dynamic_fields,
  active_products: template?.active_products,
  previews: template?.previews,
};

export const TemplateReducer = (initialState, action) => {
  switch (action.type) {
    case "PUBLISH":
      template = {
        ...template,
        renderStatus: action.payload,
      }
      break;
    case "TEMPLATE_NAME_UPDATED":
      template = {
        ...template,
        name: action.payload,
      }
      break;
    case "TEMPLATE_UPDATED":
      template = {
        ...template,
        [action.payload.name]: action.payload.value,
      }
      break;
    case "TEMPLATE_PRIORITY_UPDATED":
      template = {
        ...template,
        priority: action.payload,
      }
      break;
    case "TEMPLATE_ACTIVATED":
    case "TEMPLATE_DISABLED":
      template = {
        ...template,
        status: action.payload,
      }
      break;
    case "TEMPLATE_DELETED":
      template = {}
      break;
    case "TEMPLATE_CONDITIONS_UPDATED":
      template = {
        ...template,
        conditions: action.payload,
      }
      break;
    case "TEMPLATE_LOADED":
      template = {
        id: action.payload?.id,
        name: action.payload?.name,
        priority: action.payload?.priority,
        status: action.payload?.status,
        conditions: action.payload?.conditions,
        locale: action.payload?.locale,
        renderStatus: action.payload?.render_status,
        dynamic_fields: action.payload?.dynamic_fields,
        start_date: action.payload?.start_date,
        end_date: action.payload?.end_date,
        active_products: action.payload?.active_products,
        previews: action.payload?.previews,
      }
      break;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
  return template;
};
