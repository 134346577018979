import React, {useEffect, useState} from "react";
import classnames from "classnames";
import {Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Row,} from "reactstrap";
import {Link, useNavigate} from "react-router-dom";
import {useAuthDispatch, useAuthState} from "../../context/auth/AuthIndex";
import {jwtDecode} from "jwt-decode";
import {Auth} from "../../services/api/Auth";
import {loginSuccess} from "../../context/auth/AuthActions";
import {clearProjectState, useProjectDispatch} from "../../context/project/ProjectIndex";
import AuthHeader from "../Headers/AuthHeader";
import {useTranslation} from "react-i18next";

const Login = () => {
  const authState = useAuthState()
  const authDispatch = useAuthDispatch();
  const projectStateDispatch = useProjectDispatch()
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const {t} = useTranslation('auth')

  const [submitted, setSubmitted] = useState(false);
  const [inputs, setInputs] = useState({
    email: '',
    password: '',
  });
  const {email, password} = inputs;
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);


  function handleChange(e) {
    const {name, value} = e.target;
    setInputs(inputs => ({...inputs, [name]: value}));
  }

  const handleSubmitClick = async (e) => {
    e.preventDefault();

    setSubmitted(true);
    if (email && password) {
      let payload = {
        "email": email,
        "password": password,
      }
      setLoading(true);
      Auth.token(payload).then((r) => {
        setLoading(false);
        if (r?.status < 400) {
          let data = r.data;
          if (data.token) {
            let tokenData = jwtDecode(data.token);
            let currentUser = {};
            currentUser.auth_token = {};
            currentUser.auth_token.token = data.token;
            currentUser.auth_token.exp = tokenData.exp;
            currentUser.user = {};
            currentUser.user.email = tokenData.eml;
            currentUser.user.role = tokenData.role;
            currentUser.user.id = tokenData.uid;
            currentUser.user.name = tokenData.name;
            loginSuccess(authDispatch, currentUser);
            clearProjectState(projectStateDispatch)
          }
        } else {
          setError(r.response.data.error);
        }
      }).catch((error) => {
        setLoading(false);
        setError(error);
      });
    }
  }

  useEffect(() => {
    if (submitted && authState?.token !== null && authState?.user !== null && authState?.token !== undefined && authState?.user !== undefined) {
      if (localStorage.getItem('credentials') != null) {
        navigate('/admin/shopify/create_connection')
      } else {
        navigate('/admin/companies');
      }
    }
  }, [authState])

  return (
    <>
      <AuthHeader
        title={t('WELCOME')}
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-white border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>Login</small>
                </div>
                <Form className="needs-validation" noValidate>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedEmail,
                    })}
                  >
                    <label
                      className="form-control-label"
                    >
                      Email
                    </label>
                    <Input
                      placeholder="Email"
                      valid={submitted && email.length > 0}
                      invalid={submitted && !email}
                      type="email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      onFocus={() => setfocusedEmail(true)}
                      onBlur={() => setfocusedEmail(true)}
                    />
                    <div className="invalid-feedback">
                      {t('ERROR_EMAIL')}
                    </div>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <label
                      className="form-control-label"
                    >
                      Password
                    </label>
                    <Input
                      valid={submitted && password.length > 0}
                      invalid={submitted && !password}
                      placeholder="Password"
                      type="password"
                      name="password"
                      value={password}
                      onChange={handleChange}
                      onFocus={() => setfocusedPassword(true)}
                      onBlur={() => setfocusedPassword(true)}
                    />
                    <div className="invalid-feedback">
                      {t('ERROR_PASSWORD')}
                    </div>
                  </FormGroup>
                  <div className="text-danger">{error}</div>
                  <div className="text-center">
                    <Button className="my-4" color="primary" type="button"
                            onClick={handleSubmitClick} disabled={isLoading}
                    >
                      {isLoading &&
                        <span className="spinner-border spinner-border-sm me-1"/>}
                      Login
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col className="text-start" xs="6">
                <Link to="/auth/forgot_password"> <small>{t('RECOVER_PASSWORD')}</small></Link>
              </Col>
              <Col className="text-end" xs="6">
                <Link to="/auth/register"> <small>{t('CREATE_ACCOUNT')}</small></Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
