export function loadCompanies(dispatch, payload) {
  dispatch({
    type: 'LOAD_COMPANIES',
    payload: payload
  })
}

export function selectCompany(dispatch, payload) {
  dispatch({
    type: 'SELECT_COMPANY',
    payload: payload
  })
}

export function loadProjects(dispatch, payload) {
  dispatch({
    type: 'LOAD_PROJECTS',
    payload: payload
  })
}

export function selectProject(dispatch, payload) {
  dispatch({
    type: 'SELECT_PROJECT',
    payload: payload
  })
}

export function loadObjects(dispatch, payload) {
  dispatch({
    type: 'LOAD_OBJECTS',
    payload: payload
  })
}

export function selectObject(dispatch, payload) {
  dispatch({
    type: 'SELECT_OBJECT',
    payload: payload
  })
}
