import React from 'react';
import {Badge, Button, Card, CardBody, CardImg, Col} from "reactstrap";
import {Link} from "react-router-dom";
import classNames from "classnames";

const CompanyItem = ({company}) => {
  return (
    <>
      <Col md={3}>
        <Card>
          <CardImg className={'rounded-0'} src={require('assets/img/company_placeholder.jpg')}/>
          <CardBody>
            <div className={'d-flex flex-column'}>
              <h3 className={'mb-3'}>{company.name}</h3>
              <div className={'d-flex flex-column'}>
                <div className={'d-flex flex-row justify-content-start'}>
                  <p className={'mb-0'}>
                    <Badge color="" className="badge-dot badge-lg">
                      <i className={classNames({
                        'bg-success': true,
                      })}/>
                    </Badge>
                    {company.resume['ACTIVE'] ?? 0} Active projects
                  </p>
                </div>
                <div className={'d-flex flex-row justify-content-start'}>
                  <p className={'mb-0'}>
                    <Badge color="" className="badge-dot badge-lg">
                      <i className={classNames({
                        'bg-disabled': true,
                      })}/>
                    </Badge>
                    {company.resume['DISABLED'] ?? 0} Disabled projects
                  </p>
                </div>
              </div>
              <div className={'align-self-end'}>
                <Link
                  to={`/admin/companies/${company.id}`}>
                  <Button color={'primary'} className={'ps-5 pe-5'}>
                    Access
                  </Button>
                </Link>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  )
}

export default CompanyItem;
