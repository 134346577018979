import React from "react";
import {useProjectState} from "../../../context/project/ProjectIndex";
import {Button} from "reactstrap";
import {useAnalyticsState} from "../../../context/analytics/AnalyticsIndex";

export const AnalyticsTableExport = ({columns, data}) => {
  const analyticsState = useAnalyticsState()
  const projectState = useProjectState();
  const generateFileName = () => {
    let start = new Intl.DateTimeFormat(projectState.locale.replace('_', '-'))
      .format(new Date(analyticsState.dates.start_date))
    let end = new Intl.DateTimeFormat(projectState.locale.replace('_', '-'))
      .format(new Date(analyticsState.dates.end_date))
    return `analytics_${analyticsState.dimensions.join('_')}_${start}-${end}.csv`
  }

  const downloadCSV = () => {
    let link = document.createElement('a');
    let fieldSeparator = ',';
    let lineSeparator = '\n';
    let csv = '';
    csv += columns.map(column => column.name).join(fieldSeparator);
    csv += lineSeparator;

    const filename = generateFileName();

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    data.forEach(datum => {
      let ctr = 0;
      columns.forEach(column => {
        if (ctr > 0) csv += fieldSeparator;
        if (column.hasOwnProperty('format')) {
          let formatted = column.format(datum);
          if (typeof formatted === 'object') {
            csv += `"${column.selector(datum)}"`;
          } else {
            csv += `"${column.format(datum)}"`;
          }

        } else {
          csv += `"${column.selector(datum)}"`;
        }
        ctr++;
      })
      csv += lineSeparator;
    })

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }


  return (
    <>
      <Button color={'primary'} onClick={downloadCSV}>
        <span className={'fa fa-download'}/>
      </Button>
    </>
  )
}
