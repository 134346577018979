import React from "react";
import {useTranslation} from "react-i18next";

export const LandingFeedManagement = () => {
  const {t} = useTranslation('landing')
  return (
    <>
      <div className="">
        <div className={'d-flex responsive'}>
          <div className="col-6 h-50 custom-w100">
            <div className="image-fix">
              <img
                alt="..."
                className="img-fluid  animate__animated animate__fadeInLeft"
                src={require("assets/img/landing/Mac_1.png")}
              />
            </div>
          </div>
          <div className="col-6 p-6 d-flex flex-column justify-content-center custom-w100 custom-p3">
            <div className="mb-4 d-flex gap-2">
                  <span className="text-feed-manager d-flex flex-column justify-content-center">
                  <img
                    alt="..."
                    width='40px'
                    className="my-auto img-fluid"
                    src={require("assets/img/landing/feed.png")}
                  />
                  </span>
              <h3 className="m-0 title">{t('FEED_MANAGER')}</h3>
            </div>
            <div>
              <p>{t('FEED_MANAGER_DESCRIPTION_1')}</p>
              <ul className="text-lists-orange">
                <li>{t('FEED_MANAGER_SOCIAL_MEDIA')}</li>
                <li>{t('FEED_MANAGER_MARKETPLACES')}</li>
                <li>{t('FEED_MANAGER_RETARGETING')}</li>
                <li>{t('FEED_MANAGER_AFFILIATION')}</li>
                <li>{t('FEED_MANAGER_PRICE_COMPARATORS')}</li>
              </ul>
              <p>{t('FEED_MANAGER_DESCRIPTION_2')}</p>
              <p>{t('FEED_MANAGER_DESCRIPTION_3')}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
