import {Card, CardBody, CardHeader} from "reactstrap";
import React from "react";

export const EventInfoItem = ({event}) => {

  return (<>
    <Card className={'w-50 m-3 align-items-center'}>
      <CardHeader>
        <h3>{event.action}</h3>
      </CardHeader>
      <CardBody className={'d-flex flex-column align-items-center'}>
        <span>Success: </span><h1>{event.count_success}</h1>
        <span>Errors:</span><h3>{event.count_error}</h3>
      </CardBody>
    </Card>
  </>);
}
