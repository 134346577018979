import React, {useEffect, useState} from 'react';
import {DataSourceMappingPropertiesSelector} from "./DataSourceMappingPropertiesSelector";
import {Input} from "reactstrap";

export const DataSourceMappingItem = ({property, handleChangeMappingItem, availableProperties, index}) => {
  const [mappingItem, setMappingItem] = useState({
    'origin': property?.origin,
    'destination': property.destination,
    'editable': property.editable,
  })

  const handleChange = (e) => {
    setMappingItem({...mappingItem, [e.target.name]: e.target.value,})
  }

  useEffect(() => {
    handleChangeMappingItem(mappingItem, index)
  }, [mappingItem])

  return (
    <>
      <tr>
        <td>
          {
            property.editable ? <Input
                placeholder="Origin"
                type="text"
                name="origin"
                value={mappingItem.origin}
                onChange={handleChange}
              />
              : mappingItem.origin
          }
        </td>
        <td>
          <DataSourceMappingPropertiesSelector
            name={'destination'}
            item={mappingItem.destination}
            availableProperties={availableProperties}
            handleChange={handleChange}
          />
        </td>
      </tr>
    </>
  )
}
