import React from 'react';
import {Button, ButtonGroup} from "reactstrap";
import {useProjectState} from "../../../context/project/ProjectIndex";
import {Link} from "react-router-dom";

export const FeedExportStepButtonGroup = ({id, step}) => {
  const projectState = useProjectState()
  const idIsNotPresent = () => {
    return id === null || id === undefined || id === ''
  }

  return (
    <>
      <ButtonGroup aria-label="Settings" role="group">
        <Button active={step === 'settings'} className={"fe-menu-btn  border border-lavender-mist"}>
          {
            !idIsNotPresent() ? <Link
                to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/${id}/edit`}>
                Settings
              </Link> :
              'Settings'
          }
        </Button>
        <div className='bg-lavender-mist d-flex align-items-center'>
            <div className="vertical-line"></div> {/* Línea vertical */}
            </div>
        <Button disabled={idIsNotPresent()} active={step === 'mapping'} className={"fe-menu-btn  border border-lavender-mist"}>
          {!idIsNotPresent() ? <Link
            to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/${id}/mapping`}>
            Mapping
          </Link> : 'Mapping'}
        </Button>
        <div className='bg-lavender-mist d-flex align-items-center'>
            <div className="vertical-line"></div> {/* Línea vertical */}
        </div>
        <Button disabled={idIsNotPresent()} active={step === 'rules'} className={"fe-menu-btn  border border-lavender-mist"}>
          {!idIsNotPresent() ? <Link
            to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/${id}/rules`}>
            Rules
          </Link> : 'Rules'}
        </Button>
        <div className='bg-lavender-mist d-flex align-items-center'>
            <div className="vertical-line"></div> {/* Línea vertical */}
        </div>
        <Button disabled={idIsNotPresent()} active={step === 'schedule'} className={"fe-menu-btn  border border-lavender-mist"}>
          {!idIsNotPresent() ? <Link
            to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/${id}/schedule_params`}>
            Schedule & URL Params
          </Link> : 'Schedule & URL Params'}
        </Button>
        <div className='bg-lavender-mist d-flex align-items-center'>
            <div className="vertical-line"></div> {/* Línea vertical */}
        </div>
        <Button disabled={idIsNotPresent()} active={step === 'finish'}  className={"fe-menu-btn  border border-lavender-mist"}>
          {!idIsNotPresent() ? <Link
            to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/${id}/finish`}>
            Finish
          </Link> : 'Finish'}
        </Button>
      </ButtonGroup>
    </>
  )
}
