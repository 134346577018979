import CanvasImage from "./CanvasObject/CanvasImage";
import React, {useCallback, useEffect, useState} from "react";
import {useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {CanvasTwoPrice} from "./CanvasObject/CanvasTwoPrice";
import {CanvasText} from "./CanvasObject/CanvasText";

const CanvasFactory = ({canvasObject, position}) => {
  const apiErrorDispatch = useApiErrorDispatch();
  const [component, setComponent] = useState(null)

  const parseTemplateImageObject = useCallback(() => {
    setComponent(<CanvasImage
      position={position}
    />)
  }, [canvasObject]);

  const parseTemplateTwoPriceObject = useCallback(() => {
    setComponent(<CanvasTwoPrice
      position={position}
    />)
  }, [canvasObject]);

  const parseTemplateTextObject = useCallback(() => {
    setComponent(<CanvasText
      position={position}
    />)
  }, [canvasObject]);

  useEffect(() => {
    switch (canvasObject.object.type.split(/[\\ ]+/).pop()) {
      case "TemplateImageObject":
        parseTemplateImageObject();
        break;
      case "TemplateTwoPriceObject":
        parseTemplateTwoPriceObject();
        break;
      case "TemplateTextObject":
        parseTemplateTextObject();
        break;
      default:
    }
  }, [canvasObject, apiErrorDispatch, parseTemplateImageObject, parseTemplateTwoPriceObject]);

  return (
    component === null ? <></> : component
  )
}

export default CanvasFactory;
