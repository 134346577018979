import React, {useState} from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Row,} from "reactstrap";
// core components
import {Auth} from "../../services/api/Auth";
import {useNavigate} from "react-router-dom";
import AuthHeader from "../Headers/AuthHeader";
import {useTranslation} from "react-i18next";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [focusedEmail, setFocusedEmail] = React.useState(false);
  const [loggingIn, setLoggingIn] = useState(false)
  const [errorResponse, setErrorResponse] = useState('')
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const {t} = useTranslation('auth')

  const parseErrors = (errors) => {
    let response = '';
    for (const [key, value] of Object.entries(errors)) {
      response += `${key}: ${value}`;
    }

    return response;
  }

  function handleEmailChange(e) {
    const {name, value} = e.target;
    setEmail(value);
  }

  const handleSubmitClick = (e) => {
    setSubmitted(true);
    e.preventDefault();

    const payload = {
      "email": email,
    }
    if (!email) {
      return
    }
    setLoggingIn(true);
    Auth.resetPasswordRequest(payload).then((r) => {
      setLoggingIn(false);
      if (r?.status < 400) {
        navigate('/auth/forgot_password/thanks');
      } else {
        setErrorResponse(parseErrors(r.response.data));
      }
    })
  }

  return (
    <>
      <AuthHeader
        title={t('RESET_PASSWORD')}
        lead={t('RESET_PASSWORD_TEXT')}
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="8">
            <Card className="bg-white border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                </div>
                <Form role="form">
                  <FormGroup
                    className={classnames({
                      focused: focusedEmail,
                    })}
                  >
                    <label
                      className="form-control-label"
                    >
                      Email
                    </label>
                    <Input
                      placeholder="Email"
                      valid={submitted && email.length > 0}
                      invalid={submitted && !email}
                      type="email"
                      name="email"
                      value={email}
                      onChange={handleEmailChange}
                      onFocus={() => setFocusedEmail(true)}
                      onBlur={() => setFocusedEmail(true)}
                    />
                    <div className="invalid-feedback">
                      {t('ERROR_EMAIL')}
                    </div>
                  </FormGroup>
                  <div className="text-danger">{errorResponse}</div>
                  <div className="text-center">
                    <Button className="mt-4" color="primary" type="button"
                            onClick={handleSubmitClick}
                    >
                      {loggingIn &&
                        <span className="spinner-border spinner-border-sm me-1"/>}
                      {t('RESET_PASSWORD')}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ForgotPassword;
