import React, {useEffect, useState} from 'react'
import {apiErrorRaised} from "../../context/api_error/ApiErrorActions";
import {Auth} from "../../services/api/Auth";
import {useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {Button, Card, CardBody, CardFooter, CardHeader, Input} from "reactstrap";

export const UserProfile = () => {
  const [user, setUser] = useState({
    person: {
      full_name: {
        first_name: '',
        last_name: ''
      }
    }

  });
  const apiErrorDispatch = useApiErrorDispatch()
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    Auth.get().then((r) => {
      if (r?.status < 400) {
        setUser(r.data)
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    })
  }, [apiErrorDispatch])

  const handlePersonFullnameChange = (e) => {
    const {name, value} = e.target;
    setUser(
      {
        ...user,
        person: {
          ...user.person,
          full_name: {
            ...user.person.full_name,
            [name]: value
          }
        }
      }
    );
  }

  const handleSubmit = () => {
    setLoading(true)
    Auth.put(user.person).then((r) => {
      setLoading(false)
      if (r?.status < 400) {
        //
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    })
  }

  return (
    <>
      <Card>
        <CardHeader>
          <h1>Profile</h1>
        </CardHeader>
        <CardBody>
          <div className={'d-flex flex-column'}>
            <div className={'d-flex'}>
              <div className={'d-flex flex-column mb-3 flex-grow-0 me-5'}>
                <h4>Email</h4>
                <div className={'d-flex'}>
                  <Input value={user.email} disabled={'true'}/>
                </div>
              </div>
            </div>
            <div className={'d-flex'}>
              <div className={'d-flex flex-column mb-3 flex-grow-0 me-5'}>
                <h4>First Name</h4>
                <div className={'d-flex'}>
                  <Input

                    placeholder="First Name"
                    type="text"
                    name="first_name"
                    value={user?.person?.full_name.first_name}
                    onChange={handlePersonFullnameChange}
                  />
                </div>
              </div>
              <div className={'d-flex flex-column mb-3 flex-grow-0 me-5'}>
                <h4>Last Name</h4>
                <div className={'d-flex'}>
                  <Input

                    placeholder="Last Name"
                    type="text"
                    name="last_name"
                    value={user?.person?.full_name.last_name}
                    onChange={handlePersonFullnameChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <div className={'d-flex justify-content-end'}>
            <Button color={'primary'} onClick={handleSubmit} className={'float-right'}>

              {
                isLoading ? <span className={'fa fa-spinner'}/> : 'Save'
              }
            </Button>
          </div>
        </CardFooter>
      </Card>
    </>
  )
}
