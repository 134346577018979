import React, {useState} from 'react'
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {addAndQuery, addOrQuery, deleteQuery, useRuleDispatch} from "../../../../context/rule/RuleIndex";
import {deleteOrQuery} from "../../../../context/rule/RuleActions";

export const FeedExportRuleQueryAction = ({position, orPosition}) => {
  const ruleDispatch = useRuleDispatch()
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleAndAction = () => {
    addAndQuery(ruleDispatch)
  }

  const handleDeleteAction = () => {
    if (orPosition === undefined) {
      deleteQuery(ruleDispatch, position)
    } else {
      deleteOrQuery(ruleDispatch, position, orPosition)
    }
  }

  const handleAddOrAction = () => {
    addOrQuery(ruleDispatch, position)
  }

  return (
    <>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={'down'}>
        <DropdownToggle className='btn-icon-only btn-terciary'>
          <span className={'fa-solid fa-plus fs-3'}/>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header> Options</DropdownItem>
          {
            orPosition === undefined ?
              <DropdownItem onClick={handleAndAction}><span className={'fa-solid fa-plus text-designer'}/> And</DropdownItem> : ''
          }
          <DropdownItem onClick={handleAddOrAction}><span className={'fa-solid fa-plus text-designer'}/> Or</DropdownItem>
          {/*<DropdownItem><span className={'fa fa-copy'}/> Copy</DropdownItem>*/}
          {
            position !== 0 || orPosition !== undefined ?
              <DropdownItem onClick={handleDeleteAction}><span className={'fa-solid fa-trash-alt text-warning'}/> Delete</DropdownItem> : ''
          }
        </DropdownMenu>
      </Dropdown>
    </>
  )
}
