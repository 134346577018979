import React from 'react'
import {Col, Row} from "reactstrap";
import {FeedExportRuleQueryCondition} from "./FeedExportRuleQueryCondition";
import {useRuleState} from "../../../../context/rule/RuleIndex";
import {FeedExportRuleOrQueryCondition} from "./FeedExportRuleOrQueryCondition";
import {FeedExportRuleQueryAction} from "./FeedExportRuleQueryAction";

export const FeedExportRuleQuery = ({position}) => {

  const ruleState = useRuleState()
  return (
    <>
      <div className='d-flex'>
        <div md={10} className='w-100'>
          <FeedExportRuleQueryCondition
            position={position}
          />
        </div>
        <div md={1} className={'col-1 d-flex align-items-center p-2'}>
          <FeedExportRuleQueryAction position={position}/>
        </div>
      </div>

      {ruleState.queries[position]?.or_queries && ruleState.queries[position]?.or_queries.map((or_query, i) => {
        return (
          <div key={i} className='d-flex'>
            <div md={10} className='col'>
              <FeedExportRuleOrQueryCondition
                key={i}
                position={position}
                orPosition={i}/>
            </div>
            <div md={1} className='col-1 d-flex align-items-center p-2'>
              <FeedExportRuleQueryAction position={position} key={i} orPosition={i}/>
            </div>
          </div>
        )
      })}
    </>
  )
}
