import {v4 as uuid4} from "uuid";
import {BASE_PRIORITY} from "../CanvasReducer";

export const objectAdd = (canvas, type) => {
  let newObject = createNewObject(canvas, type);
  canvas.objects.push(newObject)
  canvas = {
    ...canvas,
    selectedObject: newObject,
    objects: canvas.objects.map(object => ({...object, selected: object.id === newObject.id}))
  }
  return canvas;
};

const createNewObject = (canvas, type) => {
  let newId = canvas.objects.length;
  let uuid = uuid4();
  let lastPriority = canvas.objects.length > 0 ? canvas.objects[newId - 1].object.priority : 1
  let object = {};

  switch (type) {
    case 'two_prices_object':
      object = newTwoPricesObject(uuid, lastPriority, canvas.template.id, canvas.template.locale)
      break;
    case 'text_object':
      object = newTextObject(uuid, lastPriority, canvas.template.id, canvas.template.locale)
      break;
    case 'image':
      object = newImageObject(uuid, lastPriority, canvas.template.id, canvas.template.locale)
      break;
    default:
      throw new Error(`Unhandled object type: ${type}`);
  }
  return {
    id: uuid,
    object: object,
    index: BASE_PRIORITY - newId,
    selected: true,
    visible: true,
    errors: [],
  };
}

const newImageObject = (newId, lastPriority, templateId, locale) => {
  return {
    id: newId,
    template_id: templateId,
    data: {
      origin: {
        x: 0,
        y: 0
      },
      size: {
        x: 400,
        y: 400
      },
      content: {
        imageContent: {
          type: "property",
          value: "{{image}}",
          format: {
            type: "image"
          }
        }
      },
      align: {
        horizontal: "center",
        vertical: "center"
      },
      displayTransformation: "fit",
      backgroundColor: {
        red: 0,
        blue: 0,
        green: 0,
        alpha: 0,
      },
      padding: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      },
      margin: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      }
    },
    status: 1,
    priority: lastPriority + 1,
    locale: locale,
    conditions: [],
    type: "Cyberclick\\Designer\\TemplateObject\\Domain\\TemplateImageObject\\TemplateImageObject"
  }
}

const newTwoPricesObject = (newId, lastPriority, templateId, locale) => {
  return {
    id: newId,
    template_id: templateId,
    data: {
      content: {
        offerContent: {
          type: "property",
          value: "{{salePrice}}",
          format: {
            type: "money"
          }
        },
        priceContent: {
          type: "property",
          value: "{{price}}",
          format: {
            type: "money"
          }
        }
      },
      strickedPriceFont: "",
      origin: {
        x: 0,
        y: 0
      },
      size: {
        x: 400,
        y: 200
      },
      strickedPriceData: {
        whole: {
          size: 50,
          elevation: 0
        },
        decimal: {
          size: 50,
          elevation: 0
        },
        currency: {
          size: 25,
          elevation: 25
        }
      },
      strickedPriceAngle: 0,
      strickedPriceColor: {
        red: 255,
        green: 0,
        blue: 0,
        alpha: 127
      },
      strickedPriceAlign: {
        horizontal: "right",
        vertical: "bottom"
      },
      strickedPricePadding: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      },
      strickedPriceMargin: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      },
      strickedPriceLine: {
        origin: "bottom",
        thickness: 5,
        lineColor: {
          red: 255,
          green: 255,
          blue: 255,
          alpha: 0
        }
      },
      strickedPriceInnerBackgroundColor: {
        red: 0,
        blue: 0,
        green: 0,
        alpha: 0,
      },
      strickedPricePercentageSize: 50,
      finalPriceFont: "",
      finalPriceData: {
        whole: {
          size: 80,
          elevation: 0
        },
        decimal: {
          size: 80,
          elevation: 0
        },
        currency: {
          size: 40,
          elevation: 40
        },
      },
      finalPriceAngle: 0,
      finalPriceColor: {
        red: 255,
        green: 0,
        blue: 0,
        alpha: 127
      },
      finalPriceAlign: {
        horizontal: "right",
        vertical: "bottom"
      },
      finalPricePadding: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      },
      finalPriceMargin: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      },
      finalPriceInnerBackgroundColor: {
        red: 0,
        blue: 0,
        green: 0,
        alpha: 0,
      },
      finalPricePercentageSize: 50,
      offerIsFirst: 1,
      oneAboveOther: 0,
      onlyFinalPrice: 0,
      backgroundColor: {
        red: 0,
        blue: 0,
        green: 0,
        alpha: 0,
      },
      padding: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      },
      margin: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      }
    },
    status: 1,
    priority: lastPriority + 1,
    locale: locale,
    conditions: [],
    type: "Cyberclick\\Designer\\TemplateObject\\Domain\\TemplateTextObject\\TemplateTwoPriceObject"
  }
}

const newTextObject = (newId, lastPriority, templateId, locale) => {
  return {
    id: newId,
    template_id: templateId,
    data: {
      content: {
        textContent: {
          type: "property",
          value: "{{title}}"
        }
      },
      font: "",
      fontSize: 27,
      origin: {
        x: 0,
        y: 0
      },
      size: {
        x: 400,
        y: 200
      },
      angle: 0,
      color: {
        red: 255,
        green: 0,
        blue: 0,
        alpha: 127
      },
      align: {
        horizontal: "left",
        vertical: "bottom"
      },
      innerBackgroundColor: {
        red: 0,
        green: 0,
        blue: 0,
        alpha: 0
      },
      fitTextOnArea: 0,
      backgroundColor: {
        red: 0,
        blue: 0,
        green: 0,
        alpha: 0,
      },
      padding: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      },
      margin: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      }
    },
    status: 1,
    priority: lastPriority + 1,
    locale: locale,
    conditions: [],
    type: "Cyberclick\\Designer\\TemplateObject\\Domain\\TemplateTextObject\\TemplateTextObject"
  }
}
