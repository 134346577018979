import React, {useEffect} from "react";
import {Container,} from "reactstrap";
import TemplateList from "../../../components/Templates/TemplateList";
import {useProjectDispatch} from "../../../context/project/ProjectContext";
import {useParams} from "react-router-dom";
import {updateCompanyAndProjectBasedOnUrl} from "../../../context/project/ProjectActions";
import {TableFiltersProvider} from "../../../context/table_filters/TableFiltersIndex";

const TemplatesView = () => {
  const projectDispatch = useProjectDispatch()
  const routerParams = useParams();
  useEffect(() => {
    updateCompanyAndProjectBasedOnUrl(projectDispatch, routerParams.companyId, routerParams.projectId)
  }, [])

  return (
    <>
      <Container className="mt-5" fluid>
        <TableFiltersProvider>
          <TemplateList
            companyId={routerParams.companyId}
            projectId={routerParams.projectId}
          />
        </TableFiltersProvider>
      </Container>
    </>
  );
};

export default TemplatesView;
