export function loadCompanies(dispatch, payload) {
  dispatch({
    type: 'LOAD_COMPANIES',
    payload: payload
  })
}

export function selectCompany(dispatch, payload) {
  dispatch({
    type: 'SELECT_COMPANY',
    payload: payload
  })
}

export function loadProjects(dispatch, payload) {
  dispatch({
    type: 'LOAD_PROJECTS',
    payload: payload
  })
}

export function selectProject(dispatch, payload) {
  dispatch({
    type: 'SELECT_PROJECT',
    payload: payload
  })
}

export function loadFeedExports(dispatch, payload) {
  dispatch({
    type: 'LOAD_FEED_EXPORTS',
    payload: payload
  })
}

export function selectFeedExport(dispatch, payload) {
  dispatch({
    type: 'SELECT_FEED_EXPORT',
    payload: payload
  })
}

export function loadRules(dispatch, payload) {
  dispatch({
    type: 'LOAD_RULES',
    payload: payload
  })
}

export function selectRule(dispatch, payload) {
  dispatch({
    type: 'SELECT_RULE',
    payload: payload
  })
}
