import {useCanvasDispatch, useCanvasState} from "../../../../../context/canvas/CanvasIndex";
import {updateData} from "../../../../../context/canvas/CanvasActions";
import {Input} from "reactstrap";
import React from "react";

export const CanvasObjectInfoSidebarDetailPriceContent = ({title, property}) => {
  const canvasState = useCanvasState()
  const canvasDispatch = useCanvasDispatch()
  const templateObject = canvasState.selectedObject

  const handleChange = (e) => {
    const {value} = e.target;
    updateData(
      canvasDispatch,
      templateObject?.id,
      'content',
      {
        ...templateObject?.object.data.content,
        [property]: {
          ...templateObject?.object.data.content[property],
          value: value,
        }
      }
    )
  }

  return (
    <>
      <div className={'d-flex flex-column'}>
        <strong className={'mt-1'}>{title}</strong>
        <Input
          placeholder="{{price}}"
          value={templateObject?.object.data.content?.[property]?.value ?? ''}
          name="contentValue"
          onChange={handleChange}
        />
      </div>
    </>
  )
}
