import {setDates} from "./ReducerActions/Dates/SetDates";
import {setDatesWithComparation} from "./ReducerActions/Dates/SetDatesWithComparation";
import {subDays} from "date-fns";
import {updateDimensions} from "./ReducerActions/Dimensions/UpdateDimensions";
import {addFilter} from "./ReducerActions/Filters/AddFilter";
import {removeFilter} from "./ReducerActions/Filters/RemoveFilter";

export const initialState = {
  dates: {
    start_date: subDays((new Date()).setHours(0, 0, 0, 0), 7),
    end_date: subDays((new Date()).setHours(0, 0, 0, 0), 0),
  },
  compare_dates: null,
  filters: [],
  metrics: [{value: 'products', label: 'Products', type: 'number'}],
  dimensions: [],
  selected_rows: [],
};

export const AnalyticsReducer = (initialState, action) => {
  switch (action.type) {
    case "SET_DATES":
      initialState = setDates(initialState, action.payload);
      break;
    case "SET_DATES_WITH_COMPARATION":
      initialState = setDatesWithComparation(initialState, action.payload);
      break;
    case "UPDATE_DIMENSIONS":
      initialState = updateDimensions(initialState, action.payload);
      break;
    case "ADD_FILTER":
      initialState = addFilter(initialState, action.payload);
      break;
    case "REMOVE_FILTER":
      initialState = removeFilter(initialState, action.payload);
      break;
    case "SELECT_ROWS":
      initialState = {...initialState, selected_rows: action.payload};
      break;
    case "SELECT_METRIC":
      initialState = {
        ...initialState,
        metrics: [
          ...initialState.metrics.slice(0, action.payload.index),
          action.payload.metric,
          ...initialState.metrics.slice(action.payload.index + 1)
        ],
      }
      break
    case "ADD_METRIC":
      initialState = {
        ...initialState,
        metrics: [
          ...initialState.metrics,
          action.payload.metric,
        ],
      }
      break
    case "REMOVE_METRIC":
      initialState = {
        ...initialState,
        metrics: [
          ...initialState.metrics.slice(0, action.payload.index),
          ...initialState.metrics.slice(action.payload.index + 1)
        ],
      }
      break
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
  return initialState;
};
