import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

apiClient.interceptors.request.use((config) => {
    if (config?.data instanceof FormData) {
      config.headers['content-type'] = 'multipart/form-data';
    }
    let authToken = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).token
      : "";
    if (!authToken?.token) {
      return config
    }
    config.headers.Authorization = `Bearer ${authToken.token}`;
    return config
  },
  error => Promise.reject(error),
);

apiClient.interceptors.response.use((response) =>
    response,
  async (error) => {
    if (error?.response?.data?.code === 'expired_token') {
      window.location = '/auth/login'
      localStorage.clear();
    }
    return Promise.reject(error);
  },
);

const {get, post, put, delete: destroy} = apiClient;
export {get, post, put, destroy};
