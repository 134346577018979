import React, {useEffect, useState} from 'react';
import {useProjectState} from "../../context/project/ProjectIndex";
import {Badge, Card, CardBody, CardFooter, CardHeader, CardImg} from "reactstrap";
import {Link} from "react-router-dom";
import classNames from "classnames";

export const ProductItemGrid = ({data, renderedImage}) => {
  const projectState = useProjectState()
  const [product, setProduct] = useState(data);

  useEffect(() => {
    setProduct(data)
  }, [data]);

  const parsePrice = (price) => {
    if (price === undefined || price === null) {
      return '-'
    }
    const formatter = new Intl.NumberFormat(projectState.locale.replace('_', '-'), {
      style: 'currency',
      currency: price.currency,
    });

    return formatter.format(price.amount / 1000)
  }

  return (
    <>
      <Card className={'product-card'}>
        <CardHeader className={'d-flex flex-column w-100'}>
          <Badge color="" className="badge-stroke pt-0 pb-3 product-card_header_badge">
            <i className={classNames({
              'bg-gray': product.status === 0,
              'bg-success': product.status === 1,
              'bg-red-danger': product.status === 2,
              'bg-warning': product.status === 3,
            })}/>
          </Badge>
          <h3 className={'product-card_header_text'}>
            <Link
              to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/products/${product.id}`}>
              {product.title}
            </Link>
          </h3>
        </CardHeader>
        <CardBody className='p-0'>
          <div>
            {
              renderedImage === true ?
                <CardImg className="rounded-0" src={product.renderedImage.url}/>
                :
                <CardImg className="rounded-0" src={product.image.url}/>
            }
          </div>
        </CardBody>
        <CardFooter className={'d-flex flex-row w-100 justify-content-between'}>
          <p>{parsePrice(product.price)}</p>
          <p>{parsePrice(product.salePrice)}</p>
        </CardFooter>
      </Card>
    </>
  )
}
