export const setDates = (analyticsState, data) => {
  analyticsState = {
    ...analyticsState,
    dates: {
      start_date: data.start_date,
      end_date: data.end_date
    },
    compare_dates: null,
  }
  return analyticsState
};
