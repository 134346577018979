import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";

export const ProductImagesCarroussel = ({images, showThumbs}) => {

  const handleClick = (index) => {
    window.open(images[index].url)
  }

  return (
    <>
      <Carousel className="d-flex justify-content-center" showArrows={true} showThumbs={showThumbs} showStatus={false} infiniteLoop={true}
                onClickItem={handleClick}>
        {
          images && images.map((image, i) =>
            <img key={i} className="product-image-detail mb-5" alt="" src={image.url}/>
          )
        }
      </Carousel>
    </>
  )
}
