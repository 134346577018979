import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const LandingCarouselLogo = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1800, // Adjust the speed in milliseconds
    slidesToShow: 7, // Number of logos shown at a time
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      <div className='centered-logo'>
        <img src={require("../../assets/img/landing/logos/idealo.png")} alt="Logo Idealo"/>
      </div>
      <div className='centered-logo'>
        <img src={require("../../assets/img/landing/logos/Pinterest.png")} alt="Logo Pinterest"/>
      </div>
      <div className='centered-logo'>
        <img src={require("../../assets/img/landing/logos/Criteo.png")} alt="Logo Criteo"/>
      </div>
      <div className='centered-logo'>
        <img src={require("../../assets/img/landing/logos/Adform.png")} alt="Logo Adform"/>
      </div>
      <div className='centered-logo'>
        <img src={require("../../assets/img/landing/logos/Awin.png")} alt="Logo Awin"/>
      </div>
      <div className='centered-logo'>
        <img src={require("../../assets/img/landing/logos/Google.png")} alt="Logo Google"/>
      </div>
      <div className='centered-logo'>
        <img src={require("../../assets/img/landing/logos/kuanto-kusta 1.png")} alt="Logo Kuanto Kusta"/>
      </div>
      <div className='centered-logo'>
        <img src={require("../../assets/img/landing/logos/Meta.png")} alt="Logo Meta"/>
      </div>
      <div className='centered-logo'>
        <img src={require("../../assets/img/landing/logos/microsoft 1.png")} alt="Logo Microsoft"/>
      </div>
      <div className='centered-logo'>
        <img src={require("../../assets/img/landing/logos/TikTok.png")} alt="Logo TikTok"/>
      </div>
      <div className='centered-logo'>
        <img src={require("../../assets/img/landing/logos/amazon.png")} alt="Logo Amazon"/>
      </div>
    </Slider>
  );
};

export default LandingCarouselLogo;
