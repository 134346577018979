import {updateData, useCanvasDispatch, useCanvasState} from "../../../../../context/canvas/CanvasIndex";
import {Input} from "reactstrap";
import React from "react";

export const CanvasObjectInfoSidebarDetailPriceData = ({title, property}) => {
  const canvasState = useCanvasState()
  const canvasDispatch = useCanvasDispatch()
  const templateObject = canvasState.selectedObject

  const handleChange = (e) => {
    const {name, value} = e.target;
    let wholeSize = templateObject?.object.data[property].whole.size
    let wholeElevation = templateObject?.object.data[property].whole.elevation
    let decimalSize = templateObject?.object.data[property].decimal.size
    let decimalElevation = templateObject?.object.data[property].decimal.elevation
    let currencySize = templateObject?.object.data[property].currency.size
    let currencyElevation = templateObject?.object.data[property].currency.elevation

    switch (name) {
      case 'whole.size':
        wholeSize = value;
        break;
      case 'whole.elevation':
        wholeElevation = value;
        break;
      case 'decimal.size':
        decimalSize = value;
        break;
      case 'decimal.elevation':
        decimalElevation = value;
        break;
      case 'currency.size':
        currencySize = value;
        break;
      case 'currency.elevation':
        currencyElevation = value;
        break;
      default:
    }
    updateData(
      canvasDispatch,
      templateObject?.id,
      property,
      {
        whole: {
          size: parseInt(wholeSize),
          elevation: parseInt(wholeElevation),
        },
        decimal: {
          size: parseInt(decimalSize),
          elevation: parseInt(decimalElevation),
        },
        currency: {
          size: parseInt(currencySize),
          elevation: parseInt(currencyElevation),
        }
      }
    )
  }

  return (
    <>
      <div className={'d-flex flex-column'}>
        <strong className={'mt-1'}>{title}</strong>
        <div className={'d-flex flex-row mt-1'}>
          <div className={'d-flex flex-column flex-grow-1 m-2'}>
            <strong>Size</strong>
            <Input
              placeholder="50"
              type="number"
              value={templateObject?.object.data[property]?.whole.size ?? 0}
              name="whole.size"
              onChange={handleChange}
            />
          </div>
          <div className={'d-flex flex-column flex-grow-1 m-2'}>
            <strong>Elevation</strong>
            <Input
              placeholder="50"
              type="number"
              value={templateObject?.object.data[property]?.whole.elevation ?? 0}
              name="whole.elevation"
              onChange={handleChange}
            />
          </div>
        </div>
        <strong className={'mt-1'}>Cents</strong>
        <div className={'d-flex flex-row mt-1'}>
          <div className={'d-flex flex-column flex-grow-1 m-2'}>
            <strong>Size</strong>
            <Input
              placeholder="50"
              type="number"
              value={templateObject?.object.data[property]?.decimal.size ?? 0}
              name="decimal.size"
              onChange={handleChange}
            />
          </div>
          <div className={'d-flex flex-column flex-grow-1 m-2'}>
            <strong>Elevation</strong>
            <Input
              placeholder="50"
              type="number"
              value={templateObject?.object.data[property]?.decimal.elevation ?? 0}
              name="decimal.elevation"
              onChange={handleChange}
            />
          </div>
        </div>
        <strong className={'mt-1'}>Currency</strong>
        <div className={'d-flex flex-row mt-1'}>
          <div className={'d-flex flex-column flex-grow-1 m-2'}>
            <strong>Size</strong>
            <Input
              placeholder="50"
              type="number"
              value={templateObject?.object.data[property]?.currency.size ?? 0}
              name="currency.size"
              onChange={handleChange}
            />
          </div>
          <div className={'d-flex flex-column flex-grow-1 m-2'}>
            <strong>Elevation</strong>
            <Input
              placeholder="50"
              type="number"
              value={templateObject?.object.data[property]?.currency.elevation ?? 0}
              name="currency.elevation"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </>
  )
}
