import {Card, CardBody, CardFooter, CardImg} from "reactstrap";
import {Link} from "react-router-dom";

export const AssetsItemGrid = ({asset}) => {
  const fallbackImg = require('assets/img/asset_placeholder.png')
    return (
        <>
            <Card className={'asset-card'}>
                <CardBody className='p-0 d-flex'>
                        {
                            asset.type === "image" ?
                                <CardImg className="rounded-0 card-image-fit" src={asset.externalUrl}
                                         onError={({ currentTarget }) => {
                                           currentTarget.onerror = null;
                                           currentTarget.src=fallbackImg;
                                           currentTarget.classList.remove('card-image-fit')
                                         }}
                                /> :
                                <span className="ni ni-5x ni-caps-small asset-font" />
                        }
                </CardBody>
                <CardFooter className={'d-flex flex-row w-100 justify-content-between'}>
                    <Link className={'mw-100'} target={"_blank"}
                        to={asset.externalUrl}>
                        {asset.name}
                    </Link>
                </CardFooter>
            </Card>
        </>
    )
}
