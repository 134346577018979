import React from "react";
import {Container,} from "reactstrap";
import {useParams} from "react-router-dom";
import FeedExportHistoryDetail from "../../../components/FeedExports/FeedExportHistorys/FeedExportHistoryDetail";

export default function FeedExportHistoryView() {
  const routerParams = useParams();
  return (
    <>
      <Container className="mt-5" fluid>
        <FeedExportHistoryDetail
          companyId={routerParams.companyId}
          projectId={routerParams.projectId}
          feedExportId={routerParams.feedExportId}
          id={routerParams.feedExportHistoryId}
        />
      </Container>
    </>
  );
};
