export const ProductFilterOptions = [
  {value: 'sku', label: 'Sku', type: 'string'},
  {value: 'title', label: 'Name', type: 'string'},
  {value: 'link', label: 'Url', type: 'string'},
  {value: 'availability', label: 'Availability', type: 'string'},
  {value: 'price_amount', label: 'Price', type: 'money'},
  {value: 'sale_price_amount', label: 'Sale Price', type: 'money'},
  {value: 'condition', label: 'Condition', type: 'string'},
  {value: 'status', label: 'Status', type: 'integer'},
  {value: 'brand', label: 'Brand', type: 'string'},
  {value: 'category', label: 'Product Category', type: 'string'},
  {value: 'type', label: 'Product Type', type: 'string'},
  {value: 'gtin', label: 'Gtin', type: 'string'},
  {value: 'itemGroupId', label: 'Item Group Id', type: 'string'},
  {value: 'mpn', label: 'Mpn', type: 'string'},
  {value: 'properties', label: 'Custom Properties', type: 'properties'},
]
