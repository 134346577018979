import React, {useEffect, useState} from 'react'
import Select from "react-select";
import {useProjectState} from "../../../../context/project/ProjectIndex";

export const FeedExportRuleActionPropertiesSelect = ({value, onChange}) => {
  const projectState = useProjectState()
  const [properties, setProperties] = useState([])

  const defaultProperties = [
    {label: 'All', value: 'all'},
    {label: 'Availability', value: 'availability'},
    {label: 'Brand', value: 'brand'},
    {label: 'Category', value: 'category'},
    {label: 'Color', value: 'color'},
    {label: 'Condition', value: 'condition'},
    {label: 'Description', value: 'description'},
    {label: 'Discount', value: 'discount'},
    {label: 'Gender', value: 'gender'},
    {label: 'GTIN', value: 'gtin'},
    {label: 'Inventories', value: 'inventories'},
    {label: 'Image', value: 'image'},
    {label: 'Link', value: 'link'},
    {label: 'Material', value: 'material'},
    {label: 'MPN', value: 'mpn'},
    {label: 'Price', value: 'price'},
    {label: 'Quantity', value: 'quantity'},
    {label: 'Rendered Image', value: 'renderedImage'},
    {label: 'Sale Price', value: 'salePrice'},
    {label: 'Shipping', value: 'shipping'},
    {label: 'Shipping Price', value: 'shipping.price.amount'},
    {label: 'Shipping Currency', value: 'shipping.price.currency'},
    {label: 'Shipping Country', value: 'shipping.country'},
    {label: 'Shipping Region', value: 'shipping.region'},
    {label: 'Shipping Service', value: 'shipping.service'},
    {label: 'Size', value: 'size'},
    {label: 'SKU', value: 'sku'},
    {label: 'Store Id', value: 'store_id'},
    {label: 'Title', value: 'title'},
    {label: 'Type', value: 'type'},
    {label: 'Weight', value: 'weight'},
    {label: 'Energy Efficiency Class', value: 'energyEfficiencyClass'},
    {label: 'Item Group Id', value: 'itemGroupId'},
  ]

  useEffect(() => {
    const prop = defaultProperties;
    projectState.properties.map((property, i) => {
      prop.push({
        label: `Custom - ${property}`,
        value: `properties.${property}`
      })
    })
    setProperties(prop)

  }, [projectState.properties])


  return (
    <>
      <Select
        value={properties.find(listField => listField.value === value)}
        onChange={onChange}
        options={properties}
      />
    </>
  )
}
