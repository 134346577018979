import React, {useState} from "react";
import {Collapse, Container} from "reactstrap";
import classnames from "classnames";
import {CanvasObjectInfoSidebarDetailPadding} from "../Common/CanvasObjectInfoSidebarDetailPadding";
import {CanvasObjectInfoSidebarDetailColor} from "../Common/CanvasObjectInfoSidebarDetailColor";
import {CanvasObjectInfoSidebarDetailOrigin} from "../Common/CanvasObjectInfoSidebarDetailOrigin";
import {CanvasObjectInfoSidebarDetailSize} from "../Common/CanvasObjectInfoSidebarDetailSize";
import {CanvasObjectInfoSidebarDetailAlign} from "../Common/CanvasObjectInfoSidebarDetailAlign";
import {CanvasObjectInfoSidebarDetailImageSource} from "./CanvasObjectInfoSidebarDetailImageSource";
import {CanvasObjectInfoSidebarDetailImageDisplayTransformation} from "./CanvasObjectInfoSidebarDetailImageDisplayTransformation";

export const CanvasObjectInfoSidebarDetailImage = () => {
  const [collapse, setCollapse] = useState(false);

  const handleCollapse = () => {
    setCollapse(!collapse);
  }

  return (
    <>
      <div onClick={handleCollapse}
           className={'canvas_sidebar_header d-flex flex-row justify-content-between align-items-center p-3'}>
        <h3 className="mb-0">Image Info</h3>
        <span className={classnames({
          'fa': true,
          'fa-chevron-up': !collapse,
          'fa-chevron-down': collapse,
        })}/>
      </div>
      <Container className="">
        <Collapse isOpen={!collapse}>
          <div className={'d-flex flex-column'}>
            <CanvasObjectInfoSidebarDetailOrigin/>
            <CanvasObjectInfoSidebarDetailSize/>
            <CanvasObjectInfoSidebarDetailImageSource/>
            <CanvasObjectInfoSidebarDetailAlign
              title={'Align'}
              property={'align'}
            />
            <CanvasObjectInfoSidebarDetailImageDisplayTransformation/>
            <CanvasObjectInfoSidebarDetailPadding
              title={'Padding'}
              property={'padding'}
            />
            <CanvasObjectInfoSidebarDetailColor
              title={'Background Color'}
              property={'backgroundColor'}
            />
          </div>
        </Collapse>
      </Container>
    </>
  );
};
