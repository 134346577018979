import React, {useEffect, useState} from 'react';
import {Badge, Button, Col, Row} from "reactstrap";
import {Link} from "react-router-dom";

export const ProductIssueItemRow = ({data}) => {
  const [productIssue, setProductIssue] = useState(data);

  useEffect(() => {
    setProductIssue(data)
  }, [data]);

  const determineBadgeColor = () => {
    switch (productIssue.severity) {
      case 'WARNING':
        return 'warning'
      case 'ERROR':
        return 'danger'
      case 'CRITICAL':
        return 'black'
      case 'UNKNOWN':
        return 'gray'
      case 'INFO':
        return 'info'
    }
  }

  return (
    <>
      <Row className={'p-2 border-bottom align-items-center'}>
        <Col md={1}>{productIssue.sku}</Col>
        <Col md={1}>
          <div className={'d-flex justify-content-center align-items-center'}>
            <Badge className="badge-md status ms-1 me-1" color={determineBadgeColor()}>
              {productIssue.severity}
            </Badge>
            <Link to={productIssue.link} target={"_blank"}>
              <Button className={'btn-icon-only'} color={'primary'}>
                <span className={'fa fa-solid fa-arrow-up-right-from-square'}/>
              </Button>
            </Link>
          </div>
        </Col>
        <Col md={5}>{productIssue.title}</Col>
        <Col md={5}>{productIssue.description}</Col>
      </Row>
    </>
  )
}
