import {Button, FormGroup, Input, Modal} from "reactstrap";
import React, {useState} from "react";
import {v4 as uuid4} from "uuid";
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {useProjectState} from "../../context/project/ProjectIndex";
import {Users} from "../../services/api/Users";

const UserInviteModal = ({showModal, setShowModal, setInvitedUser}) => {
  const projectState = useProjectState()
  const dispatch = useApiErrorDispatch();
  const [isLoading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const [invite, setInvite] = useState({
    email: '',
    role: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitted(true);
    if (invite.email && invite.role) {
      let id = uuid4();
      let payload = {
        email: invite.email,
        role: invite.role
      }
      Users.put(projectState.companyId, id, payload).then((r) => {
        setInvitedUser(false)
        setLoading(false);
        if (r?.status < 400) {
          setInvitedUser(true)
          setShowModal(false)
        } else {
          apiErrorRaised(dispatch, r?.response)
        }
      });
    }
  }

  const handleChange = (e) => {
    const {name, value} = e.target;
    setInvite(invite => ({...invite, [name]: value}));
  }


  return (
    <>
      <Modal
        size="md"
        className="modal-dialog-centered"
        isOpen={showModal}
        toggle={() => setShowModal(false)}
      >
        <div className="modal-header">

          <button
            aria-label="Close"
            className="btn-close"
            data-dismiss="modal"
            type="button"
            onClick={() => setShowModal(false)}
          >
          </button>
        </div>
        <div className="modal-body d-flex flex-column pt-0 pb-0">
          <h6 className="modal-title end text-center mb-4">
            Invite User
          </h6>
          <FormGroup
            className="mb-3">
            <label
              className="form-control-label"
            >
              Email
            </label>
            <Input
              placeholder="Email"
              type="email"
              valid={submitted && invite.email.length > 0}
              invalid={submitted && !invite.email}
              name="email"
              value={invite.email}
              onChange={handleChange}
            />
            <div className="invalid-feedback">
              Please provide an email
            </div>
          </FormGroup>
          <FormGroup
            className="mb-3">
            <label
              className="form-control-label"
            >
              Role
            </label>
            <Input
              placeholder="Role"
              type="select"
              className={'form-control'}
              name="role"
              valid={submitted && invite.role.length > 0}
              invalid={submitted && !invite.role}
              value={invite.role}
              onChange={handleChange}
            >
              <option value={''}>Select a role</option>
              <option value={'admin'}>Admin</option>
              <option value={'colaborator'}>Colaborator</option>
              <option value={'viewer'}>Viewer</option>
            </Input>
            <div className="invalid-feedback">
              Please provide an email
            </div>
          </FormGroup>
        </div>
        <div className="modal-footer d-flex justify-content-end">
          <Button color="primary" type="button"
                  onClick={handleSubmit}>
            {isLoading &&
              <span className="spinner-border spinner-border-sm me-1"/>}
            Invite
          </Button>
        </div>
      </Modal>
    </>
  );
};


export default UserInviteModal;
