import React, {useEffect, useState} from "react";
import {apiErrorRaised} from "../../../context/api_error/ApiErrorActions";
import {useApiErrorDispatch} from "../../../context/api_error/ApiErrorContext";
import {RuleGroups} from "../../../services/api/RuleGroups";
import {RuleGroupImportRuleGroupItem} from "./RuleGroupImportRuleGroupItem";
import Paginator from "../../Pagination/Paginator";
import {TableFilters} from "../../TableFilters/TableFilters";
import {useTableFiltersState} from "../../../context/table_filters/TableFiltersContext";
import {loadObjects, useImportDispatch, useImportState} from "../../Import/Context/ImportIndex";

export const RuleGroupImportRuleGroupsList = () => {
  const [isLoading, setLoading] = useState(false)
  const dispatch = useApiErrorDispatch();
  const importState = useImportState()
  const importDispatch = useImportDispatch()
  const [pagination, setPagination] = useState({})
  const [offset, setOffset] = useState(0)
  const tableFilters = useTableFiltersState()
  const [limit, setLimit] = useState(10)

  const handleClick = (e, offset, limit) => {
    e.preventDefault();
    setLimit(limit)
    setOffset(offset)
  }

  useEffect(() => {
    if (importState.selected_company === '' || importState.selected_project === '') {
      return
    }
    setLoading(true)
    RuleGroups.index(importState.selected_company, importState.selected_project,
      {filters: tableFilters, limit: limit, offset: offset}).then((r) => {
      if (r?.status < 400) {
        loadObjects(importDispatch, r.data?.rule_groups)
        setPagination(r.data?.pagination);
      } else {
        apiErrorRaised(dispatch, r?.response)
      }
      setLoading(false);
    })
  }, [importState.selected_company, importState.selected_project, limit, offset, tableFilters])

  return (
    <>
      <h5>Rule Groups</h5>
      <TableFilters entity={'ruleGroup'} isCollapsed={false}/>
      <div className={'d-flex flex-column justify-content-between flex-grow-1'}>
        <div className={'d-flex flex-column'}>
          {
            isLoading ? <span className="spinner-border spinner-border-sm me-1"/> :
              importState.objects && importState.objects.map((ruleGroup, i) => {
                return (
                  <RuleGroupImportRuleGroupItem key={i} ruleGroup={ruleGroup}/>
                )
              })
          }
        </div>
        {
          importState.objects.length > 0 && <Paginator
            pagination={pagination}
            handleClick={handleClick}
            offset={offset}
            limit={limit}
            showRowOptions={false}
          />
        }
      </div>
    </>
  )
}
