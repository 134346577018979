import React, {useState} from "react";
import {Card, Collapse} from "reactstrap";
import {TableFilterEdit} from "./TableFilterEdit";
import {removeFilter, useTableFiltersDispatch} from "../../context/table_filters/TableFiltersIndex";

export const TableFilter = ({filter}) => {
  const dispatch = useTableFiltersDispatch();
  const [collapse, setCollapse] = useState(true);

  const handleCollapse = () => {
    setCollapse(!collapse);
  }

  const filterToText = () => {
    return `${parseLabel()} ${parseOperator()} ${parseValue()}`;
  }

  const parseLabel = () => {
    if (filter.type === 'properties') {
      return filter.property
    }
    return filter.label
  }

  const parseOperator = () => {
    if (filter.type === 'string' && filter.operator === '=') {
      return 'equals'
    }
    if (filter.type === 'string' && filter.operator === '!=') {
      return 'not equals'
    }
    return filter.operator.toLowerCase();
  }

  const parseValue = () => {
    if (filter.operator === 'IS NULL' || filter.operator === 'IS NOT NULL') {
      return '';
    }
    if (filter.type === 'string' || filter.type === 'properties') {
      return `'${filter.value.replaceAll('%', '')}'`;
    }
    if (filter.type === 'money') {
      return `${filter.value / 1000}`;
    }
    return filter.value
  }

  const handleRemoveClick = () => {
    removeFilter(dispatch, filter.id);
  }

  return (
    <>
      <div className="md-chip me-2">
        <div className="md-chip-clickable" onClick={handleCollapse}>
          <span>{filterToText()}</span>
          <button type="button" className="md-chip-remove" onClick={handleRemoveClick}></button>
        </div>
        <Collapse isOpen={!collapse} className="filter_selector edit">
          <Card>
            <TableFilterEdit
              filter={filter}
              closeDialog={() => setCollapse(true)}
            />
          </Card>
        </Collapse>
      </div>
    </>
  )
}
