export function loadCanvasObjects(dispatch, canvasData) {
  dispatch({type: 'CANVAS_LOADED', payload: canvasData});
}

export function loadCanvasTemplate(dispatch, template) {
  dispatch({type: 'CANVAS_TEMPLATE_LOADED', payload: template});
}

export function selectCanvasObject(dispatch, object) {
  dispatch({type: 'OBJECT_SELECTED', payload: object})
}

export function resizeObject(dispatch, id, x, y, width, height) {
  dispatch({
    type: 'OBJECT_RESIZED',
    payload: {
      id: id,
      x: x,
      y: y,
      width: width,
      height: height
    }
  })
}

export function updateImageContent(dispatch, id, imageContent) {
  dispatch({
    type: 'OBJECT_UPDATE_IMAGE_CONTENT',
    payload: {
      id: id,
      imageContent: imageContent,
    }
  })
}

export function updateFontContent(dispatch, id, type, assetId) {
  dispatch({
    type: 'OBJECT_UPDATE_FONT',
    id: id,
    payload: {
      type: type,
      assetId: assetId,
    }
  })
}

export function updateData(dispatch, id, property, data) {
  dispatch(
    {
      type: 'TEMPLATE_OBJECT_UPDATE_DATA',
      id: id,
      property: property,
      payload: data,
    }
  )
}

export function updateConditions(dispatch, id, data) {
  dispatch(
    {
      type: 'OBJECT_UPDATE_CONDITIONS',
      id: id,
      payload: data
    }
  )
}

export function toggleCanvasObjectVisibility(dispatch, id) {
  dispatch({
    type: 'OBJECT_TOGGLE_VISIBILITY',
    payload: id,
  })
}

export function addCanvasObject(dispatch, type) {
  dispatch({
    type: 'OBJECT_ADD',
    payload: type,
  })
}

export function removeCanvasObject(dispatch, id) {
  dispatch({
    type: 'OBJECT_REMOVE',
    payload: id,
  })
}

export function addFont(fontName, fontUrl) {
  let font = new FontFace(fontName, 'url(' + fontUrl + ')');
  font.load().then(function (loadedFont) {
    document.fonts.add(loadedFont);
  }).catch(function (error) {
  });
}

export function canvasObjectsReorder(dispatch, objects) {
  dispatch({
    type: 'CANVAS_OBJECTS_REORDER',
    payload: objects,
  })
}

export function canvasToggleGrid(dispatch) {
  dispatch({
    type: 'CANVAS_TOGGLE_GRID',
  })
}

export function canvasToggleStickToGrid(dispatch) {
  dispatch({
    type: 'CANVAS_TOGGLE_STICK_TO_GRID',
  })
}

export function canvasSelectProduct(dispatch, product) {
  dispatch({
    type: 'CANVAS_SELECT_PRODUCT',
    payload: product
  })
}

export function setValidationError(dispatch, id, error) {
  dispatch({
    type: 'CANVAS_SET_VALIDATION_ERROR',
    id: id,
    payload: error,
  })
}

export function clearValidationError(dispatch, id, error) {
  dispatch({
    type: 'CANVAS_CLEAR_VALIDATION_ERROR',
    id: id,
    payload: error,
  })
}

export function canvasSave(dispatch) {
  dispatch({
    type: 'CANVAS_SAVE'
  })
}
