const colorToHex = (color) => {
  let hexadecimal = color.toString(16);
  return hexadecimal.length === 1 ? "0" + hexadecimal : hexadecimal;
}

export const ColorToHex = (red, green, blue) => {
  return "#" + colorToHex(red) + colorToHex(green) + colorToHex(blue);
}

export const HexToColor = (hex) => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    red: parseInt(result[1], 16),
    green: parseInt(result[2], 16),
    blue: parseInt(result[3], 16)
  } : null;
}
