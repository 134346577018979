import React from "react";
import ReactFlagsSelect from "react-flags-select";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export const LanguageSwitcher = () => {
  const {i18n} = useTranslation();
  const navigate = useNavigate()
  const customLocaleLabels = {
    "GB": {
      "primary": "English",
      "language": "en",
    },
    "ES": {
      "primary": "Español",
      "language": "es",
    },
    "PT": {
      "primary": "Português",
      "language": "pt",
    }
  }

  const languageMapping = {
    'es': 'ES',
    'en': 'GB',
    'pt': 'PT'
  }

  const handleSelectLocale = (code) => {
    i18n.changeLanguage(customLocaleLabels[code].language)
    const pathname = window.location.pathname;
    if (pathname.includes('auth')) {
      return
    }
    const currentLanguage = pathname.split('/')[1];
    const newUrl = currentLanguage ? pathname.replace(`/${currentLanguage}`, `/${i18n.language}`) : `/${i18n.language}`;
    navigate(newUrl)
  };

  return (
    <>
      <ReactFlagsSelect
        selected={languageMapping[i18n.language]}
        countries={['ES', 'GB', 'PT']}
        customLabels={customLocaleLabels}
        className={'pb-0'}
        onSelect={(code) => handleSelectLocale(code)}
      />
    </>
  );
};
