import React from 'react'
import {useModalDispatch} from "../../../context/modal/ModalContext";
import {closeModal, openModal} from "../../../context/modal/ModalActions";
import {Button} from "reactstrap";
import {deleteRule, useRulesDispatch, useRulesState} from "../../../context/rules/RulesIndex";

export const FeedExportRuleDeleteButton = () => {
  const rulesState = useRulesState()
  const rulesDispatch = useRulesDispatch();
  const modalDispatcher = useModalDispatch();

  const handleDeleteClick = () => {
    openModal(modalDispatcher, {
      title: 'Delete rule',
      message: `Do you want to delete ${rulesState.selected.name} rule?`,
      onConfirm: () => {
        deleteRule(rulesDispatch, {...rulesState.selected, status: 'DELETED'})
        closeModal(modalDispatcher)
      },
      onCancel: () => closeModal(modalDispatcher),
      onBackdropClick: () => closeModal(modalDispatcher),
      buttonColor: 'danger',
      buttonText: 'Yes'
    })
  }

  return (
    <>
      {
        <Button onClick={handleDeleteClick} disabled={rulesState.rules.length === 1} className={'btn-icon-only'}
                color={'danger'}>
          <span className={'fa-solid fa-trash-alt'}/>
        </Button>
      }
    </>
  );
}
