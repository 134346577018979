import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {useProjectState} from "../../context/project/ProjectContext";
import {Templates} from "../../services/api/Templates";
import {Badge} from "reactstrap";
import {formatUtcDateIntoTimezone} from "../../utils/DateTime";
import classNames from "classnames";

const TemplateItem = ({data}) => {
  const projectState = useProjectState()
  const dispatchApiError = useApiErrorDispatch()
  const [template, setTemplate] = useState(data)

  useEffect(() => {
    setTemplate(data)
  }, [data])

  const [badgeInformation, setBadgeInformation] = useState({
    'name': '',
    'color': '',
  })

  useEffect(() => {

    switch (template.render_status) {
      case 'PUBLISHED':
        setBadgeInformation({
          'name': 'Published',
          'color': 'success'
        })
        break;
      case 'DRAFT':
        setBadgeInformation({
          'name': 'Draft',
          'color': 'warning'
        })
        break;
      case 'EXPIRED':
        setBadgeInformation({
          'name': 'Expired',
          'color': 'danger'
        })
        break;
      case 'SCHEDULED':
        setBadgeInformation({
          'name': 'Scheduled',
          'color': 'info'
        })
        break;
      default:
        setBadgeInformation({
          'name': '',
          'color': '',
        })
    }
  }, [template.render_status])

  const handleToggle = (e) => {
    const {name, checked} = e.target;
    if (checked) {
      Templates.activate(projectState.companyId, projectState.projectId, template.id).then((r) => {
        if (r?.status < 400) {
          setTemplate(template => ({...template, [name]: checked}))
        } else {
          apiErrorRaised(dispatchApiError, r?.response)
        }
      });
    } else {
      Templates.disable(projectState.companyId, projectState.projectId, template.id).then((r) => {
        if (r?.status < 400) {
          setTemplate(template => ({...template, [name]: checked}))
        } else {
          apiErrorRaised(dispatchApiError, r?.response)
        }
      });
    }
  }

  return (
    <>
      <tr>
        <td className={'d-flex flex-row justify-content-center'}>
          <img width="100px" className={classNames({
            'product-image-detail': true,
            'img-blackwhite': !template.status,
          })} alt="" src={template.previews[0]?.url}/>
        </td>
        <td>
          <Link
            to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/templates/${template.id}`}>
            <p className={classNames({
              'text-gray': !template.status,
            })}
            ><Badge className="badge-lg me-2" color={badgeInformation.color}>
              {badgeInformation.name}
            </Badge>{template.name}
            </p>
          </Link>
        </td>
        <td>
          <p className={classNames({
            'text-center': true,
            'text-gray': !template.status,
          })}>{template.priority}</p>
        </td>
        <td>
          <p className={classNames({
            'text-center': true,
            'text-gray': !template.status,
          })}>{template.active_products}</p>
        </td>
        <td>
          <p className={classNames({
            'text-center': true,
            'text-gray': !template.status,
          })}>{formatUtcDateIntoTimezone(template.start_date, "yyyy-MM-dd HH:mm", projectState.timezone)}</p>
        </td>
        <td>
          <p className={classNames({
            'text-center': true,
            'text-gray': !template.status,
          })}>{formatUtcDateIntoTimezone(template.end_date, "yyyy-MM-dd HH:mm", projectState.timezone)}</p>
        </td>

        <td><label className="custom-toggle me-1">
          <input type="checkbox" name="status" checked={template.status} onChange={handleToggle}/>
          <span className="custom-toggle-slider rounded-circle"/>
        </label></td>
      </tr>
    </>
  )
}

export default TemplateItem;
