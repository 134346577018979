import React, {useEffect, useState} from 'react';
import FeedExportMappingItem from "./FeedExportMappingItem";
import {Button, Card, CardBody, CardHeader, Table} from "reactstrap";
import handlePlatformMapping from "../FeedExportPlatform/FeedExportPlatformProperties/FeedExportPropertiesMapping";
import {FeedExportStepButtonGroup} from "../FeedExportStepButtonGroup/FeedExportStepButtonGroup";
import {FeedExports} from "../../../services/api/FeedExports";
import {apiErrorRaised} from "../../../context/api_error/ApiErrorActions";
import {useProjectState} from "../../../context/project/ProjectIndex";
import {useApiErrorDispatch} from "../../../context/api_error/ApiErrorIndex";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router";

const FeedExportMappingList = ({feedExportId}) => {
  const projectState = useProjectState()
  const navigate = useNavigate()
  const [feedExport, setFeedExport] = useState({})
  const [isLoading, setLoading] = useState(false)
  const apiErrorDispatch = useApiErrorDispatch()
  const [existingPropertyList, setExistingPropertyList] = useState([])
  const [defaultPropertyList, setDefaultPropertyList] = useState([])
  const [mappedProperties, setMappedProperties] = useState([])

  useEffect(() => {
    setLoading(true)
    FeedExports.get(projectState.companyId, projectState.projectId, feedExportId).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        setFeedExport(r.data)
        setExistingPropertyList(r.data.mapping.mapping)
        setMappedProperties(r.data.mapping.mapping)
        if (r.data.mapping.platform === 'custom') {
          setDefaultPropertyList(r.data.mapping.mapping);
        } else {
          setDefaultPropertyList(handlePlatformMapping(r.data.mapping.platform))
        }

      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    });
  }, [feedExportId])

  const handleChangeMappingItem = (index, mappingItem) => {
      mappedProperties[index] = mappingItem
      const nextProperties = [
        ...mappedProperties.slice(0, index),
        mappingItem,
        ...mappedProperties.slice(index + 1)
      ];
      setMappedProperties(nextProperties);
  }

  const handleClick = () => {
    let finalMappedProperties = mappedProperties.filter(
      mappingItem => mappingItem.origin !== '' && mappingItem.origin !== null && mappingItem.origin !== undefined
    )

    let data = {
      filename: feedExport.filename,
      type: feedExport.type,
      format: feedExport.format,
      mapping: {
        platform: feedExport.mapping.platform,
        mapping: finalMappedProperties
      },
      schedule: feedExport.schedule,
      url_parameters: feedExport.url_parameters,
      rule_group_id: feedExport.rule_group_id,
      shared_rule_groups: feedExport.shared_rule_groups,
    }
    FeedExports.update(projectState.companyId, projectState.projectId, feedExportId, data).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        navigate(`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/${feedExportId}/rules`)
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    });
  }

  const handleNewField = () => {
    setDefaultPropertyList([...defaultPropertyList, {
      origin: '',
      destination: '',
      format: '',
      isRequired: false
    }])
  }

  return (
    <>
      <div className={'d-flex flex-column'}>
        <div className={'d-flex flex-row justify-content-between'}>
          <div className={'d-flex flex-row'}>
            <Link
              to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/`}>
              <Button className={'btn-icon-only text-dark'}>
                <span className={'fa fa-arrow-left'}/>
              </Button>
            </Link>
            <h1 className={'ms-4'}>{feedExport.name}: Mapping</h1>
          </div>
          <FeedExportStepButtonGroup id={feedExportId} step={'mapping'}/>
        </div>
        <div className={'d-flex flex-row mt-4'}>
          <Card className={'flex-grow-1'}>
            <CardHeader>
              <h3>Mapping</h3>
            </CardHeader>
            <CardBody className={'d-flex flex-column'}>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                <tr>
                  <th scope="col">Internal Field Name</th>
                  <th scope="col">Platform Field Name</th>
                  <th scope="col">Format</th>
                  <th scope="col">Status ¿?</th>
                </tr>
                </thead>
                <tbody className={'list'}>
                  {
                    defaultPropertyList.map((property, i) => {
                      if (existingPropertyList !== undefined && existingPropertyList !== null) {
                        let found = existingPropertyList.find(existing => existing.destination === property.destination)

                        return (
                          <FeedExportMappingItem
                            key={i}
                            platformPropertyDetail={property}
                            property={found}
                            handleChangeMappingItem={handleChangeMappingItem.bind(this, i)}
                            custom={feedExport.mapping.platform === 'custom'}
                          />
                        )
                      }
                      return (
                        <FeedExportMappingItem
                          key={i}
                          platformPropertyDetail={property}
                          property={property}
                          handleChangeMappingItem={handleChangeMappingItem.bind(this, i)}
                        />
                      )
                    })
                  }
                  {
                    feedExport?.mapping?.platform === 'custom' ?
                      <tr>
                        <td>
                          <Button color={'primary'} className={'btn-icon-only'} onClick={handleNewField}>
                            <span className={'fa fa-solid fa-plus'}/>
                          </Button>
                        </td>
                      </tr>
                    : <></>
                  }
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </div>
        <div className={'d-flex flew-row justify-content-between'}>
          <Link
            to={`/admin/companies/${projectState.companyId}/projects/${projectState.projectId}/feed_exports/${feedExportId}/edit`}>
            <Button color={'secondary'}>Back</Button>
          </Link>
          <Button color={'primary'} onClick={handleClick}>
            Next
          </Button>
        </div>
      </div>
    </>
  )
}

export default FeedExportMappingList;


