import {get, post, put} from './config/base';
import {handleError, handleResponse} from "./config/response";
import {urlParser} from "./config/utils";

const url = 'companies/{companyId}/projects/{projectId}/rules/{id}';

export const Rules = {
  index: (companyId, projectId, ruleGroupId, params = null) =>
    get(urlParser(url, companyId, projectId, ruleGroupId), {params: params})
      .then(handleResponse)
      .catch(handleError),
  put_batch: (companyId, projectId, ruleGroupId, payload) => {
    return put(`${urlParser(url, companyId, projectId, ruleGroupId)}`, payload)
      .then(handleResponse)
      .catch(handleError)
  },
  import: (companyId, projectId, ruleGroup, rules) =>
    post(`${urlParser(url, companyId, projectId, '')}import`, {
      rule_group: ruleGroup,
      rules: rules,
    })
      .then(handleResponse)
      .catch(handleError),
}
