import React from "react";
import {Container,} from "reactstrap";
import {FeedExportNew} from "../../../components/FeedExports/FeedExportNew/FeedExportNew";

export default function FeedExportNewView() {
  return (
    <>
      <Container className="mt-4" fluid>
        <FeedExportNew/>
      </Container>
    </>
  );
};
