import React, {useState} from "react";
import ReactPlayer from 'react-player/lazy'
import {Button, Col, Container, Row} from "reactstrap";
import {scrollToTargetDiv} from "../Landing/scrollUtils";
import {useTranslation} from "react-i18next";

function IndexHeader() {
  const [isPlaying, setIsPlaying] = useState(false);
  const {t} = useTranslation('landing')
  const handlePlay = () => {
    setIsPlaying(true);
  }
  return (
    <>
      <section className={'bg-home bg-white padding-header'}>
        <Container>
          <Row className="justify-content-center animate__animated animate__fadeInUp">
            <Col md={8} className={'ps-5 pe-5 mt-auto mb-auto custom-p3 text-center'}>
              <Row>
                <Col>
                  <h1 className={'landing_header'}>
                    {t('HEADER')} <span
                    className={'text-primary'}> Feedest</span>!
                  </h1>
                  <p className="mt-4">
                    <span className={'text-primary'}>Feedest</span> {t('SUB_HEADER')}
                  </p>
                </Col>
              </Row>
              <Row className={'mt-4'}>
                <Col>
                  <Button color={'primary'} onClick={() => scrollToTargetDiv('submitForm')}>
                    {t('MORE_INFO')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Col className={'pt-6 animate__animated animate__fadeInUp'}>
            <div className="d-flex justify-content-center video-container">
              <ReactPlayer
                controls={true}
                playing={isPlaying}
                playIcon={(
                  <div className={`bg-button ${isPlaying ? 'hidden' : ''}`} onClick={handlePlay}>
                    <i className="fa-solid fa-play play-button"></i>
                  </div>
                )}
                width={'100%'}
                height={'100%'}
                light="https://cyberclick-feed-optimizer.s3.eu-west-1.amazonaws.com/assets/image/preview.png"
                url={t('VIDEO')}
                fallback={
                  <video playsInline muted controls>
                    <source src={t('VIDEO')}
                            type="video/webm"/>
                    <p>Tu navegador no reproduce video</p>
                  </video>
                }
              />
            </div>
          </Col>
        </Container>
      </section>
    </>
  );
}

export default IndexHeader;
