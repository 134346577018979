import {Badge, Card, CardBody} from "reactstrap";
import DataTable from "react-data-table-component";
import React, {useEffect, useState} from "react";
import {EventsManagerTableHeaders} from "./EventsManagerTableHeaders";
import {apiErrorRaised} from "../../../context/api_error/ApiErrorActions";
import {EventsManager} from "../../../services/api/EventsManager";
import {useProjectState} from "../../../context/project/ProjectContext";
import {useApiErrorDispatch} from "../../../context/api_error/ApiErrorContext";
import {
  ArcElement, BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  DoughnutController, Legend,
  LinearScale,
  LineController, LineElement, PointElement, TimeScale, Title, Tooltip
} from "chart.js";
import {EventsManagerSummary} from "../EventsManagerSummary";


const customStyles = {
  rows: {
    style: {
      fontSize: '14px', // Cambia el tamaño de la fuente de las filas
      color: '#42325D',
      fontWeight: 'bold'
    },
  },
  headRow: {
    style: {
      backgroundColor: '#F6F9FC',
      color: '#8F88AA',
      fontSize: '10px',
      fontWeight: 'bold'
    },
  },
  cells: {
    style: {
      maxWidth: '50px',
    },
  },
};

export const EventsManagerTable = () => {
  const projectState = useProjectState();
  const apiErrorDispatch = useApiErrorDispatch();
  const [isLoading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    setColumns(EventsManagerTableHeaders);
    EventsManager.recentEvents(projectState.companyId, projectState.projectId, {}).then((r) => {
      setLoading(false)
      if (r?.status < 400) {
        parseData(r.data)
      } else {
        if (r?.response !== undefined) {
          apiErrorRaised(apiErrorDispatch, r?.response)
        } else {
          apiErrorRaised(apiErrorDispatch, {
            status: r.status,
            statusText: r.name,
            data: {
              messge: r.message,
            }
          })
        }
      }
    })
  }

  ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    BarElement,
    LineController,
    DoughnutController,
    BarController,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    TimeScale,
  );

  const ExpandedComponent = ({ data }) =>
    <>
      <Card>
        <CardBody className={'d-flex flex-column'}>
          {data.error ?? 'No errors detected'}
        </CardBody>
      </Card>
      <Card>
        <pre className={'m-3'}>{JSON.stringify(data.params_info, null, 2)}</pre>
      </Card>
    </>

  const ErrorSuccessComponent = (data) =>
    <Badge className="badge-lg status" color={data.error == null ? 'success' : 'danger'}>
          {data.error == null ? 'Success' : 'Error'}
    </Badge>

  const parseData = (eventsData) => {
    let parsedData = [];

    eventsData.forEach(event => {
      parsedData.push({
        'id': event.id,
        'event_name': event.action,
        'status': ErrorSuccessComponent(event),
        'date': event.createdOn,
        'params': JSON.stringify(Object.keys(event.params)),
        'params_info': event.params,
        'error': event.error
      })
    })

    ExpandedComponent({ data: parsedData })

    setTableData(parsedData)
  }

  return (
    <>
      <EventsManagerSummary eventsData={tableData} isLoading={isLoading}/>
      <Card>
        <CardBody>
          <div className={'mt-2 analytics_table_body'}>
            <DataTable
              pagination
              paginationPerPage={20}
              paginationComponentOptions={{selectAllRowsItem: true}}
              progressPending={isLoading}
              selectableRowsNoSelectAll={true}
              selectableRowsHighlight={true}
              customStyles={customStyles}
              data={tableData}
              columns={columns}
              expandableRows
              expandableRowsComponent={ExpandedComponent}
            />
          </div>
        </CardBody>
      </Card>
    </>
  )
}
