import React from 'react'
import {Button, Col, Input, Row} from "reactstrap";
import {FeedExportRuleActionPropertiesSelect} from "../FeedExportRuleFields/FeedExportRuleActionPropertiesSelect";
import {updateAction, useRuleDispatch, useRuleState} from "../../../../context/rule/RuleIndex";

export const FeedExportRuleActionCombineValue = ({value, type, position}) => {
  const ruleState = useRuleState()
  const ruleDispatch = useRuleDispatch()

  const handleChange = (e, key) => {
    let {value} = e.target
    updateAction(ruleDispatch,
      {
        action: {
          ...ruleState[type][position],
          value: [
            ...ruleState[type][position]?.value.slice(0, key),
            {'value': value, type: 'STATIC'},
            ...ruleState[type][position]?.value.slice(key + 1)
          ]
        },
        position: position,
        type: type,
      })
  }

  const handleAddField = () => {
    let elementsToAdd
    if (value?.length === 0 || value?.length === null || value?.length === undefined) {
      elementsToAdd = [
        {value: '', type: 'STATIC'},
        {value: 'title', type: 'FIELD'},
        {value: '', type: 'STATIC'},
      ]
    } else {
      elementsToAdd = [
        {value: 'title', type: 'FIELD'},
        {value: '', type: 'STATIC'},
      ]
    }
    updateAction(ruleDispatch,
      {
        action: {
          ...ruleState[type][position],
          value: [
            ...(ruleState[type][position]?.value === null ? [] : ruleState[type][position].value),
            ...elementsToAdd
          ]
        },
        position: position,
        type: type,
      })
  }

  const handleUpdateField = (e, key) => {
    let {value} = e
    updateAction(ruleDispatch,
      {
        action: {
          ...ruleState[type][position],
          value: [
            ...ruleState[type][position]?.value.slice(0, key),
            {'value': value, type: 'FIELD'},
            ...ruleState[type][position]?.value.slice(key + 1)
          ]
        },
        position: position,
        type: type,
      })
  }

  const handleDeleteField = (key) => {
    updateAction(ruleDispatch,
      {
        action: {
          ...ruleState[type][position],
          value: [
            ...ruleState[type][position]?.value.slice(0, key),
            ...ruleState[type][position]?.value.slice(key + 1)
          ]
        },
        position: position,
        type: type,
      })
  }

  const renderItem = (item, i) => {
    if (item.type === 'STATIC') {
      return (
        <div key={i}>
          <Input
            className={'feed_export_rule_action_combine_input'}
            defaultValue={item.value}
            value={item.value}
            style={{width: (item.value.length) + 'ch'}}
            key={i}
            onChange={(e) => handleChange(e, i)}
          />
          <Button className={' btn-icon-only feed_export_rule_action_combine_select_remove'}
                  onClick={() => handleDeleteField(i)}>
            <span className={'fa fa-remove'}/>
          </Button>
        </div>
      )
    }

    return (
      <>
        <div className={'feed_export_rule_action_combine_select_box'} key={i}>
          <div className={'feed_export_rule_action_combine_select'} key={i}>
            <FeedExportRuleActionPropertiesSelect
              value={item.value}
              key={i}
              onChange={(value) => handleUpdateField(value, i)}/>
          </div>
          <Button className={' btn-icon-only feed_export_rule_action_combine_select_remove'}
                  onClick={() => handleDeleteField(i)}>
            <span className={'fa fa-remove'}/>
          </Button>
        </div>
      </>
    )
  }

  return (
    <>
      <Row>
        <Col md={8}>
          <div className={'feed_export_rule_action_combine_box'}>
            {value?.map((item, i) => renderItem(item, i))}
          </div>
        </Col>
        <Col md={4}>
          <Button onClick={handleAddField}>
            <span className={'fa fa-plus-circle'}/>
          </Button>
        </Col>
      </Row>
    </>
  )
}
