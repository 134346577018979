import React, {useEffect} from "react";
import {Container,} from "reactstrap";
import {useParams, useSearchParams} from "react-router-dom";
import {useProjectDispatch} from "../../../context/project/ProjectIndex";
import {updateCompanyAndProjectBasedOnUrl} from "../../../context/project/ProjectActions";
import {ProjectSettings} from "../../../components/Project/Settings/ProjectSettings";

export const ProjectSettingsView = () => {
    const projectDispatch = useProjectDispatch()
    const routerParams = useParams();
    const [searchParams] = useSearchParams()
    useEffect(() => {
        updateCompanyAndProjectBasedOnUrl(projectDispatch, routerParams.companyId, routerParams.projectId)
    }, [])

    return (
        <>
            <Container className="mt-7">
                <ProjectSettings
                    companyId={routerParams.companyId}
                    projectId={routerParams.projectId}
                    from={searchParams.get('from')}
                />
            </Container>
        </>
    );
};
