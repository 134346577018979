import {get} from './config/base';
import {handleError, handleResponse} from "./config/response";
import {urlParser} from "./config/utils";

const url = 'companies/{companyId}/projects/{projectId}/product_data/';

export const ProductData = {
  get: (companyId, projectId, productSku, params = null) =>
    get(`${urlParser(url, companyId, projectId)}${productSku}`, {params: params})
      .then(handleResponse)
      .catch(handleError),
}
