import {Col, Collapse, Container, Row} from "reactstrap";
import React from "react";
import {TableFilterInput} from "./TableFilterInput";
import {useTableFiltersState} from "../../context/table_filters/TableFiltersIndex";
import {TableFilter} from "./TableFilter";

export const TableFilters = ({entity, isCollapsed}) => {
  const tableFilters = useTableFiltersState()
  return (
    <>
      <Collapse isOpen={!isCollapsed}>
        <Container fluid>
          <Row className={'mb-3 ms-1'}>
            <Col md={"auto"}>
              {tableFilters.map((filter, key) => (
                <TableFilter filter={filter} key={key}/>
              ))}
            </Col>
            <TableFilterInput entity={entity}/>
          </Row>
        </Container>
      </Collapse>
    </>
  )
}
