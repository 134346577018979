export const currencies = [
  {
    "value": "AED",
    "label": "United Arab Emirates dirham",
    "numeric": "784",
    "exponent": 2
  },
  {
    "value": "AFN",
    "label": "Afghan afghani",
    "numeric": "971",
    "exponent": 2
  },
  {
    "value": "ALL",
    "label": "Albanian lek",
    "numeric": "008",
    "exponent": 2
  },
  {
    "value": "AMD",
    "label": "Armenian dram",
    "numeric": "051",
    "exponent": 2
  },
  {
    "value": "ANG",
    "label": "Netherlands Antillean guilder",
    "numeric": "532",
    "exponent": 2
  },
  {
    "value": "AOA",
    "label": "Angolan kwanza",
    "numeric": "973",
    "exponent": 2
  },
  {
    "value": "ARS",
    "label": "Argentine peso",
    "numeric": "032",
    "exponent": 2
  },
  {
    "value": "AUD",
    "label": "Australian dollar",
    "numeric": "036",
    "exponent": 2
  },
  {
    "value": "AWG",
    "label": "Aruban florin",
    "numeric": "533",
    "exponent": 2
  },
  {
    "value": "AZN",
    "label": "Azerbaijani manat",
    "numeric": "944",
    "exponent": 2
  },
  {
    "value": "BAM",
    "label": "Bosnia and Herzegovina convertible mark",
    "numeric": "977",
    "exponent": 2
  },
  {
    "value": "BBD",
    "label": "Barbados dollar",
    "numeric": "052",
    "exponent": 2
  },
  {
    "value": "BDT",
    "label": "Bangladeshi taka",
    "numeric": "050",
    "exponent": 2
  },
  {
    "value": "BGN",
    "label": "Bulgarian lev",
    "numeric": "975",
    "funding": true,
    "exponent": 2
  },
  {
    "value": "BHD",
    "label": "Bahraini dinar",
    "numeric": "048",
    "exponent": 3
  },
  {
    "value": "BIF",
    "label": "Burundian franc",
    "numeric": "108",
    "exponent": 0
  },
  {
    "value": "BMD",
    "label": "Bermudian dollar",
    "numeric": "060",
    "exponent": 2
  },
  {
    "value": "BND",
    "label": "Brunei dollar",
    "numeric": "096",
    "exponent": 2
  },
  {
    "value": "BOB",
    "label": "Boliviano",
    "numeric": "068",
    "exponent": 2
  },
  {
    "value": "BRL",
    "label": "Brazilian real",
    "numeric": "986",
    "exponent": 2
  },
  {
    "value": "BSD",
    "label": "Bahamian dollar",
    "numeric": "044",
    "exponent": 2
  },
  {
    "value": "BTN",
    "label": "Bhutanese ngultrum",
    "numeric": "064",
    "exponent": 2
  },
  {
    "value": "BWP",
    "label": "Botswana pula",
    "numeric": "072",
    "exponent": 2
  },
  {
    "value": "BYR",
    "label": "Belarusian ruble",
    "numeric": "974",
    "exponent": 0
  },
  {
    "value": "BZD",
    "label": "Belize dollar",
    "numeric": "084",
    "exponent": 2
  },
  {
    "value": "CAD",
    "label": "Canadian dollar",
    "numeric": "124",
    "exponent": 2
  },
  {
    "value": "CDF",
    "label": "Congolese franc",
    "numeric": "976",
    "exponent": 2
  },
  {
    "value": "CHF",
    "label": "Swiss franc",
    "numeric": "756",
    "funding": true,
    "exponent": 2
  },
  {
    "value": "CLP",
    "label": "Chilean peso",
    "numeric": "152",
    "exponent": 0
  },
  {
    "value": "CNY",
    "label": "Chinese yuan",
    "numeric": "156",
    "exponent": 2
  },
  {
    "value": "COP",
    "label": "Colombian peso",
    "numeric": "170",
    "exponent": 2
  },
  {
    "value": "CRC",
    "label": "Costa Rican colon",
    "numeric": "188",
    "exponent": 2
  },
  {
    "value": "CUP",
    "label": "Cuban peso",
    "numeric": "192",
    "exponent": 2
  },
  {
    "value": "CVE",
    "label": "Cape Verde escudo",
    "numeric": "132",
    "exponent": 2
  },
  {
    "value": "CZK",
    "label": "Czech koruna",
    "numeric": "203",
    "funding": true,
    "exponent": 2
  },
  {
    "value": "DJF",
    "label": "Djiboutian franc",
    "numeric": "262",
    "exponent": 0
  },
  {
    "value": "DKK",
    "label": "Danish krone",
    "numeric": "208",
    "funding": true,
    "exponent": 2
  },
  {
    "value": "DOP",
    "label": "Dominican peso",
    "numeric": "214",
    "exponent": 2
  },
  {
    "value": "DZD",
    "label": "Algerian dinar",
    "numeric": "012",
    "exponent": 2
  },
  {
    "value": "EGP",
    "label": "Egyptian pound",
    "numeric": "818",
    "exponent": 2
  },
  {
    "value": "ERN",
    "label": "Eritrean nakfa",
    "numeric": "232",
    "exponent": 2
  },
  {
    "value": "ETB",
    "label": "Ethiopian birr",
    "numeric": "230",
    "exponent": 2
  },
  {
    "value": "EUR",
    "label": "Euro - €",
    "numeric": "978",
    "funding": true,
    "exponent": 2
  },
  {
    "value": "FJD",
    "label": "Fiji dollar",
    "numeric": "242",
    "exponent": 2
  },
  {
    "value": "FKP",
    "label": "Falkland Islands pound",
    "numeric": "238",
    "exponent": 2
  },
  {
    "value": "GBP",
    "label": "Pound sterling - £",
    "numeric": "826",
    "funding": true,
    "exponent": 2
  },
  {
    "value": "GEL",
    "label": "Georgian lari",
    "numeric": "981",
    "exponent": 2
  },
  {
    "value": "GHS",
    "label": "Ghanaian cedi",
    "numeric": "936",
    "exponent": 2
  },
  {
    "value": "GIP",
    "label": "Gibraltar pound",
    "numeric": "292",
    "exponent": 2
  },
  {
    "value": "GMD",
    "label": "Gambian dalasi",
    "numeric": "270",
    "exponent": 2
  },
  {
    "value": "GNF",
    "label": "Guinean franc",
    "numeric": "324",
    "exponent": 0
  },
  {
    "value": "GTQ",
    "label": "Guatemalan quetzal",
    "numeric": "320",
    "exponent": 2
  },
  {
    "value": "GYD",
    "label": "Guyanese dollar",
    "numeric": "328",
    "exponent": 2
  },
  {
    "value": "HKD",
    "label": "Hong Kong dollar",
    "numeric": "344",
    "exponent": 2
  },
  {
    "value": "HNL",
    "label": "Honduran lempira",
    "numeric": "340",
    "exponent": 2
  },
  {
    "value": "HRK",
    "label": "Croatian kuna",
    "numeric": "191",
    "exponent": 2,
    "deprecated": true
  },
  {
    "value": "HTG",
    "label": "Haitian gourde",
    "numeric": "332",
    "exponent": 2
  },
  {
    "value": "HUF",
    "label": "Hungarian forint",
    "numeric": "348",
    "funding": true,
    "exponent": 2
  },
  {
    "value": "IDR",
    "label": "Indonesian rupiah",
    "numeric": "360",
    "exponent": 2
  },
  {
    "value": "ILS",
    "label": "Israeli new shekel",
    "numeric": "376",
    "exponent": 2
  },
  {
    "value": "INR",
    "label": "Indian rupee",
    "numeric": "356",
    "exponent": 2
  },
  {
    "value": "IQD",
    "label": "Iraqi dinar",
    "numeric": "368",
    "exponent": 3
  },
  {
    "value": "IRR",
    "label": "Iranian rial",
    "numeric": "364",
    "exponent": 2
  },
  {
    "value": "ISK",
    "label": "Icelandic króna",
    "numeric": "352",
    "exponent": 2
  },
  {
    "value": "JMD",
    "label": "Jamaican dollar",
    "numeric": "388",
    "exponent": 2
  },
  {
    "value": "JOD",
    "label": "Jordanian dinar",
    "numeric": "400",
    "exponent": 3
  },
  {
    "value": "JPY",
    "label": "Japanese yen",
    "numeric": "392",
    "exponent": 0
  },
  {
    "value": "KES",
    "label": "Kenyan shilling",
    "numeric": "404",
    "exponent": 2
  },
  {
    "value": "KGS",
    "label": "Kyrgyzstani som",
    "numeric": "417",
    "exponent": 2
  },
  {
    "value": "KHR",
    "label": "Cambodian riel",
    "numeric": "116",
    "exponent": 2
  },
  {
    "value": "KMF",
    "label": "Comoro franc",
    "numeric": "174",
    "exponent": 0
  },
  {
    "value": "KPW",
    "label": "North Korean won",
    "numeric": "408",
    "exponent": 2
  },
  {
    "value": "KRW",
    "label": "South Korean won",
    "numeric": "410",
    "exponent": 0
  },
  {
    "value": "KWD",
    "label": "Kuwaiti dinar",
    "numeric": "414",
    "exponent": 3
  },
  {
    "value": "KYD",
    "label": "Cayman Islands dollar",
    "numeric": "136",
    "exponent": 2
  },
  {
    "value": "KZT",
    "label": "Kazakhstani tenge",
    "numeric": "398",
    "exponent": 2
  },
  {
    "value": "LAK",
    "label": "Lao kip",
    "numeric": "418",
    "exponent": 2
  },
  {
    "value": "LBP",
    "label": "Lebanese pound",
    "numeric": "422",
    "exponent": 2
  },
  {
    "value": "LKR",
    "label": "Sri Lankan rupee",
    "numeric": "144",
    "exponent": 2
  },
  {
    "value": "LRD",
    "label": "Liberian dollar",
    "numeric": "430",
    "exponent": 2
  },
  {
    "value": "LSL",
    "label": "Lesotho loti",
    "numeric": "426",
    "exponent": 2
  },
  {
    "value": "MAD",
    "label": "Moroccan dirham",
    "numeric": "504",
    "exponent": 2
  },
  {
    "value": "MDL",
    "label": "Moldovan leu",
    "numeric": "498",
    "exponent": 2
  },
  {
    "value": "MGA",
    "label": "Malagasy ariary",
    "numeric": "969",
    "exponent": 2
  },
  {
    "value": "MKD",
    "label": "Macedonian denar",
    "numeric": "807",
    "exponent": 2
  },
  {
    "value": "MMK",
    "label": "Myanmar kyat",
    "numeric": "104",
    "exponent": 2
  },
  {
    "value": "MNT",
    "label": "Mongolian tögrög",
    "numeric": "496",
    "exponent": 2
  },
  {
    "value": "MOP",
    "label": "Macanese pataca",
    "numeric": "446",
    "exponent": 2
  },
  {
    "value": "MRU",
    "label": "Mauritanian ouguiya",
    "numeric": "929",
    "exponent": 2
  },
  {
    "value": "MRO",
    "label": "Mauritanian ouguiya",
    "numeric": "478",
    "exponent": 2,
    "deprecated": true
  },
  {
    "value": "MUR",
    "label": "Mauritian rupee",
    "numeric": "480",
    "exponent": 2
  },
  {
    "value": "MVR",
    "label": "Maldivian rufiyaa",
    "numeric": "462",
    "exponent": 2
  },
  {
    "value": "MWK",
    "label": "Malawian kwacha",
    "numeric": "454",
    "exponent": 2
  },
  {
    "value": "MXN",
    "label": "Mexican peso",
    "numeric": "484",
    "exponent": 2
  },
  {
    "value": "MYR",
    "label": "Malaysian ringgit",
    "numeric": "458",
    "exponent": 2
  },
  {
    "value": "MZN",
    "label": "Mozambican metical",
    "numeric": "943",
    "exponent": 2
  },
  {
    "value": "NAD",
    "label": "Namibian dollar",
    "numeric": "516",
    "exponent": 2
  },
  {
    "value": "NGN",
    "label": "Nigerian naira",
    "numeric": "566",
    "exponent": 2
  },
  {
    "value": "NIO",
    "label": "Nicaraguan córdoba",
    "numeric": "558",
    "exponent": 2
  },
  {
    "value": "NOK",
    "label": "Norwegian krone",
    "numeric": "578",
    "funding": true,
    "exponent": 2
  },
  {
    "value": "NPR",
    "label": "Nepalese rupee",
    "numeric": "524",
    "exponent": 2
  },
  {
    "value": "NZD",
    "label": "New Zealand dollar",
    "numeric": "554",
    "exponent": 2
  },
  {
    "value": "OMR",
    "label": "Omani rial",
    "numeric": "512",
    "exponent": 3
  },
  {
    "value": "PAB",
    "label": "Panamanian balboa",
    "numeric": "590",
    "exponent": 2
  },
  {
    "value": "PEN",
    "label": "Peruvian Sol",
    "numeric": "604",
    "exponent": 2
  },
  {
    "value": "PGK",
    "label": "Papua New Guinean kina",
    "numeric": "598",
    "exponent": 2
  },
  {
    "value": "PHP",
    "label": "Philippine peso",
    "numeric": "608",
    "exponent": 2
  },
  {
    "value": "PKR",
    "label": "Pakistani rupee",
    "numeric": "586",
    "exponent": 2
  },
  {
    "value": "PLN",
    "label": "Polish złoty",
    "numeric": "985",
    "funding": true,
    "exponent": 2
  },
  {
    "value": "PYG",
    "label": "Paraguayan guaraní",
    "numeric": "600",
    "exponent": 0
  },
  {
    "value": "QAR",
    "label": "Qatari riyal",
    "numeric": "634",
    "exponent": 2
  },
  {
    "value": "RON",
    "label": "Romanian leu",
    "numeric": "946",
    "funding": true,
    "exponent": 2
  },
  {
    "value": "RSD",
    "label": "Serbian dinar",
    "numeric": "941",
    "exponent": 2
  },
  {
    "value": "RUB",
    "label": "Russian ruble",
    "numeric": "643",
    "exponent": 2
  },
  {
    "value": "RWF",
    "label": "Rwandan franc",
    "numeric": "646",
    "exponent": 0
  },
  {
    "value": "SAR",
    "label": "Saudi riyal",
    "numeric": "682",
    "exponent": 2
  },
  {
    "value": "SBD",
    "label": "Solomon Islands dollar",
    "numeric": "090",
    "exponent": 2
  },
  {
    "value": "SCR",
    "label": "Seychelles rupee",
    "numeric": "690",
    "exponent": 2
  },
  {
    "value": "SDG",
    "label": "Sudanese pound",
    "numeric": "938",
    "exponent": 2
  },
  {
    "value": "SEK",
    "label": "Swedish krona",
    "numeric": "752",
    "funding": true,
    "exponent": 2
  },
  {
    "value": "SGD",
    "label": "Singapore dollar",
    "numeric": "702",
    "exponent": 2
  },
  {
    "value": "SHP",
    "label": "Saint Helena pound",
    "numeric": "654",
    "exponent": 2
  },
  {
    "value": "SLE",
    "label": "Sierra Leonean leone",
    "numeric": "925",
    "exponent": "2"
  },
  {
    "value": "SLL",
    "label": "Sierra Leonean leone",
    "numeric": "694",
    "exponent": 2,
    "deprecated": true
  },
  {
    "value": "SOS",
    "label": "Somali shilling",
    "numeric": "706",
    "exponent": 2
  },
  {
    "value": "SRD",
    "label": "Surinamese dollar",
    "numeric": "968",
    "exponent": 2
  },
  {
    "value": "STN",
    "label": "São Tomé and Príncipe dobra",
    "numeric": "930",
    "exponent": 2
  },
  {
    "value": "STD",
    "label": "São Tomé and Príncipe dobra",
    "numeric": "678",
    "exponent": 2,
    "deprecated": true
  },
  {
    "value": "SYP",
    "label": "Syrian pound",
    "numeric": "760",
    "exponent": 2
  },
  {
    "value": "SZL",
    "label": "Swazi lilangeni",
    "numeric": "748",
    "exponent": 2
  },
  {
    "value": "THB",
    "label": "Thai baht",
    "numeric": "764",
    "exponent": 2
  },
  {
    "value": "TJS",
    "label": "Tajikistani somoni",
    "numeric": "972",
    "exponent": 2
  },
  {
    "value": "TMT",
    "label": "Turkmenistani manat",
    "numeric": "934",
    "exponent": 2
  },
  {
    "value": "TND",
    "label": "Tunisian dinar",
    "numeric": "788",
    "exponent": 3
  },
  {
    "value": "TOP",
    "label": "Tongan paʻanga",
    "numeric": "776",
    "exponent": 2
  },
  {
    "value": "TRY",
    "label": "Turkish lira",
    "numeric": "949",
    "exponent": 2
  },
  {
    "value": "TTD",
    "label": "Trinidad and Tobago dollar",
    "numeric": "780",
    "exponent": 2
  },
  {
    "value": "TWD",
    "label": "New Taiwan dollar",
    "numeric": "901",
    "exponent": 2
  },
  {
    "value": "TZS",
    "label": "Tanzanian shilling",
    "numeric": "834",
    "exponent": 2
  },
  {
    "value": "UAH",
    "label": "Ukrainian hryvnia",
    "numeric": "980",
    "exponent": 2
  },
  {
    "value": "UGX",
    "label": "Ugandan shilling",
    "numeric": "800",
    "exponent": 0
  },
  {
    "value": "USD",
    "label": "United States dollar - $",
    "numeric": "840",
    "funding": true,
    "exponent": 2
  },
  {
    "value": "UYU",
    "label": "Uruguayan peso",
    "numeric": "858",
    "exponent": 2
  },
  {
    "value": "UZS",
    "label": "Uzbekistan som",
    "numeric": "860",
    "exponent": 2
  },
  {
    "value": "VEF",
    "label": "Venezuelan bolívar",
    "numeric": "937",
    "exponent": 2
  },
  {
    "value": "VES",
    "label": "Venezuelan bolívar soberano",
    "numeric": "928",
    "exponent": 2,
    "deprecated": true
  },
  {
    "value": "VND",
    "label": "Vietnamese dong",
    "numeric": "704",
    "exponent": 0
  },
  {
    "value": "VUV",
    "label": "Vanuatu vatu",
    "numeric": "548",
    "exponent": 0
  },
  {
    "value": "WST",
    "label": "Samoan tala",
    "numeric": "882",
    "exponent": 2
  },
  {
    "value": "XAF",
    "label": "CFA franc BEAC",
    "numeric": "950",
    "exponent": 0
  },
  {
    "value": "XCD",
    "label": "East Caribbean dollar",
    "numeric": "951",
    "exponent": 2
  },
  {
    "value": "XOF",
    "label": "CFA franc BCEAO",
    "numeric": "952",
    "exponent": 0
  },
  {
    "value": "XPF",
    "label": "CFP franc",
    "numeric": "953",
    "exponent": 0
  },
  {
    "value": "YER",
    "label": "Yemeni rial",
    "numeric": "886",
    "exponent": 2
  },
  {
    "value": "ZAR",
    "label": "South African rand",
    "numeric": "710",
    "exponent": 2
  },
  {
    "value": "ZMK",
    "label": "Zambian kwacha",
    "numeric": "894",
    "exponent": 2
  },
  {
    "value": "ZWL",
    "label": "Zimbabwean dollar",
    "numeric": "716",
    "exponent": 2
  }
]
